import Axios from "axios";
import React from 'react';
import { connect } from "react-redux";
import { logOut, registerRoutes } from "../../../func";
import moment from 'moment';
import { AutoComplete, IconButton, Input } from "../../../layout/form";
import M from "@materializecss/materialize";

class TrackingTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        trackingList: null,
        employeeList: null,
        employeeListAsObject: null,
        values: this.initValues(),
    })

    initValues = () => ({
        orderHeaderId: this.props.orderHeaderId,
        trackingNumber: '',
        weightInLbs: undefined,
        timeStamp: moment().format('YYYY-MM-DDTHH:mm'),
        preparedByName: '',
        receivedByName: `${this.employeeData.firstName} ${this.employeeData.lastName}`
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.networkCalls.getTracking();
        this.networkCalls.getEmployeeList();
        this.initTooltips();
    }

    initTooltips = () => setTimeout(() => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 }), 100)
    removeTooltips = () => document.querySelectorAll('.tooltipped').forEach(el => M.Tooltip.getInstance(el)?.destroy());

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))
    networkCalls = {
        getTracking: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/order/tracking/incoming/read/all/by/orderheaderid/deep', {
                        params: {
                            ...this.authData,
                            orderHeaderId: this.props.orderHeaderId
                        }
                    })
                        .then(result => this.setState({ trackingList: result.data }, this.initTooltips))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        getEmployeeList: {
            func: () => {
                Axios.get('/api/v1/employee/read/active', {
                    params: this.authData
                })
                    .then(result => {
                        const employeeList = result.data;
                        const employeeListAsObject = {};

                        employeeList.forEach(e => employeeListAsObject[`${e.firstName} ${e.lastName}`] = null)

                        this.setState({ employeeList, employeeListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        createTracking: {
            func: () => {

                const preparedById = this.state.employeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#preparedByName').value)[0]?.id;
                const receivedById = this.state.employeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#receivedByName').value)[0]?.id;

                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/order/tracking/incoming/create/one', {
                        ...this.authData,
                        orderHeaderId: this.props.orderHeaderId,
                        trackingNumber: this.state.values.trackingNumber,
                        preparedById,
                        receivedById,
                        weightInLbs: this.state.values.weightInLbs || null,
                        timeStamp: moment(this.state.values.timeStamp).format('YYYY-MM-DD HH:mm'),
                        timezone: Intl.DateTimeFormat().resolvedOptions().timezone,
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.removeTooltips();
                            this.setState({ values: this.initValues() }, () => {
                                document.querySelector('#trackingNumber')?.focus();
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        },
        updateTracking: {
            func: () => {
                const preparedById = this.state.employeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#preparedByName').value)[0]?.id || null;
                const receivedById = this.state.employeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#receivedByName').value)[0]?.id;

                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/order/tracking/incoming/update/one', {
                        ...this.authData,
                        id: this.state.values.id,
                        trackingNumber: this.state.values.trackingNumber,
                        preparedById,
                        receivedById,
                        weightInLbs: this.state.values.weightInLbs || null,
                        timeStamp: moment(this.state.values.timeStamp).format('YYYY-MM-DD HH:mm'),
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.removeTooltips();
                            this.setState({ editing: false, values: this.initValues() }, this.networkCalls.getTracking);
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ updating: false }))
                })
            },
            type: 'u'
        },
        deleteTracking: {
            func: id => {
                if (window.confirm(`Are you sure you'd like to delete this tracking information?`))
                    this.setState({ deleting: true }, () => {
                        Axios.post('/api/v1/order/tracking/incoming/delete', {
                            ...this.authData,
                            id
                        })
                            .then(result => {
                                this.props.dispatch(result.data);
                                this.removeTooltips();
                                this.networkCalls.getTracking();
                            })
                            .catch(logOut)
                            .finally(() => this.setState({ deleting: false }))
                    })
            },
            type: 'd'
        }
    }

    createTracking = () => {
        this.removeTooltips();
        this.setState({ creating: true, values: this.initValues() }, () => {
            this.initTooltips();
            document.querySelector('#trackingNumber')?.focus();
        });
    }

    cancelCreate = () => {
        this.removeTooltips();
        this.setState({
            creating: false,
            editing: false,
            values: this.initValues()
        }, this.networkCalls.getTracking
        )
    }

    editTracking = t => {
        this.removeTooltips();
        this.setState({
            editing: true,
            values: {
                id: t.id,
                trackingNumber: t.trackingNumber,
                preparedByName: `${t.preparedBy?.firstName || ''} ${t.preparedBy?.lastName || ''}`,
                receivedByName: `${t.receivedBy.firstName} ${t.receivedBy.lastName}`,
                timeStamp: moment(t.timeStamp).format('YYYY-MM-DDTHH:mm'),
                weightInLbs: t.weightInLbs
            }
        }, this.initTooltips)
    }

    render = () => {
        if (this.state.loading)
            return (
                <div style={{ padding: '1vw' }}>
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                </div>
            )
        if (this.state.creating || this.state.editing)
            return (
                <div style={{ padding: '1vw' }}>
                    <div className="row">
                        <div className="col s4 m3 l2">
                            <table>
                                <tbody>
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Tracking #</td></tr>
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Prepared By</td></tr>
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Received By</td></tr>
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Weight (lbs)</td></tr>
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Date Received</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col s8 m9 l10">
                            <table>
                                <tbody>
                                    <Input id="trackingNumber" type="text" value={this.state.values.trackingNumber} onChange={this.onChange} col="s12 m4" />
                                    <AutoComplete id="preparedByName" startingValue={this.state.values.preparedByName} col="s12 m7" data={this.state.employeeListAsObject} />
                                    <AutoComplete id="receivedByName" startingValue={this.state.values.receivedByName} col="s12 m7" data={this.state.employeeListAsObject} />
                                    <Input id="weightInLbs" type="number" value={this.state.values.weightInLbs} onChange={this.onChange} col="s6 m2" />
                                    <Input id="timeStamp" type="datetime-local" value={this.state.values.timeStamp} onChange={this.onChange} col="s8 m4" />
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.editing ?
                            <IconButton icon="upload" tooltip="Update Tracking Info" onClick={this.networkCalls.updateTracking} disabled={this.state.updating} />

                            :
                            <IconButton icon="save" tooltip="Save Tracking Info" onClick={this.networkCalls.createTracking} disabled={this.state.saving} />
                        }
                        <IconButton icon="close" tooltip="Cancel" onClick={this.cancelCreate} disabled={this.state.saving || this.state.updating} />
                    </div>
                </div>
            )
        else
            return (
                <div style={{ padding: '1vw' }}>
                    <div className="row">
                        <IconButton icon="add" tooltip="Add Tracking" onClick={this.createTracking} disabled={!this.state.employeeListAsObject} />
                    </div>
                    <div className="row">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ padding: '2px' }}>Tracking #</th>
                                    <th style={{ padding: '2px' }}>Prepared By</th>
                                    <th style={{ padding: '2px' }}>Received By</th>
                                    <th style={{ padding: '2px' }}>Weight (lbs)</th>
                                    <th style={{ padding: '2px' }}>Date Received</th>
                                    <th style={{ padding: '2px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.trackingList?.map(t => (
                                    <tr key={t.id}>
                                        <td style={{ padding: '2px' }}>{t.trackingNumber}</td>
                                        <td style={{ padding: '2px' }}>{t.preparedBy?.firstName} {t.preparedBy?.lastName}</td>
                                        <td style={{ padding: '2px' }}>{t.receivedBy?.firstName} {t.receivedBy?.lastName}</td>
                                        <td style={{ padding: '2px' }}>{t.weightInLbs}</td>
                                        <td style={{ padding: '2px' }}>{moment(t.timeStamp).format('MM/DD/YY h:mm a')}</td>
                                        <td style={{ padding: '2px' }}>
                                            <IconButton icon="edit" tooltip="Edit Tracking" onClick={() => this.editTracking(t)} disabled={this.state.deleting} />
                                            <IconButton icon="delete" tooltip="Delete Tracking" onClick={() => this.networkCalls.deleteTracking(t.id)} disabled={this.state.deleting} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
    }
}

export default connect()(TrackingTab);