import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../func';

class InvoiceTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.id = this.props.match.params.id;
        this.state = this.initState();
    }

    initState = () => ({
        templateList: null,
        pharmacy: null,
        values: this.initValues()
    })

    initValues = () => ({
        code: '',
        description: '',
        calcType: '',
        rate: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getPharmacy();
        this.getPharmacyInvoiceTemplate();
        M.FormSelect.init(document.querySelectorAll('select'))
    }

    networkCalls = {
        getPharmacy: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/one/by/id/deep', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => this.setState({ pharmacy: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        getPharmacyInvoiceTemplate: {
            func: () => {
                Axios.get('/api/v1/invoice/read/pharmacyinvoicetemplate', {
                    params: {
                        ...this.authData,
                        pharmacyId: this.id
                    }
                })
                    .then(result => this.setState({ templateList: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        },
        addRow: {
            func: () => {
                Axios.post('/api/v1/invoice/create/pharmacyinvoicetemplate', {
                    ...this.authData,
                    ...this.state.values,
                    pharmacyId: this.id
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.getPharmacyInvoiceTemplate();
                        this.setState({ values: this.initValues() }, () => {
                            M.FormSelect.init(document.querySelectorAll('select'));
                            M.updateTextFields();
                        })
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        deleteRow: {
            func: id => {
                Axios.post('/api/v1/invoice/delete/pharmacyinvoicetemplate', {
                    ...this.authData,
                    id
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.getPharmacyInvoiceTemplate();
                    })
            },
            type: 'd'
        }
    }

    getPharmacy = () => this.networkCalls.getPharmacy();
    getPharmacyInvoiceTemplate = () => this.networkCalls.getPharmacyInvoiceTemplate();
    addRow = e => { e?.preventDefault(); this.networkCalls.addRow(); }
    deleteRow = id => this.networkCalls.deleteRow(id);

    getCodes = () => ([
        'Basic Service Fee',
        'Minimum Service Fee',
        'Regular Destruction',
        'RCRA Destruction',
        'Shipping',
        'Other'
    ])

    getCalcTypes = () => ([
        'Flat Fee',
        'Percent of Estimated Credits',
        'Percent of Actual Credits',
        'Number of Boxes',
        'Number of Pallets',
        'Weight of Boxes',
        'Weight of Regular Destruction',
        'Weight of RCRA'
    ])

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/pharmacy">Pharmacy Overview</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/pharmacy/${this.id}`}>{this.state.pharmacy ? this.state.pharmacy.name : 'Pharmacy'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Invoice Template</span>
                </div>
            </div>
            <div className="row">
                <h5 className="orange-text">{this.state.pharmacy ? `${this.state.pharmacy.name} Invoice Template` : 'Invoice Template'}</h5>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="input-field col s12 m2">
                                    <select id="code" onChange={this.onChange} value={this.state.values.code}>
                                        <option value={''}>Select A Code</option>
                                        {this.getCodes().map(c => <option key={c} value={c}>{c}</option>)}
                                    </select>
                                    <label htmlFor="code">Code</label>
                                </div>
                                <div className="input-field col s12 m4">
                                    <input id="description" onChange={this.onChange} type="text" value={this.state.values.description} />
                                    <label htmlFor="description">Description</label>
                                </div>
                                <div className="input-field col s12 m3">
                                    <select id="calcType" onChange={this.onChange} value={this.state.values.calcType}>
                                        <option value={''}>Select A Calc Type</option>
                                        {this.getCalcTypes().map(ct => <option key={ct} value={ct}>{ct}</option>)}
                                    </select>
                                </div>
                                <div className="input-field col s12 m1">
                                    <input id="rate" type="number" onChange={this.onChange} value={this.state.values.rate} />
                                    <label htmlFor="rate">Rate</label>
                                </div>
                                <div className="input-field col s12 m2">
                                    <a href="/" className="btn-small blue white-text col s12 waves-effect waves-light" onClick={this.addRow}>Add Row</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <table className="highlight">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Description</th>
                                        <th>Calculation Type</th>
                                        <th>Rate</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.templateList && this.state.templateList.map(t => (
                                        <tr key={`tlist-${t.id}`}>
                                            <td style={{ padding: '2px' }}>{t.code}</td>
                                            <td style={{ padding: '2px' }}>{t.description}</td>
                                            <td style={{ padding: '2px' }}>{t.calcType}</td>
                                            <td style={{ padding: '2px' }}>{t.rate}</td>
                                            <td style={{ padding: '2px' }}><i className="material-icons white red-text" style={{ cursor: 'pointer' }} onClick={() => this.deleteRow(t.id)}>delete</i></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(InvoiceTemplate);