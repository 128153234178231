import React from 'react';
import { Switch, Route } from 'react-router';
import BottleLookup from './bottleLookup';

const UtilityRouter = props => (
    <Switch>
        <Route exact path="/utility/bottlelookup" component={BottleLookup} />
    </Switch>
)

export default UtilityRouter;