import { connect } from 'react-redux';
import { useState, useEffect, useRef, useCallback } from 'react';
import Axios from 'axios';
import { logOut, registerRoutes } from '../../../func';
import M from '@materializecss/materialize';
const pageId = crypto.randomUUID();


const AddOrderDetailForm = props => {
    const {
        passRedbookToParent,
        passFormValuesToParent,
        orderHeaderId,
        isVa,
        earliestReturnDate,
        isRecall,
        dispatch,
        policy,
        path,
        order
    } = props;

    const redbookFromParent = props.redbook;

    const initFormValues = useCallback(() => ({
        ndc: '',
        fullPackage: '',
        partialPackage: '',
        lotNumber: '',
        expiration: '',
        nonReturnableReasonCodeId: order?.returnServiceDeclined ? '99' : '',
        indateBarcode: '',
        vaDestructionNumber: '',
    }), [order])

    const [formValues, setFormValues] = useState(initFormValues())

    const initRedbook = () => ({
        list: null,
        match: undefined
    })
    const [redbook, setRedbook] = useState(initRedbook())

    const [saving, setSaving] = useState(false);
    const [networkCalls] = useState(registerRoutes({
        searchRedbook: {
            func: (ndc, abortController) => {
                Axios.get('/api/v1/redbook/read/search/by/ndc', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        ndc
                    },
                    signal: abortController?.signal
                })
                    .then(result => setRedbook(p => ({ ...p, list: result.data })))
                    .catch(logOut)
            },
            type: 'r'
        },
        createOrderDetail: {
            func: (formValues, orderHeaderId, earliestReturnDate, isRecall) => {
                setSaving(true);
                Axios.post('/api/v2/order/detail/create/one', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    ...formValues,
                    orderHeaderId,
                    earliestReturnDate,
                    isRecall
                })
                    .then(result => {
                        dispatch(result.data);
                        setFormValues(ref.current.initFormValues());
                        setRedbook(initRedbook());
                        ref.current.firstSevenNdcDigits = '';
                        document.querySelector(`#ndc-${pageId}`)?.focus();
                    })
                    .catch(logOut)
                    .finally(() => setSaving(false))
            },
            type: 'c'
        }
    }, path))

    const ref = useRef({
        firstSevenNdcDigits: '',
        abortController: null,
        redbookSuggestions: null,
        earliestReturnDate: undefined,
        initFormValues: initFormValues
    })

    const onChange = e => setFormValues(p => ({ ...p, [e.target.id.split('-')[0]]: e.target.value }))

    useEffect(() => {
        if (redbookFromParent)
            setFormValues(p => ({ ...p, ndc: redbookFromParent.ndc }))
    }, [redbookFromParent])

    useEffect(() => {
        const enterListener = e => {
            if (e.keyCode === 13) {
                const elements = Array.from(document.querySelectorAll(`.od-input-${pageId}`));
                const index = elements.indexOf(document.activeElement);

                if (index !== -1 && index !== elements.length - 1)
                    elements[index + 1].focus();
            }
        }

        document.addEventListener('keyup', enterListener);
        return () => document.removeEventListener('keyup', enterListener);
    }, [])

    useEffect(() => {
        if (redbook.list) {
            const match = redbook.list.find(row => row.ndc === formValues.ndc);
            setRedbook(p => ({ ...p, match }))

            if (passRedbookToParent)
                passRedbookToParent(match)
        }

        if (formValues.ndc?.length >= 7 && formValues.ndc.substring(0, 7) !== ref.current.firstSevenNdcDigits) {
            ref.current.abortController?.abort();
            ref.current.firstSevenNdcDigits = formValues.ndc.substring(0, 7);
            ref.current.abortController = new AbortController();

            networkCalls.searchRedbook(formValues.ndc, ref.current.abortController)
        }

        if (formValues.ndc?.length >= 8 && redbook.list && redbook.list.length)
            ref.current.redbookSuggestions = redbook.list.filter(rb => rb.ndc.substring(0, 8) === formValues.ndc.substring(0, 8))
        else
            ref.current.redbookSuggestions = null;

    }, [formValues.ndc, redbook.list, passRedbookToParent, networkCalls])

    const selectSuggestion = ndc => {
        destroyTooltips();
        initTooltips();
        setFormValues(p => ({ ...p, ndc }))
    }

    const initTooltips = () => {
        document.querySelectorAll('.tooltipped').forEach(tt => M.Tooltip.init(tt, {
            enterDelay: 0,
            exitDelay: 0,
            inDuration: 0,
            outDuration: 0,
            unsafeHTML: tt.getAttribute('data-info')
        }))
    }

    const destroyTooltips = () => document.querySelectorAll('.tooltipped').forEach(tt => M.Tooltip.getInstance(tt)?.destroy())

    useEffect(() => {
        setTimeout(initTooltips, 250);
        return () => destroyTooltips();
    })

    useEffect(() => passFormValuesToParent(formValues), [formValues, passFormValuesToParent])

    const getSubmitButton = () => {
        let disabled = saving ? true : false;
        let color = 'blue';
        let medType = 'Med';
        let onClick = e => { e.preventDefault(); networkCalls.createOrderDetail(formValues, orderHeaderId, earliestReturnDate, isRecall) }

        if (typeof policy === 'string') {
            disabled = true;
            onClick = e => e.preventDefault();
        }
        else if (parseInt(formValues.nonReturnableReasonCodeId) === 5) {
            medType = 'Indate';
            color = 'teal';

            if (!redbook.match || !formValues.indateBarcode) {
                disabled = true;
                onClick = e => e.preventDefault();
            }
        }
        else {
            if (!redbook.match || (!formValues.fullPackage && !formValues.partialPackage)) {
                disabled = true;
                onClick = e => e.preventDefault();
            }

            if (formValues.nonReturnableReasonCodeId) {
                color = 'orange';
                medType = 'Trash';
            }
            else if (redbook.match?.deaClass?.toLowerCase() !== 'r') {
                color = 'red';
                medType = 'control';
            }
            else if (isRecall) {
                color = 'purple';
                medType = 'recall'
            }
            else {
                color = 'blue';
                medType = 'med';
            }
        }

        return <a href="/" disabled={disabled} className={`btn-small ${color} white-text col s12 od-input-${pageId}`} onClick={onClick}>Add {medType}</a>
    }

    useEffect(() => setFormValues(p => ({ ...p, returnPolicyId: policy?.id })), [policy])

    useEffect(() => {
        setFormValues(initFormValues())
        ref.current.initFormValues = initFormValues
    }, [initFormValues])

    return (
        <div>
            <div id={`add-order-detail-form-${pageId}`} className="row">
                <div className="card">
                    <div className="card-content">
                        <ODInput id={`ndc-${pageId}`} label="NDC" type="text" onChange={onChange} value={formValues.ndc} pageId={pageId} />
                        <ODInput id={`fullPackage-${pageId}`} label="Full Packages" type="text" onChange={onChange} value={formValues.fullPackage} pageId={pageId} />
                        <ODInput id={`partialPackage-${pageId}`} label="Partial Count" type="text" onChange={onChange} value={formValues.partialPackage} pageId={pageId} />
                        <ODInput id={`lotNumber-${pageId}`} label="Lot Number" type="text" onChange={onChange} value={formValues.lotNumber} pageId={pageId} />
                        <ODInput id={`expiration-${pageId}`} label="Expiration Date" type="date" onChange={onChange} value={formValues.expiration} pageId={pageId} />
                        {!isRecall ?
                            <ODInput id={`nonReturnableReasonCodeId-${pageId}`} disabled={isRecall || order?.returnServiceDeclined} label="Reason" type="text" onChange={onChange} value={formValues.nonReturnableReasonCodeId} pageId={pageId} />
                            : null}
                        {isVa ?
                            <ODInput id={`vaDestructionNumber-${pageId}`} label="VA Destruction #" type="text" onChange={onChange} value={formValues.vaDestructionNumber} pageId={pageId} />
                            : null}
                        {parseInt(formValues.nonReturnableReasonCodeId) === 5 ?
                            <ODInput id={`indateBarcode-${pageId}`} label="Indate Barcode" type="text" onChange={onChange} value={formValues.indateBarcode} pageId={pageId} />
                            : null}
                        <div className="row">
                            <div className="input-field">
                                <div className="col s12">
                                    {getSubmitButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ref.current.redbookSuggestions && ref.current.redbookSuggestions.length > 1 ?
                <div className="row" style={{ marginBottom: '0px' }}>
                    <div
                        className="card"
                        style={{
                            height: `CALC(95vh - ${50 + document.querySelector(`#add-order-detail-form-${pageId}`)?.clientHeight || 0}px)`,
                            margin: '0px',
                            overflow: 'hidden',
                            overflowY: 'auto'
                        }}
                    >
                        <div className="card-content" style={{ maxHeight: '99%', padding: '8px', overflowY: 'auto' }}>
                            <table className="highlight">
                                <thead>
                                    <tr>
                                        <th style={{ padding: '2px' }}>NDC</th>
                                        <th style={{ padding: '2px' }}>Pk Size</th>
                                    </tr>
                                </thead>
                                <tbody style={{ maxHeight: '100%', overflowY: 'auto' }}>
                                    {ref.current.redbookSuggestions.map(rb => {
                                        if (rb.ndc === formValues.ndc)
                                            return null;
                                        else
                                            return <tr
                                                key={rb.id}
                                                onClick={() => selectSuggestion(rb.ndc)}
                                                style={{ padding: '2px', cursor: 'pointer', whiteSpace: 'nowrap' }}
                                                className="tooltipped multiline"
                                                data-position="right"
                                                data-info={`<span class="left" style="font-weight: 500">${rb.medName}</span><br />
                                                        <span class="left">NDC: ${rb.ndc}</span><br />
                                                        <span class="left">Pack Size: ${rb.packSize}</span><br />
                                                        <span class="left">Unit: ${rb.unit?.unit || ''}</span><br />
                                                        <span class="left">Liquid Size: ${rb.liquidSize || ''}`}
                                            >
                                                <td style={{ padding: '2px' }}>{rb.ndc}</td>
                                                <td style={{ padding: '2px' }}>{rb.packSize}</td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}

export default connect()(AddOrderDetailForm);


const ODInput = props => (
    <div style={{ marginBottom: '18px', width: '100%' }}>
        <input
            className={`browser-default od-input-${props.pageId}`}
            id={props.id}
            type={props.type}
            onChange={props.onChange}
            value={props.value}
            style={{
                width: '100%',
                border: 'none',
                borderBottom: '1px solid black',
                padding: '4px',
            }}
            disabled={props.disabled ? 'disabled' : ''}
        />
        <label htmlFor={props.id} style={{ display: 'block' }}>{props.label}</label>
    </div>
)