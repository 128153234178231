import Axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { logOut, openSideNav, registerRoutes } from "../../../func";
import Table from './Table';
import M from '@materializecss/materialize';
const componentId = '_' + crypto.randomUUID()

const CustomQuery = props => {
    const { path } = useRouteMatch();

    const [loading, setLoading] = useState(false);
    const [tableList, setTableList] = useState(null);
    const [selectedTableName, setSelectedTableName] = useState('');
    const [columns, setColumns] = useState(null);
    const [possibleJoins, setPossibleJoins] = useState([]);
    const [filters, setFilters] = useState({});
    const [joins, setJoins] = useState([])

    const networkCalls = registerRoutes({
        getTables: {
            func: () => {
                setLoading(true);

                Axios.get('/api/v2/websiteadministration/read/all/tables', {
                    params: JSON.parse(localStorage.getItem('auth_data'))
                })
                    .then(result => {
                        setSelectedTableName(result.data[0].Tables_in_orx_blank)
                        setTableList(result.data?.map(t => ({ tableName: t.Tables_in_orx_blank })))
                    })
                    .catch(logOut)
                    .finally(() => setLoading(false))
            },
            type: 'r'
        },
        getColumns: {
            func: tableName => new Promise((resolve, reject) => {
                if (!tableName) { return }

                Axios.get('/api/v2/websiteadministration/read/all/columns/by/tablename', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        tableName
                    }
                })
                    .then(result => resolve(result.data))
                    .catch(logOut);
            }),
            type: 'r'
        }
    }, path)

    useEffect(() => {
        networkCalls.getTables();
    }, [])

    useEffect(() => {
        const filters = {};
        columns?.forEach(col => filters[col.columnName] = '')
        setFilters(filters);
        setPossibleJoins(columns?.filter(row => row.referencedTableName));
        setJoins([])
    }, [columns])

    useEffect(() => { M.AutoInit() })

    const onChange = e => setFilters(p => ({ ...p, [e.target.id]: e.target.value }))

    const selectTable = tableName => {
        setSelectedTableName(tableName);
    }

    const toggleJoin = async join => {
        const index = joins.findIndex(j => j.columnName === join.columnName && j.referencedTableName === join.referencedTableName);
        const newJoins = [...joins];

        if (index !== -1)
            newJoins.splice(index, 1);
        else {
            const columns = await networkCalls.getColumns(join.referencedTableName);
            columns.sort((a, b) => a.ordinalPosition - b.ordinalPosition);
            newJoins.push({ ...join, type: 'INNER', columns })
        }
        setJoins([...newJoins]);
    }

    return (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Custom Query</span>
                </div>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection" style={{ maxHeight: 'CALC(50vh - 100px)', overflowY: 'auto' }}>
                        {tableList?.map(t => (
                            <li
                                className={`collection-item ${selectedTableName === t.tableName ? 'blue-text' : ''}`}
                                style={{ padding: '2px 8px', cursor: 'pointer' }}
                                onClick={() => selectTable(t.tableName)}
                            >{t.tableName}
                                {selectedTableName === t.tableName && possibleJoins?.length ?
                                    <ul className="collection">
                                        {possibleJoins.map(pj => (
                                            <li
                                                className={`collection-item ${joins.find(j => j.columnName === pj.columnName && j.referencedTableName === pj.referencedTableName) ? 'orange-text' : 'black-text'}`}
                                                style={{ padding: '2px 12px', cursor: 'pointer' }}
                                                onClick={() => toggleJoin(pj)}
                                            >{pj.referencedTableName} - {pj.columnName}</li>
                                        ))}
                                    </ul>
                                    : null}
                            </li>
                        ))}
                    </ul>
                    <ul style={{ maxHeight: 'CALC(50vh - 18px)', overflowY: 'auto', padding: '0px 20px', border: '1px solid grey' }}>
                        <h6 className="center">Filter Columns</h6>
                        {columns?.map(col => (
                            <div>
                                <label>{col.columnName}</label>
                                <input
                                    className={`browser-default search${componentId}`}
                                    style={{ marginBottom: '8px', width: '100%' }}
                                    id={col.columnName}
                                    type="text"
                                    value={filters[col.columnName]}
                                    onChange={onChange}
                                />
                            </div>
                        ))}
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    <Table
                        tableName={selectedTableName}
                        setParentColumns={setColumns}
                        filters={filters}
                        joins={joins}
                    />
                </div>
            </div>

        </div>
    )
}

export default CustomQuery;