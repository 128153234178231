import React from 'react';
import M from '@materializecss/materialize';
import { registerRoutes, logOut, } from '../../../func';
import Axios from 'axios';
import { connect } from 'react-redux';

class PagesTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        selectedPage: null,
        pageContextMenu: {
            clientX: 0,
            clientY: 0,
            show: false
        },
        selectedEmployee: null,
        employeeContextMenu: {
            clientX: 0,
            clientY: 0,
            show: false
        },
        createValues: this.initCreateValues(),
        creating: false,
        editValues: this.initEditValues(),
        editing: false,
        editEmployeeValues: this.initEditEmployeeValues(),
        editingEmployee: false
    })

    initCreateValues = () => ({
        route: '',
        notes: ''
    })

    initEditValues = () => ({
        id: -1,
        route: undefined,
        notes: undefined
    })

    initEditEmployeeValues = () => ({
        employeeId: -1,
        action: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls);
        this.initListeners();
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
    }

    componentWillUnmount = () => {
        this.removeListeners();
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt) { tt.destroy() }
        })
    }

    networkCalls = {
        postNewPage: {
            func: () => {
                Axios.post('/api/v1/website/administration/create/one/orxtoolspage', {
                    ...this.authData,
                    route: this.state.createValues.route,
                    notes: this.state.createValues.notes
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.setState({ creating: false, createValues: this.initCreateValues() }, this.props.getPages)
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        postDeletePage: {
            func: () => {
                if (window.confirm(`Delete ${this.state.selectedPage.route}?`))
                    Axios.post('/api/v1/website/administration/delete/one/orxtoolspage', {
                        ...this.authData,
                        id: this.state.selectedPage.id
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.setState({ selectedPage: null }, this.props.getPages)
                        })
                        .catch(logOut);
            },
            type: 'd'
        },
        postEditPage: {
            func: () => {
                const { id, route, notes } = this.state.editValues;

                Axios.post('/api/v1/website/administration/update/one/orxtoolspage', {
                    ...this.authData,
                    id,
                    route,
                    notes
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.setState({ editing: false, selectedPage: null }, this.props.getPages)
                    })
                    .catch(logOut)
            },
            type: 'u'
        },
        postRemoveEmployeePermission: {
            func: () => {
                if (window.confirm(`Remove access to page "${this.state.selectedPage.route}" for user ${this.state.selectedEmployee.firstName} ${this.state.selectedEmployee.lastName}?`)) {
                    const employeeId = this.state.selectedEmployee.id;
                    const pageId = this.state.selectedPage.id;

                    Axios.post('/api/v1/website/administration/delete/one/permission', {
                        ...this.authData,
                        employeeId,
                        pageId
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.setState(p => ({ selectedPage: null, selectedEmployee: null, employeeContextMenu: { clientX: 0, clientY: 0, show: false } }), this.props.getPages)
                        })
                        .catch(logOut)
                }
            },
            type: 'u'
        },
        postEmployeePermissionUpdate: {
            func: () => {
                const { id, action } = this.state.editEmployeeValues;
                const pageId = this.state.selectedPage.id;
                Axios.post('/api/v1/website/administration/update/one/permission', {
                    ...this.authData,
                    employeeId: id,
                    action,
                    pageId
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.setState({
                            selectedPage: null,
                            selectedEmployee: null,
                            editingEmployee: false,
                            editEmployeeValues: this.initEditEmployeeValues()
                        }, this.props.getPages)
                    })
                    .catch(logOut)
            },
            type: 'u'
        },
        postCreateEmployeePagePermission: {
            func: () => {
                const employeeName = document.querySelector('#addEmployeeId').value;
                const employeeId = this.props.employeeList.find(e => `${e.firstName} ${e.lastName}` === employeeName).id;
                const pageId = this.state.selectedPage.id

                Axios.post('/api/v1/website/administration/create/one/permission', {
                    ...this.authData,
                    employeeId,
                    pageId
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.setState({ selectedPage: null }, this.props.getPages)
                    })
                    .catch(logOut)
            },
            type: 'c'
        }
    }

    initListeners = () => {
        document.addEventListener('mouseup', this.clickAwayListener)
    }

    removeListeners = () => {
        document.removeEventListener('mouseup', this.clickAwayListener);
    }

    clickAwayListener = e => {
        if (e.target.id !== 'pages-context-menu' && this.state.pageContextMenu?.show)
            this.setState(p => ({
                ...p,
                pageContextMenu: {
                    ...p.pageContextMenu,
                    show: false
                }
            }))

        if (e.target.id !== 'employee-context-menu' && this.state.employeeContextMenu?.show)
            this.setState(p => ({
                ...p,
                selectedEmployee: null,
                employeeContextMenu: {
                    ...p.employeeContextMenu,
                    show: false
                }
            }))
    }

    createChange = e => {
        const { id, value } = e.target;
        this.setState(p => ({
            createValues: {
                ...p.createValues,
                [id.split('-')[1]]: value
            }
        }))
    }

    editChange = e => {
        const { id, value } = e.target;
        this.setState(p => ({
            editValues: {
                ...p.editValues,
                [id.split('-')[1]]: value
            }
        }))
    }

    pageRowClick = (selectedPage) => this.setState({
        selectedPage,
        editingEmployee: false,
        editEmployeeValues: this.initEditEmployeeValues()
    }, () => {
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
        M.Autocomplete.init(document.querySelectorAll('#addEmployeeId'), { data: this.props.employeeListAsObject })
    })

    onContextMenu = (e, selectedPage) => {
        e?.preventDefault();
        const { clientY, clientX } = e;

        this.setState(p => ({
            ...p,
            pageContextMenu: {
                clientX,
                clientY,
                show: true
            },
            selectedPage
        }))
    }

    onEmployeeContextMenu = (e, selectedEmployee) => {
        e?.preventDefault();
        const { clientX, clientY } = e;

        this.setState(p => ({
            ...p,
            employeeContextMenu: {
                clientX,
                clientY,
                show: true
            },
            selectedEmployee
        }))
    }

    postNewPage = e => { e?.preventDefault(); this.networkCalls.postNewPage(); }

    postDeletePage = e => { e?.preventDefault(); this.networkCalls.postDeletePage(); }

    postEditPage = e => { e?.preventDefault(); this.networkCalls.postEditPage(); }

    postRemoveEmployeePermission = () => { this.networkCalls.postRemoveEmployeePermission(); }

    postEmployeePermissionUpdate = e => { e?.preventDefault(); this.networkCalls.postEmployeePermissionUpdate(); }

    postCreateEmployeePagePermission = () => { this.networkCalls.postCreateEmployeePagePermission(); }

    setEditPage = e => {
        e?.preventDefault();

        const { id, route, notes } = this.state.selectedPage;
        this.setState({
            editing: true,
            editValues: {
                id,
                route,
                notes: notes || '',
            }
        }, () => {
            M.updateTextFields();
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
        })
    }

    setEditEmployeePermission = e => {
        e?.preventDefault();
        this.setState(p => ({
            editEmployeeValues: {
                firstName: p.selectedEmployee.firstName,
                lastName: p.selectedEmployee.lastName,
                id: p.selectedEmployee.id,
                action: p.selectedEmployee.action
            },
            editingEmployee: true
        }), () => {
            M.updateTextFields();
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
        })
    }

    render = () => (
        <div style={{ padding: '1vw', overflow: 'auto' }}>
            <div className="blue-text row">
                {this.state.editing ?
                    <div style={{ minHeight: '34px' }} />
                    :
                    <i
                        className="material-icons tooltipped right"
                        data-position="top"
                        data-tooltip="Create New Page"
                        style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', cursor: 'pointer' }}
                        onClick={() => this.setState({ creating: true, selectedPage: null }, () => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 }))}
                    >add</i>
                }
            </div>
            <div className="card">
                <div className="card-content">
                    {this.state.creating ?
                        <div>
                            <div className="row blue-text">
                                <i
                                    id="creating-back-button"
                                    className="material-icons tooltipped"
                                    data-position="right"
                                    data-tooltip="Back"
                                    style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', cursor: 'pointer' }}
                                    onClick={() => {
                                        M.Tooltip.getInstance(document.querySelector('#creating-back-button')).destroy();
                                        this.setState({ creating: false, createValues: this.initCreateValues() })
                                    }}
                                >arrow_back</i>
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m3">
                                    <input id="create-route" type="text" onChange={this.createChange} value={this.state.createValues.route} />
                                    <label htmlFor="create-route">Route</label>
                                </div>
                                <div className="input-field col s12 m6">
                                    <input id="create-notes" type="text" onChange={this.createChange} value={this.state.createValues.notes} />
                                    <label htmlFor="create-notes">Notes</label>
                                </div>
                                <div className="input-field col s12 m3">
                                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.postNewPage}>Create New Page</a>
                                </div>
                            </div>
                        </div>
                        :
                        this.state.editing ?
                            <div>
                                <div className="row blue-text">
                                    <i
                                        id="editing-back-button"
                                        className="material-icons tooltipped left"
                                        data-position="right"
                                        data-tooltip="Back"
                                        style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', cursor: 'pointer' }}
                                        onClick={() => {
                                            M.Tooltip.getInstance(document.querySelector('#editing-back-button')).destroy();
                                            this.setState({ editing: false, editValues: this.initEditValues() })
                                        }}
                                    >arrow_back</i>
                                    <h5>{this.state.selectedPage?.route}</h5>
                                </div>
                                <div className="row">
                                    <div className="input-field col s12 m3">
                                        <input id="edit-route" type="text" onChange={this.editChange} value={this.state.editValues.route} />
                                        <label htmlFor="edit-route">Route</label>
                                    </div>
                                    <div className="input-field col s12 m6">
                                        <input id="edit-notes" type="text" onChange={this.editChange} value={this.state.editValues.notes} />
                                        <label htmlFor="edit-notes">Notes</label>
                                    </div>
                                    <div className="input-field col s12 m3">
                                        <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.postEditPage}>Update Page</a>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row" style={{ maxHeight: '30vh', overflow: 'auto' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ padding: '2px' }}>ID</th>
                                            <th style={{ padding: '2px' }}>Route</th>
                                            <th style={{ padding: '2px' }}>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.pageList?.map(p => (
                                            <tr
                                                key={p.id}
                                                className={p.id === this.state.selectedPage?.id ? 'blue white-text' : ''}
                                                onClick={() => this.pageRowClick(p)}
                                                onContextMenu={e => this.onContextMenu(e, p)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{p.id}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{p.route}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{p.notes}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
            <div className="card">
                <div className="card-content">
                    {this.state.selectedPage ?
                        <div className="row">
                            <div className="input-field col s11 m2 offset-m9">
                                <input id="addEmployeeId" type="text" className="autocomplete" />
                                <label htmlFor="addEmployeeId">Employee Name</label>
                            </div>
                            <div className="input-field col s1">
                                <i
                                    id="editing-back-button"
                                    className="material-icons blue-text tooltipped"
                                    data-position="bottom"
                                    data-tooltip="Add Employee To Page"
                                    style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', cursor: 'pointer' }}
                                    onClick={this.postCreateEmployeePagePermission}
                                >add</i>
                            </div>
                        </div>
                        : null}
                    <div className="row" style={{ maxHeight: '37vh', overflowY: 'auto' }}>
                        {this.state.editingEmployee ?
                            <div style={{ maxWidth: '100%', padding: '0px', margin: '0px', overflow: 'hidden' }}>
                                <div className="row blue-text">
                                    <div className="col">
                                        <i
                                            id="editing-employee-back-button"
                                            className="material-icons tooltipped"
                                            data-position="right"
                                            data-tooltip="Back"
                                            style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', cursor: 'pointer' }}
                                            onClick={() => {
                                                M.Tooltip.getInstance(document.querySelector('#editing-employee-back-button')).destroy();
                                                this.setState({ editingEmployee: false, editEmloyee: this.initEditEmployeeValues() })
                                            }}
                                        >arrow_back</i>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s3">
                                        <h6>{this.state.editEmployeeValues.firstName} {this.state.editEmployeeValues.lastName}</h6>
                                    </div>
                                    <div className="input-field col s3">
                                        <input id="editEmployee-action" type="text" onChange={e => this.setState(p => ({ editEmployeeValues: { ...p.editEmployeeValues, action: e.target.value } }))} value={this.state.editEmployeeValues.action} />
                                        <label htmlFor="editEmployee-action">Permission (CRUD no spaces, no dashes)</label>
                                    </div>
                                    <div className="input-field col s3">
                                        <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.postEmployeePermissionUpdate}>Update Permissions</a>
                                    </div>
                                </div>
                            </div>
                            :
                            <table>
                                <tbody>
                                    {this.state.selectedPage?.employees.map(e => (
                                        <tr key={e.id} className={this.state.selectedEmployee?.id === e.id ? 'grey lighten-3' : ''} onContextMenu={ev => this.onEmployeeContextMenu(ev, e)}>
                                            <td style={{ padding: '2px' }}>{e.firstName} {e.lastName}</td>
                                            <td style={{ padding: '2px' }}>{e.action?.split('').join('-')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
            <div id="pages-context-menu" className={this.state.pageContextMenu?.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: this.state.pageContextMenu?.clientY,
                left: this.state.pageContextMenu?.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={this.postDeletePage}>Delete Page</a>
                        <div style={{ height: '6px' }} />
                        <div className="divider" />
                        <div style={{ height: '6px' }} />
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={this.setEditPage}>Edit Page</a>
                    </li>
                </ul>
            </div>
            <div id="employee-context-menu" className={this.state.employeeContextMenu?.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: this.state.employeeContextMenu?.clientY,
                left: this.state.employeeContextMenu?.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'
            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => e.preventDefault()} onMouseDown={this.setEditEmployeePermission}>Edit Permissions</a>
                        <div style={{ height: '6px' }} />
                        <div className="divider" />
                        <div style={{ height: '6px' }} />
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => e.preventDefault()} onMouseDown={this.postRemoveEmployeePermission}>Remove</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default connect()(PagesTab);