import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReturnHandlerOveriew from './returnHandlerOverview';
import ReturnHandlerDetail from './returnHandlerDetail';
import UpdateReturnHandler from './updateReturnHandler';
import CreateReturnHandler from './createReturnHandler';

const returnHandlerRouter = props => (
    <Switch>
        <Route exact path="/returnhandler" component={ReturnHandlerOveriew} />
        <Route exact path="/returnhandler/create" component={CreateReturnHandler} />
        <Route exact path="/returnhandler/:id" component={ReturnHandlerDetail} />
        <Route exact path="/returnhandler/:id/update" component={UpdateReturnHandler} />
    </Switch>
)

export default returnHandlerRouter;