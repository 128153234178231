import M from '@materializecss/materialize';
import React from 'react';

export const Input = props => (
    <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
        <td style={{ padding: '0px' }}>
            <input
                style={{
                    height: '30.5px',
                    padding: '0px',
                    margin: '0px',
                }}
                id={props.id}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                className={`col ${props.col}`}
                disabled={props.disabled ? 'disabled' : ''}
            />
        </td>
    </tr>
)

export const CheckBox = props => (
    <tr style={{ border: 'none', margin: '0px', padding: '0px', position: 'relative' }}>
        <td style={{ padding: '0px', position: 'relative' }}>
            <label style={{ padding: '0px', margin: '0px', position: 'relative' }}>
                <input
                    style={{
                        padding: '0px',
                        margin: '0px',
                    }}
                    id={props.id}
                    type="checkbox"
                    value={props.value}
                    onChange={props.onChange}
                    checked={props.value ? 'checked' : ''}
                    className={`filled-in col ${props.col}`}
                    disabled={props.disabled ? 'disabled' : ''}
                />
                <span style={{ padding: '0px', margin: '0px', position: 'absolute', top: '6px' }} />
            </label>
            <p style={{ height: '31.5px', padding: '0px', margin: '0px' }}>&nbsp;</p>
        </td>
    </tr>
)

export const TextArea = props => (
    <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
        <td style={{ padding: '0px' }}>
            <textarea
                style={{
                    height: '30.5px',
                    padding: '0px',
                    margin: '0px',
                    lineHeight: '30.5px',
                    minHeight: '0px'
                }}
                id={props.id}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                className={`materialize-textarea col ${props.col}`}
                disabled={props.disabled ? 'disabled' : ''}
            />
        </td>
    </tr>
)

export const IconButton = props => (
    <a href="/" onClick={e => { e.preventDefault(); return props.disabled ? null : props.onClick() }}>
        <i className={`material-icons tooltipped ${props.disabled ? 'grey-text' : ''} ${props.classes}`}
            data-position="top"
            data-tooltip={props.tooltip}
            style={{ border: `1px solid ${props.disabled ? 'grey' : '#2196f3'}`, padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
        >{props.icon}</i>
    </a>
)

export class TableSelect extends React.Component {

    componentDidMount = () => {
        if (this.props.id) {
            M.FormSelect.init(document.querySelector(`#${this.props.id}`), { classes: 'orx-table-select' });
            const parent = document.querySelector(`#${this.props.id}`)?.parentElement;
            parent.style.height = '30.5px';
            parent.children[0].style.height = '30.5px';
        }
    }

    componentWillUnmount = () => {
        if (this.props.id)
            M.FormSelect.getInstance(document.querySelector(`#${this.props.id}`))?.destroy();
    }

    UNSAFE_componentWillReceiveProps = props => {
        if (props.value !== this.props.value)
            setTimeout(() => M.FormSelect.init(document.querySelector(`#${this.props.id}`), { classes: 'orx-table-select' }), 50)

        if (props.options !== this.props.options)
            setTimeout(() => M.FormSelect.init(document.querySelector(`#${this.props.id}`), { classes: 'orx-table-select' }), 50)
    }

    render = () => (
        <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
            <td style={{ padding: '0px' }}>
                <div className={`col ${this.props.col}`} style={{ padding: '0px', margin: '0px' }}>
                    <select
                        id={this.props.id}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled === true || !this.props.options || !this.props.options.length ? 'disabled' : ''}
                        multiple={this.props.multiple}
                    >
                        {this.props.options?.map(o => (
                            <option key={typeof o === 'object' ? o.key : o} value={typeof o === 'object' ? o.value : o}>{typeof o === 'object' ? o.display : o}</option>
                        ))}
                    </select>
                </div>
            </td>
        </tr>
    )
}

export class AutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.startingValue }
    }

    componentDidMount = () => {
        this.initAutoComplete(this.props);
    }

    componentWillUnmount = () => {
        this.removeAutoComplete();
    }

    UNSAFE_componentWillReceiveProps = props => {
        if (props.data !== this.props.data) {
            this.removeAutoComplete();
            this.initAutoComplete(props);
        }
    }

    initAutoComplete = props => {
        M.Autocomplete.init(document.querySelector(`#${this.props.id}`), {
            data: props ? props.data : this.props.data,
            onAutocomplete: value => this.setState({ value }),
            limit: props?.limit ? props.limit : Infinity
        })
    }
    removeAutoComplete = () => M.Autocomplete.getInstance(document.querySelector(`#${this.props.id}`))?.destroy();

    render = () => (
        <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
            <td style={{ padding: '0px' }}>
                <div className="input-field" style={{ margin: '0px', padding: '0px' }}>
                    <input
                        id={this.props.id}
                        type="text"
                        className={`autocomplete col ${this.props.col}`}
                        style={{ margin: '0px', padding: '0px', height: '30.5px' }}
                        value={this.state.value}
                        onChange={e => this.setState({ value: e.target.value })}
                        disabled={!this.props.data ? 'disabled' : ''}
                    />
                </div>
            </td>
        </tr>
    )
}