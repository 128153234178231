import Axios from 'axios';
import M from '@materializecss/materialize';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import * as xlsx from 'xlsx';

class ShippingDetail extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        raNumber: '',
        receivingPartyId: -1,
        trackingNumber: '',
        partyName: '',
        barcodeList: [],
        raNumberList: [],
        partyList: [],
        accordMonth: 0,
        accordYear: 0
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.Modal.init(document.querySelectorAll('.modal'));
        M.FormSelect.init(document.querySelectorAll('select'));
        document.querySelector('#raNumber').focus();
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.modal').forEach(el => {
            const m = M.Modal.getInstance(el);
            if (m) { m.destroy() }
        })
    }

    networkCalls = {
        addBarcode: {
            func: e => {
                if (e.keyCode === 13) {
                    const raNumberList = JSON.parse(JSON.stringify(this.state.raNumberList));
                    if (raNumberList.indexOf(this.state.raNumber) !== -1) {
                        this.setState({ raNumber: '' })
                        return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Duplicate RA Number', class: 'yellow red-text' } })
                    }

                    Axios.get('/api/v1/debitmemo/read/one/by/ranumber', {
                        params: { ...this.authData, raNumber: this.state.raNumber }
                    })
                        .then(result => {
                            const barcodeList = JSON.parse(JSON.stringify(this.state.barcodeList));
                            barcodeList.unshift(result.data);
                            raNumberList.push(this.state.raNumber);
                            this.setState({ raNumber: '', barcodeList, raNumberList }, () => {
                                document.getElementById('raNumber').focus();
                            })
                        })
                        .catch(logOut);
                }
            },
            type: 'r'
        },
        partyBlur: {
            func: () => {
                M.Modal.getInstance(document.querySelector('#party-modal')).open();
                Axios.get('/api/v1/party/all/for/shipping', {
                    params: { ...this.authData, partyName: this.state.partyName }
                })
                    .then(result => this.setState({ partyList: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        },
        saveAndShip: {
            func: () => {
                const debitMemoIdList = [];
                this.state.barcodeList.forEach(dm => debitMemoIdList.push(dm.id))

                Axios.post('/api/v1/debitmemo/update/ship', {
                    ...this.authData,
                    debitMemoIdList,
                    trackingNumber: this.state.trackingNumber,
                    receivingPartyId: this.state.receivingPartyId
                })
                    .then(result => {
                        this.props.dispatch(result.data)
                        this.setState(this.initState(), () => {
                            M.Modal.getInstance(document.querySelector('#ship-modal')).close();
                            document.querySelector('#raNumber').focus();
                        });
                    })
                    .catch(logOut)
            },
            type: 'u'
        },
        downloadAccordCsv: {
            func: () => {
                const { accordMonth, accordYear } = this.state;

                if (!accordMonth || !accordYear)
                    return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Select a Month and Year', class: 'orange white-text' } })

                Axios.post('/api/v1/file/download/accordcsv', {
                    ...this.authData,
                    month: this.state.accordMonth,
                    year: this.state.accordYear
                })
                    .then(result => {
                        if (!result.data.length)
                            return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Results', class: 'orange white-text' } })

                        M.Modal.getInstance(document.querySelector('#accord-modal')).close();
                        const wb = xlsx.utils.book_new();
                        const sheet = xlsx.utils.json_to_sheet(result.data);

                        const colWidths = {};

                        Object.keys(sheet).forEach(key => {
                            const [col, row] = key.match(/[^\d]+|\d+/g);
                            if (col !== '!') {
                                const len = sheet[key].v?.toString().length;

                                if (!colWidths[col])
                                    colWidths[col] = [];

                                colWidths[col].push(len);
                            }

                            if (parseInt(row) > 1) {
                                switch (col) {
                                    case 'H':
                                        sheet[key].t = 'd';
                                        break;
                                    case 'K':
                                        sheet[key].t = 'n';
                                        break;
                                    case 'M':
                                        sheet[key].t = 'n';
                                        sheet[key].z = '0.00';
                                        break;
                                    default:
                                        break;
                                }
                            }
                        })

                        const colWidthsFinal = [];

                        Object.keys(colWidths).forEach(key => {
                            colWidthsFinal.push({
                                wch: Math.max(...colWidths[key])
                            })
                        })

                        sheet['!cols'] = colWidthsFinal;
                        xlsx.utils.book_append_sheet(wb, sheet, 'Sheet1');
                        xlsx.writeFile(wb, `accord_credits_${this.state.accordMonth.toString().padStart(2, '0')}_${this.state.accordYear}.xlsx`);
                    })
                    .catch(logOut)
            },
            type: 'r'
        }
    }

    addBarcode = e => this.networkCalls.addBarcode(e);
    partyBlur = () => this.networkCalls.partyBlur();
    saveAndShip = e => { e?.preventDefault(); this.networkCalls.saveAndShip(); }
    downloadAccordCsv = e => { e?.preventDefault(); this.networkCalls.downloadAccordCsv(); }

    assembleDebitMemoNumber = dm => {
        if (!dm.number)
            return '';
        else
            return `ORX${dm.prefix || ''}-${dm.month}-${dm.control === 'R' ? '' : 'C'}${dm.number.toString().padStart(4, '0')}`;
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    removeEntry = index => {
        const raNumberList = JSON.parse(JSON.stringify(this.state.raNumberList));
        let barcodeList = JSON.parse(JSON.stringify(this.state.barcodeList));
        raNumberList.splice(raNumberList.indexOf(barcodeList[index].raNumber), 1);
        barcodeList.splice(index, 1);
        this.setState({ barcodeList, raNumberList });
    }

    openShipModal = e => {
        e.preventDefault();
        M.Modal.getInstance(document.querySelector('#ship-modal')).open();
        document.querySelector('#trackingNumber').focus();
    }

    selectParty = (e, p) => {
        e.preventDefault();

        this.setState({
            partyList: null,
            partyName: p.name,
            receivingPartyId: p.id
        }, () => {
            M.Modal.getInstance(document.querySelector('#party-modal')).close()
        })
    }

    partyModalCancel = e => {
        e.preventDefault();

        this.setState({
            partyName: '',
            partyList: null,
            receivingPartyId: -1
        })

        M.Modal.getInstance(document.querySelector('#party-modal')).close();
    }

    getMonths = () => ([
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ])

    getYears = () => {
        const arr = [];
        for (let i = 2021; i <= moment().year(); i++)
            arr.push(i);
        return arr;
    }

    openAccordModal = e => {
        e?.preventDefault();
        M.Modal.getInstance(document.querySelector('#accord-modal')).open();
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Outbound Shipping</span>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m4 l2" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="raNumber">RA Number</label>
                    <input id="raNumber" className="browser-default" type="text" onChange={this.onChange} onKeyUp={this.addBarcode} value={this.state.raNumber} />
                </div>
                <div className="col s12 m4 l2 right">
                    <a href="/" className="btn-small teal white-text waves-effect col s12" onClick={this.openAccordModal}>Download Accord CSV</a>
                </div>
                <div className="col s12 m4 l2 right">
                    <a href="/" className="btn-small blue white-text waves-effect col s12" onClick={this.openShipModal}>Ship Box</a>
                </div>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th>Debit Memo</th>
                            <th>Return Authorization</th>
                            <th>Manufacturer</th>
                            <th>Order Number</th>
                            <th>Date Processed</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.barcodeList && this.state.barcodeList.map((dm, index) => (
                            <tr key={dm.raNumber}>
                                <td style={{ padding: '2px' }}>{this.assembleDebitMemoNumber(dm)}</td>
                                <td style={{ padding: '2px' }}>{dm.raNumber}</td>
                                <td style={{ padding: '2px' }}>{dm.manufacturerName}</td>
                                <td style={{ padding: '2px' }}>{dm.orderNumber}</td>
                                <td style={{ padding: '2px' }}>{moment(dm.dateCreated).format('MM/DD/YYYY')}</td>
                                <td style={{ padding: '2px' }}><i className="material-icons red-text" style={{ cursor: 'pointer' }} onClick={() => this.removeEntry(index)}>cancel</i></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div id="ship-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>Ship Box</h5>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input id="trackingNumber" type="text" onChange={this.onChange} value={this.state.trackingNumber} />
                            <label htmlFor="trackingNumber">Tracking Number</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input id="partyName" type="text" onChange={this.onChange} value={this.state.partyName} onBlur={this.partyBlur} />
                            <label htmlFor="partyName">Ship To:</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 l2 right">
                            <a href="/" className="btn-small green white-text waves-effect col s12" onClick={this.saveAndShip}>Save & Ship</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="party-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        {this.state.partyList && this.state.partyList.map(p => (
                            <a key={`party-${p.id}`} href="/" className="col s12 m4" onClick={e => this.selectParty(e, p)}>{p.name}</a>)
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12 m2">
                            <a href="/" className="btn-small red white-text waves-effect col s12" onClick={this.partyModalCancel}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="accord-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>Accord CSV</h5>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m3">
                            <select id="accordMonth" onChange={this.onChange}>
                                <option value={0}>Select a Month</option>
                                {this.getMonths().map((m, index) => <option key={m} value={index + 1}>{m}</option>)}
                            </select>
                        </div>
                        <div className="input-field col s12 m2">
                            <select id="accordYear" onChange={this.onChange}>
                                <option value={0}>Select A Year</option>
                                {this.getYears().map(y => <option key={y} value={y}>{y}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4 right">
                            <a href="/" className="btn-small teal white-text waves-effect waves-light col s12" onClick={this.downloadAccordCsv}>Download Accord CSV</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(ShippingDetail);