import React from 'react';
import { CheckBox, Input } from '../../../layout/form';

const ReturnPolicyTab = props => (
    <div style={{ padding: '1vw' }}>
        <div className="row">
            <div className="switch">
                <label>
                    <input id="includeReturnPolicy" type="checkbox" value={props.values.includeReturnPolicy} onChange={props.onCheckboxChange} checked={props.values.includeReturnPolicy ? 'checked' : ''} />
                    <span className="lever" />
                    Include Return Policy
                </label>
            </div>
        </div>
        <div className="row">
            <div className="col s4 m3 l2">
                <table>
                    <tbody>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}>Is Default Return Policy</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Earliest Return</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Latest Return</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Is Returnable</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Sealed Only</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Labels OK</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Controls OK</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Min Package %</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}>AWP Adjustment</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Effective Date</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}>Expiration Date</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}>Notes</td></tr>
                    </tbody>
                </table>
            </div>
            <div className="col s8 m9 l10">
                <table>
                    <tbody>
                        <CheckBox id="isDefault" value={props.values.isDefault} onChange={props.onCheckboxChange} col="s4" disabled={!props.values.includeReturnPolicy} />
                        <Input id="earliestReturnInMonths" type="number" value={props.values.earliestReturnInMonths} onChange={props.onChange} col="s4 m2" disabled={!props.values.includeReturnPolicy} />
                        <Input id="latestReturnInMonths" type="number" value={props.values.latestReturnInMonths} onChange={props.onChange} col="s4 m2" disabled={!props.values.includeReturnPolicy} />
                        <CheckBox id="isReturnable" value={props.values.isReturnable} onChange={props.onCheckboxChange} col="s4" disabled={!props.values.includeReturnPolicy} />
                        <CheckBox id="sealedPackagesOnly" value={props.values.sealedPackagesOnly} onChange={props.onCheckboxChange} col="s4" disabled={!props.values.includeReturnPolicy} />
                        <CheckBox id="labelsAllowed" value={props.values.labelsAllowed} onChange={props.onCheckboxChange} col="s4" disabled={!props.values.includeReturnPolicy} />
                        <CheckBox id="controlsAllowed" value={props.values.controlsAllowed} onChange={props.onCheckboxChange} col="s4" disabled={!props.values.includeReturnPolicy} />
                        <Input id="minPackagePercentage" type="number" value={props.values.minPackagePercentage} onChange={props.onChange} col="s4 m2" disabled={!props.values.includeReturnPolicy} />
                        <Input id="awpAdjustment" type="number" value={props.values.awpAdjustment} onChange={props.onChange} col="s4 m2" disabled={!props.values.includeReturnPolicy} />
                        <Input id="effectiveDate" type="date" value={props.values.effectiveDate} onChange={props.onChange} col="s6 m3" disabled={!props.values.includeReturnPolicy} />
                        <Input id="expirationDate" type="date" value={props.values.expirationDate} onChange={props.onChange} col="s6 m3" disabled={!props.values.includeReturnPolicy} />
                        <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
                            <td style={{ padding: '0px' }}>
                                <textarea
                                    style={{
                                        height: '30.5px',
                                        padding: '0px',
                                        margin: '0px',
                                        lineHeight: '30.5px'
                                    }}
                                    id="notes"
                                    value={props.values.notes}
                                    onChange={props.onChange}
                                    className="materialize-textarea col s12 m8"
                                    disabled={!props.values.includeReturnPolicy}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row" style={{ marginTop: '40px' }}>
            <a href="/" onClick={e => { e.preventDefault(); props.setTab({ target: { id: 'tab-returnInfo' } }) }}>
                <i className="material-icons tooltipped"
                    data-position="top"
                    data-tooltip="Previous"
                    style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                >arrow_back</i>
            </a>
            <a href="/" onClick={props.saving ? () => { } : e => { e.preventDefault(); props.save(); }}>
                <i className={`material-icons tooltipped ${props.saving ? 'grey-text' : ''}`}
                    data-position="top"
                    data-tooltip="Save Manufacturer"
                    style={{ border: `1px solid ${props.saving ? 'grey' : '#2196f3'}`, padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                >save</i>
            </a>
        </div>
    </div>
)

export default ReturnPolicyTab