import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../func';
import M from '@materializecss/materialize';
import moment from 'moment';

class PotentialCustomerList extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        potentialCustomers: null,
        potentialCustomer: null,
        searchParameters: this.initSearchparameters()
    })

    initSearchparameters = () => ({
        name: '',
        fullAddress: '',
        zip: '',
        status: '',
        phone: '',
        preferredDays: '',
        hour: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.initListeners();
        M.FormSelect.init(document.querySelectorAll('select'))
        if (this.props.location?.state?.searchParameters)
            this.loadPreviousState();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.addEventListener('keyup', this.enterListener)
    }

    removeListeners = () => {
        document.removeEventListener('keyup', this.enterListener)
    }

    enterListener = e => {
        if (e.target.classList.contains('search') && e.keyCode === 13)
            this.search();
    }

    loadPreviousState = () => {
        this.setState({ searchParameters: this.props.location.state.searchParameters }, () => {
            M.updateTextFields();
            this.search()
        })
    }

    networkCalls = {
        search: {
            func: () => {
                Axios.get('/api/v1/sales/read/search/general', {
                    params: {
                        ...this.authData,
                        ...this.state.searchParameters,
                        hour: this.state.searchParameters.hour ? parseInt(this.state.searchParameters.hour.split(':')[0]) : ''
                    }
                })
                    .then(result => {
                        const potentialCustomers = result.data
                        if (!potentialCustomers.length)
                            this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Results', class: 'orange white-text' } })
                        else {
                            potentialCustomers.forEach(pc => {
                                let availableDays = [];
                                let phoneNumbers = [];
                                let lowestAfter = 24;
                                let highestBefore = 0;
                                pc.contacts?.forEach(c => {
                                    c.preferredDays?.split('').forEach(day => {
                                        if (availableDays.indexOf(parseInt(day)) === -1)
                                            availableDays.push(parseInt(day));
                                    })

                                    if (c.preferredHourAfter && lowestAfter > c.preferredHourAfter)
                                        lowestAfter = c.preferredHourAfter;
                                    if (c.preferredHourBefore && highestBefore < c.preferredHourBefore)
                                        highestBefore = c.preferredHourBefore;

                                    if (c.phone && phoneNumbers.indexOf(c.phone) === -1)
                                        phoneNumbers.push(c.phone);
                                })

                                pc.lowestAfter = lowestAfter === 24 ? 0 : lowestAfter;
                                pc.highestBefore = highestBefore;

                                pc.availableDays = availableDays.sort()
                                pc.availableDays = pc.availableDays?.map(day => {
                                    if (day === 1)
                                        day = 'M'
                                    else if (day === 2)
                                        day = 'T'
                                    else if (day === 3)
                                        day = 'W'
                                    else if (day === 4)
                                        day = 'TH'
                                    else if (day === 5)
                                        day = 'F'
                                    else if (day === 6)
                                        day = 'S'
                                    else
                                        day = 'SU'

                                    return day;
                                })

                                pc.availableDays = pc.availableDays?.join(', ');
                                pc.phoneNumbers = phoneNumbers;
                            })
                        }

                        this.setState({ potentialCustomers })
                    })
                    .catch(logOut);
            },
            type: 'r'
        }
    }

    search = e => { e?.preventDefault(); this.networkCalls.search(); }

    onChange = e => this.setState(p => ({ searchParameters: { ...p.searchParameters, [e.target.id]: e.target.value } }))

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Sales</span>
                </div>
            </div>
            <div className="row">
                <Link to="/sales/search" className="col s2 offset-s10">Import Pharmacies</Link>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input id="name" className="search" type="text" onChange={this.onChange} value={this.state.searchParameters.name} />
                    <label htmlFor="name">Pharmacy Name</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="fullAddress" className="search" type="text" onChange={this.onChange} value={this.state.searchParameters.fullAddress} />
                    <label htmlFor="fullAddress">Address</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="zip" type="text" className="search" onChange={this.onChange} value={this.state.searchParameters.zip} />
                    <label htmlFor="zip">Zip Code</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <select id="status" onChange={this.onChange} value={this.state.searchParameters.status}>
                        <option value=""></option>
                        <option value="not contacted">Not Contacted</option>
                        <option value="contact attempted">Contact Attempted</option>
                        <option value="contacted">Contacted</option>
                        <option value="scheduled">Scheduled</option>
                        <option value="closed">Closed</option>
                    </select>
                    <label htmlFor="status">Status</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <select id="highestResultOfContact" onChange={this.onChange} value={this.state.searchParameters.highestResultOfContact}>
                        <option value=""></option>
                        <option value="no answer">No Answer</option>
                        <option value="not available">Not Available</option>
                        <option value="not interested">Not Interested</option>
                        <option value="interested">Interested</option>
                        <option value="mailed literature">Mailed Literature</option>
                        <option value="sent labels">Sent Labels</option>
                        <option value="scheduled appointment">Scheduled Appointment</option>
                        <option value="set up new customer">New Customer</option>
                    </select>
                    <label htmlFor="highestResultOfContact">Result Of Contact</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input id="phone" type="text" onChange={this.onChange} value={this.state.searchParameters.phone} />
                    <label htmlFor="phone">Phone</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <select id="preferredDays" onChange={this.onChange} value={this.state.searchParameters.preferredDays}>
                        <option value=""></option>
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                    </select>
                    <label htmlFor="preferredDays">Contact Day</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="hour" type="time" onChange={this.onChange} value={this.state.searchParameters.hour} />
                    <label htmlFor="hour">Contact Hour</label>
                </div>
                <div className="input-field col s12 m3 l2 offset-m3 offset-l2">
                    <a href="/" className="btn-small blue white-text col s12 waves-effect waves-light" onClick={this.search}>Search</a>
                </div>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th>Pharmacy Name</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Availability</th>
                            <th>Last Contacted</th>
                            <th>Result Of Contact</th>
                            <th>Phone(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.potentialCustomers?.map((c, index) => (
                            <tr key={c.id}>
                                <td style={{ padding: '2px' }}><Link to={{
                                    pathname: `/sales/potentialcustomer/${c.id}`,
                                    state: { searchParameters: this.state.searchParameters }
                                }}>
                                    {c.name}</Link></td>
                                <td style={{ padding: '2px' }}>{c.fullAddress}</td>
                                <td style={{ padding: '2px' }}>{c.status}</td>
                                <td style={{ padding: '2px' }}>{c.availableDays} <span style={{ marginLeft: '12px' }}>
                                    {c.lowestAfter ? moment(c.lowestAfter, 'HH').format('h:mm A') : ''}
                                    {c.lowestAfter && c.highestBefore ? ' - ' : ''}
                                    {c.highestBefore ? moment(c.highestBefore, 'HH').format('h:mm A') : ''}</span></td>
                                <td style={{ padding: '2px' }}>{c.lastContact ? moment(c.lastContact).format('MM/DD/YY') : ''}</td>
                                <td style={{ padding: '2px' }}>{c.highestResultOfContact}</td>
                                <td style={{ padding: '2px' }}>{c.phoneNumbers?.join(', ')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect()(PotentialCustomerList);