import React from 'react';
import { Switch, Route } from 'react-router-dom';
import newOrderDetailOverview from './orderDetailOverview/orderDetailOverview';
import OrderDetailInputPage from './orderDetailInput/OrderDetailInputPage';

const OrderDetailRouter = props => (
    <Switch>
        <Route exact path="/regular/:id" component={newOrderDetailOverview} />
        <Route exact path="/regular/:id/orderdetail" component={OrderDetailInputPage} />
        <Route exact path="/regular/:id/orderdetail/listonly" component={OrderDetailInputPage} />
    </Switch>
);

export default OrderDetailRouter;