import Axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { logOut, registerRoutes } from '../../../func';
import { IconButton } from '../../../layout/form';
import FileUploader from '../../../utilities/fileUploader';
const componentId = crypto.randomUUID();

const AttachmentTab = props => {
    const {
        path,
        orderHeaderId,
        dispatch
    } = props;

    const [loading, setLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [attachmentList, setAttachmentList] = useState(null);
    const [attachmentsToBeAdded, setAttachmentsToBeAdded] = useState([])
    const [notes, setNotes] = useState('');
    const [progress, setProgress] = useState(null);
    const [downloading, setDownloading] = useState([]);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [contextMenu, setContextMenu] = useState({ show: false })

    const networkCalls = registerRoutes({
        getAttachments: {
            func: () => {
                setLoading(true);
                Axios.get('/api/v2/order/header/read/all/attachments/by/orderheaderid', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        orderHeaderId
                    }
                })
                    .then(result => setAttachmentList(result.data))
                    .catch(logOut)
                    .finally(() => setLoading(false))
            },
            type: 'r'
        },
        saveAttachments: {
            func: files => {
                const fileUploader = new FileUploader(
                    files,
                    '/api/v2/file/upload/orderheader/attachment',
                    { orderHeaderId, notes }
                );
                fileUploader.setProgressCallback(progress => setProgress({ ...progress }))
                fileUploader.setOnUploadFinish(() => {
                    setNotes('');
                    setAttachmentsToBeAdded(null);
                    setProgress(null)
                    setIsAdding(false);
                    networkCalls.getAttachments();
                })

                return fileUploader.upload()
            },
            type: 'c'
        },
        downloadFile: {
            func: orderHeaderAttachmentId => {
                setDownloading(p => [...p, orderHeaderAttachmentId])

                Axios.get('/api/v2/file/download/orderheader/attachment', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        orderHeaderAttachmentId
                    }
                })
                    .then(result => {
                        const { content, filename } = result.data;
                        const uint8Array = Uint8Array.from(content.data);
                        const blob = new Blob([uint8Array])
                        const url = window.URL.createObjectURL(blob)
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut)
                    .finally(() => {
                        downloading.splice(downloading.indexOf(orderHeaderAttachmentId), 1);
                        setDownloading([...downloading])
                    })
            },
            type: 'r'
        },
        deleteAttachment: {
            func: orderHeaderAttachmentId => {
                Axios.post('/api/v2/file/delete/orderheader/attachment', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    orderHeaderAttachmentId
                })
                    .then(result => {
                        networkCalls.getAttachments();
                        dispatch(result.data)
                    })
                    .catch(logOut)
            },
            type: 'd'
        }
    }, path);

    useEffect(() => {
        networkCalls.getAttachments()

        const clickAwayListener = ({ target }) => {
            if (!document.querySelector(`#context-menu-${componentId}`)?.contains(target)) {
                setContextMenu({ show: false })
                setSelectedAttachment(null);
            }
        }

        const rightClickAwayListener = ({ target }) => {
            if (
                !document.querySelector(`#context-menu-${componentId}`)?.contains(target)
                && !target.classList?.contains('attachment-item')
                && !target.parentNode?.classList?.contains('attachment-item')
            ) {
                setContextMenu({ show: false })
                setSelectedAttachment(null)
            }
        }

        const initListeners = () => {
            document.addEventListener('click', clickAwayListener);
            document.addEventListener('contextmenu', rightClickAwayListener);
        }

        const removeListeners = () => {
            document.removeEventListener('click', clickAwayListener);
            document.removeEventListener('contextmenu', rightClickAwayListener);
        }

        initListeners();

        return () => removeListeners();
    }, [])

    const saveAttachments = e => {
        e.preventDefault();
        networkCalls.saveAttachments(attachmentsToBeAdded);
    }

    const onDrop = e => {
        e.preventDefault();

        const { files } = e.dataTransfer;
        const arr = [];

        for (let i = 0; i < files.length; i++)
            arr.push(files[i])

        setAttachmentsToBeAdded(arr);
    }

    const onDragOver = e => {
        e.stopPropagation();
        e.preventDefault();
    }

    const onContextMenu = (e, a) => {
        e?.preventDefault();
        setSelectedAttachment(a);
        setContextMenu({
            show: true,
            clientX: e.clientX + 150 > window.innerWidth ? e.clientX - 145 : e.clientX,
            clientY: e.clientY + 80 > window.innerHeight ? e.clientY - 75 : e.clientY
        })
    }

    const downloadAttachment = e => {
        e.preventDefault();
        networkCalls.downloadFile(selectedAttachment.id)
        setContextMenu({ show: false })
        setSelectedAttachment(null)
    }

    const deleteAttachment = e => {
        e.preventDefault();
        if (window.confirm(`Are you sure you want to delete ${selectedAttachment.filename}?`))
            networkCalls.deleteAttachment(selectedAttachment.id)

        setContextMenu({ show: false })
        setSelectedAttachment(null)

    }

    return (
        <div style={{ padding: '1vw' }}>
            {loading ?
                <div className='progress blue'>
                    <div className='indeterminate' />
                </div>
                :
                <>
                    <div className="row">
                        {isAdding ?
                            <IconButton
                                icon="arrow_back"
                                onClick={() => setIsAdding(false)}
                                tooltip="Back"
                            />
                            :
                            <IconButton
                                icon="add"
                                onClick={() => {
                                    setIsAdding(true)
                                    setNotes('');
                                    setAttachmentsToBeAdded([])
                                }}
                                tooltip="Add Attachment"
                            />
                        }
                    </div>
                    <div className="row">
                        {isAdding ?
                            <>
                                <div
                                    className='col s12 m7 l5'
                                    style={{
                                        minHeight: '200px',
                                        border: 'dotted grey 3px',
                                        borderRadius: '8px'
                                    }}
                                    onDrop={onDrop}
                                    onDragOver={onDragOver}
                                >
                                    <h6 className='center'>Drop Files Here</h6>
                                    <ul>
                                        {progress && Object.keys(progress).length ?
                                            Object.keys(progress).map((key, index) => (
                                                <li key={index}>{key}: Uploading {progress[key].currentPacket} of {progress[key].totalPackets} Packet(s)</li>
                                            ))
                                            :
                                            attachmentsToBeAdded?.map(a => (
                                                <li key={a.name} className="blue-text">{a.name}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="col s12 m5 l7">
                                    <div className="input-field">
                                        <textarea id="notes" className="materialize-textarea" onChange={e => setNotes(e.target.value)} value={notes} />
                                        <label htmlFor='notes'>Notes</label>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m2 offset-m10">
                                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={saveAttachments}>Save Attachment(s)</a>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '0px' }}>File Name</th>
                                        <th style={{ padding: '0px' }}>Uploader</th>
                                        <th style={{ padding: '0px' }}>File Type</th>
                                        <th style={{ padding: '0px' }}>Date Uploaded</th>
                                        <th style={{ padding: '0px' }}>Notes</th>
                                        <th style={{ padding: '0px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachmentList?.length ?
                                        attachmentList.map(a => (
                                            <tr
                                                key={a.id}
                                                className={`${selectedAttachment?.id === a.id ? 'blue white-text' : ''} attachment-item`}
                                                onContextMenu={e => onContextMenu(e, a)}
                                            >
                                                <td style={{ padding: '0px', borderRadius: '0px' }}>{a.filename}</td>
                                                <td style={{ padding: '0px', borderRadius: '0px' }}>{a.uploader.firstName} {a.uploader.lastName}</td>
                                                <td style={{ padding: '0px', borderRadius: '0px' }}>{a.contentType}</td>
                                                <td style={{ padding: '0px', borderRadius: '0px' }}>{moment(a.timeStamp).format('MM/DD/YY')}</td>
                                                <td style={{ padding: '0px', borderRadius: '0px' }}>{a.notes}</td>
                                                <td style={{ padding: '0px', borderRadius: '0px' }}>{downloading.indexOf(a.id) !== -1 ? <i className='purple-text fade-in-fade-out bold'>DOWNLOADING</i> : ''}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td style={{ padding: '0px' }}>No Attachments</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </>
            }
            <div id={`context-menu-${componentId}`} className={contextMenu.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: contextMenu.clientY,
                left: contextMenu.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px',

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={downloadAttachment}>Download</a>
                    </li>
                    <div style={{ height: '6px' }} />
                    <div className="divider" />
                    <div style={{ height: '6px' }} />
                    <li>
                        <a href="/" className="red-text" style={{ font: 'console', fontSize: '.8em' }} onClick={deleteAttachment}>Delete</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default AttachmentTab