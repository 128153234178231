import Axios from "axios";
import React from 'react';
import { connect } from "react-redux";
import { logOut, registerRoutes } from '../../../func';
import moment from 'moment';
import M from '@materializecss/materialize';
import { IconButton } from '../../../layout/form';

class OrderInputTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        printing: false,
        orderDetails: null,
        listName: 'regulars'
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.networkCalls.getOrderDetails(false);
    }

    componentWillUnmount = () => {
        this.removeTooltips();
    }

    initTooltips = () => setTimeout(() => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 }), 100)
    removeTooltips = () => document.querySelectorAll('.tooltipped').forEach(el => M.Tooltip.getInstance(el)?.destroy());


    networkCalls = {
        getOrderDetails: {
            func: print => {
                this.setState({ loading: !print, printing: print }, () => {
                    Axios.get('/api/v1/order/detail/print/sorterreview', {
                        params: {
                            ...this.authData,
                            orderHeaderId: this.props.orderHeaderId,
                            print
                        },
                        responseType: print ? 'arraybuffer' : 'json'
                    })
                        .then(result => {
                            if (print) {
                                const blob = new Blob([result.data], { type: 'application/pdf' });
                                const url = window.URL.createObjectURL(blob);
                                window.open(url, '__blank');
                            }
                            else
                                this.setState({ orderDetails: result.data }, this.initTooltips)
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false, printing: false }))
                })
            },
            type: 'r'
        }
    }

    editOrderDetails = () => this.props.history.push(`/regular/${this.props.orderHeaderId}/orderdetail`)

    swapList = () => {
        this.removeTooltips();
        this.setState(p => ({ listName: p.listName === 'regulars' ? 'controls' : 'regulars' }), this.initTooltips)
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            {this.state.loading ?
                <div className="progress blue">
                    <div className="indeterminate" />
                </div>
                :
                <div>
                    <div className="row">
                        <IconButton icon="edit" tooltip="Edit Order Details" onClick={this.editOrderDetails} />
                        <div className="right">
                            <IconButton icon="swap_horiz" tooltip={this.state.listName === 'regulars' ? 'View Controls' : 'View Regulars'} onClick={this.swapList} />
                            <IconButton icon="print" tooltip="Print Page" onClick={() => this.networkCalls.getOrderDetails(true)} disabled={this.state.printing} />
                        </div>
                    </div>
                    <div className="row">
                        <table className="highlight">
                            <tbody>
                                <tr>
                                    <td colSpan={9} className="center bold" style={{ padding: '0px' }}>{this.state.listName?.toUpperCase()}</td>
                                </tr>
                            </tbody>
                            {this.state.orderDetails && Object.keys(this.state.orderDetails[this.state.listName]).map((rh, index) => (
                                <React.Fragment key={`rh-${index}`}>
                                    <tbody>
                                        <tr>
                                            <td className="bolder blue-text" colSpan={9} style={{ padding: '0px', paddingTop: '40px', borderBottom: '3px solid #2196f3' }} >{rh}</td>
                                        </tr>
                                        <tr style={{ borderBottom: 'none' }}>
                                            <td className="bolder" style={{ padding: '2px' }}>NDC</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Class</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Med Name</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Expiration</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Lot #</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Pk Size</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Full Pk</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Part Pk</td>
                                            <td className="bolder" style={{ padding: '2px' }}>Reason</td>
                                        </tr>
                                    </tbody>
                                    {Object.keys(this.state.orderDetails[this.state.listName][rh]).map((mfr, index) => (
                                        <tbody key={`mfr-${index}`}>
                                            <tr>
                                                <td className="bold" colSpan={9} style={{ padding: '0px', paddingTop: '18px', borderBottom: '1px solid #4caf50', borderTop: 'none' }}>{mfr}</td>
                                            </tr>
                                            {this.state.orderDetails[this.state.listName][rh][mfr]?.map((od, index) => (
                                                <tr key={`od-${index}`}>
                                                    <td style={{ padding: '2px' }}>{od.ndc}</td>
                                                    <td style={{ padding: '2px' }}>{od.deaClass}</td>
                                                    <td style={{ padding: '2px' }}>{od.medName}</td>
                                                    <td style={{ padding: '2px' }}>{moment(od.expiration).format('MM/DD/YY')}</td>
                                                    <td style={{ padding: '2px' }}>{od.lotNumber}</td>
                                                    <td style={{ padding: '2px' }}>{od.packSize}</td>
                                                    <td style={{ padding: '2px' }}>{od.fullPackage}</td>
                                                    <td style={{ padding: '2px' }}>{od.partialPackage}</td>
                                                    <td style={{ padding: '2px' }}>{od.nonReturnableReasonCodeId || '-'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ))}
                                </React.Fragment>
                            ))}
                        </table>
                    </div>
                </div >
            }
        </div >
    )
}

export default connect()(OrderInputTab);