import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { logOut, registerRoutes } from '../../../func';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';
import moment from 'moment';

class OrdersTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        orderList: null,
        loading: false,
        selectedOrder: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.getOrders();
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.tooltipped').forEach(el => el ? M.Tooltip.getInstance(el).destroy() : null);
    }

    networkCalls = {
        getOrders: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/order/header/read/all/by/pharmacyid', {
                        params: {
                            ...this.authData,
                            pharmacyId: this.props.pharmacyId
                        }
                    })
                        .then(result => this.setState({ orderList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getOrders = () => this.networkCalls.getOrders();

    selectOrder = (e, order) => {
        e?.preventDefault();
        this.setState({ selectedOrder: order })
    }

    render = () => (<div style={{ padding: '1vw' }}>
        <div className="row">
            <Link to={{ pathname: "/warehouse/manager/regular/0", state: { tab: 'create' } }}>
                <i className="material-icons tooltipped" data-position="top" data-tooltip="Create New Order" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px' }}>add</i>
            </Link>
        </div>
        <div className="row">
            {this.state.loading ?
                <div className="progress blue">
                    <div className="indeterminate" />
                </div>
                :
                <React.Fragment>
                    <table className={this.state.selectedOrder ? 'col s4 m3 l2' : 'col s12'}>
                        <thead>
                            <tr>
                                <th style={{ padding: '4px' }} className={this.state.selectedOrder ? 'hide' : ''}>ID</th>
                                <th style={{ padding: '4px' }}>Order Number</th>
                                <th style={{ padding: '4px' }} className={this.state.selectedOrder ? 'hide' : ''}>Service Date</th>
                                <th style={{ padding: '4px' }} className={this.state.selectedOrder ? 'hide' : ''}>Fee</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orderList?.map(o => (
                                <tr key={o.id}>
                                    <td style={{ padding: '4px' }} className={this.state.selectedOrder ? 'hide' : ''}>{o.id}</td>
                                    <td style={{ padding: '4px', textDecoration: this.state.selectedOrder?.id === o.id ? 'underline' : '' }}><a href="/" onClick={e => { e.preventDefault(); this.selectOrder(e, o) }}>{o.orderNumber}</a></td>
                                    <td style={{ padding: '4px' }} className={this.state.selectedOrder ? 'hide' : ''}>{moment(o.orderDate).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '4px' }} className={this.state.selectedOrder ? 'hide' : ''}>{o.outdateFeeOverride}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={this.state.selectedOrder ? 'col s8 m9 l10' : 'hide'}>
                        <div className="col s4 m3">
                            <table>
                                <tbody>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>ID</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Order Number</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Service Date</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Fee</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Return Service Declined?</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Destruction Only?</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>House Return?</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Regular Trash (lbs)</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Control Trash (lbs)</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>RCRA (lbs)</td></tr>
                                    <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Notes</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col s8 m9">
                            <table>
                                <tbody>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.id}<span className="right"><i className="tiny material-icons" style={{ cursor: 'pointer', padding: '0px' }} onClick={() => this.setState({ selectedOrder: null })}>close</i></span></td></tr>
                                    <tr><td style={{ padding: '4px' }}><Link to={`/regular/${this.state.selectedOrder?.id}`}>{this.state.selectedOrder?.orderNumber}</Link></td></tr>
                                    <tr><td style={{ padding: '4px' }}>{moment(this.state.selectedOrder?.orderDate).format('MM/DD/YYYY')}</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.outdateFeeOverride}%</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.returnServiceDeclined ? 'Yes' : 'No'}</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.isDestructionOnly ? 'Yes' : 'No'}</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.isHouseReturn ? 'Yes' : 'No'}</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.trashInLbs ?? <span>&nbsp;</span>}</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.controlTrashInLbs ?? <span>&nbsp;</span>}</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.rcraInLbs ?? <span>&nbsp;</span>}</td></tr>
                                    <tr><td style={{ padding: '4px' }}>{this.state.selectedOrder?.notes || <span>&nbsp;</span>}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    </div>
    )
}

export default connect()(OrdersTab);