import React from 'react';
import M from '@materializecss/materialize';
import Axios from 'axios';
import { logOut } from '../func';

class LogBackInModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        username: '',
        password: '',
        msg: ''
    })

    componentDidMount = () => {
        M.Modal.init(document.querySelector('#log-back-in-modal'), { dismissible: false })
        document.getElementById('password').addEventListener('keyup', this.capsLockListener);
    }

    componentWillUnmount = () => {
        document.getElementById('password').removeEventListener('keyup', this.capsLockListener);
    }

    capsLockListener = e => {
        const capsLockIsOn = e?.getModifierState('CapsLock')
        this.setState({ capsLockIsOn }, () => {
            M.updateTextFields();
        })
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    submit = (e) => {
        e.preventDefault();

        this.setState({ errMsg: '', loading: true }, () => {
            if (!this.state.username) {
                return this.setState({ errMsg: 'Username must not be blank', loading: false })
            }

            if (!this.state.password) {
                return this.setState({ errMsg: 'Password must not be blank', loading: false })
            }

            Axios.post('/api/v1/auth/update/login/manual', {
                username: this.state.username,
                password: this.state.password
            })
                .then(result => {
                    M.Modal.getInstance(document.querySelector('#log-back-in-modal')).close();
                    localStorage.setItem('auth_data', JSON.stringify({ ...result.data.auth_data, user_timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles' }));
                    localStorage.setItem('employee_data', JSON.stringify(result.data.employee_data))
                    window.location.reload();
                })
                .catch(logOut)
                .finally(() => this.setState({ loading: false }))
        })
    }

    render = () => (
        <div id="log-back-in-modal" className="modal">
            <div className="modal-content">
                <div className="row">
                    <h5 className="center">You have been Logged Out</h5>
                </div>
                <form>
                    <div className="row">
                        <div className="col s12 m6 l4 offset-m3 offset-l4">
                            <div className="row">
                                <div className="input-field">
                                    <input id="username" name="username" type="text" onChange={this.onChange} value={this.state.username} />
                                    <label htmlFor="username">Username</label>
                                </div>
                                <div className="input-field" style={{ marginTop: '30px' }}>
                                    <input id="password" name="password" type="password" onChange={this.onChange} value={this.state.password} />
                                    {this.state.capsLockIsOn ?
                                        <label htmlFor="password" className="red white-text" style={{ padding: '4px', borderRadius: '2px' }}>CAPSLOCK IS ON</label>
                                        :
                                        <label htmlFor="password">Password</label>
                                    }
                                </div>
                                {this.state.loading ?
                                    <div className="preloader-wrapper small active">
                                        <div className="spinner-layer spinner-blue-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div><div className="gap-patch">
                                                <div className="circle"></div>
                                            </div><div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="input-field" style={{ marginTop: '40px' }}>
                                        <button type="submit" className="btn-small blue white-text" onClick={this.submit}>Submit</button>
                                    </div>
                                }
                                <div className="row">
                                    <p className="red-text">{this.state.errMsg}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LogBackInModal;