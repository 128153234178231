import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';

class ReturnHandlerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        returnHandler: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getReturnHandler();
    }

    networkCalls = {
        getReturnHandler: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/party/returnhandler/read/one/by/id', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => this.setState({ returnHandler: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getReturnHandler = () => this.networkCalls.getReturnHandler();

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/returnhandler">Return Handlers</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.state.returnHandler ? `${this.state.returnHandler.name} Detail` : 'Return Handler Detail'}</span>
                </div>
            </div>
            <div className="row">
                <h4>{this.state.returnHandler ? `${this.state.returnHandler.name} Detail` : 'Return Handler Detail'}</h4>
            </div>
            <div className="row">
                <div className="col s12 l8">
                    <div className="card">

                        {this.state.returnHandler && (
                            <div className="card-content">
                                <div className="row" style={{ paddingLeft: '18px' }}>
                                    <p><b>DEA Number: </b>{this.state.returnHandler.deaNumber}</p>
                                    <p><b>Address:</b></p>
                                    <p>{this.state.returnHandler.addressLineOne}</p>
                                    <p>{this.state.returnHandler.addressLineTwo}</p>
                                    <p>{this.state.returnHandler.city}, {this.state.returnHandler.state}</p>
                                    <p><b>Phone: </b>{this.state.returnHandler.phone}</p>
                                    <p><b>Email: </b>{this.state.returnHandler.email}</p>
                                    <p><b>Website: </b>{this.state.returnHandler.website}</p>
                                </div>
                                <div className="row">
                                    <div className="col s12 m4">
                                        <Link to={`/returnhandler/${this.id}/update`} className="col s12 btn-small orange white-text">Edit Return Handler</Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(ReturnHandlerDetail);