import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import { connect } from 'react-redux';

class InvoiceSearch extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        invoiceList: null,
        loading: false,
        pharmacyNameOrCustomerNumber: '',
        orderNumber: '',
        serviceDateStart: '',
        serviceDateEnd: '',
        sortColumn: '',
        reverse: false
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.initListeners()
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelectorAll('.invoice-search').forEach(el => {
            el.addEventListener('keyup', this.enterListener)
        })
    }

    removeListeners = () => {
        document.querySelectorAll('.invoice-search').forEach(el => {
            el.removeEventListener('keyup', this.enterListener);
        })
    }

    enterListener = e => e.keyCode === 13 ? this.search() : null;

    networkCalls = {
        search: {
            func: () => {
                const {
                    pharmacyNameOrCustomerNumber,
                    orderNumber,
                    serviceDateStart,
                    serviceDateEnd
                } = this.state;

                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/invoice/read/many/search', {
                        params: {
                            ...this.authData,
                            pharmacyNameOrCustomerNumber,
                            orderNumber,
                            serviceDateStart: serviceDateStart || '2020-12-01',
                            serviceDateEnd: serviceDateEnd || '2050-12-31',
                        }
                    })
                        .then(result => this.setState({ invoiceList: result.data, sortColumn: '', reverse: false }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    search = e => { e?.preventDefault(); this.networkCalls.search(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    sort = e => {
        const sortColumn = Array.from(e.target.classList).filter(c => c.search('sort-') !== -1)[0].split('-')[1];

        const invoiceList = JSON.parse(JSON.stringify(this.state.invoiceList));
        if (!invoiceList)
            return;

        const reverse = sortColumn === this.state.sortColumn ? !this.state.reverse : false;

        invoiceList.sort((a, b) => {
            let num;
            if (a[sortColumn] === b[sortColumn])
                return 0;
            else
                num = a[sortColumn] > b[sortColumn] ? 1 : -1;

            if (reverse)
                num *= -1;

            return num;
        })

        this.setState({ sortColumn, reverse, invoiceList })
    }

    render = () => (
        <div className="main">
            <div style={{ display: 'flex' }}>
                <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                <Link to="/">Home</Link>
                <i className="material-icons">chevron_right</i>
                <Link to="/accounting">Accounting</Link>
                <i className="material-icons">chevron_right</i>
                <span className="grey-text">Invoice Search</span>
            </div>
            <div className="row">
                <h5 className="center">Invoice Search</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input id="pharmacyNameOrCustomerNumber" className="invoice-search" type="text" onChange={this.onChange} value={this.state.pharmacyNameOrCustomerNumber} />
                    <label htmlFor="pharmacyNameOrCustomerNumber">Pharmacy Name/Customer Number</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="orderNumber" className="invoice-search" type="text" onChange={this.onChange} value={this.state.orderNumber} />
                    <label htmlFor="orderNumber">Order Number</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="serviceDateStart" className="invoice-search" type="date" onChange={this.onChange} value={this.state.serviceDateStart} />
                    <label htmlFor="serviceDateStart">Order Date Start</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="serviceDateEnd" className="invoice-search" type="date" onChange={this.onChange} value={this.state.serviceDateEnd} />
                    <label htmlFor="serviceDateEnd">Order Date End</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.search}>Search</a>
                </div>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th style={{ padding: '2px', cursor: 'pointer' }} className="sort-pharmacyName" onClick={this.sort}>Pharmacy {this.state.sortColumn === 'pharmacyName' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            <th style={{ padding: '2px', cursor: 'pointer' }} className="sort-invoiceId" onClick={this.sort}>Invoice # {this.state.sortColumn === 'invoiceId' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            <th style={{ padding: '2px', cursor: 'pointer' }} className="sort-orderNumber" onClick={this.sort}>Order # {this.state.sortColumn === 'orderNumber' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            <th style={{ padding: '2px', cursor: 'pointer' }} className="sort-serviceDate" onClick={this.sort}>Service Date {this.state.sortColumn === 'serviceDate' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            <th style={{ padding: '2px', cursor: 'pointer' }} className="sort-value" onClick={this.sort}>Value {this.state.sortColumn === 'value' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.invoiceList?.map(i => (
                            <tr key={i.id}>
                                <td style={{ padding: '2px' }}><Link to={`/pharmacy/${i.pharmacyId}`}>{i.pharmacyName}</Link></td>
                                <td style={{ padding: '2px' }}><Link to={`/invoice/update/${i.id}`}>{i.id.toString().padStart(4, '0')}</Link></td>
                                <td style={{ padding: '2px' }}><Link to={`/regular/${i.orderId}`}>{i.orderNumber}</Link></td>
                                <td style={{ padding: '2px' }}>{moment(i.serviceDate).format('MM/DD/YYYY')}</td>
                                <td style={{ padding: '2px' }}>{parseFloat(i.value).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect()(InvoiceSearch);