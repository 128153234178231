import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { logOut, registerRoutes } from '../../../func';
import { IconButton, Input, CheckBox, TextArea, TableSelect } from '../../../layout/form';
import M from '@materializecss/materialize';

class GeneralTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        orderHeader: null,
        editing: false,
        emailing: false,
        newValues: null,
        customerEmailList: [],
        email: this.initEmailValues()
    })

    initEmailValues = () => ({
        additionalEmails: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.networkCalls.getOrder();
        this.networkCalls.getPharmacies();
        this.networkCalls.getPharmacyContacts();
        window.addEventListener('resize', this.setRowHeights);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setRowHeights);
        this.removeTooltips();
        this.removeModal();
    }

    initModal = () => {
        setTimeout(() => {
            M.Modal.init(document.querySelectorAll('#email-modal'))
        }, 100)
    }
    removeModal = () => document.querySelectorAll('.modal').forEach(el => M.Modal.getInstance(el)?.destroy());

    initToolTips = () => setTimeout(() => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 }), 100)

    removeTooltips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt) { tt.destroy() }
        })
    }

    initAutoComplete = () => M.Autocomplete.init(document.querySelector('#pharmacyName'), {
        data: this.state.pharmacyListAsObject,
        onAutocomplete: pharmacyName => this.setState(p => ({
            newValues: {
                ...p.newValues,
                pharmacyName
            }
        }))
    })

    disposeAutoComplete = () => M.Autocomplete.getInstance(document.querySelector('#pharmacyName'))?.destroy();

    setRowHeights = e => {
        const ids = [
            'id',
            'orderNumber',
            'parentOrderNumber',
            'pharmacy',
            'fee',
            'trash',
            'control',
            'rcra',
            'destructionOnly',
            'returnServiceDeclined',
            'houseReturn',
            'notes'
        ];

        ids.forEach(id => {
            document.querySelector(`#${id}`)?.setAttribute('height', document.querySelector(`#label-${id}`)?.clientHeight + 1);
        })

    }

    networkCalls = {
        getOrder: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/order/header/read/one/by/id/simple', {
                        params: {
                            ...this.authData,
                            id: this.props.orderHeaderId
                        }
                    })
                        .then(result => this.setState({ orderHeader: result.data }, () => {
                            this.initToolTips();
                            this.initModal();
                        }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }, this.setRowHeights))
                })
            },
            type: 'r'
        },
        getPharmacies: {
            func: () => {
                Axios.get('/api/v1/pharmacy/read/all/simple', {
                    params: this.authData
                })
                    .then(result => {
                        const pharmacyList = result.data;
                        const pharmacyListAsObject = {};

                        pharmacyList.forEach(p => pharmacyListAsObject[`${p.name} - ${p.customerNumber}`] = null);

                        this.setState({ pharmacyList, pharmacyListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        updateOrderHeader: {
            func: () => {
                const pharmacyId = this.state.pharmacyList.filter(p => `${p.name} - ${p.customerNumber}` === document.querySelector('#pharmacyName').value)[0]?.id;
                if (!pharmacyId)
                    return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Pharmacy Not Found', class: 'red white-text' } })

                this.setState({ updating: true }, () => {

                    Axios.post('/api/v1/order/header/update/one', {
                        ...this.authData,
                        id: this.state.newValues.id,
                        pharmacyId,
                        orderNumber: this.state.newValues.orderNumber,
                        trashInLbs: this.state.newValues.trashInLbs || null,
                        outdateFeeOverride: this.state.newValues.outdateFeeOverride || 0,
                        notes: this.state.newValues.notes || null,
                        rcraInLbs: this.state.newValues.rcraInLbs || null,
                        controlTrashInLbs: this.state.newValues.controlTrashInLbs || null,
                        returnServiceDeclined: this.state.newValues.returnServiceDeclined ? true : false,
                        isDestructionOnly: this.state.newValues.isDestructionOnly ? true : false,
                        isHouseReturn: this.state.newValues.isHouseReturn ? true : false,
                        parentOrderNumber: this.state.newValues.parentOrderNumber || null
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.disposeAutoComplete();
                            this.removeTooltips();
                            this.setState({ editing: false }, this.networkCalls.getOrder)
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ updating: false }))
                })
            },
            type: 'u'
        },
        getPharmacyContacts: {
            func: () => {
                Axios.get('/api/v1/pharmacy/contact/read/emails/by/orderheaderid', {
                    params: {
                        ...this.authData,
                        orderHeaderId: this.props.orderHeaderId
                    }
                })
                    .then(result => {
                        const customerEmailList = [];
                        result.data.forEach(row => customerEmailList.push({ key: row.emailAddress, value: row.emailAddress, display: row.emailAddress }))
                        this.setState({ customerEmailList })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        send: {
            func: () => {
                this.setState({ emailing: true }, () => {
                    const emailList = M.FormSelect.getInstance(document.querySelector('#customerEmails')).getSelectedValues();
                    const additionalEmails = this.state.email.additionalEmails.split(',');
                    additionalEmails.forEach(email => { if (email) { emailList.push(email.trim()) } })

                    Axios.post('/api/v1/order/header/email/sendalphabeticalreport', {
                        ...this.authData,
                        orderHeaderId: this.props.orderHeaderId,
                        emailList
                    })
                        .then(result => {
                            this.props.dispatch(result.data)
                            M.Modal.getInstance(document.querySelector('#email-modal')).close();
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ emailing: false }))
                })
            },
            type: 'r'
        },
    }

    editOrder = () => {
        this.removeTooltips();
        this.removeModal();
        this.setState(p => ({
            editing: true,
            newValues: {
                id: p.orderHeader.id,
                orderNumber: p.orderHeader.orderNumber,
                parentOrderNumber: p.orderHeader.parentOrderNumber || '',
                pharmacyName: `${p.orderHeader.pharmacyName} - ${p.orderHeader.customerNumber}`,
                outdateFeeOverride: p.orderHeader.outdateFeeOverride || 0,
                trashInLbs: p.orderHeader.trashInLbs,
                controlTrashInLbs: p.orderHeader.controlTrashInLbs,
                rcraInLbs: p.orderHeader.rcraInLbs,
                isDestructionOnly: p.orderHeader.isDestructionOnly,
                returnServiceDeclined: p.orderHeader.returnServiceDeclined,
                isHouseReturn: p.orderHeader.isHouseReturn,
                notes: p.orderHeader.notes
            }
        }), () => {
            this.initToolTips();
            this.initAutoComplete();
        })
    }

    cancelEdit = () => {
        this.removeTooltips();
        this.disposeAutoComplete();

        this.setState({ editing: false, newValues: null }, () => {
            this.setRowHeights();
            this.initToolTips();
            this.initModal();
        })
    }

    onChange = e => this.setState(p => ({ newValues: { ...p.newValues, [e.target.id]: e.target.value } }))
    checkboxChange = e => this.setState(p => ({ newValues: { ...p.newValues, [e.target.id]: !p.newValues[e.target.id] } }))
    emailChange = e => this.setState(p => ({ email: { ...p.email, [e.target.id]: e.target.value } }))

    openEmailModal = () => {
        M.Modal.getInstance(document.querySelector('#email-modal')).open();
        M.FormSelect.init(document.querySelector('#customerEmails'));
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            {this.state.loading ?
                <div className="row">
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                </div>
                :
                <div>
                    {this.state.editing ?
                        <div>
                            <div className="row">
                                <div className="row">
                                    <div className="col s4 m3 l2">
                                        <table>
                                            <tbody>
                                                <tr id="label-id"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Order ID</td></tr>
                                                <tr id="label-orderNumber"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Order Number</td></tr>
                                                <tr id="label-parentOrderNumber"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Parent Order Number</td></tr>
                                                <tr id="label-pharmacy"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Pharmacy</td></tr>
                                                <tr id="label-fee"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Fee Override</td></tr>
                                                <tr id="label-trash"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Trash In Lbs</td></tr>
                                                <tr id="label-control"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Control Trash In Lbs</td></tr>
                                                <tr id="label-rcra"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>RCRA In Lbs</td></tr>
                                                <tr id="label-destructionOnly"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Destruction Only</td></tr>
                                                <tr id="label-returnServiceDeclined"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Return Service Declined</td></tr>
                                                <tr id="label-houseReturn"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>House Return</td></tr>
                                                <tr id="label-notes"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Notes</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col s8 m9 l10">
                                        <table>
                                            <tbody>
                                                <Input id="id" type="text" value={this.state.newValues.id} col="s12 m2" disabled={true} />
                                                <Input id="orderNumber" type="text" value={this.state.newValues.orderNumber} onChange={this.onChange} col="s12 m2" disabled={this.props.path !== '/warehouse/manager/regular/:id'} />
                                                <Input id="parentOrderNumber" value={this.state.newValues.parentOrderNumber} onChange={this.onChange} col="s12 m2" disabled={this.props.path !== '/warehouse/manager/regular/:id'} />
                                                <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
                                                    <td style={{ padding: '0px' }}>
                                                        <div className="input-field" style={{ margin: '0px', padding: '0px' }}>
                                                            <input
                                                                id="pharmacyName"
                                                                type="text"
                                                                className="col s12 m6 autocomplete"
                                                                style={{ margin: '0px', padding: '0px', height: '30.5px' }}
                                                                value={this.state.newValues.pharmacyName}
                                                                onChange={this.onChange}
                                                                disabled={this.props.path !== '/warehouse/manager/regular/:id'}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <Input id="outdateFeeOverride" type="number" value={this.state.newValues.outdateFeeOverride} onChange={this.onChange} col="s6 m1" disabled={this.props.path !== '/warehouse/manager/regular/:id'} />
                                                <Input id="trashInLbs" type="number" value={this.state.newValues.trashInLbs} onChange={this.onChange} col="s6 m1" />
                                                <Input id="controlTrashInLbs" type="number" value={this.state.newValues.controlTrashInLbs} onChange={this.onChange} col="s6 m1" />
                                                <Input id="rcraInLbs" type="number" value={this.state.newValues.rcraInLbs} onChange={this.onChange} col="s6 m1" />
                                                <CheckBox id="isDestructionOnly" value={this.state.newValues.isDestructionOnly} onChange={this.checkboxChange} col="s12 m2" disabled={this.props.path !== '/warehouse/manager/regular/:id'} />
                                                <CheckBox id="returnServiceDeclined" value={this.state.newValues.returnServiceDeclined} onChange={this.checkboxChange} col="s12 m2" disabled={this.props.path !== '/warehouse/manager/regular/:id'} />
                                                <CheckBox id="isHouseReturn" value={this.state.newValues.isHouseReturn} onChange={this.checkboxChange} col="s12 m2" disabled={this.props.path !== '/warehouse/manager/regular/:id'} />
                                                <TextArea id="notes" value={this.state.newValues.notes} onChange={this.onChange} col="s12 m7" disabled={this.props.path !== '/warehouse/manager/regular/:id'} />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <IconButton icon="close" tooltip="Cancel Changes" onClick={this.cancelEdit} disabled={this.state.updating} />
                                <IconButton icon="upload" tooltip="Update Order Header" onClick={this.networkCalls.updateOrderHeader} disabled={this.state.updating} />
                            </div>
                        </div>
                        :
                        <div>
                            <div className="row">
                                <IconButton icon="edit" disabled={!this.state.pharmacyList} onClick={this.editOrder} tooltip="Edit Order" />
                                <IconButton
                                    icon="email"
                                    onClick={this.openEmailModal}
                                    tooltip="Send Customer Email"
                                />
                            </div>
                            <div className="row">
                                <div className="col s4 m3 l2">
                                    <table>
                                        <tbody>
                                            <tr id="label-id"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Order ID</td></tr>
                                            <tr id="label-orderNumber"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Order Number</td></tr>
                                            <tr id="label-parentOrderNumber"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Parent Order Number</td></tr>
                                            <tr id="label-pharmacy"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Pharmacy</td></tr>
                                            <tr id="label-fee"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Fee Override</td></tr>
                                            <tr id="label-trash"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Trash In Lbs</td></tr>
                                            <tr id="label-control"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Control Trash In Lbs</td></tr>
                                            <tr id="label-rcra"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>RCRA In Lbs</td></tr>
                                            <tr id="label-destructionOnly"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Destruction Only</td></tr>
                                            <tr id="label-returnServiceDeclined"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Return Service Declined</td></tr>
                                            <tr id="label-houseReturn"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>House Return</td></tr>
                                            <tr id="label-notes"><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Notes</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col s8 m9 l10">
                                    <table>
                                        <tbody>
                                            <tr id="id"><td style={{ padding: '4px' }}>{this.state.orderHeader?.id}</td></tr>
                                            <tr id="orderNumber"><td style={{ padding: '4px' }}>{this.state.orderHeader?.orderNumber}</td></tr>
                                            <tr id="parentOrderNumber"><td style={{ padding: '4px' }}>{this.state.orderHeader?.parentOrderNumber}</td></tr>
                                            <tr id="pharmacy"><td style={{ padding: '4px' }}>{this.state.orderHeader?.pharmacyName} - {this.state.orderHeader?.customerNumber}</td></tr>
                                            <tr id="fee"><td style={{ padding: '4px' }}>{this.state.orderHeader?.outdateFeeOverride || 0}%</td></tr>
                                            <tr id="trash"><td style={{ padding: '4px' }}>{this.state.orderHeader?.trashInLbs}</td></tr>
                                            <tr id="control"><td style={{ padding: '4px' }}>{this.state.orderHeader?.controlTrashInLbs}</td></tr>
                                            <tr id="rcra"><td style={{ padding: '4px' }}>{this.state.orderHeader?.rcraInLbs}</td></tr>
                                            <tr id="destrucionOnly"><td style={{ padding: '4px' }}>{this.state.orderHeader?.isDestructionOnly ? 'Yes' : 'No'}</td></tr>
                                            <tr id="returnServiceDeclined"><td style={{ padding: '4px' }}>{this.state.orderHeader?.returnServiceDeclined ? 'Yes' : 'No'}</td></tr>
                                            <tr id="houseReturn"><td style={{ padding: '4px' }}>{this.state.orderHeader?.isHouseReturn ? 'Yes' : 'No'}</td></tr>
                                            <tr id="notes"><td style={{ padding: '4px' }}>{this.state.orderHeader?.notes}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div id="email-modal" className="modal">
                                <div className="modal-content">
                                    <div className="row">
                                        <h5>Send Alphabetical Report</h5>
                                    </div>
                                    <div className="row">
                                        <div className="col s5 m4 l3">
                                            <table>
                                                <tbody>
                                                    <tr><td style={{ padding: '4px' }}>Customer Emails</td></tr>
                                                    <tr><td style={{ padding: '4px' }}>Additional Emails (Comma Separated)</td></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col s7 m8 l9">
                                            <div className="row">
                                                <table>
                                                    <tbody>
                                                        <TableSelect id="customerEmails" col="s12 m8" options={this.state.customerEmailList} multiple={true} />
                                                        <Input id="additionalEmails" type="text" value={this.state.email.additionalEmails} onChange={this.emailChange} col="s12" />
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <IconButton icon="send" tooltip="Send Email" onClick={() => this.networkCalls.send()} disabled={this.state.emailing} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default connect()(GeneralTab);