import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../../func';

class NewCustomerReport extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        customerList: null,
        loading: false,
        sortColumn: '',
        reverse: false
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getCustomerList();
    }

    networkCalls = {
        getCustomerList: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/all/newcustomers', {
                        params: this.authData
                    })
                        .then(result => this.setState({ customerList: result.data, sortColumn: '', reverse: false }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getCustomerList = () => this.networkCalls.getCustomerList();

    sort = e => {
        const sortColumn = Array.from(e.target.classList).filter(c => c.search('sort-') !== -1)[0].split('-')[1];

        const customerList = JSON.parse(JSON.stringify(this.state.customerList));
        if (!customerList)
            return;

        const reverse = sortColumn === this.state.sortColumn ? !this.state.reverse : false;

        customerList.sort((a, b) => {
            let num;
            if (a[sortColumn] === b[sortColumn])
                return 0;
            else
                num = a[sortColumn] > b[sortColumn] ? 1 : -1;

            if (reverse)
                num *= -1;

            return num;
        })

        this.setState({ sortColumn, reverse, customerList })
    }

    render = () => (
        <div className="main">
            <div style={{ display: 'flex' }}>
                <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                <Link to="/">Home</Link>
                <i className="material-icons">chevron_right</i>
                <Link to="/accounting">Accounting</Link>
                <i className="material-icons">chevron_right</i>
                <span className="grey-text">New Customers Report</span>
            </div>
            <div className="row">
                <h5 className="center">New Customer Report</h5>
                <div className="center"><small>6 Months Running</small></div>
            </div>
            <div className="row">
                {this.state.loading ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-pharmacyName">Pharmacy Name {this.state.sortColumn === 'pharmacyName' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-customerNumber">Customer Number {this.state.sortColumn === 'customerNumber' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-serviceDate">Service Date {this.state.sortColumn === 'serviceDate' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-repName">Rep On-site {this.state.sortColumn === 'repName' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.customerList?.map(c => (
                                <tr key={c.id}>
                                    <td style={{ padding: '2px' }}>{c.pharmacyName}</td>
                                    <td style={{ padding: '2px' }}>{c.customerNumber}</td>
                                    <td style={{ padding: '2px' }}>{moment(c.serviceDate).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '2px' }}>{c.repName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default NewCustomerReport;