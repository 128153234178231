import Axios from "axios";
import { useEffect, useState } from "react";
import { logOut, registerRoutes } from "../../../../func";
import { AutoComplete, Input, TableSelect } from "../../../../layout/form";
import { connect } from 'react-redux';
const pageId = crypto.randomUUID();

const CreateMedTab = props => {
    const {
        passRedbookToParent,
        setTab,
        path
    } = props;

    const initMedValues = () => ({
        ndc: '',
        medName: '',
        deaClass: '',
        manufacturerId: -1,
        unitId: -1,
        packSize: '',
        liquidSize: '',
        awp: '',
        returnPolicyId: -1 //This value does not change on this page/sorters do not set return policies
    })
    const [medValues, setMedValues] = useState(initMedValues())

    const [unitList, setUnitList] = useState([]);
    const [manufacturer, setManufacturer] = useState(null);
    const [saving, setSaving] = useState(false);



    const [networkCalls] = useState(registerRoutes({
        getManufacturers: {
            func: abortController => {
                Axios.get('/api/v1/party/manufacturer/read/all', {
                    params: JSON.parse(localStorage.getItem('auth_data')),
                    signal: abortController.signal
                })
                    .then(result => {
                        const manufacturerList = result.data;
                        const manufacturerListAsObject = {};
                        manufacturerList.forEach(mfr => manufacturerListAsObject[mfr.name] = null);

                        setManufacturer({ manufacturerList, manufacturerListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getUnits: {
            func: abortController => {
                Axios.get('/api/v1/unit/read/all', {
                    params: JSON.parse(localStorage.getItem('auth_data')),
                    signal: abortController.signal
                })
                    .then(result => {
                        const unitList = [];
                        result.data.forEach(u => unitList.push({ key: u.id, value: u.id, display: u.unit }))
                        unitList.unshift({ key: -1, value: -1, display: '' })

                        setUnitList(unitList)
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        createMed: {
            func: values => {
                setSaving(true);

                Axios.post('/api/v1/redbook/create/one', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    ...values,
                })
                    .then(result => {
                        props.dispatch(result.data);
                        setMedValues(initMedValues())
                        passRedbookToParent({ ndc: values.ndc })
                        setTab('general');
                    })
                    .catch(logOut)
                    .finally(() => setSaving(false))
            },
            type: 'c'
        }
    }, path))

    useEffect(() => {
        const abortController = new AbortController();
        networkCalls.getManufacturers(abortController);
        networkCalls.getUnits(abortController);

        return () => abortController.abort();
    }, [networkCalls])

    const getClasses = () => (['', 'R', '2', '3', '4', '5'])
    const onChange = e => setMedValues(p => ({ ...p, [e.target.id.split('-')[0]]: e.target.value }))

    const createMed = e => {
        e?.preventDefault();
        const values = medValues;

        const { manufacturerList } = manufacturer;
        values.manufacturerId = manufacturerList.filter(m => m.name === document.querySelector(`#manufacturerId-${pageId}`).value)[0]?.id || -1;
        values.createdById = JSON.parse(localStorage.getItem('employee_data'))?.id || -1;

        networkCalls.createMed(values);
    }

    return (
        <div style={{ padding: '1vh', position: 'relative', height: '99%', margin: '0px' }}>
            <div className="row" style={{ height: '100%', marginBottom: '0px' }}>
                <div className="col s4">
                    <table>
                        <tbody>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">NDC</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">Med Name</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">Manufacturer</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">Unit</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">Pack Size</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">Liquid Size</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">AWP</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }} className="bold">DEA Class</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8">
                    <div className="row">
                        <table>
                            <tbody>
                                <Input id={`ndc-${pageId}`} type="text" value={medValues.ndc} onChange={onChange} col="s12 m4" />
                                <Input id={`medName-${pageId}`} type="text" value={medValues.medName} onChange={onChange} col="s12" />
                                <AutoComplete id={`manufacturerId-${pageId}`} data={manufacturer?.manufacturerListAsObject} limit={8} col="s12 m5" />
                                <TableSelect id={`unitId-${pageId}`} options={unitList} value={medValues.unitId} onChange={onChange} col="s4 m2" />
                                <Input id={`packSize-${pageId}`} type="text" value={medValues.packSize} onChange={onChange} col="s6 m2" />
                                <Input id={`liquidSize-${pageId}`} type="text" value={medValues.liquidSize} onChange={onChange} col="s6 m2" />
                                <Input id={`awp-${pageId}`} type="text" value={medValues.awp} onChange={onChange} col="s4 m2" />
                                <TableSelect id={`deaClass-${pageId}`} options={getClasses()} value={medValues.deaClass} onChange={onChange} col="s6 m3" />
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 l3">
                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" disabled={saving} onClick={createMed}>Save</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(CreateMedTab);