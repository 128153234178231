import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RegularOrderOverview from './regularOrderOverview';
import OrderDetailRouter from '../orderDetail/orderDetailRouter';

const RegularOrderRouter = props => (
    <Switch>
        <Route exact path="/regular" component={RegularOrderOverview} />
        <Route path="/regular/:id" component={OrderDetailRouter} />
    </Switch>
);

export default RegularOrderRouter;