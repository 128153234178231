import Axios from "axios";
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../../func';
import InvoiceTab from './invoiceTab';
import GeneralTab from './generalTab';
import ChecksTab from "./checksTab";
import M from '@materializecss/materialize';

class CreditReport extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        loadingOrderSummary: false,
        loadingAccountSummary: false,
        tab: 'general',
        data: null,
        orderNumber: '',
        accountSummaryURL: null,
        orderSummaryURL: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.initListeners();
        if(this.props.location.state?.orderNumber)
            this.setState({orderNumber: this.props.location.state.orderNumber}, () => {
                M.updateTextFields();
                this.networkCalls.getData();
            })
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelector('#orderNumber').addEventListener('keyup', this.enterListener);
    }

    removeListeners = () => {
        document.querySelector('#orderNumber').removeEventListener('keyup', this.enterListener);
    }

    enterListener = e => {
        if (e.keyCode === 13)
            this.networkCalls.getData();
    }

    networkCalls = {
        getData: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/invoice/read/one/creditreport', {
                        params: {
                            ...this.authData,
                            orderNumber: this.state.orderNumber
                        }
                    })
                        .then(result => this.hydrateState(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        printOrderSummary: {
            func: () => {
                if (this.state.data)
                    this.setState({ loadingOrderSummary: true, tab: 'orderSummary', orderSummaryURL: null }, () => {
                        Axios.get('/api/v1/invoice/print/ordersummary', {
                            params: {
                                ...this.authData,
                                orderHeaderId: this.state.data.orderHeader.id,
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles',
                            },
                            responseType: 'arraybuffer'
                        })
                            .then(result => {
                                const blob = new Blob([result.data], { type: 'application/pdf' });
                                const url = window.URL.createObjectURL(blob) + '#view=Fit';
                                this.setState({orderSummaryURL: url})
                            })
                            .catch(logOut)
                            .finally(() => this.setState({ loadingOrderSummary: false }))
                    })
                else
                    this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Order Loaded', class: 'orange white-text' } })
            },
            type: 'r'
        },
        printAccountSummary: {
            func: () => {
                if (this.state.data)
                    this.setState({ loadingAccountSummary: true, tab: 'accountSummary', accountSummaryURL: null }, () => {
                        Axios.get('/api/v1/invoice/print/accountsummary', {
                            params: {
                                ...this.authData,
                                pharmacyId: this.state.data.pharmacy.id,
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles',
                            },
                            responseType: 'arraybuffer'
                        })
                            .then(result => {
                                const blob = new Blob([result.data], { type: 'application/pdf' });
                                const url = window.URL.createObjectURL(blob) + '#view=Fit';
                                this.setState({ accountSummaryURL: url })
                            })
                            .catch(logOut)
                            .finally(() => this.setState({ loadingAccountSummary: false }))
                    })
                else
                    this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Order Loaded', class: 'orange white-text' } })
            },
            type: 'r'
        }
    }

    hydrateState = obj => {
        this.setState({ data: obj, orderNumber: '', tab: 'general' })
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] })

    getContent = () => {
        let component = null;

        switch (this.state.tab) {
            case 'general':
                component = <GeneralTab
                    orderHeader={this.state.data?.orderHeader}
                    pharmacy={this.state.data?.pharmacy}
                />
                break;
            case 'invoices':
                component = <InvoiceTab
                    invoices={this.state.data?.invoices}
                />
                break;
            case 'checks':
                component = <ChecksTab
                    checks={this.state.data?.outboundChecks}
                />
                break;
            case 'accountSummary':
                component = (
                    <div>
                        <embed style={{ width: '100%', minHeight: 'CALC(100vh - 80px)' }} src={this.state.accountSummaryURL} type="application/pdf" />
                    </div>
                )
                break;
            case 'orderSummary':
                component = (
                    <div>
                        <embed style={{ width: '100%', minHeight: 'CALC(100vh - 80px)' }} src={this.state.orderSummaryURL} type="application/pdf" />
                    </div>
                )
                break;
            default:
                break;
        }

        return component;
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Credit Report: {this.state.data?.orderHeader.orderNumber}</span>
                </div>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <div className="input-field col s12">
                        <input id="orderNumber" type="text" onChange={this.onChange} value={this.state.orderNumber} />
                        <label htmlFor="orderNumber">Search: Order Number</label>
                    </div>
                    <ul className="collection">
                        <li id="tab-general" className={`collection-item ${this.state.tab === 'general' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>{this.state.data ? this.state.data.orderHeader.orderNumber : 'General'}</li>
                        <li id="tab-invoices" className={`collection-item ${this.state.tab === 'invoices' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Invoices</li>
                        <li id="tab-checks" className={`collection-item ${this.state.tab === 'checks' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Checks</li>
                        {this.state.loadingOrderSummary ?
                            <li className="collection-item"><div className="progress blue"><div className="indeterminate" /></div></li>
                            :
                            <li className={`collection-item waves-effect waves-dark ${this.state.tab === 'orderSummary' ? 'blue white-text' : ''}`} onClick={() => this.networkCalls.printOrderSummary()} style={{ cursor: 'pointer', width: '100%' }}>Order Summary</li>
                        }
                        {this.state.loadingAccountSummary ?
                            <li className="collection-item"><div className="progress blue"><div className="indeterminate" /></div></li>
                            :
                            <li className={`collection-item waves-effect waves-dark ${this.state.tab === 'accountSummary' ? 'blue white-text' : ''}`} onClick={() => this.networkCalls.printAccountSummary()} style={{ cursor: 'pointer', width: '100%' }}>Account Summary</li>
                        }
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    {this.getContent()}
                </div>
            </div>
        </div>
    )
}

export default connect()(CreditReport);