import React from 'react';
import { openSideNav, logOut, registerRoutes } from '../../../func';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { connect } from 'react-redux';
import PagesTab from './pagesTab';
import EmployeesTab from './employeesTab';


class PermissionOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        pageList: null,
        employeeList: null,
        tab: 'pages'
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getPages();
        this.getEmployees();
    }

    networkCalls = {
        getPages: {
            func: () => {
                Axios.get('/api/v1/website/administration/read/all/orxtoolspage', { params: this.authData })
                    .then(result => {
                        const pageListAsObject = {};
                        result.data.forEach(row => pageListAsObject[row.route] = null);
                        this.setState({ pageList: result.data, pageListAsObject })
                    })
                    .catch(logOut);
            },
            type: 'r'
        },
        getEmployees: {
            func: () => {
                Axios.get('/api/v1/employee/read/all', { params: this.authData })
                    .then(result => {
                        const employeeListAsObject = {}
                        result.data.forEach(row => employeeListAsObject[`${row.firstName} ${row.lastName}`] = null)
                        this.setState({ employeeList: result.data, employeeListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        }

    }

    getEmployees = () => this.networkCalls.getEmployees();
    getPages = () => this.networkCalls.getPages();

    setTab = e => {
        const tab = e.target.id.split('-')[1];
        this.setState({ tab })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Website Admin</span>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Permissions</span>
                </div>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection">
                        <li id="tab-pages" className={`collection-item ${this.state.tab === 'pages' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Pages</li>
                        <li id="tab-employees" className={`collection-item ${this.state.tab === 'employees' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Employees</li>
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    {this.state.tab === 'pages' && <PagesTab
                        pageList={this.state.pageList}
                        employeeList={this.state.employeeList}
                        employeeListAsObject={this.state.employeeListAsObject}
                        getPages={this.getPages}
                    />}
                    {this.state.tab === 'employees' && <EmployeesTab
                        pageList={this.state.pageList}
                        pageListAsObject={this.state.pageListAsObject}
                        employeeList={this.state.employeeList}
                        employeeListAsObject={this.state.employeeListAsObject}
                    />}
                </div>
            </div>
        </div>
    )
}

export default connect()(PermissionOverview);