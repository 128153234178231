import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';

class Corrections extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        correctionsList: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getCorrections();
    }

    networkCalls = {
        getCorrections: {
            func: () => {
                Axios.get('/api/v1/order/detail/read/all/corrections', {
                    params: this.authData
                })
                    .then(result => this.setState({ correctionsList: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        }
    }

    getCorrections = () => this.networkCalls.getCorrections();

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Order Detail Corrections</span>
                </div>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>

                            <th>Pharmacy</th>
                            <th>Order Number</th>
                            <th>Order Date</th>
                            <th>NDC</th>
                            <th>Med</th>
                            <th>Full Pack</th>
                            <th>Partial Count</th>
                            <th>Lot Number</th>
                            <th>Exp Date</th>
                            <th>Returnable</th>
                            <th>Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.correctionsList?.map(c => (
                            <React.Fragment key={JSON.stringify(c)}>
                                <tr>
                                    <td style={{ padding: '2px' }}>{c.pharmacyName}</td>
                                    <td style={{ padding: '2px' }}>{c.orderNumber}</td>
                                    <td style={{ padding: '2px' }}>{moment(c.orderDate).format('MM/DD/YY')}</td>
                                    <td style={{ padding: '2px' }}>{c.correction.redbook.ndc}</td>
                                    <td style={{ padding: '2px' }}>{c.correction.redbook.medName}</td>
                                    <td style={{ padding: '2px' }}>{c.correction.fullPackage}</td>
                                    <td style={{ padding: '2px' }}>{c.correction.partialPackage}</td>
                                    <td style={{ padding: '2px' }}>{c.correction.lotNumber}</td>
                                    <td style={{ padding: '2px' }}>{moment(c.correction.expiration).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '2px' }}>{c.correction.isReturnable ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '2px' }}>{c.correction.nonReturnableReasonCodeId ? c.correction.nonReturnableReasonCodeId : '-'}</td>
                                </tr>
                                <tr style={{ borderBottom: '2px solid black' }}>
                                    <td style={{ padding: '2px' }}></td>
                                    <td style={{ padding: '2px', fontWeight: '600' }}>Corrected By: </td>
                                    <td style={{ padding: '2px', fontWeight: '600' }}>{c.correction.employee.firstName} {c.correction.employee.lastName}</td>
                                    <td className={c.orderDetail.redbook.id !== c.correction.redbook.id ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{c.orderDetail.redbook.ndc}</td>
                                    <td className={c.orderDetail.redbook.id !== c.correction.redbook.id ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{c.orderDetail.redbook.medName}</td>
                                    <td className={c.orderDetail.fullPackage !== c.correction.fullPackage ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{c.orderDetail.fullPackage}</td>
                                    <td className={c.orderDetail.partialPackage !== c.correction.partialPackage ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{c.orderDetail.partialPackage}</td>
                                    <td className={c.orderDetail.lotNumber !== c.correction.lotNumber ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{c.orderDetail.lotNumber}</td>
                                    <td className={c.orderDetail.expiration !== c.correction.expiration ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{moment(c.orderDetail.expiration).format('MM/DD/YYYY')}</td>
                                    <td className={c.orderDetail.isReturnable !== c.correction.isReturnable ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{c.orderDetail.isReturnable ? 'Yes' : 'No'}</td>
                                    <td className={c.orderDetail.nonReturnableReasonCodeId !== c.correction.nonReturnableReasonCodeId ? 'red white-text bolder' : ''} style={{ padding: '2px' }}>{c.orderDetail.nonReturnableReasonCodeId ? c.orderDetail.nonReturnableReasonCodeId : '-'}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect()(Corrections);