import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';

class AccountsTab extends React.Component {

    componentDidMount = () => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
    componentWillUnmount = () => {
        document.querySelectorAll('.tooltipped').forEach(el => el ? M.Tooltip.getInstance(el).destroy() : null);
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <Link to={`/pharmacy/${this.props.pharmacyId}/accounts`}>
                    <i className="material-icons tooltipped" data-position="top" data-tooltip="Edit Accounts" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px' }}>edit</i>
                </Link>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Wholesaler</th>
                            <th style={{ padding: '4px' }}>Acct Number</th>
                            <th style={{ padding: '4px' }}>Active Date</th>
                            <th style={{ padding: '4px' }}>Expiration</th>
                            <th style={{ padding: '4px' }}>Default?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.accounts?.map(a => (
                            <tr key={a.id}>
                                <td style={{ padding: '4px' }}>{a.wholesalerName}</td>
                                <td style={{ padding: '4px' }}>{a.accountNumber}</td>
                                <td style={{ padding: '4px' }}>{a.activeDate ? moment(a.activeDate).format('MM/DD/YYYY') : '-'}</td>
                                <td style={{ padding: '4px' }}>{a.expiration ? moment(a.expiration).format('MM/DD/YYYY') : '-'}</td>
                                <td style={{ padding: '4px' }}>{a.isDefault ? 'Yes' : 'No'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AccountsTab;