import Axios from "axios";
import moment from "moment";
import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../func';

class Changelog extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        entries: null,
        selectedEntryIndex: -1
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getEntries();
    }

    networkCalls = {
        getEntries: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/website/administration/read/all/changelogentries', {
                        params: this.authData
                    })
                        .then(result => {
                            this.setState({ entries: result.data, selectedEntryIndex: 0 })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Website Change Log</span>
                </div>
            </div>
            <div className="row">
                <h4 className="center">Orx Tools Website Change Log</h4>
            </div>
            {this.state.entries ? (
                <div className="row">
                    <div className="col s4 m3 l2" style={{ maxHeight: 'CALC(100vh - 180px)', overflowY: 'auto' }}>
                        <ul className="collection">
                            {this.state.entries?.map((e, index) => (
                                <li
                                    key={e.id}
                                    className={`collection-item ${this.state.selectedEntryIndex === index ? 'blue white-text' : ''}`}
                                    onClick={e => this.setState({ selectedEntryIndex: index })}
                                >{moment.utc(e.dateWebsiteUpdated).local().format('MM/DD/YY')}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="col s8 m9 l10" style={{ maxHeight: `CALC(100vh - 180px)`, overflowY: 'auto' }}>
                        <div style={{ padding: '1vw' }}>
                            <h5 style={{ textDecoration: 'underline #2196f3' }}>{moment.utc(this.state.entries[this.state.selectedEntryIndex]?.dateWebsiteUpdated).local().format('dddd MMMM Do, YYYY @ h:mm A')}</h5>
                            {this.state.entries[this.state.selectedEntryIndex]?.listItems?.map(li => (
                                <p key={li.id}>&bull; {li.description}</p>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
            {this.state.loading ? (
                <div className="row">
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default connect()(Changelog);