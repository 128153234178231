import React from 'react'
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';

class GeneralTab extends React.Component {

    componentDidMount = () => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
    componentWillUnmount = () => {
        document.querySelectorAll('.tooltipped').forEach(el => el ? M.Tooltip.getInstance(el).destroy() : null);
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <Link to="/pharmacy/create">
                    <i className="material-icons tooltipped" data-position="top" data-tooltip="Create New Pharmacy" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>add</i>
                </Link>
                <Link to={`/pharmacy/${this.props.pharmacy.id}/update`}>
                    <i className="material-icons tooltipped" data-position="top" data-tooltip="Edit Pharmacy" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>edit</i>
                </Link>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <table>
                        <tbody>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>ID</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Customer Number</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Name</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Phone</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Email</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Address</td></tr>
                            {this.props.pharmacy.addressLineTwo ? <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>&nbsp;</td></tr> : null}
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>&nbsp;</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>DEA Number</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Hospital?</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Government?</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>340B?</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Veteran's Affairs?</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>340B Number</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Default Fee Percentage</td></tr>
                            <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Notes</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8 m9 l10">
                    <table className="highlight">
                        <tbody>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.id}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.customerNumber}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.name}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.phone || <span>&nbsp;</span>}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.email || <span>&nbsp;</span>}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.addressLineOne ?? <span>&nbsp;</span>}</td></tr>
                            {this.props.pharmacy.addressLineTwo ? <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.addressLineTwo}</td></tr> : null}
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.city}, {this.props.pharmacy.state} {this.props.pharmacy.zip}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.deaNumber || <span>&nbsp;</span>}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.isHospital ? 'Yes' : 'No'}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.isGov ? 'Yes' : 'No'}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.is340b ? 'Yes' : 'No'}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.isVa ? 'Yes' : 'No'}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.threeFortyBNumber || <span>&nbsp;</span>}</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.defaultFeePercentage || <span>&nbsp;</span>}%</td></tr>
                            <tr><td style={{ padding: '4px' }}>{this.props.pharmacy.notes || <span>&nbsp;</span>}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default GeneralTab;