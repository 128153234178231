import Axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState, useMemo } from "react";
import { logOut, registerRoutes } from "../../../../func";

const CommissionList = props => {
    const {
        path,
        selectedEmployeeId,
        setSelectedCommissionId
    } = props;


    const [loading, setLoading] = useState(false);
    const [commissionList, setCommissionList] = useState(null);
    const [selectedOrderNumber, setSelectedOrderNumber] = useState('');
    const [payPeriods, setPayPeriods] = useState(null);

    const networkCalls = useMemo(() => registerRoutes({
        readCommissionsByEmployeeId: {
            func: employeeId => {
                setLoading(true);
                Axios.get('/api/v2/sales/read/all/commission/by/employeeid', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        employeeId
                    }
                })
                    .then(result => getPayPeriods(result.data))
                    .catch(logOut)
                    .finally(() => setLoading(false))
            },
            type: 'r'
        }
    }, path), [path])

    const ref = useRef({
        payables: []
    })

    useEffect(() => {
        networkCalls.readCommissionsByEmployeeId(selectedEmployeeId);
        ref.current.grandTotal = [];
    }, [selectedEmployeeId, networkCalls])

    const selectRow = c => {
        setSelectedCommissionId(c.newEmployeeCommissionId);
        setSelectedOrderNumber(c.orderNumber)
    }

    const getPayPeriods = arr => {
        const cRates = [];
        arr.forEach(row => {
            if (row.newEmployeeCommissionId && !row.isVa && !cRates.find(r => r.id === row.newEmployeeCommissionId))
                cRates.push({
                    id: row.newEmployeeCommissionId,
                    commissionType: row.commissionType,
                    payOnNewAccountsOnly: row.payOnNewAccountsOnly,
                    paymentIntervalInMonths: parseInt(row.paymentIntervalInMonths),
                    commissionMinimumValue: parseFloat(row.commissionMinimumValue),
                    commissionRate: parseFloat(row.commissionRate),
                    effectiveDate: moment(row.effectiveDate),
                    expirationDate: row.expirationDate ? moment(row.expirationDate) : moment()
                })
        })

        const payPeriods = [];
        let iterator = cRates[0].effectiveDate;
        const dateCap = cRates[cRates.length - 1].expirationDate;

        while (iterator.isBefore(dateCap, 'days')) {
            const commissionRate = cRates.find(rate => iterator.isBetween(rate.effectiveDate, rate.expirationDate, 'days', '[]'));
            const start = iterator.clone();
            let end;

            if (commissionRate) {
                iterator.add(commissionRate.paymentIntervalInMonths, 'months');

                if (iterator.isAfter(commissionRate.expirationDate)) {
                    iterator = commissionRate.expirationDate.clone().add(1, 'days');
                    end = commissionRate.expirationDate.clone();
                }
                else
                    end = iterator.clone().subtract(1, 'days')
            }
            else
                end = dateCap.clone();

            payPeriods.push({
                start,
                end,
                commissionType: commissionRate.commissionType,
                payOnNewAccountsOnly: parseInt(commissionRate.payOnNewAccountsOnly),
                paymentIntervalInMonths: parseInt(commissionRate.paymentIntervalInMonths),
                commissionMinimumValue: parseFloat(commissionRate.commissionMinimumValue),
                commissionRate: parseFloat(commissionRate.commissionRate),
                invoices: 0.00
            })

        }

        arr.forEach(row => {
            const index = payPeriods.findIndex(pp => moment(row.serviceDate).isBetween(pp.start, pp.end, 'days', '[]'))

            if (index !== -1)
                payPeriods[index].invoices += parseFloat(row.invoiceTotal);
        })

        setPayPeriods(payPeriods);
        setCommissionList(arr);
    }

    const getCommissionDue = c => {
        if (!payPeriods) { return }

        const payPeriod = payPeriods.find(pp => moment(c.serviceDate).isBetween(pp.start, pp.end, 'days', '[]'));
        let value;

        if (!payPeriod)
            return 'No Comm. Data';
        else {
            if (payPeriod.payOnNewAccountsOnly && !c.isNewAccount)
                value = 'Not New Acct.'
            else if (payPeriod.commissionMinimumValue > payPeriod.invoices)
                value = 'Min not Met'
            else if (c.isVa)
                value = ((c.invoiceTotal * parseFloat(c.commissionRate)) > parseFloat(c.maxPayout) ? parseFloat(c.maxPayout) : (parseFloat(c.invoiceTotal) * parseFloat(c.commissionRate)) - parseFloat(c.amountPreviouslyPaid));
            else if (payPeriod.commissionType === 'bonus')
                value = ((parseFloat(c.invoiceTotal) * payPeriod.commissionRate) - parseFloat(c.amountPreviouslyPaid))
            else if (payPeriod.commissionType === 'simple')
                value = (payPeriod.commissionRate * (parseFloat(c.invoiceTotal) < parseFloat(c.creditTotal) ? parseFloat(c.invoiceTotal) : parseFloat(c.creditTotal)) - parseFloat(c.amountPreviouslyPaid))
        }

        if (typeof value === 'number')
            if (!ref.current.payables.find(row => row.orderNumber === c.orderNumber))
                ref.current.payables.push({ orderNumber: c.orderNumber, value });

        return typeof value === 'string' ? value : value.toFixed(2);
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading ?

                <div className="progress blue">
                    <div className="indeterminate" />
                </div>
                :
                <div style={{ overflow: 'auto' }}>
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '0px' }}>Pharmacy Name</th>
                                <th style={{ padding: '0px' }}>Order Number</th>
                                <th style={{ padding: '0px' }}>Service Date</th>
                                <th style={{ padding: '0px' }}>Serviced By</th>
                                <th style={{ padding: '0px' }}>Is New Account</th>
                                <th style={{ padding: '0px' }}>Invoice</th>
                                <th style={{ padding: '0px' }}>Credits</th>
                                <th style={{ padding: '0px' }}>Previous Payments</th>
                                <th style={{ padding: '0px', textAlign: 'right' }}>Amt Due</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commissionList?.map(c => (
                                <tr
                                    key={c.orderNumber}
                                    className={selectedOrderNumber === c.orderNumber ? 'blue white-text' : ''}
                                    onClick={() => selectRow(c)}
                                >
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.pharmacyName}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.orderNumber}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{moment(c.serviceDate).format('MM/DD/YY')}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.employeeName}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.isNewAccount ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.invoiceTotal}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.creditTotal}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.amountPreviouslyPaid}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px', textAlign: 'right' }}>{getCommissionDue(c)}</td>
                                </tr>
                            ))}
                            <tr style={{ borderTop: '2px solid black' }} className="bold">
                                <td style={{ padding: '0px' }} colSpan={8}>Grand Total</td>
                                <td style={{ padding: '0px', textAlign: 'right' }}>{ref.current.payables.reduce((sum, next) => sum + next.value, 0).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div >
    )
}

export default CommissionList;