import React from 'react';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';
import Axios from 'axios';
import { logOut } from '../func';
import moment from 'moment';
import notificationSound from '../assets/notification.mp3';


class Reminder extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
        this.clearTimeout = 0;
        this.cearInterval = 0;
    }

    init = () => {
        this.setState(this.initState(), () => {
            this.getReminders();
        })
    }

    initState = () => ({
        reminders: null,
        currentReminder: null,
        createReminderAlarmDate: '',
        createReminderAlarmTime: '',
        createReminderText: '',
        createAlarmDate: '',
        createAlarmTime: ''
    })

    componentDidMount = () => {
        this.getReminders();

        M.Modal.init(document.querySelectorAll('#top-level-reminder-modal'), {
            onOpenStart: this.init
        });

        M.Modal.init(document.querySelectorAll('#top-level-create-reminder-modal'), {
            onOpenStart: () => this.closeModal('top-level-reminder-modal'),
            onCloseStart: () => this.openModal('top-level-reminder-modal')
        });

        M.Modal.init(document.querySelectorAll('#top-level-reminder-detail-modal'), {
            onOpenStart: () => this.closeModal('top-level-reminder-modal'),
            onCloseStart: () => this.openModal('top-level-reminder-modal')
        })
    }

    getReminders = () => {
        Axios.get('/api/v1/reminder/read/all/by/employeeid', {
            params: this.authData
        })
            .then(result => this.setState({ reminders: result.data }, this.startInterval))
            .catch(logOut)
    }

    startInterval = () => {
        this.checkReminders();

        const now = moment();
        const minutesRemaining = (15 - now.minutes() % 15) * 60000 - 6000;
        const secondsRemaining = (60 - now.seconds() % 60) * 1000;

        window.clearTimeout(this.clearTimeout);
        window.clearInterval(this.clearInterval);

        this.clearTimeout = setTimeout(() => {
            this.checkReminders()
            this.clearInterval = setInterval(this.checkReminders, 900000);
        }, minutesRemaining + secondsRemaining);
    }

    checkReminders = () => {
        let playSound = false;

        this.state.reminders?.forEach(reminder => {
            reminder.alarms.forEach(alarm => {
                if (moment(alarm.alarm).diff(moment(), 'seconds') <= 0) {
                    playSound = true;
                    this.props.dispatch({
                        type: 'GLOBAL_TOAST',
                        payload: {
                            msg: `REMINDER: ${reminder.reminder}`,
                            class: 'teal white-text'
                        }
                    })
                }
            })
        })

        if(playSound)
            this.playAudio();

    }

    playAudio = () => {
        const audio = new Audio(notificationSound);
        audio.play();
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    openModal = id => M.Modal.getInstance(document.querySelector(`#${id}`)).open();

    closeModal = id => M.Modal.getInstance(document.querySelector(`#${id}`)).close();

    createReminder = e => {
        e?.preventDefault();
        const date = this.state.createReminderAlarmDate;
        const time = this.state.createReminderAlarmTime;


        if (moment(date + ' ' + time).diff(moment(), 'minutes') < 5)
            return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Alarm must at least 5 minutes from now', class: 'red white-text' } })


        Axios.post('/api/v1/reminder/create/one/reminder', {
            ...this.authData,
            reminder: this.state.createReminderText,
            date,
            time
        })
            .then(result => {
                this.props.dispatch(result.data)
                this.closeModal('top-level-create-reminder-modal');
            })
            .catch(logOut)
    }

    createNewAlarm = e => {
        e?.preventDefault();

        const date = this.state.createAlarmDate;
        const time = this.state.createAlarmTime;


        if (moment(date + ' ' + time).diff(moment(), 'minutes') < 5)
            return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Alarm must at least 5 minutes from now', class: 'red white-text' } })

        Axios.post('/api/v1/reminder/create/one/alarm', {
            ...this.authData,
            employeeReminderId: this.state.currentReminder.id,
            date,
            time
        })
            .then(result => {
                this.props.dispatch(result.data);
                this.closeModal('top-level-reminder-detail-modal');
            })
            .catch(logOut)
    }

    deleteAlarm = (e, id) => {
        e?.preventDefault();

        if (this.state.currentReminder.alarms.length < 2)
            return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Reminder Must Have At Least 1 Alarm', class: 'orange white-text' } })

        Axios.post('api/v1/reminder/delete/one/alarm', {
            ...this.authData,
            id
        })
            .then(result => {
                this.props.dispatch(result.data)
                this.closeModal('top-level-reminder-detail-modal')
            })
            .catch(logOut)
    }

    deleteReminder = (e, id) => {
        e?.preventDefault();

        Axios.post('/api/v1/reminder/delete/one/reminder', {
            ...this.authData,
            id
        })
            .then(result => {
                this.props.dispatch(result.data);
                this.getReminders();
            })
            .catch(logOut)
    }

    render = () => (
        <div style={{ maxHeight: '0px', maxWidth: '0px' }}>
            <div id="top-level-reminder-modal" className="modal" style={{ minWidth: '67vw', minHeight: '80vh' }}>
                <div className="modal-content">
                    <div className="row">
                        <h5 className="col s8">{this.employeeData.firstName} {this.employeeData.lastName}{this.employeeData.lastName[this.employeeData.lastName.length - 1].toLowerCase() === 's' ? '\'' : '\'s'} Reminders</h5>
                        <div className="input-field col s4">
                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={e => { e.preventDefault(); this.openModal('top-level-create-reminder-modal') }}>New Reminder</a>
                        </div>
                    </div>
                    <div className="row">
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th>Message</th>
                                    <th>Date Created</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.reminders?.map(r => (
                                    <tr key={`reminder-${r.id}`}>
                                        <td style={{ padding: '4px' }}>
                                            <a
                                                href="/"
                                                onClick={e => { e.preventDefault(); this.setState({ currentReminder: r }, () => { this.openModal('top-level-reminder-detail-modal') }) }}
                                            >
                                                {r.reminder}
                                            </a>
                                        </td>
                                        <td style={{ padding: '4px' }}>{moment(r.dateCreated).subtract(new Date().getTimezoneOffset(), 'minutes').format('MM/DD/YYYY h:mm A')}</td>
                                        <td style={{ padding: '4px' }}><i className="material-icons red-text" style={{ cursor: 'pointer' }} onClick={e => this.deleteReminder(e, r.id)}>delete</i></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="top-level-create-reminder-modal" className="modal" style={{ minWidth: '67vw', minHeight: '80vh' }}>
                <div className="modal-content">
                    <div className="row">
                        <h5>New Reminder</h5>
                    </div>
                    <div className="row">
                        <div className="input-field col s3">
                            <input id="createReminderAlarmDate" type="date" onChange={this.onChange} value={this.state.createReminderAlarmDate} />
                            <label htmlFor="createReminderAlarmDate">Alarm Date</label>
                        </div>
                        <div className="input-field col s3">
                            <input id="createReminderAlarmTime" type="time" step={60 * 15} onChange={this.onChange} value={this.state.createReminderAlarmTime} />
                            <label htmlFor="createRemidnerAlarmTime">Alarm Time</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s9">
                            <textarea id="createReminderText" className="materialize-textarea" onChange={this.onChange} value={this.state.createReminderText} />
                            <label htmlFor="createReminderText">Reminder Text</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s3">
                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.createReminder}>Create Reminder</a>
                        </div>
                        <div className="input-field col s3">
                            <a href="/" className="btn-small red white-text waves-effect waves-light col s12" onClick={e => { e.preventDefault(); this.closeModal('top-level-create-reminder-modal') }}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="top-level-reminder-detail-modal" className="modal">
                <div className="modal-content">
                    {this.state.currentReminder && (
                        <div>
                            <div className="row">
                                <i className="material-icons col s1" style={{ position: 'relative', top: '20px' }}>
                                    <a href="/" onClick={e => { e.preventDefault(); this.closeModal('top-level-reminder-detail-modal') }}>
                                        arrow_back
                                    </a>
                                </i>
                                <h5 className="col s11">{this.state.currentReminder.reminder.toUpperCase()}</h5>
                            </div>
                            <div className="row">
                                <table className="highlight" style={{ marginBottom: '60px' }}>
                                    <thead>
                                        <th>Alarm Time</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        {this.state.currentReminder.alarms?.map(a => (
                                            <tr key={`alarm-${a.id}`}>
                                                <td style={{ padding: '2px' }}>{moment(a.alarm).format('MM/DD/YYYY h:mm A')}</td>
                                                <td style={{ padding: '2px' }}><a href="/" onClick={e => this.deleteAlarm(e, a.id)}>Delete Alarm</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="input-field col s3">
                                    <input id="createAlarmDate" type="date" onChange={this.onChange} value={this.state.createAlarmDate} />
                                    <label htmlFor="createAlarmDate">Alarm Date</label>
                                </div>
                                <div className="input-field col s3">
                                    <input id="createAlarmTime" type="time" step={60 * 15} onChange={this.onChange} valut={this.state.createAlarmTime} />
                                    <label htmlFor="createAlarmTime">Alarm Time</label>
                                </div>
                                <div className="input-field col s3">
                                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.createNewAlarm}>New Alarm</a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

}

export default connect()(Reminder);