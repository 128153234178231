import Axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { logOut, registerRoutes } from "../../../func";
import FileUploader from "../../../utilities/fileUploader";
const componentId = crypto.randomUUID();

const AttachmentList = props => {
    const {
        debitMemo,
        path,
        dispatch
    } = props;

    const [contextMenu, setContextMenu] = useState({ show: false })
    const [attachment, setAttachment] = useState(null);
    const [readyForDrop, setReadyForDrop] = useState(false);
    const [progress, setProgress] = useState(null);

    const [networkCalls] = useState(registerRoutes({
        uploadFile: {
            func: files => {
                const fileUploader = new FileUploader(
                    files,
                    '/api/v2/file/upload/debitmemo/attachment',
                    { debitMemoId: debitMemo.id }
                );
                fileUploader.setProgressCallback(progress => setProgress({...progress}))
                return fileUploader.upload();
            },
            type: 'u'
        },
        downloadFile: {
            func: debitMemoDocumentId => {
                Axios.get('/api/v2/file/download/debitmemo/attachment', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        debitMemoDocumentId
                    }
                })
                    .then(result => {
                        const { content, filename } = result.data;
                        const uint8Array = Uint8Array.from(content.data);
                        const blob = new Blob([uint8Array])
                        const url = window.URL.createObjectURL(blob)
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut);
            },
            type: 'r'
        },
        deleteFile: {
            func: debitMemoDocumentId => {
                Axios.post('/api/v2/file/delete/debitmemo/attachment', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoDocumentId
                })
                    .then(result => dispatch(result.data))
                    .catch(logOut)
            },
            type: 'd'
        }
    }, path))

    const onContextMenu = (e, attachment) => {
        e?.preventDefault();
        setAttachment(attachment);

        const clientX = e.clientX + 150 > window.innerWidth ? e.clientX - 145 : e.clientX;
        const clientY = e.clientY + 80 > window.innerHeight ? e.clientY - 75 : e.clientY;

        setContextMenu({
            show: true,
            clientX,
            clientY
        })
    }

    useEffect(() => {
        const clickAwayListener = ({ target }) => {
            if (!document.querySelector(`#context-menu-${componentId}`).contains(target)) {
                setContextMenu({ show: false })
                setAttachment(null);
            }
        }

        const onDragEnter = e => e.preventDefault();

        const onDragOver = e => {
            e.stopPropagation();
            e.preventDefault();
            if (document.querySelector(`#drag-area-${componentId}`).contains(e.target))
                setReadyForDrop(true);

        }

        const onDragLeave = e => {
            e.preventDefault();
            if (document.querySelector(`#card-content-${componentId}`) === e.target)
                setReadyForDrop(false);
        }

        const onDrop = e => {
            e?.preventDefault();
            setReadyForDrop(false);
            const { files } = e.dataTransfer;
            networkCalls.uploadFile(files);
        }

        const initListeners = () => {
            document.addEventListener('click', clickAwayListener)
            document.addEventListener('dragenter', onDragEnter);
            document.addEventListener('dragover', onDragOver)
            document.addEventListener('dragleave', onDragLeave)
            document.addEventListener('drop', onDrop);
        }

        const removeListeners = () => {
            document.removeEventListener('click', clickAwayListener);
            document.removeEventListener('dragenter', onDragEnter);
            document.removeEventListener('dragover', onDragOver)
            document.removeEventListener('dragleave', onDragLeave)
            document.removeEventListener('drop', onDrop);
        }

        initListeners()

        return () => {
            removeListeners();
        }
    })

    const downloadFile = e => {
        e?.preventDefault();
        networkCalls.downloadFile(attachment.id);
        setContextMenu({ show: false })
        setAttachment(null);
    }

    const deleteFile = e => {
        e?.preventDefault();
        networkCalls.deleteFile(attachment.id);
        setContextMenu({ show: false })
        setAttachment(null);
    }

    return (
        <div id={`drag-area-${componentId}`} style={{ flex: 1, overflowY: 'auto' }} className="card">
            <div id={`card-content-${componentId}`} className="card-content" style={{ height: '100%', border: readyForDrop ? 'grey 3px dotted' : '' }}>
                <div className="row">
                    {readyForDrop ?
                        <h3 className="center grey-text">Drop Files Here</h3>
                        :
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th style={{ padding: '0px' }}>File Name</th>
                                    <th style={{ padding: '0px' }}>Date Uploaded</th>
                                    <th style={{ padding: '0px' }}>Uploaded By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {debitMemo?.attachments?.map(row => (
                                    <tr key={row.id} className={attachment?.id === row.id ? 'blue white-text' : ''} onContextMenu={e => onContextMenu(e, row)}>
                                        <td style={{ padding: '0px', borderRadius: '0px' }}>{row.filename}</td>
                                        <td style={{ padding: '0px', borderRadius: '0px' }}>{moment(row.timeStamp).format('MM/DD/YY')}</td>
                                        <td style={{ padding: '0px', borderRadius: '0px' }}>{row.uploadedBy}</td>
                                    </tr>
                                ))}
                                {progress ? Object.keys(progress).map((key, index) => (
                                    <tr key={`${key}-${index}`} className="blue-text">
                                        <td style={{ padding: '0px', borderRadius: '0px' }}>{key}</td>
                                        <td style={{ padding: '0px', borderRadius: '0px' }}>Uploading: {progress[key].currentPacket} of {progress[key].totalPackets} Packets(s)</td>
                                        <td style={{ padding: '0px', borderRadius: '0px' }}></td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            <div id={`context-menu-${componentId}`} className={contextMenu.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: contextMenu.clientY,
                left: contextMenu.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={downloadFile}>Download</a>
                    </li>
                    <div style={{ height: '6px' }} />
                    <div className="divider" />
                    <div style={{ height: '6px' }} />
                    <li>
                        <a href="/" className="red-text" style={{ font: 'console', fontSize: '.8em' }} onClick={deleteFile}>Delete Attachment</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default AttachmentList;