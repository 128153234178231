import React from 'react';

const BackgroundLoader = props => (
    <div style={{ position: 'fixed', top: '20px', right: '20px' }}>
        <div className="preloader-wrapper small active">
            <div className="spinner-layer spinner-grey-only">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div><div className="gap-patch">
                    <div className="circle"></div>
                </div><div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    </div>
);

export default BackgroundLoader;