import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DebitMemoOverview from './debitMemoOverview';
import DebitMemoProcessPage from "./debitMemoProcess/DebitMemoProcessPage";

const DebitMemoRouter = props => (
    <Switch>
        <Route exact path="/debitmemo" component={DebitMemoOverview} />
        <Route exact path="/debitmemo/:ordernumber" component={DebitMemoProcessPage} />
    </Switch>
)

export default DebitMemoRouter;