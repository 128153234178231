import React from 'react';
import { Switch, Route } from 'react-router-dom';
import shippingDetail from './shippingDetail';

const ShippingRouter = props => (
    <Switch>
        <Route exact path="/shipping" component={shippingDetail} />
    </Switch>
)

export default ShippingRouter;