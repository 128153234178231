import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { logOut, openSideNav, registerRoutes } from '../../func';
import { Link } from 'react-router-dom';
import moment from 'moment';
import M from '@materializecss/materialize';

class ScheduleTwoForm extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        orderNumber: '',
        values: this.initValues(),
        loadingMedNames: [],
    })

    initValues = () => ({
        deaNumber: '',
        supplierName: '',
        supplierAddress: '',
        supplierCityStateZip: '',
        orderDate: '',
        orderDetails: [],
        lineCount: 0,
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getPharmacies();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelector('#orderNumber').addEventListener('keyup', this.searchListener);
    }

    removeListeners = () => {
        document.querySelector('#orderNumber').removeEventListener('keyup', this.searchListener);
    }

    searchListener = e => {
        if (e.keyCode === 13)
            this.search();
    }

    networkCalls = {
        getPharmacies: {
            func: () => {
                Axios.get('/api/v1/pharmacy/read/all/simple', { params: this.authData })
                    .then(result => {
                        const pharmacyList = result.data;
                        const pharmacyListAsObject = {};
                        pharmacyList.forEach(p => pharmacyListAsObject[p.name] = null);
                        M.Autocomplete.init(document.querySelectorAll('#pharmacyName'), { data: pharmacyListAsObject, onAutocomplete: this.autocomplete });

                        this.setState({ pharmacyList, pharmacyListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        search: {
            func: () => {
                Axios.get('/api/v1/order/detail/print/read/scheduletwo', {
                    params: {
                        ...this.authData,
                        orderNumber: this.state.orderNumber
                    }
                })
                    .then(result => {
                        if (!result.data)
                            this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Results', class: 'orange white-text' } })
                        else {
                            this.setState({ values: result.data });
                        }
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        submit: {
            func: () => {
                const orderDetails = JSON.parse(JSON.stringify(this.state.values.orderDetails));

                orderDetails.forEach(od => od.ndc = od.ndc.toString().padStart(11, '0'));

                Axios.post('/api/v1/order/detail/print/scheduletwoform', {
                    ...this.authData,
                    ...this.state.values,
                    orderDetails,
                    orderDate: moment(this.state.values.orderDate).format('MM/DD/YYYY')
                })
                    .then(result => {
                        const win = window.open('', '_blank');
                        setTimeout(() => {
                            win.document.write(result.data);
                            win.print();
                        }, 500)
                    })
                    .catch(logOut);
            },
            type: 'c'
        },
        printCorrectionLetter: {
            func: () => {
                if (!this.state.values.deaNumber) { return }

                Axios.get('/api/v1/order/detail/print/correctionletter', {
                    params: {
                        ...this.authData,
                        orderNumber: this.state.orderNumber
                    },
                    responseType: 'blob'
                })
                    .then(result => {
                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', `${this.state.orderNumber}_correction_letter.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        searchRedbook: {
            func: ({ target }) => {
                const index = parseFloat(target.id.split('-')[1]);
                const value = target.value;
                const loadingMedNames = JSON.parse(JSON.stringify(this.state.loadingMedNames));
                const orderDetails = JSON.parse(JSON.stringify(this.state.values.orderDetails));
                loadingMedNames.push(index);

                if (orderDetails[index].medName || !value) { return }

                this.setState({ loadingMedNames }, () => {
                    Axios.get('/api/v1/redbook/read/one/by/ndc/shallow', {
                        params: {
                            ...this.authData,
                            ndc: value
                        }
                    })
                        .then(result => {
                            const rb = result.data[0];
                            if (rb) {
                                orderDetails[index].medName = rb.medName;
                                this.setState(p => ({ values: { ...p.values, orderDetails } }))
                            }
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loadingMedNames: loadingMedNames.filter(i => i !== index) }))
                })
            },
            type: 'r'
        },

    }

    search = () => this.networkCalls.search();
    submit = e => { e?.preventDefault(); this.networkCalls.submit(); }
    printCorrectionLetter = e => { e?.preventDefault(); this.networkCalls.printCorrectionLetter(); }

    onChange = ({ target }) => this.setState({ [target.id]: target.value })

    formChange = ({ target }) => this.setState(p => ({ values: { ...p.values, [target.id]: target.value } }))

    detailChange = ({ target }) => {
        const [name, index] = target.id.split('-');
        const orderDetails = JSON.parse(JSON.stringify(this.state.values.orderDetails));
        orderDetails[index][name] = target.value;

        this.setState(p => ({ values: { ...p.values, orderDetails } }))
    }

    addLine = e => {
        e.preventDefault();
        const newLine = {
            numberOfPackages: '',
            packSize: '',
            medName: '',
            ndc: '',
            numberShipped: ''
        }

        const orderDetails = JSON.parse(JSON.stringify(this.state.values.orderDetails));
        orderDetails.push(newLine);

        this.setState(p => ({ values: { ...p.values, orderDetails } }))
    }

    deleteLine = index => {
        const orderDetails = JSON.parse(JSON.stringify(this.state.values.orderDetails));
        orderDetails.splice(index, 1);
        this.setState(p => ({ values: { ...p.values, orderDetails } }))
    }

    autocomplete = name => {
        const pharmacy = this.state.pharmacyList.filter(p => p.name === name)[0];
        this.setState(p => ({
            values: {
                ...p.values,
                deaNumber: pharmacy.deaNumber,
                supplierName: pharmacy.name,
                supplierAddress: `${pharmacy.addressLineOne}${pharmacy.addressLineTwo ? ',': ''} ${pharmacy.addressLineTwo}`,
                supplierCityStateZip: `${pharmacy.city}, ${pharmacy.stateCode} ${pharmacy.zip}`
            }
        }))

    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m4 l2">
                    <input id="orderNumber" type="text" onChange={this.onChange} value={this.state.orderNumber} />
                    <label htmlFor="orderNumber">Order Number</label>
                </div>
                <div className="input-field col s12 m6 l4">
                    <input id="pharmacyName" type="text" className="autocomplete" />
                    <label htmlFor="pharmacyName">Pharmacy</label>
                </div>
                <div className="input-field col s12 m4 l2 right">
                    <a href="/" className="btn-small purple white-text waves-effect waves-dark col s12" onClick={this.printCorrectionLetter}>Print Correction Letter</a>
                </div>
                <div className="input-field col s12 m4 l2 right">
                    <a href="/" className="btn-small teal white-text waves-effect waves-dark col s12" onClick={this.submit}>Print 222</a>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="col s6 m2" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label htmlFor="orderDate">Order Date</label>
                                    <input id="orderDate" className="browser-default col s12" type="date" onChange={this.formChange} value={this.state.values.orderDate} />
                                </div>
                                <div className="col s6 m2 offset-m6">
                                    <label htmlFor="deaNumber">DEA Number</label>
                                    <input id="deaNumber" className="browser-default col s12" type="text" onChange={this.formChange} value={this.state.values.deaNumber} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m3">
                                    <label htmlFor="supplierName">Supplier Name</label>
                                    <input id="supplierName" className="browser-default col s12" type="text" onChange={this.formChange} value={this.state.values.supplierName} />
                                </div>
                                <div className="col s12 m3">
                                    <label htmlFor="supplierAddress">Supplier Address</label>
                                    <input id="supplierAddress" className="browser-default col s12" type="text" onChange={this.formChange} value={this.state.values.supplierAddress} />
                                </div>
                                <div className="col s12 m3">
                                    <label htmlFor="supplierCityStateZip">Supplier City, State Zip</label>
                                    <input id="supplierCityStateZip" className="browser-default col s12" type="text" onChange={this.formChange} value={this.state.values.supplierCityStateZip} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="divider" />
                            </div>
                            <div className="row" style={{ marginTop: '50px' }}>
                                <div style={{ display: 'flex', flex: 'row', marginBottom: '18px' }}>
                                    <span style={{ width: '28px' }}></span>
                                    <b style={{ flex: 1 }}>No. of Packages</b>
                                    <b style={{ flex: 1 }}>Package Size</b>
                                    <b style={{ flex: 4 }}>Name of Item</b>
                                    <b style={{ flex: 1 }}>National Drug Code</b>
                                    <b style={{ flex: 1 }}>Number Shipped</b>
                                    <span style={{ width: '28px' }}></span>
                                </div>
                                {this.state.values.orderDetails && this.state.values.orderDetails.map((od, index) => (
                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: '6px' }}>
                                        <span style={{ width: '28px', fontWeight: 700 }}>{index + 1}</span>
                                        <input className="browser-default" style={{ flex: 1, minWidth: '80px' }} id={`numberOfPackages-${index}`} type="text" onChange={this.detailChange} value={this.state.values.orderDetails[index].numberOfPackages} />
                                        <input className="browser-default" style={{ flex: 1, marginLeft: '4px', minWidth: '80px' }} id={`packSize-${index}`} type="text" onChange={this.detailChange} value={this.state.values.orderDetails[index].packSize} />
                                        {
                                            this.state.loadingMedNames.indexOf(index) === -1 ?
                                                <input className="browser-default" style={{ flex: 4, marginLeft: '4px', minWidth: '240px' }} id={`medName-${index}`} type="text" onChange={this.detailChange} value={this.state.values.orderDetails[index].medName} />
                                                :
                                                <div style={{ flex: 4, marginLeft: '4px', minWidth: '240px' }} className="progress blue"><div className="indeterminate" /></div>
                                        }
                                        <input className="browser-default" style={{ flex: 1, marginLeft: '4px', minWidth: '160px' }} id={`ndc-${index}`} type="text" onChange={this.detailChange} onBlur={(e) => this.networkCalls.searchRedbook(e)} value={this.state.values.orderDetails[index].ndc} />
                                        <input className="browser-default" style={{ flex: 1, marginLeft: '4px', minWidth: '80px' }} id={`numberShipped-${index}`} type="text" onChange={this.detailChange} value={this.state.values.orderDetails[index].numberShipped} />
                                        <span style={{ width: '28px' }}><i className="material-icons red-text" style={{ cursor: 'pointer' }} onClick={() => this.deleteLine(index)}>delete</i></span>
                                    </div>
                                ))}
                            </div>
                            <div className="row" style={{ marginTop: '80px' }}>
                                <div className="col s12 m4 l2 right">
                                    <a href="/" className="btn-small green white-text waves-effect waves-light col s12" onClick={this.addLine}>Add Line</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(ScheduleTwoForm);