import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';
import Axios from 'axios';
import { logOut, openSideNav } from '../../func';

class CreateRedbook extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        saving: false,
        loadingManufacturerList: false,
        manufacturerList: null,
        unitList: null,
        values: this.initValues(),
        manufacturer: ''
    })

    initValues = () => ({
        manufacturerId: -1,
        unitId: -1,
        createdById: this.employeeData.id,
        returnPolicyId: -1,
        ndc: '',
        deaClass: '',
        medName: '',
        liquidSize: '',
        packSize: '',
        awp: '',
    })

    componentDidMount = () => {
        this.getUnits();
        M.FormSelect.init(document.querySelectorAll('select'))
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false })
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const select = M.FormSelect.getInstance(el);
            if (select) { select.destroy() }
        })

        document.querySelectorAll('.modal').forEach(el => {
            const modal = M.Modal.getInstance(el);
            if (modal) { modal.destroy() }
        })
    }

    networkCalls = {
        getUnits: {
            func: () => {
                Axios.get('/api/v1/unit/read/all', { params: this.authData })
                    .then(result => this.setState({ unitList: result.data }, () => M.FormSelect.init(document.querySelectorAll('select'))))
                    .catch(logOut)
            },
            type: 'r'
        },
        manufacturerChange: {
            func: e => {
                if (this.state.loadingManufacturerList)
                    this.source.cancel();

                this.setState({ manufacturer: e.target.value, loadingManufacturerList: true }, () => {
                    this.source = Axios.CancelToken.source();

                    Axios.get('/api/v1/party/manufacturer/read/search/by/name', {
                        params: {
                            ...this.authData,
                            name: this.state.manufacturer
                        },
                        cancelToken: this.source.token
                    })
                        .then(result => this.setState({ manufacturerList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loadingManufacturerList: false }))
                })
            },
            type: 'r'
        },
        selectManufacturer: {
            func: mfr => {
                this.setState(p => ({ values: { ...p.values, manufacturerId: mfr.id }, manufacturer: mfr.name }), () => {
                    Axios.get('/api/v1/returnpolicy/read/many/by/partyid', {
                        params: {
                            ...this.authData,
                            partyId: mfr.id
                        }
                    })
                        .then(result => {
                            this.setState({ policies: result.data }, () => {
                                M.Modal.getInstance(document.querySelector('#mfr-modal')).close();
                            })
                        })
                        .catch(logOut);
                })
            },
            type: 'r'
        },
        save: {
            func: () => {
                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/redbook/create/one', {
                        ...this.authData,
                        ...this.state.values
                    })
                        .then(result => this.props.dispatch(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        }
    }

    getUnits = () => this.networkCalls.getUnits.func();
    manufacturerChange = e => this.networkCalls.manufacturerChange.func(e);
    selectManufacturer = (e, mfr) => { e?.preventDefault(); this.networkCalls.selectManufacturer.func(mfr); }
    save = e => { e?.preventDefault(); this.networkCalls.save.func(); }

    getClasses = () => (['R', '2', '3', '4', '5'])

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    openModal = () => {
        M.Modal.getInstance(document.querySelector('#mfr-modal')).open();
    }

    modalCancel = e => {
        e.preventDefault();

        this.setState({ manufacturer: '' }, () => {
            M.Modal.getInstance(document.querySelector('#mfr-modal')).close();
        })
    }

    clear = e => {
        e.preventDefault();

        this.setState({ values: this.initValues(), manufacturer: '', manufacturerList: null, policies: null }, () => {
            M.updateTextFields();
            M.FormSelect.init(document.querySelectorAll('select'))
        })
    }

    selectReturnPolicy = rp => {
        const returnPolicyId = this.state.values.returnPolicyId === rp.id ? -1 : rp.id;
        this.setState(p => ({ values: { ...p.values, returnPolicyId } }))
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/redbook">Redbook</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">New Med</span>
                </div>
            </div>
            <div className="row">
                <h4>New Med</h4>
            </div>
            <form>
                <div className="row">
                    <div className="input-field col s12 m3 l2">
                        <input id="ndc" type="text" onChange={this.onChange} value={this.state.values.ndc} />
                        <label htmlFor="ndc">NDC</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m4">
                        <input id="medName" type="text" onChange={this.onChange} value={this.state.values.medName} />
                        <label htmlFor="medName">Med Name</label>
                    </div>
                    <div className="input-field col s12 m2">
                        <select id="deaClass" onChange={this.onChange} value={this.state.values.deaClass}>
                            <option value={-1}>Select A DEA Class</option>
                            {this.getClasses().map(c => <option key={`dea-class-${c}`} value={c}>{c}</option>)}
                        </select>
                    </div>
                    <div className="input-field col s12 m4">
                        <input id="manufacturer" type="text" onChange={this.manufacturerChange} value={this.state.manufacturer} onBlur={this.openModal} />
                        <label htmlFor="manufacturer">Manufacturer Name</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s4 l2">
                        <input id="packSize" type="text" onChange={this.onChange} value={this.state.values.packSize} />
                        <label htmlFor="packSize">Pack Size</label>
                    </div>
                    <div className="input-field col s4 l2">
                        <select id="unitId" onChange={this.onChange} value={this.state.values.unitId}>
                            <option value={-1}></option>
                            {this.state.unitList && this.state.unitList.map(u => <option key={`u-${u.id}`} value={u.id}>{u.unit}</option>)}
                        </select>
                        <label htmlFor="unitId">Unit</label>
                    </div>
                    <div className="input-field col s4 l2">
                        <input id="liquidSize" type="text" onChange={this.onChange} value={this.state.values.liquidSize} />
                        <label htmlFor="liquidSize">Liquid Size</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6 l2">
                        <input id="awp" type="number" onChange={this.onChange} value={this.state.values.awp} />
                        <label htmlFor="awp">AWP</label>
                    </div>
                </div>
                <div className="row">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '2px' }}>Returnable</th>
                                <th style={{ padding: '2px' }}>Return Window</th>
                                <th style={{ padding: '2px' }}>Sealed Only</th>
                                <th style={{ padding: '2px' }}>Labels Allowed</th>
                                <th style={{ padding: '2px' }}>Min Package Percentage</th>
                                <th style={{ padding: '2px' }}>Controls Allowed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.policies && this.state.policies.map(rp => (
                                <tr key={`rp-${rp.id}`} className={rp.id === this.state.values.returnPolicyId ? 'blue white-text' : ''} onDoubleClick={e => this.selectReturnPolicy(rp)}>
                                    <td style={{ padding: '2px' }}>{rp.isReturnable ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '2px' }}>{rp.earliestReturnInMonths !== null ? `${rp.earliestReturnInMonths}-${rp.latestReturnInMonths}` : ''}</td>
                                    <td style={{ padding: '2px' }}>{rp.sealedPackagesOnly ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '2px' }}>{rp.labelsAllowed ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '2px' }}>{parseInt(parseFloat(rp.minPackagePercentage) * 100)}</td>
                                    <td style={{ padding: '2px' }}>{rp.controlsAllowed ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col s12 m2">
                        <a href="/" className="btn-small blue white-text waves-effect col s12" onClick={this.save}>Save Med</a>
                    </div>
                    <div className="col s12 m2">
                        <a href="/" className="btn-small white black-text waves-effect waves-dark col s12" onClick={this.clear}>Clear</a>
                    </div>
                </div>
            </form>
            <div id="mfr-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        {this.state.manufacturerList && this.state.manufacturerList.map(m => (
                            <a key={`mfr-${m.id}`} href="/" className="col s12 m4" onClick={e => this.selectManufacturer(e, m)}>{m.name}</a>)
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12 m2">
                            <a href="/" className="btn-small red white-text waves-effect col s12" onClick={this.modalCancel}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(CreateRedbook);