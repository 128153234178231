import Axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logOut, openSideNav, registerRoutes } from "../../../func";
import EmployeeListTab from "./employeeListTab";

class Employees extends React.Component {
	constructor(props) {
		super(props);
		this.authData = JSON.parse(localStorage.getItem("auth_data"));
		this.state = this.initState();
	}

	initState = () => ({
		loading: false,
		tab: 'active',
		activeEmployees: null,
		archivedEmployees: null,
	});

	componentDidMount = () => {
		this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
		this.networkCalls.getEmployees();
	}

	networkCalls = {
		getEmployees: {
			func: () => {
				this.setState({ loading: true }, () => {
					Axios.get('/api/v1/employee/read/all', { params: this.authData })
						.then(result => this.hydrateState(result.data))
						.catch(logOut)
						.finally(() => this.setState({ loading: false }))
				})
			},
			type: 'r'
		}
	}

	hydrateState = employees => {
		const activeEmployees = employees.filter(e => !e.isArchived);
		const archivedEmployees = employees.filter(e => e.isArchived);

		this.setState({ activeEmployees, archivedEmployees });
	}

	getContent = () => {
		let component = null;

		switch (this.state.tab) {
			case 'active':
				component = <EmployeeListTab
					employeeList={this.state.activeEmployees}
				/>
				break;
			case 'archived':
				component = <EmployeeListTab
					employeeList={this.state.activeEmployees}
				/>
				break;
			default:
				break;
		}

		return component;
	}

	setTab = e => this.setState({tab: e.target.id.split('-')[1]})

	render = () => (
		<div className="main">
			<div className="row">
				<div style={{ display: "flex" }}>
					<Link to="/" onClick={openSideNav} style={{ marginRight: "12px" }}>
						<i className="material-icons black-text">menu</i>
					</Link>
					<Link to="/">Home</Link>
					<i className="material-icons">chevron_right</i>
					<span className="grey-text">Website Admin</span>
					<i className="material-icons">chevron_right</i>
					<span className="grey-text">Employees</span>
				</div>
			</div>
			<div className="row">
				<div className="col s4 m3 l2">
					<ul className="collection">
						<li id="tab-active" className={`collection-item ${this.state.tab === 'active' ? 'blue white-text' : ''}`} style={{ cursor: 'pointer' }} onClick={this.setTab}>Active</li>
						<li id="tab-archived" className={`collection-item ${this.state.tab === 'archived' ? 'blue white-text' : ''}`} style={{ cursor: 'pointer' }} onClick={this.setTab}>Archived</li>
					</ul>
				</div>
				<div className="col s8 m9 l10">

				</div>
			</div>
		</div>
	);
}

export default connect()(Employees);
