import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../func';
import M from '@materializecss/materialize';

class PotentialCustomerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.id = this.props.match.params.id;
        this.state = this.initState();
    }

    initState = () => ({
        potentialCustomer: null,
        firstName: '',
        lastName: '',
        jobTitle: '',
        phone: '',
        email: '',
        preferredDays: '',
        preferredHourAfter: '',
        preferredHourBefore: '',
        dateContacted: '',
        contactType: '',
        resultOfContact: '',
        notes: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getPotentialCustomer();
    }

    networkCalls = {
        getPotentialCustomer: {
            func: () => {
                Axios.get('/api/v1/sales/read/one/by/id', {
                    params: {
                        ...this.authData,
                        id: this.id
                    }
                })
                    .then(result => {
                        let potentialCustomer = result.data;
                        if (potentialCustomer.contacts) {
                            potentialCustomer.contacts.forEach(c => {
                                c.preferredDays = c.preferredDays ? this.replacePreferredDays(c.preferredDays) : '';
                                c.preferredHourAfter = c.preferredHourAfter ? moment(c.preferredHourAfter, 'HH').format('h:mm A') : '';
                                c.preferredHourBefore = c.preferredHourBefore ? moment(c.preferredHourBefore, 'HH').format('h:mm A') : '';
                            })
                        }

                        if (!potentialCustomer.phone) {
                            Axios.get('/api/v1/sales/read/search/details', {
                                params: {
                                    ...this.authData,
                                    googleMapsId: potentialCustomer.googleMapsId
                                }
                            })
                                .then(result => {
                                    const details = result.data.result;
                                    potentialCustomer = {
                                        ...potentialCustomer,
                                        phone: details.formatted_phone_number,
                                        website: details.website,
                                        googleMapsUrl: details.url,
                                        hours: details.opening_hours
                                    }
                                    this.setState({ potentialCustomer })
                                })
                        }
                        else
                            this.setState({ potentialCustomer })
                    })
                    .catch(logOut)
                    .finally(() => M.FormSelect.init(document.querySelectorAll('select')))
            },
            type: 'r'
        },
        createContact: {
            func: () => {
                const { firstName, lastName, phone, email, jobTitle } = this.state;
                const preferredDays = M.FormSelect.getInstance(document.querySelector('#preferredDays')).getSelectedValues().sort().join('');
                const preferredHourAfter = this.state.preferredHourAfter ? parseInt(this.state.preferredHourAfter.split(':')[0]) : null;
                const preferredHourBefore = this.state.preferredHourBefore ? parseInt(this.state.preferredHourBefore.split(':')[0]) : null;

                Axios.post('/api/v1/sales/create/one/potentialcustomercontact', {
                    ...this.authData,
                    potentialCustomerId: this.state.potentialCustomer.id,
                    firstName,
                    lastName,
                    jobTitle,
                    phone,
                    email,
                    preferredDays,
                    preferredHourAfter,
                    preferredHourBefore
                })
                    .then(result => {
                        this.props.dispatch(result.data.msg)
                        const potentialCustomer = JSON.parse(JSON.stringify(this.state.potentialCustomer));
                        if (!potentialCustomer.contacts) { potentialCustomer.contacts = [] }
                        potentialCustomer.contacts.push({
                            id: result.data.insertId,
                            firstName,
                            lastName,
                            phone,
                            email,
                            jobTitle,
                            preferredDays: preferredDays.toString().replace('1', 'M').replace('2', 'T').replace('3', 'W').replace('4', 'TH').replace('5', 'F').split('').join(', '),
                            preferredHourAfter: moment(preferredHourAfter, 'HH').format('h:mm A'),
                            preferredHourBefore: moment(preferredHourBefore, 'HH').format('h:mm A')
                        })
                        this.setState({
                            potentialCustomer,
                            firstName: '',
                            lastName: '',
                            phone: '',
                            email: ''
                        })
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        deleteContact: {
            func: pcc => {
                Axios.post('/api/v1/sales/delete/one/potentialcustomercontact', {
                    ...this.authData,
                    id: pcc.id
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        const contacts = JSON.parse(JSON.stringify(this.state.potentialCustomer.contacts)).filter(c => c.id !== pcc.id);
                        this.setState(p => ({ potentialCustomer: { ...p.potentialCustomer, contacts } }))
                    })
                    .catch(logOut)
            },
            type: 'd'
        },
        createLogEntry: {
            func: () => {
                const { dateContacted, contactType, resultOfContact, notes } = this.state;

                Axios.post('/api/v1/sales/create/one/potentialcustomerlogentry', {
                    ...this.authData,
                    dateContacted,
                    contactType,
                    resultOfContact,
                    notes,
                    employeeId: this.employeeData.id,
                    potentialCustomerId: this.state.potentialCustomer.id
                })
                    .then(result => {
                        this.props.dispatch(result.data.msg)

                        const potentialCustomer = JSON.parse(JSON.stringify(this.state.potentialCustomer))
                        if (!potentialCustomer.log) { potentialCustomer.log = [] }

                        potentialCustomer.log.push({
                            id: result.data.insertId,
                            dateContacted,
                            contactType,
                            resultOfContact,
                            notes,
                            employee: this.employeeData
                        })

                        this.setState({
                            potentialCustomer,
                            dateContacted: '',
                            contactType: '',
                            resultOfContact: '',
                            notes: ''
                        })
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        deleteLogEntry: {
            func: logEntry => {
                Axios.post('/api/v1/sales/delete/one/potentialcustomerlogentry', {
                    ...this.authData,
                    id: logEntry.id
                })
                    .then(result => {
                        this.props.dispatch(result.data);

                        const log = JSON.parse(JSON.stringify(this.state.potentialCustomer.log)).filter(l => l.id !== logEntry.id);
                        this.setState(p => ({ potentialCustomer: { ...p.potentialCustomer, log } }))
                    })
                    .catch(logOut)
            },
            type: 'd'
        },
        updateStatus: {
            func: e => {
                if (!e.target.value) { return }

                Axios.post('/api/v1/sales/update/potentialcustomer/status', {
                    ...this.authData,
                    id: this.id,
                    status: e.target.value
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.setState(p => ({ potentialCustomer: { ...p.potentialCustomer, status: e.target.value } }))
                    })
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    getPotentialCustomer = () => this.networkCalls.getPotentialCustomer();
    deleteContact = (e, pcc) => { e?.preventDefault(); this.networkCalls.deleteContact(pcc); }
    createContact = e => { e?.preventDefault(); this.networkCalls.createContact(); }
    deleteLogEntry = (e, logEntry) => { e?.preventDefault(); this.networkCalls.deleteLogEntry(logEntry); }
    updateStatus = e => this.networkCalls.updateStatus(e);
    createLogEntry = e => {e?.preventDefault(); this.networkCalls.createLogEntry();}

    replacePreferredDays = numbers => {
        numbers = numbers.toString();
        numbers = numbers.replace('1', 'M')
            .replace('2', 'T')
            .replace('3', 'W')
            .replace('4', 'TH')
            .replace('5', 'F')

        return numbers.split('').join(', ');
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    getStatuses = () => ([
        'Not Contacted',
        'Contact Attempted',
        'Contacted',
        'Scheduled',
        'Closed'
    ])

    sendMail = e => {
        e?.preventDefault();
        const subject = encodeURI(`${this.state.potentialCustomer.name} - Lead From Inside Sales`)
        const body = this.generateEmailBody();
        const link = document.createElement('a');
        link.hidden = true;
        link.href = `mailto:?subject=${subject}&body=${body}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)

    }

    generateEmailBody = () => {
        let str = ''
        str += `Website: ${this.state.potentialCustomer.website}`
        str += `\n\nGoogle Maps: ${this.state.potentialCustomer.googleMapsUrl}`
        str += `\n\nPhone: ${this.state.potentialCustomer.phone}`
        str += `\n\nAddress: ${this.state.potentialCustomer.fullAddress}`

        str += '\n\nContacts:\n';

        this.state.potentialCustomer.contacts?.forEach(c => {
            str += `\t${c.jobTitle} ${c.firstName || ''} ${c.lastName} - ${c.email || ''} - ${c.phone || ''}\n`
        })

        str += '\nCustomer Contact Log:\n'

        this.state.potentialCustomer.log?.forEach(l => {
            str += `\t${moment(l.dateContacted).format('MM/DD/YYYY')} - ${l.notes}\n`
        })

        return encodeURI(str);
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={{ pathname: '/sales', state: this.props.location.state }}>Sales</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.state.potentialCustomer ? this.state.potentialCustomer.name : 'Potential Customer Detail'}</span>
                </div>
            </div>
            <div className="row">
                <div className="col s12 xl4">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <h5 className="col s8">{this.state.potentialCustomer?.name}</h5>
                                <div className="input-field col s4">
                                    <select id="status" onChange={this.updateStatus} value={this.state.potentialCustomer?.status}>
                                        <option value=""></option>
                                        {this.getStatuses().map(s => <option key={s} value={s.toLowerCase()}>{s}</option>)}
                                    </select>
                                    <label htmlFor="status">Status</label>
                                </div>
                            </div>
                            <div className="row">
                                <p><b>Website: </b><a href={this.state.potentialCustomer?.website} target="_blank" rel="noreferrer">{this.state.potentialCustomer?.website}</a></p>
                                <p><b>Maps URL: </b><a href={this.state.potentialCustomer?.googleMapsUrl} target="_blank" rel="noreferrer">{this.state.potentialCustomer?.googleMapsUrl}</a></p>
                                <p><b>Phone: </b> {this.state.potentialCustomer?.phone}</p>
                                <p><b>Address: </b> {this.state.potentialCustomer?.fullAddress}</p>
                                <p><b>Date Added:</b> {this.state.potentialCustomer ? moment(this.state.potentialCustomer.dateAdded).format('MM/DD/YYYY') : ''}</p>
                                <p><b>Hours:</b></p>
                                {this.state.potentialCustomer?.hours?.weekday_text?.map(h => <p key={h}>&emsp;&emsp;&emsp;&emsp;{h}</p>)}
                            </div>
                            <div className="row">
                                <a href="/" className="btn-small col s12 blue white-text" onClick={this.sendMail}>Send Contact To Rep</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 xl8">
                    <div className="row">
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="row">
                                        <h5 className="center">Contacts</h5>
                                    </div>
                                    <div className="row">
                                        <table className="highlight">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Contact Days</th>
                                                    <th>Contact After</th>
                                                    <th>Contact Before</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.potentialCustomer?.contacts?.map(c => (
                                                    <tr key={`contact-${c.id}`}>
                                                        <td style={{ padding: '2px' }}>{c.jobTitle}</td>
                                                        <td style={{ padding: '2px' }}>{c.firstName}</td>
                                                        <td style={{ padding: '2px' }}>{c.lastName}</td>
                                                        <td style={{ padding: '2px' }}>{c.phone}</td>
                                                        <td style={{ padding: '2px' }}>{c.email}</td>
                                                        <td style={{ padding: '2px' }}>{c.preferredDays}</td>
                                                        <td style={{ padding: '2px' }}>{c.preferredHourAfter}</td>
                                                        <td style={{ padding: '2px' }}>{c.preferredHourBefore}</td>
                                                        <td style={{ padding: '2px' }}><i className="material-icons red-text" onClick={e => this.deleteContact(e, c)} style={{ cursor: 'pointer' }}>delete</i></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row" style={{ marginTop: '60px' }}>
                                        <div className="input-field col s3">
                                            <input id="firstName" type="text" onChange={this.onChange} value={this.state.firstName} />
                                            <label htmlFor="firstName">First Name</label>
                                        </div>
                                        <div className="input-field col s3">
                                            <input id="lastName" type="text" onChange={this.onChange} value={this.state.lastName} />
                                            <label htmlFor="lastName">Last Name</label>
                                        </div>
                                        <div className="input-field col s2">
                                            <input id="jobTitle" type="text" onChange={this.onChange} value={this.state.jobTitle} />
                                            <label htmlFor="jobTitle">Title</label>
                                        </div>
                                        <div className="input-field col s2">
                                            <input id="phone" type="text" onChange={this.onChange} value={this.state.phone} />
                                            <label htmlFor="phone">Phone</label>
                                        </div>
                                        <div className="input-field col s2">
                                            <input id="email" type="text" onChange={this.onChange} value={this.state.email} />
                                            <label htmlFor="email">Email</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s6">
                                            <select id="preferredDays" multiple onChange={this.onChange} value={this.state.preferredDay}>
                                                <option value={1}>Monday</option>
                                                <option value={2}>Tuesday</option>
                                                <option value={3}>Wednesday</option>
                                                <option value={4}>Thursday</option>
                                                <option value={5}>Friday</option>
                                            </select>
                                            <label htmlFor="preferredDays">Contact On</label>
                                        </div>
                                        <div className="input-field col s2">
                                            <input id="preferredHourAfter" type="time" onChange={this.onChange} value={this.state.preferredHourAfter} />
                                            <label htmlFor="preferredHourAfter">Contact After</label>
                                        </div>
                                        <div className="input-field col s2">
                                            <input id="preferredHourBefore" type="time" onChange={this.onChange} value={this.state.preferredHourBefore} />
                                            <label htmlFor="preferredHourBefore">Contact Before</label>
                                        </div>
                                        <div className="input-field col s2">
                                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.createContact}>Create Contact</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <h5 className="center">Log</h5>
                            </div>
                            <div className="row">
                                <table className="highlight">
                                    <thead>
                                        <tr>
                                            <th>Date Contacted</th>
                                            <th>Contact Type</th>
                                            <th>Result of Contact</th>
                                            <th>Employee</th>
                                            <th>Notes</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.potentialCustomer?.log?.map(l => (
                                            <tr key={`log-${l.id}`}>
                                                <td style={{ padding: '2px' }}>{moment(l.dateContacted).format('MM/DD/YYYY')}</td>
                                                <td style={{ padding: '2px' }}>{l.contactType}</td>
                                                <td style={{ padding: '2px' }}>{l.resultOfContact}</td>
                                                <td style={{ padding: '2px' }}>{l.employee.firstName} {l.employee.lastName}</td>
                                                <td style={{ padding: '2px' }}>{l.notes}</td>
                                                <td style={{ padding: '2px' }}><i className="material-icons red-text" style={{ cursor: 'pointer' }} onClick={e => this.deleteLogEntry(e, l)}>delete</i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row" style={{ marginTop: '80px' }}>
                                <div className="input-field col s3">
                                    <input id="dateContacted" type="date" onChange={this.onChange} value={this.state.dateContacted} />
                                    <label htmlFor="dateContacted">Date</label>
                                </div>
                                <div className="input-field col s3">
                                    <select id="contactType" onChange={this.onChange} value={this.state.contactType}>
                                        <option value="">Select An Option</option>
                                        <option value="phone">Phone</option>
                                        <option value="email">Email</option>
                                        <option value="in-person">In Person</option>
                                    </select>
                                    <label htmlFor="contactType">Contact Type</label>
                                </div>
                                <div className="input-field col s3">
                                    <select id="resultOfContact" onChange={this.onChange} value={this.state.resultOfContact}>
                                        <option value="">Select An Option</option>
                                        <option value="no answer">No Answer</option>
                                        <option value="not available">Not Available</option>
                                        <option value="not interested">Not Interested</option>
                                        <option value="interested">Interested</option>
                                        <option value="scheduled appointment">Scheduled Appointment</option>
                                        <option value="new customer">New Customer</option>
                                        <option value="mailed literature">Mailed Literature</option>
                                        <option value="sent labels">Sent Labels</option>
                                    </select>
                                    <label htmlFor="resultOfContact">Result of Contact</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s10">
                                    <textarea id="notes" className="materialize-textarea" onChange={this.onChange} value={this.state.notes} />
                                    <label htmlFor="notes">Notes</label>
                                </div>
                                <div className="input-field col s2">
                                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.createLogEntry}>Save Log Entry</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(PotentialCustomerDetail);