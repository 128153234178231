import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../func';

class PharmacyAccountsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        accounts: null,
        newValues: this.initValues(),
        editValues: this.initValues(),
    })

    initValues = () => ({
        id: -1,
        pharmacyId: this.id,
        wholesalerId: -1,
        accountNumber: '',
        expirationDate: '',
        isDefault: false
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getWholesalers();
        this.getPharmacy();
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false })
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    networkCalls = {
        getPharmacy: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/one/by/id/deep', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => this.setState({ pharmacy: result.data, accounts: result.data.accounts }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        getWholesalers: {
            func: () => {
                Axios.get('/api/v1/party/wholesaler/read/all/simple', {
                    params: this.authData
                })
                    .then(result => this.setState({ wholesalerList: result.data }, () => M.FormSelect.init(document.querySelectorAll('select'))))
                    .catch(logOut)
            },
            type: 'r'
        },
        save: {
            func: () => {
                Axios.post('/api/v1/pharmacy/account/create/one', {
                    ...this.authData,
                    ...this.state.newValues
                })
                    .then(result => {
                        this.props.dispatch(result.data)
                        this.cancelSave();
                        this.getPharmacy();
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        update: {
            func: () => {
                Axios.post('/api/v1/pharmacy/account/update/one', {
                    ...this.authData,
                    ...this.state.editValues
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        M.Modal.getInstance(document.querySelector('#edit-modal')).close();
                        this.getPharmacy();
                    })
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    getPharmacy = () => this.networkCalls.getPharmacy();
    getWholesalers = () => this.networkCalls.getWholesalers();
    save = e => { e?.preventDefault(); this.networkCalls.save(); }
    update = e => { e?.preventDefault(); this.networkCalls.update(); }

    onChange = e => {
        const { value } = e.target;
        const [type, id] = e.target.id.split('-');

        if (id === 'isDefault')
            this.setState(p => ({ [`${type}Values`]: { ...p[`${type}Values`], isDefault: !p[`${type}Values`].isDefault } }));
        else
            this.setState(p => ({ [`${type}Values`]: { ...p[`${type}Values`], [id]: value } }))
    }

    addAccount = e => {
        e.preventDefault();
        M.Modal.getInstance(document.querySelector('#add-modal')).open();
    }

    cancelSave = e => {
        if (e) { e.preventDefault() }

        M.Modal.getInstance(document.querySelector('#add-modal')).close();
        this.setState({ newValues: this.initValues() }, () => {
            M.FormSelect.init(document.querySelector('#new-wholesalerId'));
        })
    }

    editAccount = acct => {
        this.setState({ editValues: { ...acct, isDefault: acct.isDefault ? true : false } }, () => {
            M.FormSelect.init(document.querySelector('#edit-wholesalerId'));
            M.Modal.getInstance(document.querySelector('#edit-modal')).open();
            M.updateTextFields();
        })
    }

    cancelUpdate = e => {
        e.preventDefault();

        this.setState({ editValues: this.initValues() }, () => {
            M.Modal.getInstance(document.querySelector('#edit-modal')).close();
        })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/pharmacy">Pharmacies</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/pharmacy/${this.id}`}>{this.state.pharmacy ? this.state.pharmacy.name : 'Pharmacy Detail'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Wholesaler Accounts</span>
                </div>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Wholesaler</th>
                            <th style={{ padding: '4px' }}>Acct Number</th>
                            <th style={{ padding: '4px' }}>Date Active</th>
                            <th style={{ padding: '4px' }}>Expiration</th>
                            <th style={{ padding: '4px' }}>Is Default</th>
                            <th style={{ padding: '4px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.accounts && this.state.accounts.map(acct => (
                            <tr key={`wa-${acct.id}`}>
                                <td style={{ padding: '4px' }}>{acct.wholesalerName}</td>
                                <td style={{ padding: '4px' }}>{acct.accountNumber}</td>
                                <td style={{ padding: '4px' }}>{moment(acct.dateActive).format('MM/DD/YYYY')}</td>
                                <td style={{ padding: '4px' }}>{acct.expiration ? moment(acct.expiration).format('MM/DD/YYYY') : ''}</td>
                                <td style={{ padding: '4px' }}>{acct.isDefault ? 'Yes' : 'No'}</td>
                                <td style={{ padding: '4px' }}><i style={{ cursor: 'pointer' }} className="material-icons" onClick={() => this.editAccount(acct)}>edit</i></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.addAccount}>Add New Account</a>
                </div>
            </div>
            <div id="add-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="input-field col s12 m6">
                            <select id="new-wholesalerId" onChange={this.onChange} value={this.state.newValues.wholesalerId}>
                                <option value={-1} disabled={true}>Choose A Wholesaler</option>
                                {this.state.wholesalerList && this.state.wholesalerList.map(w => (
                                    <option key={`w-${w.id}`} value={w.id}>{w.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input id="new-accountNumber" type="text" onChange={this.onChange} value={this.state.newValues.accountNumber} />
                            <label htmlFor="new-accountNumber">Account Number</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <p>
                                <label>
                                    <input id="new-isDefault" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.newValues.isDefault ? 'checked' : ''} value={this.state.newValues.isDefault} />
                                    <span>Default Account</span>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 l2 right">
                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.save}>Save Account</a>
                        </div>
                        <div className="col s12 m4 l2 right">
                            <a href="/" className="btn-small white black-text waves-effect waves-dark col s12" onClick={this.cancelSave}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="edit-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="input-field col s12 m6">
                            <select id="edit-wholesalerId" onChange={this.onChange} value={this.state.editValues.wholesalerId}>
                                <option value={-1} disabled={true}>Choose A Wholesaler</option>
                                {this.state.wholesalerList && this.state.wholesalerList.map(w => (
                                    <option key={`w-${w.id}`} value={w.id}>{w.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input id="edit-accountNumber" type="text" onChange={this.onChange} value={this.state.editValues.accountNumber} />
                            <label htmlFor="edit-accountNumber">Account Number</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input id="edit-expirationDate" type="date" onChange={this.onChange} value={this.state.editValues.expirationDate} />
                            <label htmlFor="edit-expirationDate">Expiration Date</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <p>
                                <label>
                                    <input id="edit-isDefault" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.editValues.isDefault ? 'checked' : ''} value={this.state.newValues.isDefault} />
                                    <span>Default Account</span>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 l2 right">
                            <a href="/" className="btn-small orange white-text waves-effect waves-light col s12" onClick={this.update}>Update Account</a>
                        </div>
                        <div className="col s12 m4 l2 right">
                            <a href="/" className="btn-small white black-text waves-effect waves-dark col s12" onClick={this.cancelUpdate}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(PharmacyAccountsDetail);