import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../../func';
import GeneralTab from './generalTab';
import ReturnInfoTab from './returnInfoTab';
import ReturnPolicyTab from './returnPolicyTab';
import M from '@materializecss/materialize';

class ManufacturerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
        this.unlisten = null;
    }

    initState = () => ({
        manufacturer: null,
        tab: 'general'
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getManufacturer();
        this.initHistoryListener();
    }

    componentWillUnmount = () => {
        this.removeHistoryListener();
        this.removeToolTips();
    }

    networkCalls = {
        getManufacturer: {
            func: () => {
                this.setState({ loading: true, manufacturer: null }, () => {
                    Axios.get('/api/v1/party/manufacturer/read/one/by/id/deep', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => this.setState({ manufacturer: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        setDefaultReturnPolicy: {
            func: returnPolicyId => {
                Axios.post('/api/v1/party/manufacturer/update/one', {
                    ...this.authData,
                    ...this.state.manufacturer,
                    parentPartyId: this.state.manufacturer.parentPartyId || -1,
                    returnHandlerId: this.state.manufacturer.returnHandlerId || -1,
                    exclusiverReturnWholesalerId: this.state.manufacturer.exclusiverReturnWholesalerId || -1,
                    stateId: this.state.manufacturer.state?.id || -1,
                    returnPolicyId
                })
                    .then(result => {
                        this.props.dispatch(result.data)
                        this.networkCalls.getManufacturer();
                    })
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] })

    getContent = () => {
        let component = null;

        switch (this.state.tab) {
            case 'general':
                component = <GeneralTab manufacturer={this.state.manufacturer} goToParentParty={this.goToParentParty} />
                break;
            case 'returnInfo':
                component = <ReturnInfoTab manufacturer={this.state.manufacturer} />
                break;
            case 'returnPolicy':
                component = <ReturnPolicyTab manufacturer={this.state.manufacturer} setDefaultReturnPolicy={this.networkCalls.setDefaultReturnPolicy} />
                break;
            default:
                break;
        }

        setTimeout(() => {
            this.removeToolTips();
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
        }, 500);
        return component;
    }

    initHistoryListener = () => {
        this.unlisten = this.props.history.listen((path) => {
            this.id = parseInt(path.pathname.split('/')[2]);
            this.networkCalls.getManufacturer();
        })
    }

    removeHistoryListener = () => this.unlisten();

    removeToolTips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt)
                tt.destroy();
        })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/manufacturer">Manufacturers</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.state.mfr ? `${this.state.mfr.name}` : 'Manufacturer Detail'}</span>
                </div>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection">
                        <li id="tab-general" className={`collection-item ${this.state.tab === 'general' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>General</li>
                        <li id="tab-returnInfo" className={`collection-item ${this.state.tab === 'returnInfo' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Return Info</li>
                        <li id="tab-returnPolicy" className={`collection-item ${this.state.tab === 'returnPolicy' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Return Policies</li>
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    {this.getContent()}
                </div>
            </div>
        </div>
    )
}

export default connect()(ManufacturerDetail);