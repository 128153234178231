import { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { logOut, registerRoutes } from "../../../../func";
const pageId = crypto.randomUUID();

const SearchTab = props => {
    const { passRedbookToParent, path } = props;

    const [searchValues, setSearchValues] = useState({
        ndc: '',
        medName: '',
        manufacturerName: ''
    })

    const [searching, setSearching] = useState(false)
    const [redbookList, setRedbookList] = useState(null)
    const [networkCalls] = useState(registerRoutes({
        search: {
            func: () => {
                setSearching(true);
                ref.current.abortController?.abort();
                ref.current.abortController = new AbortController();

                Axios.get('/api/v1/redbook/read/search/by/general/deep', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        ...ref.current.searchValues,
                        deaClass: ''
                    },
                    signal: ref.current.abortController.signal
                })
                .then(result => setRedbookList(result.data))
                .catch(logOut)
                .finally(() => setSearching(false))
            },
            type: 'r'
        }
    }, path))

    const ref = useRef({
        searchValues: null,
        abortController: null,
    })

    useEffect(() => ref.current.searchValues = searchValues, [searchValues])

    const search = e => {
        e?.preventDefault();
        networkCalls.search();
    }

    useEffect(() => {
        const enterListener = e => e.keyCode === 13 && networkCalls.search();

        document.querySelectorAll(`.enter-listener-${pageId}`)?.forEach(el => el.addEventListener('keyup', enterListener))

        return () => document.querySelectorAll(`.enter-listener-${pageId}`)?.forEach(el => el.removeEventListener('keyup', enterListener));

    }, [networkCalls])

    const onChange = e => setSearchValues(p => ({ ...p, [e.target.id.split('-')[0]]: e.target.value }))



    return (
        <div style={{ position: 'relative', padding: '1vh', height: '100%', margin: '0px' }}>
            <div className="row" style={{ marginBottom: '0px' }}>
                <div className="input-field col s3">
                    <input id={`ndc-${pageId}`} className={`enter-listener-${pageId}`} type="text" onChange={onChange} value={searchValues.ndc} />
                    <label htmlFor={`ndc-${pageId}`}>NDC</label>
                </div>
                <div className="input-field col s3">
                    <input id={`medName-${pageId}`} className={`enter-listener-${pageId}`} type="text" onChange={onChange} value={searchValues.medName} />
                    <label htmlFor={`medName-${pageId}`}>Med Name</label>
                </div>
                <div className="input-field col s4">
                    <input id={`manufacturerName-${pageId}`} className={`enter-listener-${pageId}`} type="text" onChange={onChange} value={searchValues.manufacturerName} />
                    <label htmlFor={`manufacturerName-${pageId}`}>Manufacturer</label>
                </div>
                <div className="input-field col s2">
                    <a href="/" disabled={searching} className="btn-small blue white-text waves-effect waves-light col s12" onClick={search}>Search Redbook</a>
                </div>
            </div>
            <div className="row" style={{ marginBottom: '0px', maxHeight: '100%' }}>
                {searching ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '2px' }}>NDC</th>
                                <th style={{ padding: '2px' }}>Med Name</th>
                                <th style={{ padding: '2px' }}>Manufacturer</th>
                                <th style={{ padding: '2px' }}>Pack/Unit/Liquid Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {redbookList?.map(rb => (
                                <tr key={rb.id} style={{ cursor: 'pointer' }} onClick={() => passRedbookToParent(rb)}>
                                    <td style={{ padding: '2px' }}>{rb.ndc}</td>
                                    <td style={{ padding: '2px' }}>{rb.medName}</td>
                                    <td style={{ padding: '2px' }}>{rb.manufacturer.name}</td>
                                    <td style={{ padding: '2px' }}>{rb.packSize}/{rb.unit?.unit || '-'}/{rb.liquidSize}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default SearchTab;