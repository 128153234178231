import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut, openSideNav, registerRoutes } from '../../func';
import Axios from 'axios';
import M from '@materializecss/materialize';

class CreateRecall extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        ndc: '',
        redbookList: null,
        redbookId: -1,
        lotNumber: '',
        expirationStart: '',
        expirationEnd: '',
        loadingRedbook: false
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
    }

    networkCalls = {
        searchNdc: {
            func: () => {
                if (!this.state.ndc)
                    return;
                this.setState({ loadingRedbook: true }, () => {
                    Axios.get('/api/v1/redbook/read/search/by/ndc', {
                        params: {
                            ...this.authData,
                            ndc: this.state.ndc
                        }
                    })
                        .then(result => {
                            const rb = result.data[0]
                            if (!rb)
                                return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Result!', class: 'red white-text' } })
                            else
                                this.setState({
                                    redbookId: rb.id,
                                    medName: rb.medName,
                                    packSize: rb.packSize,
                                    manufacturerName: rb.manufacturer.name
                                }, () => M.updateTextFields())
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loadingRedbook: false }, () => M.updateTextFields()))
                })
            },
            type: 'r'
        }
    }

    searchNdc = () => this.networkCalls.searchNdc();

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    createRecall = e => {
        e.preventDefault();

        const { redbookId, lotNumber, expirationStart, expirationEnd } = this.state;

        Axios.post('/api/v1/recall/create/one', {
            ...this.authData,
            redbookId,
            lotNumber,
            expirationStart,
            expirationEnd
        })
            .then(result => this.props.dispatch(result.data))
            .catch(logOut)
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/recall">Recalls</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Create New Recall</span>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s2">
                    <input id="ndc" type="text" onChange={this.onChange} onBlur={this.searchNdc} value={this.state.ndc} />
                    <label htmlFor="ndc">NDC</label>
                </div>
                {this.state.loadingRedbook ?
                    <div className="input-field col s10">
                        <div className="progress blue">
                            <div className="indeterminate" />
                        </div>
                    </div>
                    :
                    <div>
                        <div className="input-field col s4">
                            <input disabled id="medName" type="text" onChange={this.onChange} value={this.state.medName} />
                            <label htmlFor="medName">Med Name</label>
                        </div>
                        <div className="input-field col s2">
                            <input disabled id="manufacturerName" type="text" onChange={this.onChange} value={this.state.manufacturerName} />
                            <label htmlFor="manufacturerName">Manufacturer</label>
                        </div>
                        <div className="input-field col s1">
                            <input disabled id="packSize" type="text" onChange={this.onChange} value={this.state.packSize} />
                            <label htmlFor="packSize">Pack Size</label>
                        </div>
                    </div>
                }
            </div>
            <div className="row">
                <div className="input-field col s3">
                    <input id="lotNumber" type="text" onChange={this.onChange} value={this.state.lotNumber} />
                    <label htmlFor="lotNumber">Lot Number</label>
                </div>
                <div className="input-field col s2">
                    <input id="expirationStart" type="date" onChange={this.onChange} value={this.state.expirationStart} />
                    <label htmlFor="expirationStart">Expiration Start</label>
                </div>
                <div className="input-field col s2">
                    <input id="expirationEnd" type="date" onChange={this.onChange} value={this.state.expirationEnd} />
                    <label htmlFor="expirationEnd">Expiration End</label>
                </div>
                <div className="input-field col s2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.createRecall}>Create Recall</a>
                </div>
            </div>
        </div>
    )
}

export default connect()(CreateRecall);