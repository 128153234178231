import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import M from '@materializecss/materialize';

class BottleLookup extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.loadState();
    }

    initState = () => ({
        orderDetailList: null,
        loading: false,
        ndc: '',
        lotNumber: '',
        sortColumn: '',
        reverse: false,
        showContextMenu: false,
        clientX: 0,
        clientY: 0
    })

    loadState = () => this.props.loadedState ? { ...this.initState(), ...this.props.loadedState } : this.initState();

    saveState = () => {
        const { orderDetailList, ndc, lotNumber } = this.state;
        this.props.dispatch({
            type: 'SAVE_STATE',
            payload: {
                url: this.props.match.path,
                state: { orderDetailList, ndc, lotNumber }
            }
        })
    }

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        document.addEventListener('click', this.clickAwayListener);
        document.querySelectorAll('.bottle-lookup-search').forEach(el => {
            el.addEventListener('keyup', this.enterListener)
        })
        M.updateTextFields();
    }

    componentWillUnmount = () => {
        this.saveState();
        document.removeEventListener('click', this.clickAwayListener)
        document.querySelectorAll('.bottle-lookup-search').forEach(el => {
            el.removeEventListener('keyup', this.enterListener)
        })
    }

    clickAwayListener = e => {
        if (e.target.id !== 'context-menu')
            this.setState({ showContextMenu: false })
    }

    enterListener = e => e.keyCode === 13 ? this.search() : null;

    networkCalls = {
        search: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/order/detail/read/many/by/ndcorlotnumber', {
                        params: {
                            ...this.authData,
                            ndc: this.state.ndc,
                            lotNumber: this.state.lotNumber
                        }
                    })
                        .then(result => {
                            if (!result.data.length)
                                this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Results', class: 'orange white-text' } })
                            else
                                this.setState({ orderDetailList: result.data })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    search = e => { e?.preventDefault(); this.networkCalls.search(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    sort = e => {
        const sortColumn = Array.from(e.target.classList).filter(c => c.search('sort-') !== -1)[0].split('-')[1];

        const orderDetailList = JSON.parse(JSON.stringify(this.state.orderDetailList));
        if (!orderDetailList)
            return;

        const reverse = sortColumn === this.state.sortColumn ? !this.state.reverse : false;

        orderDetailList.sort((a, b) => {
            let num;
            if (a[sortColumn] === b[sortColumn])
                return 0;
            else
                num = a[sortColumn] > b[sortColumn] ? 1 : -1;

            if (reverse)
                num *= -1;

            return num;
        })

        this.setState({ sortColumn, reverse, orderDetailList })
    }

    showContextMenu = (e, od) => {
        e?.preventDefault();

        const { clientX, clientY } = e;

        this.setState({
            showContextMenu: true,
            clientX,
            clientY,
            selectedOrderId: od.orderId
        })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Bottle Lookup</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Bottle Lookup</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input id="ndc" className="bottle-lookup-search" type="text" onChange={this.onChange} value={this.state.ndc} />
                    <label htmlFor="ndc">NDC</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="lotNumber" className="bottle-lookup-search" type="text" onChange={this.onChange} value={this.state.lotNumber} />
                    <label htmlFor="lotNumber">Lot Number</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.search}>Search</a>
                </div>
            </div>
            <div className="row">{
                this.state.loading ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th className="sort-pharmacyName" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Pharmacy Name {this.state.sortColumn === 'pharmacyName' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-orderNumber" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Order Number {this.state.sortColumn === 'orderNumber' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-serviceDate" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Service Date {this.state.sortColumn === 'serviceDate' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-ndc" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>NDC {this.state.sortColumn === 'ndc' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-lotNumber" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Lot Number {this.state.sortColumn === 'lotNumber' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-fullPackage" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Full Pkg {this.state.sortColumn === 'fullPackage' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-partialPackage" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Partial Pkg {this.state.sortColumn === 'partialPackage' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-expiration" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Expiration {this.state.sortColumn === 'expiration' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-isReturnable" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Returnable {this.state.sortColumn === 'isReturnable' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-nonReturnableReasonCodeId" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Reason  {this.state.sortColumn === 'nonReturnableReasonCodeId' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orderDetailList?.map(od => (
                                <tr key={od.id} onContextMenu={e => this.showContextMenu(e, od)} className="special-context">
                                    <td style={{ padding: '2px' }}>{od.pharmacyName}</td>
                                    <td style={{ padding: '2px' }}>{od.orderNumber}</td>
                                    <td style={{ padding: '2px' }}>{moment(od.serviceDate).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '2px' }}>{od.ndc}</td>
                                    <td style={{ padding: '2px' }}>{od.lotNumber}</td>
                                    <td style={{ padding: '2px' }}>{od.fullPackage}</td>
                                    <td style={{ padding: '2px' }}>{od.partialPackage}</td>
                                    <td style={{ padding: '2px' }}>{moment(od.expiration).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '2px' }}>{od.isReturnable ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '2px' }}>{od.nonReturnableReasonCodeId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
            }
            </div>
            <div id="context-menu" className={this.state.showContextMenu ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: this.state.clientY,
                left: this.state.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <Link className="black-text" style={{ font: 'console', fontSize: '.8em' }} to={`/regular/${this.state.selectedOrderId}`}>Go To Order</Link>
                    </li>
                    <div style={{ height: '6px' }} />
                    <div className="divider" />
                    <div style={{ height: '6px' }} />
                    <li>
                        <Link className="black-text" style={{ font: 'console', fontSize: '.8em' }} to={`/regular/${this.state.selectedOrderId}/orderDetail`}>Go To Detail Input</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const loadedState = state.stateByPage['/utility/bottlelookup'];
    return { loadedState }
}

export default connect(mapStateToProps)(BottleLookup);