import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav } from '../../func';

class WholesalerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        wholesaler: null
    })

    componentDidMount = () => {
        this.getWholesaler();
    }

    networkCalls = {
        getWholesaler: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/party/wholesaler/read/one/by/id', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => this.setState({ wholesaler: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getWholesaler = e => {e?.preventDefault(); this.networkCalls.getWholesaler.func();}

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/wholesaler">Wholesalers</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.state.wholesaler ? this.state.wholesaler.name : 'Wholesaler'}</span>
                </div>
                <div className="row">
                    <h4>{this.state.wholesaler ? `${this.state.wholesaler.name} Detail` : 'Wholesaler Detail'}</h4>
                </div>
                <div className="row">
                    <div className="col s12 l8">
                        <div className="card">
                            {this.state.wholesaler && (
                                <div className="card-content">
                                    <div className="row" style={{ paddingLeft: '18px' }}>
                                        <p><b>DEA Number: </b>{this.state.wholesaler.deaNumber}</p>
                                        <p><b>Address:</b></p>
                                        <p>{this.state.wholesaler.addressLineOne}</p>
                                        <p>{this.state.wholesaler.addressLineTwo}</p>
                                        <p>{this.state.wholesaler.city}, {this.state.wholesaler.state} {this.state.wholesaler.zip}</p>
                                        <p><b>Phone: </b>{this.state.wholesaler.phone}</p>
                                        <p><b>Email: </b>{this.state.wholesaler.email}</p>
                                        <p><b>Website: </b><a href={this.state.wholesaler.website}>{this.state.wholesaler.website}</a></p>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m2">
                                            <Link to={`/wholesaler/${this.id}/update`} className="col s12 btn-small orange white-text">Edit Wholesaler</Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(WholesalerDetail);