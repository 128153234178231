import React from 'react';
import M from '@materializecss/materialize';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../func';
import Axios from 'axios';

class InsideSalesPharmacySearch extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState()
    }

    initState = () => ({
        pages: [],
        pageIndex: -1,
        searching: false,
        zip: '',
        searchRadius: 5,
        response: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.FormSelect.init(document.querySelectorAll('select'))
    }

    networkCalls = {
        search: {
            func: nextPageToken => {
                this.setState({ searching: true }, () => {
                    Axios.get('/api/v1/sales/read/search/pharmacies/by/zip', {
                        params: {
                            ...this.authData,
                            zip: this.state.zip,
                            searchRadius: this.state.searchRadius,
                            nextPageToken: nextPageToken || ''
                        }
                    })
                        .then(result => {
                            const data = result.data;
                            if (nextPageToken) {
                                const pages = JSON.parse(JSON.stringify(this.state.pages));
                                pages.push(data);
                                this.setState(p => ({ pages, pageIndex: p.pageIndex + 1 }))
                            }
                            else
                                this.setState({ pages: [data], pageIndex: 0 })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ searching: false }))
                })
            },
            type: 'r'
        },
        import: {
            func: () => {
                const imports = [];
                const pages = JSON.parse(JSON.stringify(this.state.pages));
                const page = pages[this.state.pageIndex];

                page.results.forEach(row => {
                    if (row.import === true) {
                        row.import = false;
                        row.imported = true;
                        imports.push({
                            googleMapsId: row.place_id,
                            name: row.name,
                            fullAddress: row.formatted_address,
                            zip: this.state.zip,
                            isDissmissed: false
                        });
                    }

                    if (row.dismiss === true) {
                        row.dismiss = false;
                        row.dismissed = true;
                        imports.push({
                            googleMapsId: row.place_id,
                            name: row.name,
                            fullAddress: row.formatted_address,
                            zip: this.state.zip,
                            isDismissed: true
                        })
                    }
                })

                pages[this.state.pageIndex] = page;

                this.setState({ importing: true }, () => {

                    Axios.post('/api/v1/sales/create/many/potentialcustomers', {
                        ...this.authData,
                        imports
                    })
                        .then(result => {
                            this.props.dispatch(result.data)
                            this.setState({ pages })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ importing: false }))
                })
            },
            type: 'c'
        }
    }

    search = (e, nextPageToken) => { e?.preventDefault(); this.networkCalls.search(nextPageToken); }
    import = e => { e?.preventDefault(); this.networkCalls.import(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    checkboxChange = (e, index) => {
        const pages = JSON.parse(JSON.stringify(this.state.pages));
        const row = pages[this.state.pageIndex].results[index];

        if (e.target.id.search('import') !== -1) {
            if (row.imported || row.dismissed) { return }

            row.import = row.import === undefined ? true : !row.import;
            row.dismiss = false;
            pages[this.state.pageIndex].results[index] = row;
        }
        else {
            if (row.dismissed || row.imported || row.import) { return }
            row.dismiss = row.dismiss === undefined ? true : !row.dismiss;
            pages[this.state.pageIndex].results[index] = row;
        }

        this.setState({ pages })
    }

    nextPage = e => {
        e?.preventDefault();
        if (this.state.pages.length - 1 > this.state.pageIndex)
            this.setState(p => ({ pageIndex: p.pageIndex + 1 }))
        else
            this.search(null, this.state.pages[this.state.pageIndex].next_page_token);
    }

    previousPage = e => {
        e?.preventDefault();
        this.setState(p => ({ pageIndex: p.pageIndex - 1 }))
    }

    goToPotentialCustomerDetail = (e, pc) => {
        e?.preventDefault();
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/sales">Sales</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Sales Pharmacy Search</span>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s16 m3 l2">
                    <input id="zip" type="text" onChange={this.onChange} value={this.state.zip} />
                    <label htmlFor="zip">Zip Code</label>
                </div>
                <div className="input-field col s6 m2">
                    <select id="searchRadius" onChange={this.onChange} value={this.state.searchRadius}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                    </select>
                    <label htmlFor="searchRadius">Search Radius</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <a href="/" className="btn-small blue white-text col s12 waves-effect waves-light" onClick={this.search}>Search</a>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    {this.state.pageIndex > 0 &&
                        <a href="/" className="btn-small white black-text col s12 waves-effect waves-light" onClick={this.previousPage}>Previous Page</a>
                    }
                </div>
                <div className="input-field col s12 m3 l2">
                    {this.state.pages[this.state.pageIndex]?.next_page_token &&
                        <a href="/" className="btn-small white black-text col s12 waves-effect waves-light" onClick={this.nextPage}>Next Page</a>
                    }
                </div>
                <div className="input-field col s12 m3 l2 offset-m3 offset-l6">
                    {this.state.pages[this.state.pageIndex]?.results.filter(row => row.import === true || row.dismiss === true).length ?
                        <a href="/" className="btn-small cyan white-text col s12 waves-effect waves-light" onClick={this.import}>Import</a>
                        : null}
                </div>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Import</th>
                            <th>Dismiss</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.pages[this.state.pageIndex]?.results.map((p, index) => (
                            <tr className={p.imported ? 'green lighten-5' : ''} style={{textDecoration: p.dismissed ? 'line-through' : ''}} key={JSON.stringify(p)} >
                                <td style={{ padding: '2px', borderRadius: '0px' }}>{p.name}</td>
                                <td style={{ padding: '2px', borderRadius: '0px' }}>{p.dismissed ? p.formatted_address : <a target="_blank" rel="noreferrer" href={`https://maps.google.com?q=${p.formatted_address}`}>{p.formatted_address}</a>}</td>
                                <td style={{ padding: '2px', borderRadius: '0px' }}>
                                    <label>
                                        <input id={`import-${index}`} disabled={p.imported ? 'disabled' : ''} className="filled-in" type="checkbox" checked={p.import || p.imported ? 'checked' : ''} onChange={e => this.checkboxChange(e, index)} />
                                        <span className="green-text text-darken-2">{p.imported ? 'IMPORTED' : ''}</span>
                                    </label>
                                </td>
                                <td style={{ padding: '2px', borderRadius: '0px' }}>
                                    <label>
                                        <input id={`dismiss-${index}`} disabled={p.dismissed ? 'disabled' : ''} className="filled-in" type="checkbox" checked={p.dismiss || p.dismissed ? 'checked' : ''} onChange={e => this.checkboxChange(e, index)} />
                                        <span>{p.dismissed ? 'DISMISSED' : ''}</span>
                                    </label>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect()(InsideSalesPharmacySearch);