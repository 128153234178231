import Axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';

class PharmacyOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(localStorage.getItem('auth_data'))
    }

    initState = () => ({
        loading: false,
        pharmacyList: null,
        stateList: null,
        pharmacyNameOrCustomerNumber: '',
        city: '',
        sortColumn: '',
        reverse: false
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getStates();
        this.initListeners();
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
    }

    componentWillUnmount = () => {
        this.removeListeners();
        document.querySelectorAll('.tooltipped').forEach(el => el ? M.Tooltip.getInstance(el).destroy() : null)
    }

    initListeners = () => {
        document.querySelectorAll('.pharmacy-search').forEach(el => {
            el.addEventListener('keyup', this.enterListener);
        })
    }

    removeListeners = () => {
        document.querySelectorAll('.pharmacy-search').forEach(el => {
            el.removeEventListener('keyup', this.enterListener);
        })
    }

    enterListener = e => e.keyCode === 13 ? this.search() : null;

    networkCalls = {
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', {
                    params: this.authData
                })
                    .then(result => {
                        this.setState({ stateList: result.data })
                        const obj = {};
                        result.data.forEach(s => obj[s.name] = '')
                        M.Autocomplete.init(document.querySelectorAll('#stateId'), { data: obj })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        search: {
            func: () => {
                const {
                    pharmacyNameOrCustomerNumber,
                    city
                } = this.state;

                const stateInputValue = document.querySelector('#stateId').value;
                const stateId = stateInputValue ? this.state.stateList.filter(s => s.name === stateInputValue)[0].id : ''

                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/many/search', {
                        params: {
                            ...this.authData,
                            pharmacyNameOrCustomerNumber,
                            city,
                            stateId
                        }
                    })
                        .then(result => this.setState({ pharmacyList: result.data, sortColumn: '', reverse: false }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getStates = () => this.networkCalls.getStates();
    search = e => { e?.preventDefault(); this.networkCalls.search(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    sort = e => {
        const sortColumn = Array.from(e.target.classList).filter(c => c.search('sort-') !== -1)[0].split('-')[1];

        const pharmacyList = JSON.parse(JSON.stringify(this.state.pharmacyList));
        if (!pharmacyList)
            return;

        const reverse = sortColumn === this.state.sortColumn ? !this.state.reverse : false;

        pharmacyList.sort((a, b) => {
            let num;
            if (a[sortColumn] === b[sortColumn])
                return 0;
            else
                num = a[sortColumn] > b[sortColumn] ? 1 : -1;

            if (reverse)
                num *= -1;

            return num;
        })

        this.setState({ sortColumn, reverse, pharmacyList })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Pharmacies</span>
                </div>
            </div>
            <div className="row">
                <Link to="/pharmacy/create">
                    <i className="material-icons tooltipped right" data-position="top" data-tooltip="Create New Pharmacy" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px' }}>add</i>
                </Link>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input id="pharmacyNameOrCustomerNumber" className="pharmacy-search" type="text" onChange={this.onChange} value={this.state.pharmacyNameOrCustomerNumber} />
                    <label htmlFor="pharmacyNameOrCustomerNumber">Pharmacy Name/Customer Number</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="city" className="pharmacy-search" type="text" onChange={this.onChange} value={this.state.city} />
                    <label htmlFor="city">City</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="stateId" className="autocomplete" type="text" />
                    <label htmlFor="stateId">State</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.search}>Search</a>
                </div>
            </div>
            <div className="row">
                {this.state.loading ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-name">Name {this.state.sortColumn === 'name' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-customerNumber">Customer Number {this.state.sortColumn === 'customerNumber' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-city">City {this.state.sortColumn === 'city' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort} className="sort-state">State {this.state.sortColumn === 'state' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pharmacyList?.map(p => (
                                <tr key={p.id}>
                                    <td style={{ padding: '2px' }}><Link to={`/pharmacy/${p.id}`}>{p.name}</Link></td>
                                    <td style={{ padding: '2px' }}>{p.customerNumber}</td>
                                    <td style={{ padding: '2px' }}>{p.city}</td>
                                    <td style={{ padding: '2px' }}>{p.state}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
}

export default connect()(PharmacyOverview);