import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import M from '@materializecss/materialize';

class UploadCredits extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        downloading: false,
        uploading: false,
        errorList: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.Modal.init(document.querySelectorAll('.modal'));
        this.getAllManufacturerNames();
    }

    networkCalls = {
        getAllManufacturerNames: {
            func: () => {
                Axios.get('/api/v1/party/manufacturer/read/all', {
                    params: this.authData
                })
                    .then(result => {
                        const mfrList = [];
                        result.data.forEach(row => mfrList.push({ id: row.id, name: row.name }));

                        this.setState({ mfrList });
                    })
                    .catch(logOut);
            },
            type: 'r'
        },
        downloadTemplate: {
            func: () => {
                this.setState({ downloading: true }, () => {
                    Axios.get('/api/v1/creditmemo/read/creditmemotemplate', {
                        params: this.authData,
                        responseType: 'blob'
                    })
                        .then(result => {
                            const blob = new Blob([result.data]);
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.hidden = true;
                            link.href = url;
                            link.setAttribute('download', 'credit_memo_template.xlsx');
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ downloading: false }))
                })
            },
            type: 'r'
        },
        uploadCredits: {
            func: () => {
                const file = document.getElementById('file').files[0];
                const formData = new FormData();

                formData.append('files[]', file);
                formData.append('_id', this.authData._id);
                formData.append('_session_id', this.authData._session_id);
                formData.append('_token', this.authData._token);
                formData.append('user_timezone', this.authData.user_timezone);
                formData.append('createdById', this.employeeData.id);
                formData.append('wholesalerCreditReportId', this.id)


                this.setState({ uploading: false, errorList: null }, () => {
                    Axios.post('/api/v1/file/upload/creditmemo', formData, { headers: { 'content-type': 'multipart/form-data' } })
                        .then(result => this.props.dispatch(result.data))
                        .catch(err => logOut(err, err => {
                            const data = err.response.data;
                            this.props.dispatch(data.toast);
                            this.setState({ errorList: data.errorList })
                        }))
                        .finally(() => this.setState({ uploading: false }, this.closeModal))
                })
            },
            type: 'c'
        },
        getUnresolvedMfrNames: {
            func: () => {
                Axios.get('/api/v1/creditmemo/read/all/unresolvedmanufacturernames', {
                    params: this.authData
                })
                    .then(result => {
                        const nameValues = {};
                        result.data.forEach((row, index) => nameValues[index] = '')

                        this.setState({ mfrNameList: result.data, nameValues }, () => {
                            M.Modal.getInstance(document.querySelector('#mfr-modal')).open();
                        })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        submitNames: {
            func: () => {
                let mfrNameList = JSON.parse(JSON.stringify(this.state.mfrNameList));
                const arr = mfrNameList.filter(mfr => mfr.id);

                Axios.post('/api/v1/creditmemo/create/many/manufactureraliases', {
                    ...this.authData,
                    mfrNameList: arr
                })
                    .then(result => this.props.dispatch(result.data))
                    .catch(logOut)
            },
            type: 'c'
        },
        matchCredits: {
            func: () => {
                Axios.post('/api/v1/creditmemo/update/matchcredits', this.authData)
                    .then(result => this.props.dispatch(result.data))
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    getAllManufacturerNames = () => this.networkCalls.getAllManufacturerNames();
    downloadTemplate = e => { e?.preventDefault(); this.networkCalls.downloadTemplate(); }
    uploadCredits = e => { e?.preventDefault(); this.networkCalls.uploadCredits(); }
    getUnresolvedMfrNames = e => { e?.preventDefault(); this.networkCalls.getUnresolvedMfrNames(); }
    submitNames = e => { e?.preventDefault(); this.networkCalls.submitNames(); }
    matchCredits = e => { e?.preventDefault(); this.networkCalls.matchCredits(); }

    openModal = e => {
        e?.preventDefault();
        M.Modal.getInstance(document.querySelector('#upload-modal')).open();
    }

    closeModal = e => {
        e?.preventDefault();
        M.Modal.getInstance(document.querySelector('#upload-modal')).close();
        document.getElementById('file').value = '';
        document.getElementById('file-path').value = '';
        M.updateTextFields();
    }

    setMfrId = (index, id) => {
        const mfrNameList = JSON.parse(JSON.stringify(this.state.mfrNameList));
        mfrNameList[index].id = id;
        this.setState({ mfrNameList })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting/wholesaler/credit/report">Wholesaler Credit Reports</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Upload Credit Memos</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">{this.props.location && this.props.location.state && this.props.location.state.filename}</h5>
            </div>
            <div className="row" style={{ marginTop: '80px' }}>
                <div className="col s12 m4 l2">
                    <a href="/" className="btn-small purple white-text col s12 waves-effect waves-light" onClick={this.downloadTemplate}>Download Excel Template</a>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <a href="/" className="btn-small teal white-text col s12 waves-effect waves-light" onClick={this.openModal}>Upload Credits</a>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <a href="/" className="btn-small orange white-text col s12 waves-effect waves-light" onClick={this.getUnresolvedMfrNames}>Resolve Mfr Names</a>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <a href="/" className="btn-small pink white-text col s12 waves-effect waves-light" onClick={this.matchCredits}>Match Credits</a>
                </div>
            </div>
            {this.state.errorList && (
                <div className="row">
                    <table>
                        <thead>
                            <tr>
                                <th>Row Number</th>
                                <th>Reported Debit Memo Number</th>
                                <th>Reported Manufacturer Name</th>
                                <th>Credit Memo Number</th>
                                <th>Full Credit Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.errorList && this.state.errorList.map(row => (
                                <tr key={row.rowNumber}>
                                    <td>{row.rowNumber}</td>
                                    <td>{row.reportedDebitMemoNumber || <span className="red-text">Error</span>}</td>
                                    <td>{row.reportedManufacturerName || <span className="red-text">Error</span>}</td>
                                    <td>{row.creditMemoNumber || <span className="red-text">Error</span>}</td>
                                    <td>{row.FullCreditAmount || <span className="red-text">Error</span>}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <h5 className="center orange-text">Please fix these in your file and re-upload.</h5>
                </div>
            )}
            <div id="upload-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="row">
                            <div className="file-field col s12 m4">
                                <div>
                                    <div>
                                        <input type="file" id="file" name="file" />
                                        <label htmlFor="file">Browse Files</label>
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input id="file-path" className="file-path" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4 offset-m4">
                                <a href="/" className="btn-small blue white-text col s12 waves-effect waves-light" onClick={this.uploadCredits}>Upload File</a>
                            </div>
                            <div className="col s12 m4">
                                <a href="/" className="btn-small red white-text col s12 waves-effect waves-light" onClick={this.closeModal}>Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mfr-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h6>Mfr Names</h6>
                    </div>
                    <div className="row">
                        <table>
                            <thead>
                                <tr>
                                    <th>Reported Mfr Name</th>
                                    <th>Internal Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.mfrNameList && this.state.mfrNameList.map((mfr, index) => (
                                    <tr key={`mfr-${mfr.name}`}>
                                        <td>{mfr.name}</td>
                                        <td><AutoComplete possibleValues={this.state.mfrList} index={index} setId={this.setMfrId} /></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td><a href="/" className="btn-small blue white-text col s12" onClick={this.submitNames}>Submit</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default connect()(UploadCredits);


class AutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        value: '',
        suggestion: null,
        selectedText: ''
    })

    componentDidMount = () => {
        document.querySelector(`#auto-${this.props.index}`).addEventListener('keyup', this.nextListener)
    }

    componentWillUnmount = () => {
        document.querySelector(`#auto-${this.props.index}`).removeEventListener('keyup', this.nextListener)
    }

    nextListener = e => {
        if ((e.keyCode === 9 || e.keyCode === 13) && this.state.suggestion) {
            this.props.setId(this.props.index, this.state.suggestion.id);
            this.setState({ suggestion: null, selectedText: '', value: this.state.suggestion.name })
        }
    }

    onChange = e => {
        e.persist();
        let { value } = e.target;

        if (e.nativeEvent.inputType === 'deleteContentBackward') { value = value.substring(0, value.length - 1) }

        if (!value)
            return this.setState({ value, suggestion: null, selectedText: '' })

        const suggestion = this.props.possibleValues.filter(pv => pv.name.toLowerCase().startsWith(e.target.value.toLowerCase()))[0];
        let selectedText = suggestion ? suggestion.name : '';

        selectedText = selectedText.split('')
        selectedText.splice(0, value.length);
        selectedText = selectedText.join('');

        this.setState({ value: `${value}${selectedText}`, suggestion, selectedText }, () => {
            document.querySelector(`#auto-${this.props.index}`).setSelectionRange(value.length, value.length + selectedText.length);
        })
    }

    render = () => (
        <input id={`auto-${this.props.index}`} value={this.state.value} onChange={this.onChange} />
    )
}