import React from 'react';
import { Link } from 'react-router-dom'
import { logOut, registerRoutes, openSideNav } from '../../../func';
import { connect } from 'react-redux';
import CreateRequestTab from './createRequestTab';
import ViewRequestsTab from './viewRequestTab';
import Axios from "axios";
import M from '@materializecss/materialize';


class FeatureRequest extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        tab: 'popular',
        formData: new FormData(),
        popularRequests: null,
        allRequests: null
    })

    networkCalls = {
        getRequests: {
            func: () => {
                Axios.get('/api/v1/user/read/all/featurerequests', { params: this.authData })
                    .then(result => this.hydrateState(result.data))
                    .catch(logOut);
            },
            type: 'r'
        },
        downloadAttachment: {
            func: attachment => {
                Axios.post('/api/v1/file/download/featurerequestattachment', {
                    ...this.authData,
                    filename: attachment.filename,
                    filepath: attachment.filepath
                }, { responseType: 'arraybuffer' })
                    .then(result => {
                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', attachment.filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        toggleVote: {
            func: request => {
                Axios.post('/api/v1/user/update/featurerequest', {
                    ...this.authData,
                    featureRequestId: request.id
                })
                    .then(this.networkCalls.getRequests)
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getRequests();
    }

    componentWillUnmount = () => {
        this.removeTooltips();
    }

    hydrateState = allRequests => {
        let popularRequests = allRequests.filter(r => r.votes);
        popularRequests = popularRequests.sort((a, b) => b.votes.length - a.votes.length)
        this.setState({ popularRequests, allRequests })
    }

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] })

    initTooltips = () => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 300 })

    removeTooltips = () => {
        document.querySelectorAll('.tootipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el)
            if (tt) { tt.destroy(); }
        })
    }

    getContent = () => {
        let component = null;
        switch (this.state.tab) {
            case 'popular':
                component = <ViewRequestsTab
                    requests={this.state.popularRequests}
                    downloadAttachment={this.networkCalls.downloadAttachment}
                    toggleVote={this.networkCalls.toggleVote}
                    employeeId={this.employeeData.id}
                />
                break;
            case 'all':
                component = <ViewRequestsTab
                    requests={this.state.allRequests}
                    downloadAttachment={this.networkCalls.downloadAttachment}
                    toggleVote={this.networkCalls.toggleVote}
                    employeeId={this.employeeData.id}
                />
                break;
            case 'create':
                component = <CreateRequestTab
                    refresh={this.networkCalls.getRequests}
                    dispatch={this.props.dispatch}
                    path={this.props.match.path}
                />
                break;
            default:
                break;
        }

        this.removeTooltips();
        setTimeout(this.initTooltips, 250)
        return component;
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Orx Tools Issue Tracker</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Orx Tools Feature Request</h5>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection">
                        <li id="tab-popular" className={`collection-item ${this.state.tab === 'popular' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Popular Requests</li>
                        <li id="tab-all" className={`collection-item ${this.state.tab === 'all' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>All Requests</li>
                        <li id="tab-create" className={`collection-item ${this.state.tab === 'create' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Request A Feature</li>
                    </ul>
                    {this.state.tab === 'create' ? (
                        <div style={{ padding: '6px', border: 'solid #ccc 1px' }}>
                            <h5 className="center bold" style={{ textDecoration: 'underline' }}>Instructions</h5>
                            <p>&bull; Your Title should briefly describe your request.</p>
                            <p>&bull; Try to make your description as in-depth as possible. Also, try to provide the reasoning behind the need for your new feature.</p>
                            <p>&bull; If you can find examples of other sites or apps that have features similar to yours, upload them along with your request.</p>
                            <p>&bull; <b>You cannot edit your request once it has been saved. Be thorough!</b></p>
                        </div>
                    ) : null}
                    {this.state.tab === 'popular' || this.state.tab === 'all' ?
                        <div style={{ padding: '6px', border: 'solid #ccc 1px' }}>
                            <h5 className="center bold" style={{ textDecoration: 'underline' }}>Voting</h5>
                            <p>&bull; On this page you can vote for your favorite features.</p>
                            <p>&bull; Each employee is limited to <b>two</b> votes.</p>
                            <p>&bull; You can move your votes around as often as you like.</p>
                        </div>
                        : null}
                </div>
                <div className="col s8 m9 l10">
                    {this.getContent()}
                </div>
            </div>
        </div>
    )
}

export default connect()(FeatureRequest);