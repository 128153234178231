import { useEffect, useMemo, useRef, useState } from 'react';
import { logOut, registerRoutes } from '../../../func';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import M from '@materializecss/materialize';
import ExportTable from './exportTable'

const CustomersTab = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const ref = useRef({ AbortController: new AbortController() });
  const [loading, setLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [quickbooksNameList, setQuickbooksNameList] = useState([]);
  const authData = useMemo(
    () => JSON.parse(localStorage.getItem('auth_data')),
    []
  );

  const networkCalls = useMemo(
    () =>
      registerRoutes(
        {
          getPharmacyList: {
            func: () => {
              setLoading(true);
              Axios.get('/api/v2/pharmacy/read/many/withoutqbname', {
                params: authData,
                signal: ref.current.AbortController.signal,
              })
                .then((result) => {
                  setCustomerList(result.data);
                  setQuickbooksNameList(Array(result.data.length).fill(''));
                })
                .catch(logOut)
                .finally(() => setLoading(false));
            },
            type: 'r',
          },
          updatePharmacyQuickbooksName: {
            func: (data) => {
              Axios.post(
                '/api/v2/pharmacy/update/one/quickbooksname',
                {
                  ...authData,
                  ...data,
                },
                { signal: ref.current.AbortController.signal }
              )
                .then((result) => {
                  networkCalls.getPharmacyList();
                  dispatch(result.data);
                })
                .catch(logOut);
            },
            type: 'u',
          },
        },
        pathname
      ),
    [pathname, authData, dispatch]
  );

  useEffect(() => {
    networkCalls.getPharmacyList();
    M.Tabs.init(document.querySelectorAll('ul.tabs'));
    return () => ref?.current?.AbortController?.abort();
  }, []);

  const onChange = ({ target: { value, id } }) => {
    const index = parseInt(id.split('-')[1]);
    quickbooksNameList[index] = value;
    setQuickbooksNameList((prev) => [...prev]);
  };

  const onKeyUp = ({ target: { id }, keyCode }, pharmacy) => {
    if (keyCode === 13) {
      const [, index] = id.split('-');
      const quickbooksName = quickbooksNameList[index];

      networkCalls.updatePharmacyQuickbooksName({
        id: pharmacy.id,
        quickbooksName,
      });
    }
  };

  return (
    <div style={{ padding: '1vw' }}>
      <div className="row">
        {loading ? (
          <div className="progress white">
            <div className="indeterminate blue" />
          </div>
        ) : customerList.length > 0 ? (
          <table className="highlight">
            <thead>
              <tr>
                <th>DAS Name</th>
                <th>Customer Number</th>
                <th>State</th>
                <th>QB Name</th>
              </tr>
            </thead>
            <tbody>
              {customerList?.map((row, index) => {
                const characterCount = quickbooksNameList?.[index]?.length;

                return (
                  <tr key={row.id}>
                    <td style={{ padding: '2px' }}>{row.name}</td>
                    <td style={{ padding: '2px' }}>{row.customerNumber}</td>
                    <td style={{ padding: '2px' }}>{row.state.code}</td>
                    <td style={{ padding: '2px' }}>
                      <div
                        style={{
                          display: 'flex',
                          gap: '6px',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          id={`quickbooksName-${index}`}
                          type="text"
                          value={quickbooksNameList?.[index]}
                          onChange={onChange}
                          onKeyUp={(e) => onKeyUp(e, row)}
                          style={{
                            width: '100%',
                            outline: 'none',
                            border: 'none',
                            borderBottom: '1px solid black',
                          }}
                        />
                        <span
                          className={`${
                            characterCount <= 41 ? 'green' : ' red'
                          }-text bold`}
                        >
                          {characterCount || ''}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <ExportTable exportType="customers" />
        )}
      </div>
    </div>
  );
};

export default CustomersTab;
