import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, registerRoutes, openSideNav } from '../../../func';
import GeneralTab from './generalTab';
import AddressTab from './addressTab'
import M from '@materializecss/materialize';
import Axios from "axios";
import ContactTab from "./contactTab";
import ReturnInfoTab from './returnInfoTab';
import ReturnPolicyTab from "./returnPolicyTab";

class CreateManufacturer extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        saving: false,
        tab: 'general',
        manufacturer: null,
        stateList: null,
        stateListAsObject: null,
        manufacturerList: null,
        manufacturerListAsObject: null,
        newValues: this.initValues(),
    })

    initValues = () => ({
        general: {
            name: '',
            deaNumber: '',
            parentPartyName: '',
            parentPartyId: '',
            returnHandlerName: '',
            returnHandlerId: ''
        },
        address: {
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            stateName: '',
            stateId: '',
            zip: ''
        },
        contact: {
            phone: '',
            email: '',
            website: ''
        },
        returnInfo: {
            exclusiveReturnWholesalerName: '',
            exclusiveReturnWholesalerId: '',
            directReturn: false,
            raRequired: false,
            destroyInHouse: false,
            minValue: 0
        },
        returnPolicy: {
            includeReturnPolicy: false,
            isDefault: false,
            earliestReturnInMonths: 0,
            latestReturnInMonths: 0,
            isReturnable: false,
            sealedPackagesOnly: false,
            labelsAllowed: false,
            controlsAllowed: false,
            minPackagePercentage: 0,
            awpAdjustment: 0,
            effectiveDate: '',
            expirationDate: '',
            notes: ''
        }
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getStates();
        this.networkCalls.getReturnHandlers();
        this.networkCalls.getManufacturers();
        this.networkCalls.getWholesalers();
    }

    componentWillUnmount = () => {
        this.removeToolTips();
    }

    networkCalls = {
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', { params: this.authData })
                    .then(result => {
                        const obj = {};
                        result.data.forEach(s => obj[`${s.code} - ${s.name}`] = null);
                        this.setState({ stateList: result.data, stateListAsObject: obj })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getManufacturers: {
            func: () => {
                Axios.get('/api/v1/party/manufacturer/read/all', { params: this.authData })
                    .then(result => {
                        const obj = {};
                        result.data.forEach(mfr => obj[mfr.name] = null);
                        this.setState({ manufacturerList: result.data, manufacturerListAsObject: obj })
                    })
                    .catch(logOut)
            }
        },
        getWholesalers: {
            func: () => {
                Axios.get('/api/v1/party/wholesaler/read/all/simple', { params: this.authData })
                    .then(result => {
                        const obj = {}
                        result.data.forEach(w => obj[w.name] = null);
                        this.setState({ wholesalerList: result.data, wholesalerListAsObject: obj })
                    })
                    .catch(logOut);
            }
        },
        getReturnHandlers: {
            func: () => {
                Axios.get('/api/v1/party/returnhandler/read/all', { params: this.authData })
                    .then(result => {
                        const obj = {}
                        result.data.forEach(rh => obj[rh.name] = null);
                        this.setState({ returnHandlerList: result.data, returnHandlerListAsObject: obj })
                    })
                    .catch(logOut)
            }
        },
        save: {
            func: () => {
                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/party/manufacturer/create/one', {
                        ...this.authData,
                        parentPartyId: this.state.newValues.general.parentPartyId || -1,
                        returnHandlerId: this.state.newValues.general.returnHandlerId || -1,
                        exclusiveReturnWholesalerId: this.state.newValues.returnInfo.exclusiveReturnWholesalerId || -1,
                        stateId: this.state.newValues.address.stateId || -1,
                        name: this.state.newValues.general.name,
                        addressLineOne: this.state.newValues.address.addressLineOne,
                        addressLineTwo: this.state.newValues.address.addressLineTwo,
                        city: this.state.newValues.address.city,
                        zip: this.state.newValues.address.zip,
                        phone: this.state.newValues.contact.phone,
                        email: this.state.newValues.contact.email,
                        website: this.state.newValues.contact.website,
                        deaNumber: this.state.newValues.general.deaNumber,
                        raRequired: this.state.newValues.returnInfo.raRequired,
                        directReturn: this.state.newValues.returnInfo.directReturn,
                        destroyInHouse: this.state.newValues.returnInfo.destroyInHouse,
                        minValue: this.state.newValues.returnInfo.minValue ? parseFloat(this.state.newValues.returnInfo.minValue) : 0,
                        returnPolicy: this.state.newValues.returnPolicy.includeReturnPolicy ? {
                            isDefault: this.state.newValues.returnPolicy.isDefault,
                            isReturnable: this.state.newValues.returnPolicy.isReturnable,
                            sealedPackagesOnly: this.state.newValues.returnPolicy.sealedPackagesOnly,
                            labelsAllowed: this.state.newValues.returnPolicy.labelsAllowed,
                            minPackagePercentage: this.state.newValues.returnPolicy.minPackagePercentage ? parseFloat(this.state.newValues.returnPolicy.minPackagePercentage) : 0,
                            controlsAllowed: this.state.newValues.returnPolicy.controlsAllowed,
                            awpAdjustment: this.state.newValues.returnPolicy.awpAdjustment ? parseFloat(this.state.newValues.returnPolicy.awpAdjustment) : null,
                            effectiveDate: this.state.newValues.returnPolicy.effectiveDate,
                            expirationDate: this.state.newValues.returnPolicy.expirationDate || null,
                            notes: this.state.newValues.returnPolicy.notes,
                            earliestReturnInMonths: this.state.newValues.returnPolicy.earliestReturnInMonths || 0,
                            latestReturnInMonths: this.state.newValues.returnPolicy.latestReturnInMonths || 0
                        } : null
                    })
                        .then(result => {
                            this.props.dispatch(result.data)
                            setTimeout(() => {
                               this.props.history.replace('/manufacturer'); 
                            }, 800);
                        })
                        .catch(logOut)
                })
            },
            type: 'c'
        }
    }

    onChange = (e, tab) => this.setState(p => ({ newValues: { ...p.newValues, [tab]: { ...p.newValues[tab], [e.target.id]: e.target.value } } }))

    onCheckboxChange = (e, tab) => this.setState(p => ({ newValues: { ...p.newValues, [tab]: { ...p.newValues[tab], [e.target.id]: !p.newValues[tab][e.target.id] } } }))

    getContent = () => {
        let component = null;
        switch (this.state.tab) {
            case 'general':
                component = <GeneralTab
                    onChange={e => this.onChange(e, 'general')}
                    values={this.state.newValues.general}
                    setTab={this.setTab}
                    manufacturerListAsObject={this.state.manufacturerListAsObject}
                    returnHandlerListAsObject={this.state.returnHandlerListAsObject}
                    onReturnHandlerChange={this.onReturnHandlerChange}
                    onParentPartyChange={this.onParentPartyChange}
                />
                break;
            case 'address':
                component = <AddressTab
                    onChange={e => this.onChange(e, 'address')}
                    values={this.state.newValues.address}
                    setTab={this.setTab}
                    stateListAsObject={this.state.stateListAsObject}
                    onStateChange={this.onStateChange}
                />
                break;
            case 'contact':
                component = <ContactTab
                    onChange={e => this.onChange(e, 'contact')}
                    values={this.state.newValues.contact}
                    setTab={this.setTab}
                />
                break;
            case 'returnInfo':
                component = <ReturnInfoTab
                    onChange={e => this.onChange(e, 'returnInfo')}
                    onCheckboxChange={e => this.onCheckboxChange(e, 'returnInfo')}
                    values={this.state.newValues.returnInfo}
                    setTab={this.setTab}
                    wholesalerListAsObject={this.state.wholesalerListAsObject}
                    onExclusiveReturnWholesalerChange={this.onExclusiveReturnWholesalerChange}
                />
                break;
            case 'returnPolicy':
                component = <ReturnPolicyTab
                    onChange={e => this.onChange(e, 'returnPolicy')}
                    onCheckboxChange={e => this.onCheckboxChange(e, 'returnPolicy')}
                    values={this.state.newValues.returnPolicy}
                    setTab={this.setTab}
                    save={this.networkCalls.save}
                    saving={this.state.saving}
                />
                break;
            default:
                component = null;
                break;
        }

        this.removeToolTips();

        setTimeout(() => {
            this.removeToolTips();
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
        }, 500);

        return component;
    }

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] })

    removeToolTips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt)
                tt.destroy();
        })
    }

    onStateChange = stateName => {
        const stateId = this.state.stateList.filter(s => `${s.code} - ${s.name}` === stateName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, address: { ...p.newValues.address, stateName, stateId } } }))
    }

    onReturnHandlerChange = returnHandlerName => {
        const returnHandlerId = this.state.returnHandlerList.filter(rh => rh.name === returnHandlerName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, general: { ...p.newValues.general, returnHandlerName, returnHandlerId } } }))
    }

    onParentPartyChange = parentPartyName => {
        const parentPartyId = this.state.manufacturerList.filter(mfr => mfr.name === parentPartyName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, general: { ...p.newValues.general, parentPartyName, parentPartyId } } }))
    }

    onExclusiveReturnWholesalerChange = exclusiveReturnWholesalerName => {
        const exclusiveReturnWholesalerId = this.state.wholesalerList.filter(w => w.name === exclusiveReturnWholesalerName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, returnInfo: { ...p.newValues.returnInfo, exclusiveReturnWholesalerName, exclusiveReturnWholesalerId } } }))
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/manufacturer">Manufacturers</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">New Manufacturer</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">New Manufacturer</h5>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection">
                        <li id="tab-general" className={`collection-item ${this.state.tab === 'general' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>General</li>
                        <li id="tab-address" className={`collection-item ${this.state.tab === 'address' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Address</li>
                        <li id="tab-contact" className={`collection-item ${this.state.tab === 'contact' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Contact</li>
                        <li id="tab-returnInfo" className={`collection-item ${this.state.tab === 'returnInfo' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Return Info</li>
                        <li id="tab-returnPolicy" className={`collection-item ${this.state.tab === 'returnPolicy' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Return Policy</li>
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    {this.getContent()}
                </div>
            </div>
        </div>
    )
}

export default connect()(CreateManufacturer);