import React from 'react';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../func';
import { connect } from 'react-redux';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import Axios from 'axios';
import M from '@materializecss/materialize';

class WarehouseManagerTimeClock extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        fullEmployeeList: null,
        employeeList: null,
        selectedEmployee: null,
        referenceDate: moment('2021-11-21'),
        selectedDate: moment(),
        from: moment(),
        to: moment(),
        editEntry: null,
        editValues: this.initEditValues(),
        createValues: this.initCreateValues()
    })

    initEditValues = () => ({
        time: '',
        action: ''
    })

    initCreateValues = () => ({
        date: '',
        time: '',
        action: 'in',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getEmployees();
        M.Collapsible.init(document.querySelectorAll('#manager-timesheet-employee-list'), { accordion: true })
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { inDuration: 1000 })
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false })
        M.FormSelect.init(document.querySelectorAll('select'));
        this.onDayClick(moment().format('YYYY-MM-DD'))
    }

    componentDidUpdate = () => {
        M.Collapsible.init(document.querySelectorAll('#manager-timesheet-employee-list'), { accordion: true })
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { inDuration: 1000 })
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#manager-timesheet-employee-list').forEach(el => {
            if (el)
                M.Collapsible.getInstance(el).destroy();
        })
    }

    networkCalls = {
        getEmployees: {
            func: () => {
                Axios.get('/api/v1/employee/read/active', {
                    params: this.authData
                })
                    .then(result => {
                        this.setState({ fullEmployeeList: result.data }, () => {
                            const data = {}
                            result.data.forEach(row => data[`${row.firstName} ${row.lastName}`] = '')
                            M.Autocomplete.init(document.querySelector('#create-employeeId'), { data })
                        })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        onDayClick: {
            func: date => {
                date = moment(date).hours(0).minutes(0).seconds(0).milliseconds(0);
                let diff = date.diff(this.state.referenceDate, 'days');
                let from;

                if (diff >= 0)
                    from = date.subtract(diff % 14, 'days');
                else {
                    if (diff === -0)
                        from = date;
                    else
                        from = date.add(-14 - (diff % 14), 'days');
                }

                const to = from.clone();
                to.add(13, 'days');

                this.setState({ from, to, selectedDate: date }, () => {
                    Axios.get('/api/v1/user/read/all/timeclockrange', {
                        params: {
                            ...this.authData,
                            start: from.format('YYYY-MM-DD 00:00:00'),
                            end: to.format('YYYY-MM-DD 00:00:00')
                        }
                    })
                        .then(result => this.setState({
                            employeeList: result.data,
                            selectedEmployee: result.data.length ? result.data[0] : null
                        }, () => this.listErrors(result.data)))
                        .catch(logOut)
                })
            },
            type: 'r'
        },
        deleteEntry: {
            func: entry => {
                if (window.confirm(`Delete Entry For ${moment(entry.timeStamp).format('MM/DD/YY HH:mm:ss')}?`)) {
                    Axios.post('/api/v1/user/admin/delete/timeclock', {
                        ...this.authData,
                        id: entry.id
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.onDayClick(this.state.selectedDate)
                        })
                }
            },
            type: 'd'
        },
        updateEntry: {
            func: () => {
                Axios.post('/api/v1/user/update/timeclock', {
                    ...this.authData,
                    id: this.state.editEntry.id,
                    timeStamp: `${moment(this.state.editEntry.timeStamp).format('YYYY-MM-DD')} ${this.state.editValues.time}`,
                    action: this.state.editValues.action
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.cancelEdit();
                        this.onDayClick(this.state.selectedDate);
                    })
                    .catch(logOut)
            },
            type: 'u'
        },
        createEntry: {
            func: () => {
                const id = this.state.fullEmployeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#create-employeeId').value)[0].id;
                const timeStamp = `${this.state.createValues.date} ${this.state.createValues.time}`;

                Axios.post('/api/v1/user/admin/create/timeclock', {
                    ...this.authData,
                    id,
                    timeStamp,
                    action: this.state.createValues.action
                })
                    .then(result => {
                        this.props.dispatch(result.data)
                        this.onDayClick(this.state.selectedDate);
                        this.cancelCreate();
                        this.setState({ createValues: this.initCreateValues() }, () => {
                            document.querySelector('#create-employeeId').value = '';
                        })
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        viewReport: {
            func: () => {
                if (!this.state.employeeList || !this.state.employeeList.length) {
                    return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Entries for this Pay Period', class: 'orange white-text' } })
                }

                const obj = {};

                this.state.employeeList.forEach(employee => {
                    const name = `${employee.firstName} ${employee.lastName}`
                    obj[name] = { total: moment.duration(), error: false };

                    employee.dates.forEach(date => {
                        if (date.punches.length % 2 !== 0)
                            return obj[name].error = true;

                        for (let i = 0; i < date.punches.length; i++) {
                            const prev = date.punches[i - 1];
                            const current = date.punches[i];
                            const next = date.punches[i + 1];

                            if (prev && prev.action === current.action)
                                return obj[name].error = true;

                            if (next && next.action === current.action)
                                return obj[name].error = true;

                            if (current.action === 'out' && i === 0)
                                return obj[name].error = true;

                            if (current.action === 'out') {
                                const time = Math.abs(moment(current.timeStamp).diff(moment(prev.timeStamp), 'seconds'))
                                obj[name].total.add(time, 'seconds');
                            }
                        }
                    })
                })

                const report = []

                Object.keys(obj).forEach(key => {
                    report.push({ name: key, error: obj[key].error, hours: parseFloat(obj[key].total.asHours()).toFixed(2) })
                })

                Axios.post('/api/v1/user/admin/print/payrollreport', {
                    ...this.authData,
                    report
                }, { responseType: 'blob' })
                    .then(result => {
                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', `payroll_report_${this.state.from.format('YYYY-MM-DD')}_${this.state.to.format('YYYY-MM-DD')}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut);
            },
            type: 'r'
        }
    }

    getEmployees = () => this.networkCalls.getEmployees();
    onDayClick = date => this.networkCalls.onDayClick(date);
    deleteEntry = entry => this.networkCalls.deleteEntry(entry);
    updateEntry = e => { e?.preventDefault(); this.networkCalls.updateEntry(); }
    createEntry = e => { e?.preventDefault(); this.networkCalls.createEntry(); }
    viewReport = () => this.networkCalls.viewReport();

    editChange = e => {
        const prop = e.target.id.split('-')[1];
        this.setState(p => ({ editValues: { ...p.editValues, [prop]: e.target.value } }))
    }

    createChange = e => {
        const prop = e.target.id.split('-')[1];
        this.setState(p => ({ createValues: { ...p.createValues, [prop]: e.target.value } }))
    }

    listErrors = employeeList => {
        const errorList = [];
        employeeList.forEach(e => {
       
            e.dates.forEach(d => {
                d.punches.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp))
            })

            e.dates.forEach(d => {
                if (!moment(d.date).isSame(moment(), 'date') && d.punches?.length % 2 !== 0)
                    errorList.push({ name: `${e.firstName} ${e.lastName}`, date: moment(d.date).format('MM/DD/YYYY') })
                else {
                    d.punches.forEach((p, index) => {
                        const prev = d.punches[index - 1];
                        const next = d.punches[index + 1];

                        if ((prev && prev.action === p.action) || (next && next.action === p.action)) {
                            if (errorList.findIndex(obj => obj.name === `${e.firstName} ${e.lastName}` && obj.date === d.date) === -1)
                                errorList.push({
                                    name: `${e.firstName} ${e.lastName}`,
                                    date: moment(d.date).format('MM/DD/YYYY')
                                })
                        }
                    })
                }
            })
        })
        this.setState({ errorList })
    }

    selectEmployee = employee => {
        this.setState({ selectedEmployee: employee }, () => {
            const el = M.Collapsible.getInstance(document.querySelector('#manager-timesheet-employee-list'))
            for (let i = 0; i < this.state.selectedEmployee.dates.length; i++) {
                el.close(i);
            }
        })
    }

    getDuration = (dIndex, pIndex) => {
        const punches = JSON.parse(JSON.stringify(this.state.selectedEmployee.dates[dIndex].punches));
        const prev = punches[pIndex - 1];
        const current = punches[pIndex];
        const next = punches[pIndex + 1];

        if (prev && prev.action === current.action)
            return <span className="red-text bold">ERROR!</span>
        else if (next && next.action === current.action)
            return <span className="red-text bold">ERROR!</span>
        else {
            if (current.action === 'in' || !prev)
                return '-'
            else {
                const duration = moment.duration(moment(current.timeStamp).diff(moment(prev.timeStamp), 'seconds'), 'seconds');
                return `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`
            }
        }
    }

    editEntry = entry => {
        this.setState({
            editEntry: entry,
            editValues: {
                date: moment(entry.timeStamp).format('YYYY-MM-DD'),
                time: moment(entry.timeStamp).format('HH:mm:ss'),
                action: entry.action
            }
        }, () => {
            M.FormSelect.init(document.querySelectorAll('#edit-action'));
            M.Modal.getInstance(document.querySelector('#edit-modal')).open();
        })
    }

    cancelEdit = e => {
        e?.preventDefault();
        M.Modal.getInstance(document.querySelector('#edit-modal')).close();
    }

    openCreateEntryModal = (e, date) => {
        this.setState({
            createValues: {
                ...this.initCreateValues(),
                date: date || '',
            }
        }, () => {
            if (date)
                document.querySelector('#create-employeeId').value = `${this.state.selectedEmployee.firstName} ${this.state.selectedEmployee.lastName}`

            M.updateTextFields();
            M.FormSelect.init(document.querySelector('#create-action'));
            M.Modal.getInstance(document.querySelector('#create-modal')).open();
        })
    }

    cancelCreate = e => {
        e?.preventDefault();

        this.setState({ createValues: this.initCreateValues() }, () => {
            M.Modal.getInstance(document.querySelector('#create-modal')).close();
            document.querySelector('#create-employeeId').value = '';
        })
    }

    getDayTotal = d => {
        if (!d.date)
            return;

        const duration = moment.duration();
        let error = false;

        if (!moment(d.date).isSame(moment(), 'date') && d.punches.length % 2 !== 0)
            error = true;


        d.punches.forEach((p, index) => {
            if (index === 0)
                return;
            else if (index % 2 === 0 && p.action !== 'in')
                error = true;
            else if (index % 2 === 1 && p.action !== 'out')
                error = true;
            else {
                if (p.action === 'out')
                    duration.subtract(moment(d.punches[index - 1].timeStamp).diff(moment(p.timeStamp), 'seconds'), 'seconds');
            }
        })

        return error ? <span className="red-text bold">ERROR!</span>
            :
            <span className="black-text">{`${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`}</span>
    }

    selectError = err => {
        const employeeList = JSON.parse(JSON.stringify(this.state.employeeList));
        const selectedEmployee = employeeList.find(e => `${e.firstName} ${e.lastName}` === err.name)
        this.setState({ selectedEmployee }, () => {
            const index = selectedEmployee.dates.findIndex(d => d.date === moment(err.date).format('YYYY-MM-DD'));
            const collapsible = M.Collapsible.getInstance(document.querySelector('#manager-timesheet-employee-list'));

            collapsible.open(index);
        })
    }

    render = () => (
        <div className="main">
            <div style={{ display: 'flex' }}>
                <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                <Link to="/">Home</Link>
                <i className="material-icons">chevron_right</i>
                <span className="grey-text">Warehouse Manager Timesheet</span>
            </div>
            <div className="row">
                <div className="col s12 m4 l3">
                    <DayPicker
                        onDayClick={this.onDayClick}
                        selectedDays={[{ from: new Date(this.state.from.format('YYYY, MM, DD')), to: new Date(this.state.to.format('YYYY, MM, DD')) }]}
                        modifiers={{ start: new Date(this.state.from.format('YYYY, MM, DD')), end: new Date(this.state.to.format('YYYY, MM, DD')) }}
                    />
                </div>
                <div className="col s12 m8 l9">
                    <div className="row" style={{ paddingRight: '40px' }}>
                        {this.state.employeeList ?
                            <i className="material-icons white blue-text tooltipped right" data-position="top" data-tooltip="View Report" style={{ cursor: 'pointer', padding: '4px 2px', border: '1px solid #2196f3', borderRadius: '3px' }} onClick={this.viewReport}>note_add</i>
                            :
                            null
                        }
                        <i className="material-icons white blue-text tooltipped right" data-position="top" data-tooltip="Create New Entry" style={{ cursor: 'pointer', padding: '4px 2px', border: '1px solid #2196f3', borderRadius: '3px' }} onClick={this.openCreateEntryModal}>add</i>
                    </div>
                    <div className="row">
                        {this.state.errorList?.length ?
                            <table className="highlight col s12 l10">
                                <thead>
                                    <tr>
                                        <th style={{ padding: '0px' }}>Employee</th>
                                        <th style={{ padding: '0px' }}>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.errorList?.map(e => (
                                        <tr key={JSON.stringify(e)} onClick={() => this.selectError(e)} style={{ cursor: 'pointer' }}>
                                            <td style={{ padding: '2px' }}>{e.name}</td>
                                            <td style={{ padding: '2px' }}>{e.date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            :
                            <span className="green-text ">No Errors</span>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection" style={{ height: '' }}>
                        {this.state.employeeList?.map(employee => (
                            <li
                                key={JSON.stringify(employee)}
                                className={`collection-item ${this.state.selectedEmployee?.id === employee.id ? 'blue white-text' : ''}`}
                                style={{ cursor: 'pointer', padding: '4px' }}
                                onClick={() => this.selectEmployee(employee)}
                            >
                                {employee.firstName} {employee.lastName}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col s8 m9 l10 xl7">
                    <ul id="manager-timesheet-employee-list" className="collapsible">
                        {this.state.selectedEmployee?.dates?.map((d, dIndex) => (
                            <li key={d.date}>
                                <div className="collapsible-header bold blue-text" style={{ padding: '4px', justifyContent: 'space-between' }}><span className={moment(d.date).isSame(moment(), 'date') ? 'orange-text text-darken-2 bold' : ''}>{moment(d.date).format('ddd MM/DD/YYYY')}</span>{this.getDayTotal(d)}</div>
                                <div className="collapsible-body">
                                    <div>
                                        <i className="material-icons white blue-text tooltipped right" data-position="top" data-tooltip="Create New Entry" style={{ cursor: 'pointer', padding: '4px 2px', border: '1px solid #2196f3', borderRadius: '3px' }} onClick={e => this.openCreateEntryModal(e, d.date)}>add</i>
                                    </div>
                                    <table className="highlight">
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '4px' }}>Action</th>
                                                <th style={{ padding: '4px' }}>Time</th>
                                                <th style={{ padding: '4px' }}>Duration</th>
                                                <th style={{ padding: '4px' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {d.punches?.map((p, pIndex) => (
                                                <tr key={p.id}>
                                                    <td style={{ padding: '4px' }}>{p.action.toUpperCase()}</td>
                                                    <td style={{ padding: '4px' }}>{moment(p.timeStamp).format('hh:mm:ss')}</td>
                                                    <td style={{ padding: '4px' }}>{this.getDuration(dIndex, pIndex)}</td>
                                                    <td style={{ padding: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                        <i className="material-icons blue-text tooltipped" style={{ cursor: 'pointer' }} data-position="top" data-tooltip="Edit Entry" onClick={() => this.editEntry(p)}>edit</i>
                                                        <i className="material-icons blue-text tooltipped" style={{ cursor: 'pointer' }} data-position="top" data-tooltip="Delete Entry" onClick={() => this.deleteEntry(p)}>close</i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div id="edit-modal" className="modal modal-fixed-footer" style={{ minWidth: '74vw', minHeight: '60vh' }}>
                <div className="modal-content">
                    <div className="row">
                        <h5>{moment(this.state.editEntry?.timeStamp).format('dddd, MMMM Do  @ HH:mm:ss')}</h5>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m3">
                            <input id="edit-time" type="time" onChange={this.editChange} value={this.state.editValues.time} />
                            <label htmlFor="edit-time">Time</label>
                        </div>
                        <div className="input-field col s12 m3">
                            <select id="edit-action" type="time" onChange={this.editChange} value={this.state.editValues.action}>
                                <option value={'in'}>In</option>
                                <option value={'out'}>Out</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="/" className="btn-flat white blue-text waves-effect waves-dark" style={{ backgroundColor: 'transparent', border: '1px solid #2196f3', marginRight: '20px', borderRadius: '1px' }} onClick={this.updateEntry}>Update Entry</a>
                    <a href="/" className="btn-flat white blue-text waves-effect waves-dark" style={{ backgroundColor: 'transparent', border: '1px solid #2196f3', marginRight: '20px', borderRadius: '1px' }} onClick={this.cancelEdit}>Close</a>
                </div>
            </div>
            <div id="create-modal" className="modal modal-fixed-footer" style={{ minWidth: '74vw', minHeight: '60vh' }}>
                <div className="modal-content">
                    <div className="row">
                        <h5>New Entry</h5>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input id="create-employeeId" className="autocomplete" type="text" />
                            <label htmlFor="create-employeeId">Employee Name</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m3">
                            <input id="create-date" type="date" onChange={this.createChange} value={this.state.createValues.date} />
                            <label htmlFor="create-date">Date</label>
                        </div>
                        <div className="input-field col s12 m3">
                            <input id="create-time" type="time" onChange={this.createChange} value={this.state.createValues.time} />
                            <label htmlFor="create-time">Time</label>
                        </div>
                        <div className="input-field col s12 m3">
                            <select id="create-action" onChange={this.createChange} value={this.state.createValues.action}>
                                <option value={'in'}>In</option>
                                <option value={'out'}>Out</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="/" className="btn-flat white blue-text waves-effect waves-dark" style={{ backgroundColor: 'transparent', border: '1px solid #2196f3', marginRight: '20px', borderRadius: '1px' }} onClick={this.createEntry}>Create Entry</a>
                    <a href="/" className="btn-flat white blue-text waves-effect waves-dark" style={{ backgroundColor: 'transparent', border: '1px solid #2196f3', marginRight: '20px', borderRadius: '1px' }} onClick={this.cancelCreate}>Close</a>
                </div>
            </div>
        </div>
    )
}

export default connect()(WarehouseManagerTimeClock);