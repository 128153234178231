import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';

class ManufacturerOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        mfrList: null,
        name: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
    }

    networkCalls = {
        search: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/party/manufacturer/read/search/many/by/name', {
                        params: {
                            ...this.authData,
                            name: this.state.name
                        }
                    })
                        .then(result => this.setState({ mfrList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    search = e => { e?.preventDefault(); this.networkCalls.search(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    render = () => (
        <div className="main">            <div className="row">
            <div style={{ display: 'flex' }}>
                <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                <Link to="/">Home</Link>
                <i className="material-icons">chevron_right</i>
                <span className="grey-text">Manufacturers</span>
            </div>
        </div>
            <div className="row">
                <form>
                    <div className="row">
                        <div className="input-field col s12 m6">
                            <input id="name" type="text" onChange={this.onChange} value={this.state.name} />
                            <label htmlFor="name">Manufacturer Name</label>
                        </div>
                        <div className="input-field col s12 m2">
                            <button type="submit" className="btn-small blue white-text waves-effect" onClick={this.search}>Search</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Name</th>
                            <th style={{ padding: '4px' }}>City, State</th>
                            <th style={{ padding: '4px' }}>DEA Number</th>
                            <th style={{ padding: '4px' }}>Phone</th>
                            <th style={{ padding: '4px' }}>Email</th>
                            <th style={{ padding: '4px' }}>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.mfrList && this.state.mfrList.map(m => (
                            <tr key={`mfr-${m.id}`}>
                                <td style={{ padding: '4px' }}><Link to={`/manufacturer/${m.id}`}>{m.name}</Link></td>
                                <td style={{ padding: '4px' }}>{m.city}, {m.stateCode}</td>
                                <td style={{ padding: '4px' }}>{m.deaNumber}</td>
                                <td style={{ padding: '4px' }}>{m.phone}</td>
                                <td style={{ padding: '4px' }}>{m.email}</td>
                                <td style={{ padding: '4px' }}><a href={`https://${m.website}`} target="blank">{m.website}</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col s12 m4 l2" style={{ marginTop: '20px' }}>
                    <Link to="/manufacturer/create" className="col s12 btn-small blue white-text">Create New Manufacturer</Link>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { sideNavIsOpen } = state;
    return { sideNavIsOpen };
}

export default connect(mapStateToProps)(ManufacturerOverview);