import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NewCustomerReport from './newCustomerReport';
import CardinalDebitMemoReport from './cardinalDebitMemoReport';
import SalesCommission from './new_salesCommission';
import NewSalesCommission from './SalesCommissionPage/SalesCommission';

const ReportsRouter = props => (
    <Switch>
        <Route exact path="/accounting/report/newcustomer" component={NewCustomerReport} />
        <Route exact path="/accounting/report/cardinal" component={CardinalDebitMemoReport} />
        <Route exact path="/accounting/report/commission" component={SalesCommission} />
        <Route exact path="/accounting/report/new_commission" component={NewSalesCommission} />
   </Switch>
)

export default ReportsRouter;