import React from 'react';
import { connect } from "react-redux";
import { Input, TextArea, IconButton } from '../../../layout/form';
import { logOut, registerRoutes } from '../../../func';
import M from "@materializecss/materialize";
import Axios from "axios";

class CreateIssueTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        saving: false,
        values: this.initValues()
    })

    initValues = () => ({
        title: '',
        pageUrl: '',
        message: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.initTooltips();
    }

    componentWillUnmount = () => {
        this.removeTooltips();
    }

    networkCalls = {
        save: {
            func: () => {
                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/user/create/one/errortracking', {
                        ...this.authData,
                        title: this.state.values.title,
                        pageUrl: this.state.values.pageUrl,
                        message: this.state.values.message
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.props.setTab({ target: { id: 'tab-ongoing' } })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        }
    }

    initTooltips = () => {
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
    }

    removeTooltips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt) { tt.destroy(); }
        })
    }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))


    clearValues = () => this.setState({ values: this.initValues() })

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <table>
                        <tbody>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Title</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Page URL</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Description Of Problem</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8 m9 l10">
                    <table>
                        <tbody>
                            <Input id="title" type="text" value={this.state.values.title} onChange={this.onChange} col="s12 m8 l6" />
                            <Input id="pageUrl" type="text" value={this.state.values.pageUrl} onChange={this.onChange} col="s12 m9 l8" />
                            <TextArea id="message" value={this.state.values.message} onChange={this.onChange} col="s12 m8 l6" />
                        </tbody>
                    </table>
                    <div className="row" style={{ marginTop: '40px', padding: '8px' }}>
                        <IconButton icon="save" onClick={this.networkCalls.save} disabled={this.state.saving} tooltip="Report Issue" />
                        <IconButton icon="clear" onClick={this.clearValues} tooltip="Clear Values" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(CreateIssueTab);