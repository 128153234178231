import Axios from 'axios';
import React from 'react';
import { logOut, registerRoutes } from '../../../func';
import { Input, IconButton } from '../../../layout/form';
import M from '@materializecss/materialize'

class DeaInfoTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        saving: false,
        data: null,
        oldValues: null,
        newValues: this.initValues(),
        stateList: null
    })

    initValues = () => ({
        name: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        stateId: -1,
        zip: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.networkCalls.getData();
        this.initTooltips();
    }

    componentWillUnmount = () => {
        this.removeTooltips();
    }

    initTooltips = () => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })

    removeTooltips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt) { tt.destroy(); }
        })
    }

    networkCalls = {
        getData: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/one/deainfo', {
                        params: {
                            ...this.authData,
                            deaNumber: this.props.deaNumber
                        }
                    })
                        .then(result => this.hydrateState(result.data[0]))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        create: {
            func: () => {
                const val = document.querySelector('#stateId').value;
                const state = this.props.stateList?.filter(s => s.name === val)[0];

                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/pharmacy/create/deainfo', {
                        ...this.authData,
                        deaNumber: this.props.deaNumber,
                        name: this.state.newValues.name,
                        addressLineOne: this.state.newValues.addressLineOne,
                        addressLineTwo: this.state.newValues.addressLineTwo || null,
                        city: this.state.newValues.city,
                        zip: this.state.newValues.zip,
                        stateId: state?.id
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.networkCalls.getData();
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        },
        update: {
            func: () => {
                const val = document.querySelector('#stateId').value;
                const state = this.props.stateList?.filter(s => s.name === val)[0];

                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/pharmacy/update/deainfo', {
                        ...this.authData,
                        deaNumber: this.props.deaNumber,
                        name: this.state.newValues.name,
                        addressLineOne: this.state.newValues.addressLineOne,
                        addressLineTwo: this.state.newValues.addressLineTwo || null,
                        city: this.state.newValues.city,
                        zip: this.state.newValues.zip,
                        stateId: state?.id
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.networkCalls.getData();
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'u'
        }
    }

    hydrateState = data => {
        this.initAutoComplete();

        if (data)
            this.setState({
                newValues: data,
                oldValues: data,
            }, () => {
                document.querySelector('#stateId').value = this.props.stateList?.filter(s => parseInt(s.id) === parseInt(data.stateId))[0]?.name;
            })

    }

    initAutoComplete = () => M.Autocomplete.init(document.querySelectorAll('#stateId'), { data: this.props.stateListAsObject })

    onChange = e => this.setState(p => ({ newValues: { ...p.newValues, [e.target.id]: e.target.value } }))

    revertValues = () => {
        const stateName = this.props.stateList.filter(s => parseInt(s.id) === parseInt(this.state.oldValues.stateId))[0].name;
        document.querySelector('#stateId').value = stateName;

        this.setState(p => ({ newValues: p.oldValues }))
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <table>
                        <tbody>
                            <tr><td className='bold' style={{ padding: '4px', textAlign: 'right' }}>DEA Number</td></tr>
                            <tr><td className='bold' style={{ padding: '4px', textAlign: 'right' }}>Pharmacy Name</td></tr>
                            <tr><td className='bold' style={{ padding: '4px', textAlign: 'right' }}>Address Line One</td></tr>
                            <tr><td className='bold' style={{ padding: '4px', textAlign: 'right' }}>Address Line Two</td></tr>
                            <tr><td className='bold' style={{ padding: '4px', textAlign: 'right' }}>City</td></tr>
                            <tr><td className='bold' style={{ padding: '4px', textAlign: 'right' }}>State</td></tr>
                            <tr><td className='bold' style={{ padding: '4px', textAlign: 'right' }}>Zip</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8 m9 l10">
                    <div className="row">
                        <table>
                            <tbody>
                                <Input disabled={true} value={this.props.deaNumber} col="s6 m2 l1" />
                                <Input id="name" type="text" value={this.state.newValues.name} onChange={this.onChange} col="s12 m8 l6" />
                                <Input id="addressLineOne" type="text" value={this.state.newValues.addressLineOne} onChange={this.onChange} col="s12 m8" />
                                <Input id="addressLineTwo" type="text" value={this.state.newValues.addressLineTwo} onChange={this.onChange} col="s12 m8" />
                                <Input id="city" type="text" value={this.state.newValues.city} onChange={this.onChange} col="s8 m6 l4" />
                                <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
                                    <td style={{ padding: '0px' }}>
                                        <div className="input-field" style={{ margin: '0px', padding: '0px' }}>
                                            <input
                                                id="stateId"
                                                type="text"
                                                className="autocomplete col s4 m2"
                                                style={{
                                                    height: '30.5px',
                                                    padding: '0px',
                                                    margin: '0px',
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <Input id="zip" type="text" value={this.state.newValues.zip} onChange={this.onChange} col="s5 m2" />
                            </tbody>
                        </table>
                    </div>
                    {this.state.oldValues ?
                        <div className="row">
                            <IconButton icon="upload" onClick={this.networkCalls.update} tooltip="Update" disabled={this.state.saving} />
                            <IconButton icon="refresh" onClick={this.revertValues} tooltip="Revert Changes" />
                        </div>
                        :
                        <div className="row">
                            <IconButton icon="save" onClick={this.networkCalls.create} tooltip="Save" disabled={this.state.saving} />
                            <IconButton icon="clear" onClick={() => this.setState({ newValues: this.initValues() }, () => document.querySelector('#stateId').value = '')} tooltip="Clear" />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default DeaInfoTab;