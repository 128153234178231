import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { logOut, registerRoutes } from '../../../../func';
import moment from 'moment';
import Axios from "axios";
import EditTableRow from "./EditTableRow";
import Socket from '../../../../utilities/socket';
const pageId = crypto.randomUUID();

const OrderDetailList = props => {
    const {
        orderHeaderId,
        dispatch,
        maxLines,
        listOnly,
        path,
        order
    } = props;

    const [orderDetailList, setOrderDetailList] = useState(null)
    const [editValues, setEditValues] = useState({});
    const [editing, setEditing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [websocketTimer, setWebsocketTimer] = useState(0);

    const [networkCalls] = useState(registerRoutes({
        updateOrderDetail: {
            func: (values) => {
                setUpdating(true);

                Axios.post('/api/v2/order/detail/update/one', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    ...values
                })
                    .then(result => {
                        dispatch(result.data);
                        setEditValues({});
                        setEditing(false);
                    })
                    .catch(logOut)
                    .finally(() => setUpdating(false))
            },
            type: 'u'
        },
        deleteOrderDetail: {
            func: orderDetailId => {
                setDeleting(true);

                Axios.post('/api/v2/order/detail/delete/one', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    orderDetailId
                })
                    .then(result => {
                        dispatch(result.data);
                        setEditValues({})
                    })
                    .catch(logOut)
                    .finally(() => setDeleting(false))
            },
            type: 'd'
        }
    }, path))

    useEffect(() => {

        const escapeListener = e => { if (e.keyCode === 27) { setEditing(false); setShowContextMenu(false) } }
        const enterListener = e => { if (e.keyCode === 13) { networkCalls.updateOrderDetail() } }
        const clickAwayListener = ({ target }) => { if (!document.querySelector('#context-menu').contains(target)) { setShowContextMenu(false); } }

        const initListeners = () => {
            document?.addEventListener('keyup', escapeListener);
            document?.addEventListener('click', clickAwayListener);
            document?.querySelectorAll(`.edit-${pageId}`)?.forEach(el => {
                el.addEventListener('keyup', enterListener)
            })
        }

        const removeListeners = () => {
            document?.removeEventListener('keyup', escapeListener);
            document?.removeEventListener('click', clickAwayListener);
            document?.querySelectorAll(`.edit-${pageId}`)?.forEach(el => {
                el.removeEventListener('keyup', enterListener)
            })
        }

        initListeners();
        return removeListeners;

    }, [editing, networkCalls])

    useEffect(() => {
        const socket = new Socket();
        socket.setMessageAction('data', (type, body) => setOrderDetailList(body))
        socket.setOnReady(() => socket.send({ type: 'SUBSCRIBE', body: { orderHeaderId } }))
        socket.subscribeToTimer(secondsSinceLastUpdate => setWebsocketTimer(secondsSinceLastUpdate));
        socket.open('/api/v2/websocket/order/detail/read/all/by/orderheaderid', [{ key: 'orderHeaderId', value: orderHeaderId }]);

        return () => socket.close();
    }, [orderHeaderId, dispatch])

    const onContextMenu = (e, od) => {
        e?.preventDefault();
        if (updating || deleting || editing)
            return;

        const clientX = e.clientX + 150 > window.innerWidth ? e.clientX - 145 : e.clientX;
        const clientY = e.clientY + 80 > window.innerHeight ? e.clientY - 75 : e.clientY;

        setEditing(false);
        setShowContextMenu(true);
        setEditValues({
            clientX,
            clientY,
            orderDetail: od
        })
    }

    const editOrderDetail = e => {
        e?.preventDefault();
        setShowContextMenu(false);
        setEditing(true);
    }

    const deleteOrderDetail = e => {
        e?.preventDefault();

        if (window.confirm('Are you sure you want to delete this order detail?'))
            networkCalls.deleteOrderDetail(editValues.orderDetail.id)

        setShowContextMenu(false)
    }

    return (
        <div>
            <div className="card">
                <div className="card-content">
                    <small className="grey-text left">Last Update: {Math.floor(websocketTimer / 5) * 5}s</small>
                    {listOnly ? null : <div><a href={`/regular/${orderHeaderId}/orderdetail/listonly`} target="new" noreferrer="true"><i className="material-icons right">open_in_new</i></a></div>}
                    <table>
                        <thead>
                            <tr>
                                <th style={{ padding: '0px' }}>NDC</th>
                                <th style={{ padding: '0px' }}>DEA</th>
                                <th style={{ padding: '0px' }}>Med Name</th>
                                <th style={{ padding: '0px' }}>Mfr</th>
                                <th style={{ padding: '0px' }}>Full Pkg</th>
                                <th style={{ padding: '0px' }}>Partial Cnt</th>
                                <th style={{ padding: '0px' }}>Lot #</th>
                                <th style={{ padding: '0px' }}>Exp Date</th>
                                <th style={{ padding: '0px' }}>Reason</th>
                                {props.isVa ?
                                    <th style={{ padding: '0px' }}>VA Destruction #</th>
                                    : null}
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetailList?.map((od, index) => {
                                if (editing) {
                                    if (od.id === editValues?.orderDetail?.id)
                                        return <EditTableRow
                                            key="editTableRow"
                                            path={path}
                                            orderDetail={editValues.orderDetail}
                                            update={networkCalls.updateOrderDetail}
                                            order={order}
                                        />
                                    else if (!maxLines || index < maxLines - 2)
                                        return <tr key={od.id} className={`${od.id === editValues.id && showContextMenu ? 'grey' : ''} ${updating || deleting ? 'grey-text' : ''}`} onContextMenu={e => onContextMenu(e, od)}>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.redbook?.ndc}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.redbook?.deaClass}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.redbook?.medName}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.manufacturer?.name}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.fullPackage}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.partialPackage}{parseInt(od.partialDecimal) ? `.${od.partialDecimal}` : ''}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.lotNumber}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.expiration ? moment(od.expiration).format('MM/DD/YY') : ''}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.nonReturnableReasonCodeId}</td>
                                            {props.isVa ?
                                                <td style={{ padding: '0px' }}>{od.vaDestructionNumber}</td>
                                                : null}
                                        </tr>
                                    else
                                        return null
                                }
                                else {
                                    if (!maxLines || index < maxLines)
                                        return <tr key={od.id} className={`${od.id === editValues.id && showContextMenu ? 'grey' : ''} ${updating || deleting ? 'grey-text' : ''}`} onContextMenu={e => onContextMenu(e, od)}>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.redbook?.ndc}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.redbook?.deaClass}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.redbook?.medName}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.manufacturer?.name}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.fullPackage}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.partialPackage}{parseInt(od.partialDecimal) ? `.${od.partialDecimal}` : ''}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.lotNumber}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.expiration ? moment(od.expiration).format('MM/DD/YY') : ''}</td>
                                            <td style={{ padding: '0px', borderRadius: '0px' }}>{od.nonReturnableReasonCodeId}</td>
                                            {props.isVa ?
                                                <td style={{ padding: '0px' }}>{od.vaDestructionNumber}</td>
                                                : null}
                                        </tr>

                                    else
                                        return null
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div id="context-menu" className={showContextMenu ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: editValues.clientY,
                left: editValues.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={editOrderDetail}>Edit</a>
                    </li>
                    <div style={{ height: '6px' }} />
                    <div className="divider" />
                    <div style={{ height: '6px' }} />
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={deleteOrderDetail}>Delete</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default connect()(OrderDetailList);