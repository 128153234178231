import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from "../../func";
import Axios from 'axios';
import M from '@materializecss/materialize';

class UpdateRecall extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
        this.id = this.props.match.params.id;
    }

    initState = () => ({
        ndc: '',
        medName: '',
        manufacturerName: '',
        packSize: '',
        lotNumber: '',
        expirationStart: '',
        expirationEnd: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getRecall();
    }

    networkCalls = {
        getRecall: {
            func: () => {
                Axios.get('/api/v1/recall/read/one/by/id', {
                    params: {
                        ...this.authData,
                        id: this.id
                    }
                })
                    .then(result => this.setState(p => ({ ...p, ...result.data }), () => {
                        M.updateTextFields()
                    }))
                    .catch(logOut)
            },
            type: 'r'
        },
        update: {
            func: () => {
                const { lotNumber, expirationStart, expirationEnd } = this.state;

                Axios.post('/api/v1/recall/update/one', {
                    ...this.authData,
                    id: this.id,
                    lotNumber,
                    expirationStart,
                    expirationEnd
                })
                    .then(result => this.props.dispatch(result.data))
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    getRecall = () => this.networkCalls.getRecall();
    update = e => { e?.preventDefault(); this.networkCalls.update(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    render = () => (
        <div className="main">
            <div style={{ display: 'flex' }}>
                <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                <Link to="/">Home</Link>
                <i className="material-icons">chevron_right</i>
                <span className="grey-text">Update</span>
            </div>
            <div className="row">
                <div className="input-field col s2">
                    <input disabled id="ndc" type="text" onChange={this.onChange} onBlur={this.searchNdc} value={this.state.ndc} />
                    <label htmlFor="ndc">NDC</label>
                </div>
                <div className="input-field col s4">
                    <input disabled id="medName" type="text" onChange={this.onChange} value={this.state.medName} />
                    <label htmlFor="medName">Med Name</label>
                </div>
                <div className="input-field col s2">
                    <input disabled id="manufacturerName" type="text" onChange={this.onChange} value={this.state.manufacturerName} />
                    <label htmlFor="manufacturerName">Manufacturer</label>
                </div>
                <div className="input-field col s1">
                    <input disabled id="packSize" type="text" onChange={this.onChange} value={this.state.packSize} />
                    <label htmlFor="packSize">Pack Size</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s3">
                    <input id="lotNumber" type="text" onChange={this.onChange} value={this.state.lotNumber} />
                    <label htmlFor="lotNumber">Lot Number</label>
                </div>
                <div className="input-field col s2">
                    <input id="expirationStart" type="date" onChange={this.onChange} value={this.state.expirationStart} />
                    <label htmlFor="expirationStart">Expiration Start</label>
                </div>
                <div className="input-field col s2">
                    <input id="expirationEnd" type="date" onChange={this.onChange} value={this.state.expirationEnd} />
                    <label htmlFor="expirationEnd">Expiration End</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s2">
                    <a href="/" className="btn-small orange white-text waves-effect waves-light col s12" onClick={this.update}>Update Recall</a>
                </div>
            </div>
        </div>
    )
}

export default connect()(UpdateRecall);