import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import Axios from 'axios';
import { logOut, openSideNav, registerRoutes } from '../../../func';
import * as xlsx from 'xlsx';

class CardinalDebitMemoReport extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({

    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    networkCalls = {
        download: {
            func: () => {
                const { month, year } = this.state;

                if (month && year)
                    Axios.post('/api/v1/file/download/cardinal/debitmemoreport', {
                        ...this.authData,
                        month,
                        year
                    })
                        .then(result => {
                            if (!result.data.length)
                                return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Results', class: 'orange white-text' } })

                            const wb = xlsx.utils.book_new();
                            const sheet = xlsx.utils.json_to_sheet(result.data);

                            const colWidths = {};

                            Object.keys(sheet).forEach(key => {
                                const [col, row] = key.match(/[^\d]+|\d+/g);
                                if (col !== '!') {
                                    const len = sheet[key].v?.toString().length;

                                    if (!colWidths[col])
                                        colWidths[col] = [];

                                    colWidths[col].push(len);
                                }

                                if (parseInt(row) > 1) {
                                    if (col === 'C') {
                                        sheet[key].t = 'n';
                                        sheet[key].z = '0.00';
                                    }
                                }

                            })

                            const colWidthsFinal = [];

                            Object.keys(colWidths).forEach(key => {
                                colWidthsFinal.push({
                                    wch: Math.max(...colWidths[key])
                                })
                            })

                            sheet['!cols'] = colWidthsFinal;
                            xlsx.utils.book_append_sheet(wb, sheet, 'Sheet1');
                            xlsx.writeFile(wb, `cardinal_debit_memo_report_${month}${year}.xlsx`);
                        })
                        .catch(logOut);
            },
            type: 'r'
        }
    }

    download = e => { e?.preventDefault(); this.networkCalls.download(); }

    getMonths = () => ([
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ])

    getYears = () => {
        const arr = [];
        for (let i = 2021; i <= moment().year(); i++)
            arr.push(i);
        return arr;
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Cardinal Debit Memo Report</span>
                </div>
            </div>
            <div className="row">
                <h4 className="center">Cardinal Debit Memo Report</h4>
            </div>
            <div className="row">
                <div className="input-field col s12 m3">
                    <select id="month" onChange={this.onChange}>
                        <option value={0}>Select a Month</option>
                        {this.getMonths().map((m, index) => <option key={m} value={index + 1}>{m}</option>)}
                    </select>
                </div>
                <div className="input-field col s12 m2">
                    <select id="year" onChange={this.onChange}>
                        <option value={0}>Select A Year</option>
                        {this.getYears().map(y => <option key={y} value={y}>{y}</option>)}
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m4 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.download}>Download Debit Memo Report</a>
                </div>
            </div>
        </div>
    )
}

export default connect()(CardinalDebitMemoReport);