import React from 'react';
import { Link } from 'react-router-dom';

const ReturnPolicyTab = props => (
    <div style={{ padding: '1vw' }}>
        {props.manufacturer ?
            <div>
                <div className="row">
                    <Link to={`/manufacturer/${props.manufacturer.id}/policy/create`}>
                        <i className="material-icons tooltipped" data-position="top" data-tooltip="Create New Return Policy" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>add</i>
                    </Link>
                </div>
                <div className="row">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>Returnable</th>
                                <th style={{ textAlign: 'center' }}>Sealed Only</th>
                                <th style={{ textAlign: 'center' }}>Labels</th>
                                <th style={{ textAlign: 'center' }}>Controls</th>
                                <th style={{ textAlign: 'center' }}>Return Window</th>
                                <th style={{ textAlign: 'center' }}>Min Package %</th>
                                <th style={{ textAlign: 'center' }}>AWP Adjustment</th>
                                <th style={{ textAlign: 'center' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.manufacturer.returnPolicies?.map(rp => (
                                <tr key={rp.id}>
                                    <td style={{ padding: '0px', textAlign: 'center' }}><i className={`material-icons ${rp.isReturnable ? 'green-text text-darken-2' : 'red-text'}`}>{rp.isReturnable ? 'check' : 'close'}</i></td>
                                    <td style={{ padding: '0px', textAlign: 'center' }}><i className={`material-icons ${rp.sealedPackagesOnly ? 'green-text text-darken-2' : 'red-text'}`}>{rp.sealedPackagesOnly ? 'check' : 'close'}</i></td>
                                    <td style={{ padding: '0px', textAlign: 'center' }}><i className={`material-icons ${rp.labelsAllowed ? 'green-text text-darken-2' : 'red-text'}`}>{rp.labelsAllowed ? 'check' : 'close'}</i></td>
                                    <td style={{ padding: '0px', textAlign: 'center' }}><i className={`material-icons ${rp.controlsAllowed ? 'green-text text-darken-2' : 'red-text'}`}>{rp.controlsAllowed ? 'check' : 'close'}</i></td>
                                    <td style={{ padding: '0px', textAlign: 'center' }}>{rp.earliestReturnInMonths} - {rp.latestReturnInMonths}</td>
                                    <td style={{ padding: '0px', textAlign: 'center' }}>{rp.minPackagePercentage * 100}%</td>
                                    <td style={{ padding: '0px', textAlign: 'center' }}>{rp.awpAdjustment * 100}%</td>
                                    <td style={{ padding: '2px' }}>
                                        {props.manufacturer.returnPolicyId !== rp.id ?
                                            <a href="/" onClick={e => { e.preventDefault(); props.setDefaultReturnPolicy(rp.id); }}>
                                                <i className="material-icons tooltipped" data-position="top" data-tooltip="Set Default Policy" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>arrow_circle_down</i>
                                            </a>
                                            :
                                            <a href="/" onClick={e => { e.preventDefault(); props.setDefaultReturnPolicy(-1); }}>
                                                <i className="material-icons tooltipped" data-position="top" data-tooltip="Remove Default Policy" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>remove_circle_outline</i>
                                            </a>
                                        }
                                        <Link to={`/manufacturer/${props.manufacturer.id}/policy/${rp.id}`}>
                                            <i className="material-icons tooltipped" data-position="top" data-tooltip="Edit This Policy" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>edit</i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="progress blue">
                <div className="indeterminate" />
            </div>
        }
    </div>
)

export default ReturnPolicyTab;