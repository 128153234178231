import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut, openSideNav, registerRoutes } from '../../func';
import Axios from 'axios';
import M from '@materializecss/materialize';
import moment from 'moment';
import * as xlsx from 'xlsx';

class SalesCommission extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        employeeList: null,
        employeeId: 0,
        detailList: null,
        loading: false
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getEmployees();
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    networkCalls = {
        getEmployees: {
            func: () => {
                Axios.get('/api/v1/employee/read/active', {
                    params: this.authData
                })
                    .then(result => {
                        const employeeList = result.data.filter(e => {
                            return e.jobTitle.toString().toLowerCase().search('rep') !== -1
                                && `${e.firstName} ${e.lastName}`.toLowerCase() !== 'reese scott'
                                && `${e.firstName} ${e.lastName}`.toLowerCase() !== 'seng tech';
                        });
                        this.setState({ employeeList }, () => {
                            M.FormSelect.init(document.querySelector('#employeeId'))
                        })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getCommissions: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/sales/read/commission/by/employeeid', {
                        params: {
                            ...this.authData,
                            employeeId: this.state.employeeId
                        }
                    })
                        .then(result => {
                            const totals = {
                                outdateFee: 0,
                                estimatedTotalCommission: 0,
                                paidCommission: 0,
                                commissionDue: 0
                            }

                            result.data.forEach(row => {
                                totals.outdateFee += row.outdateFee ? (row.outdateFee + Number.EPSILON) : 0;
                                totals.estimatedTotalCommission += row.estimatedTotalCommission ? (row.estimatedTotalCommission + Number.EPSILON) : 0;
                                totals.paidCommission += row.paidCommission ? (row.paidCommission + Number.EPSILON) : 0;
                                totals.commissionDue += row.commissionDue ? (row.commissionDue + Number.EPSILON) : 0;
                            })

                            this.setState({ detailList: result.data, totals })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        saveAndEmail: {
            func: () => {
                Axios.post('/api/v1/sales/create/many/commissions', this.authData)
                    .then(result => {
                        const wb = xlsx.utils.book_new();
                        xlsx.utils.book_append_sheet(wb, xlsx.utils.json_to_sheet(result.data), 'Commission Report')
                        xlsx.writeFile(wb, `commission_report_${moment().format('YYYY_MM_DD')}.xlsx`);
                    })
                    .catch(logOut)
            },
            type: 'c'
        }
    }

    getEmployees = () => this.networkCalls.getEmployees();
    getCommissions = e => { e?.preventDefault(); this.networkCalls.getCommissions(); }
    saveAndEmail = e => { e?.preventDefault(); this.networkCalls.saveAndEmail(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value }, this.getCommissions)

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Sales Commission</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Sales Commission</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <select id="employeeId" onChange={this.onChange}>
                        <option value={0}>All</option>
                        {this.state.employeeList?.map(e => <option key={`employee-${e.id}`} value={e.id}>{e.firstName} {e.lastName}</option>)}
                    </select>
                    <label htmlFor="employeeId">Sales Rep</label>
                </div>
                <div className="input-field col s12 m3 l2 offset-m3 offset-l8">
                    <a href="/" className="btn-small green white-text waves-effect waves-light col s12" onClick={this.saveAndEmail}>Save and Email Commissions</a>
                </div>
            </div>
            <div className="row">
                {this.state.loading ?
                    <div className="progress white">
                        <div className="indeterminate blue" />
                    </div>
                    :
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th>Pharmacy</th>
                                <th>Service Date</th>
                                <th>Order Number</th>
                                <th>ORX Fee</th>
                                <th>Est. Total Commission</th>
                                <th>Comm. Previously Paid</th>
                                <th>Comm. To Be Paid</th>
                                <th>Percent Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detailList?.map(d => (
                                <tr key={`detail-${d.orderHeaderId}`}>
                                    <td style={{ padding: '2px' }}>{d.pharmacyName}</td>
                                    <td style={{ padding: '2px' }}>{moment(d.serviceDate).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '2px' }}>{d.orderNumber}</td>
                                    <td style={{ padding: '2px' }}>{d.outdateFee}</td>
                                    <td style={{ padding: '2px' }}>{d.estimatedTotalCommission}</td>
                                    <td style={{ padding: '2px' }}>{d.paidCommission}</td>
                                    <td style={{ padding: '2px' }}>{d.commissionDue}</td>
                                    <td style={{ padding: '2px' }}>{d.percentPaid}</td>
                                </tr>
                            ))}
                            {this.state.totals && (
                                <React.Fragment>
                                    <tr style={{ borderTop: '2px solid black' }}>
                                        <th style={{ padding: '2px' }}>Totals</th>
                                        <th style={{ padding: '2px' }}></th>
                                        <th style={{ padding: '2px' }}></th>
                                        <th style={{ padding: '2px' }}>ORX Fee</th>
                                        <th style={{ padding: '2px' }}>Est. Total Commission</th>
                                        <th style={{ padding: '2px' }}>Comm. Previously Paid</th>
                                        <th style={{ padding: '2px' }}>Comm. To Be Paid</th>
                                        <th style={{ padding: '2px' }}></th>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '2px' }}></td>
                                        <td style={{ padding: '2px' }}></td>
                                        <td style={{ padding: '2px' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '600' }}>{this.state.totals.outdateFee.toFixed(2)}</td>
                                        <td style={{ padding: '2px', fontWeight: '600' }}>{this.state.totals.estimatedTotalCommission.toFixed(2)}</td>
                                        <td style={{ padding: '2px', fontWeight: '600' }}>{this.state.totals.paidCommission.toFixed(2)}</td>
                                        <td style={{ padding: '2px', fontWeight: '600' }}>{this.state.totals.commissionDue.toFixed(2)}</td>
                                        <td style={{ padding: '2px' }}></td>
                                    </tr>
                                </React.Fragment>
                            )}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default connect()(SalesCommission);