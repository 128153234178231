import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../func';

class UpdateRedbook extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = localStorage.getItem('employee_data') !== 'undefined' ? JSON.parse(localStorage.getItem('employee_data')) : {};
        this.state = this.initState();
        this.oldValues = null;
        this.source = null;
    }

    initState = () => ({
        loading: false,
        loadingManufacturerList: false,
        updating: false,
        manufacturerList: null,
        unitList: null,
        values: this.initValues(),
        manufacturer: '',
        med: null,
        policies: null
    })

    initValues = () => ({
        id: this.id,
        manufacturerId: -1,
        unitId: -1,
        lastUpdatedById: this.employeeData.id || -1,
        returnPolicyId: -1,
        ndc: '',
        deaClass: '',
        medName: '',
        liquidSize: '',
        packSize: '',
        awp: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getMed();
        this.getUnits();
        this.getPolicies();
        M.FormSelect.init(document.querySelectorAll('select'))
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false });
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const select = M.FormSelect.getInstance(el);
            if (select) { select.destroy() }
        })

        document.querySelectorAll('.modal').forEach(el => {
            const modal = M.Modal.getInstance(el);
            if (modal) { modal.destroy() }
        })
    }

    networkCalls = {
        getMed: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/redbook/read/one/by/id/shallow', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => {
                            this.oldValues = result.data;
                            this.setState(p => ({
                                med: result.data,
                                values: {
                                    ...p.values,
                                    ...result.data,
                                    unitId: result.data.unitId || -1,
                                    deaClass: result.data.deaClass ? result.data.deaClass.toString() : -1,
                                    returnPolicyId: result.data.returnPolicyId ? result.data.returnPolicyId : -1
                                },
                                manufacturer: result.data.manufacturer
                            }), () => {
                                M.updateTextFields();
                                M.FormSelect.init(document.querySelectorAll('select'))
                            })
                        })
                        .catch(logOut)
                })
            },
            type: 'r'
        },
        getUnits: {
            func: () => Axios.get('/api/v1/unit/read/all', {
                params: this.authData
            })
                .then(result => {
                    this.setState({ unitList: result.data }, () => {
                        M.FormSelect.init(document.querySelectorAll('#unitId'));
                    })
                })
                .catch(logOut),
            type: 'r'
        },
        getPolicies: {
            func: () => {
                Axios.get('/api/v1/returnpolicy/read/many/by/redbookid', {
                    params: { ...this.authData, redbookId: this.id }
                })
                    .then(result => this.setState({ policies: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        },
        manufacturerChange: {
            func: e => {
                if (this.state.loadingManufacturerList)
                    this.source.cancel();

                this.setState({ manufacturer: e.target.value, loadingManufacturerList: true }, () => {
                    this.source = Axios.CancelToken.source();

                    Axios.get('/api/v1/party/manufacturer/read/search/by/name', {
                        params: {
                            ...this.authData,
                            name: this.state.manufacturer
                        },
                        cancelToken: this.source.token
                    })
                        .then(result => this.setState({ manufacturerList: result.data }))
                        .catch(err => {
                            if (!Axios.isCancel(err))
                                logOut(err)
                        })
                        .finally(() => this.setState({ loadingManufacturerList: false }))
                })
            },
            type: 'r'
        },
        update: {
            func: () => {
                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/redbook/update/one', {
                        ...this.authData,
                        ...this.state.values
                    })
                        .then(result => {
                            this.props.dispatch(result.data)
                            M.Modal.getInstance(document.querySelector('#confirm-modal')).close();
                            this.setState(p => ({ med: { ...p.med, ...p.values } }));
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ updating: false }))
                })
            },
            type: 'u'
        },
        selectManufacturer: {
            func: mfr => {
                this.setState(p => ({ values: { ...p.values, manufacturerId: mfr.id }, manufacturer: mfr.name }), () => {
                    Axios.get('/api/v1/returnpolicy/read/many/by/partyid', {
                        params: { ...this.authData, partyId: mfr.id }
                    })
                        .then(result => this.setState({ policies: result.data }))
                        .catch(logOut)
                        .finally(() => M.Modal.getInstance(document.querySelector('#mfr-modal')).close())
                })
            },
            type: 'r'
        },
        replace: {
            func: () => {
                Axios.post('/api/v1/redbook/update/replace', {
                    ...this.authData,
                    ...this.state.values,
                    createdById: this.employeeData.id
                })
                    .then(result => {
                        const { insertId, toast } = result.data;
                        this.props.dispatch(toast)
                        this.id = insertId
                        M.Modal.getInstance(document.querySelector('#confirm-modal')).close();
                        this.props.history.replace(`/redbook/${insertId}/update`);
                        this.getMed();
                    })
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    getMed = () => this.networkCalls.getMed();
    getUnits = () => this.networkCalls.getUnits();
    getPolicies = () => this.networkCalls.getPolicies();
    manufacturerChange = e => this.networkCalls.manufacturerChange(e);
    update = e => { e?.preventDefault(); this.networkCalls.update(); }
    selectManufacturer = (e, mfr) => { e?.preventDefault(); this.networkCalls.selectManufacturer(mfr); }
    replace = e => { e?.preventDefault(); this.networkCalls.replace(); }

    getClasses = () => (['R', '2', '3', '4', '5'])

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    revert = e => {
        e.preventDefault();

        this.setState({
            values: {
                ...this.oldValues,
                unitId: this.oldValues.unitId || -1,
                deaClass: this.oldValues.deaClass || -1,
            },
            manufacturer: this.oldValues.manufacturer
        }, () => {
            M.FormSelect.init(document.querySelectorAll('select'));
            M.updateTextFields();
        })
    }

    openModal = () => {
        if (this.state.manufacturer === this.oldValues.manufacturer)
            return;
        M.Modal.getInstance(document.querySelector('#mfr-modal')).open();
    }

    modalCancel = e => {
        e.preventDefault();

        this.setState({ manufacturer: this.oldValues.manufacturer }, () => {
            M.Modal.getInstance(document.querySelector('#mfr-modal')).close();
        })
    }

    confirm = e => {
        e.preventDefault();
        M.Modal.getInstance(document.querySelector('#confirm-modal')).open();
    }

    cancel = e => {
        e.preventDefault();
        M.Modal.getInstance(document.querySelector('#confirm-modal')).close();
    }

    setReturnPolicyId = id => {
        const returnPolicyId = (id === this.state.values.returnPolicyId ? -1 : id);
        this.setState(p => ({ values: { ...p.values, returnPolicyId } }))
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/redbook">Redbook</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/redbook/${this.id}`}>{this.state.med ? this.state.med.ndc : 'Med'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Update</span>
                </div>
                <div className="row">
                    <h4>Update {this.state.med ? <span className="orange-text">{this.state.med.ndc}: {this.state.med.medName}</span> : 'Med'}</h4>
                </div>
                <form>
                    <div className="row">
                        <div className="input-field col s12 m3 l2">
                            <input id="ndc" type="text" onChange={this.onChange} value={this.state.values.ndc} />
                            <label htmlFor="ndc">NDC</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input id="medName" type="text" onChange={this.onChange} value={this.state.values.medName} />
                            <label htmlFor="medName">Med Name</label>
                        </div>
                        <div className="input-field col s12 m2">
                            <select id="deaClass" onChange={this.onChange} value={this.state.values.deaClass}>
                                <option value={-1}>Select A DEA Class</option>
                                {this.getClasses().map(c => <option key={`dea-class-${c}`} value={c}>{c}</option>)}
                            </select>
                        </div>
                        <div className="input-field col s12 m4">
                            <input id="manufacturer" type="text" onChange={this.manufacturerChange} value={this.state.manufacturer} onBlur={this.openModal} />
                            <label htmlFor="manufacturer">Manufacturer Name</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s4 l2">
                            <input id="packSize" type="text" onChange={this.onChange} value={this.state.values.packSize} />
                            <label htmlFor="packSize">Pack Size</label>
                        </div>
                        <div className="input-field col s4 l2">
                            <select id="unitId" onChange={this.onChange} value={this.state.values.unitId}>
                                <option value={-1}></option>
                                {this.state.unitList && this.state.unitList.map(u => <option key={`u-${u.id}`} value={u.id}>{u.unit}</option>)}
                            </select>
                            <label htmlFor="unitId">Unit</label>
                        </div>
                        <div className="input-field col s4 l2">
                            <input id="liquidSize" type="text" onChange={this.onChange} value={this.state.values.liquidSize} />
                            <label htmlFor="liquidSize">Liquid Size</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6 l2">
                            <input id="awp" type="number" onChange={this.onChange} value={this.state.values.awp} />
                            <label htmlFor="awp">AWP</label>
                        </div>
                    </div>
                    <div className="row" style={{ overflow: 'auto', marginBottom: '80px' }}>
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th style={{ padding: '4px' }}>Returnable</th>
                                    <th style={{ padding: '4px' }}>Sealed Only</th>
                                    <th style={{ padding: '4px' }}>Return Window</th>
                                    <th style={{ padding: '4px' }}>Labels</th>
                                    <th style={{ padding: '4px' }}>Controls</th>
                                    <th style={{ padding: '4px' }}>Min Package Percent</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.policies && this.state.policies.map((rp, index) => (
                                    <tr key={`rp-${rp.id}`} className={this.state.values.returnPolicyId === rp.id ? 'blue white-text' : ''} onDoubleClick={() => this.setReturnPolicyId(rp.id)}>
                                        <td style={{ padding: '4px' }}>{rp.isReturnable ? 'Yes' : 'No'}</td>
                                        <td style={{ padding: '4px' }}>{rp.sealedPackagesOnly ? 'Yes' : 'No'}</td>
                                        <td style={{ padding: '4px' }}>{rp.earliestReturnInMonths} - {rp.latestReturnInMonths}</td>
                                        <td style={{ padding: '4px' }}>{rp.labelsAllowed ? 'Yes' : 'No'}</td>
                                        <td style={{ padding: '4px' }}>{rp.controlsAllowed ? 'Yes' : 'No'}</td>
                                        <td style={{ padding: '4px' }}>{rp.minPackagePercentage || '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col s12 m2">
                            <a href="/" className="btn-small orange white-text waves-effect col s12" onClick={this.confirm}>Update Med</a>
                        </div>
                        <div className="col s12 m2">
                            <a href="/" className="btn-small white black-text waves-effect waves-dark col s12" onClick={this.revert}>Discard Changes</a>
                        </div>
                    </div>
                </form>
            </div>
            <div id="mfr-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        {this.state.manufacturerList && this.state.manufacturerList.map(m => (
                            <a key={`mfr-${m.id}`} href="/" className="col s12 m4" onClick={e => this.selectManufacturer(e, m)}>{m.name}</a>)
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12 m2">
                            <a href="/" className="btn-small red white-text waves-effect col s12" onClick={this.modalCancel}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="confirm-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12 m4">
                            <a href="/" className="btn-small orange white-text waves-effect waves-light col s12" onClick={this.update}>Update Med</a>
                        </div>
                        <div className="col s12 m4">
                            <a href="/" className="btn-small green white-text waves-effect waves-light col s12" onClick={this.replace}>Replace Med</a>
                        </div>
                        <div className="col s12 m4">
                            <a href="/" className="btn-small white black-text waves-effect waves-dark col s12" onClick={this.cancel}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(UpdateRedbook);