import React from 'react';
import { connect } from 'react-redux';
import { logOut, registerRoutes } from '../../../func';
import M from '@materializecss/materialize';
import Axios from 'axios';

class EmployeesTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        selectedEmployee: null,
        selectedPage: null,
        permissionList: null,
        loadingPermissions: false,
        pageId: -1,
        contextMenu: {
            clientX: 0,
            clientY: 0,
            show: false
        },
        quickEditValues: {
            clientX: 0,
            clientY: 0,
            show: false,
            page: null,
            action: ''
        }
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls);
        M.Autocomplete.init(document.querySelectorAll('#employeeName'), { data: this.props.employeeListAsObject })
        this.initListeners();
    }

    componentWillUnmount = () => {
        M.Autocomplete.getInstance(document.querySelector('#employeeName'))?.destroy();
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelector('#employeeName').addEventListener('keyup', this.enterListener);
        document.addEventListener('mousedown', this.clickAwayListener)
    }

    removeListeners = () => {
        document.querySelector('#employeeName').removeEventListener('keyup', this.enterListener);
        document.removeEventListener('mousedown', this.clickAwayListener);
    }

    enterListener = e => e.keyCode === 13 ? this.setSelectedEmployee() : null;

    clickAwayListener = e => {
        if (
            this.state.quickEditValues.show &&
            !document.querySelector('#quick-edit')?.contains(e.target) &&
            !document.querySelector('#context-menu').contains(e.target)
        )
            this.setState({
                quickEditValues: {
                    clientX: 0,
                    clientY: 0,
                    show: false,
                    action: '',
                    page: null
                }
            })

        if (e.target.id !== 'context-menu' && this.state.contextMenu.show)
            this.setState({
                contextMenu: {
                    clientX: 0,
                    clientY: 0,
                    show: false
                }
            })
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })
    quickEditChange = e => this.setState(p => ({ quickEditValues: { ...p.quickEditValues, action: e.target.value } }))

    networkCalls = {
        getReadEmployeePermissions: {
            func: () => {
                if (!this.state.selectedEmployee) { return }

                this.setState({ loadingPermissions: true }, () => {
                    Axios.get('/api/v1/website/administration/read/all/permissions/by/employeeid', {
                        params: {
                            ...this.authData,
                            employeeId: this.state.selectedEmployee.id
                        }
                    })
                        .then(result => this.setState({ permissionList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loadingPermissions: false }, () => {
                            M.FormSelect.init(document.querySelectorAll('select'));
                            M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
                        }))
                })
            },
            type: 'r'
        },
        postCreatePermission: {
            func: () => {
                const employeeId = this.state.selectedEmployee.id;
                const pageId = this.state.pageId;

                Axios.post('/api/v1/website/administration/create/one/permission', {
                    ...this.authData,
                    employeeId,
                    pageId
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.networkCalls.getReadEmployeePermissions();
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        postRemoveEmployeePermission: {
            func: () => {
                const employeeId = this.state.selectedEmployee.id;
                const pageId = this.state.selectedPage.orxToolsPageId;

                Axios.post('/api/v1/website/administration/delete/one/permission', {
                    ...this.authData,
                    employeeId,
                    pageId
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.networkCalls.getReadEmployeePermissions();
                    })
                    .catch(logOut)
            },
            type: 'u'
        },
        postUpdatePermission: {
            func: () => {
                const employeeId = this.state.selectedEmployee.id;
                const pageId = this.state.selectedPage.orxToolsPageId;
                const action = this.state.quickEditValues.action;

                Axios.post('/api/v1/website/administration/update/one/permission', {
                    ...this.authData,
                    employeeId,
                    pageId,
                    action
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.networkCalls.getReadEmployeePermissions();
                        this.setState({
                            quickEditValues: {
                                clientY: 0,
                                clientX: 0,
                                action: '',
                                page: null,
                                show: false
                            }
                        })
                    })
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    postCreatePermission = () => this.networkCalls.postCreatePermission();

    postRemoveEmployeePermission = e => { e?.preventDefault(); this.networkCalls.postRemoveEmployeePermission(); }

    postUpdatePermission = e => { e?.preventDefault(); this.networkCalls.postUpdatePermission(); }

    setSelectedEmployee = () => {
        const employeeName = document.querySelector('#employeeName').value;
        const employee = this.props.employeeList.find(e => `${e.firstName} ${e.lastName}` === employeeName);
        this.setState({ selectedEmployee: employee }, () => {
            this.networkCalls.getReadEmployeePermissions();
        })
    }

    onContextMenu = (e, selectedPage) => {
        e?.preventDefault();
        const { clientX, clientY } = e;

        this.setState({
            selectedPage,
            contextMenu: {
                clientX,
                clientY,
                show: true
            }
        })
    }

    setQuickEditValues = e => {
        e?.preventDefault();
        const { clientY, clientX } = e;

        this.setState(p => ({
            quickEditValues: {
                clientX,
                clientY,
                show: true,
                action: p.selectedPage.action,
                page: p.selectedPage
            }
        }))
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="input-field col s12 m2">
                    <input id="employeeName" type="text" className="autocomplete" />
                    <label htmlFor="employeeName">Employee Name</label>
                </div>
            </div>
            <div className="row">
                {this.state.loadingPermissions ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :
                    <div className="col s12" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                        <div className="card">
                            <div className="card-content">
                                {this.state.selectedEmployee && (
                                    <div className="row">
                                        <div className="input-field col s11 m5 offset-m6">
                                            <select id="pageId" value={this.state.pageId} onChange={this.onChange}>
                                                <option value={-1}></option>
                                                {this.props.pageList?.map(p => (
                                                    <option key={p.id} value={p.id}>{p.route}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="input-field col s1">
                                            <i
                                                className="material-icons tooltipped right blue-text left"
                                                data-position="bottom"
                                                data-tooltip="Grant Page Access"
                                                style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', cursor: 'pointer' }}
                                                onClick={this.postCreatePermission}
                                            >add</i>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '2px' }}>Page Route</th>
                                                <th style={{ padding: '2px' }}>Page Notes</th>
                                                <th style={{ padding: '2px' }}>User Permissions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.permissionList?.map(p => (
                                                <tr
                                                    key={`${p.employeeId}-${p.orxToolsPageId}`}
                                                    className={this.state.selectedPage?.orxToolsPageId === p.orxToolsPageId ? 'grey lighten-2' : ''}
                                                    onContextMenu={e => this.onContextMenu(e, p)}
                                                >
                                                    <td style={{ padding: '2px' }}>{p.pageRoute}</td>
                                                    <td style={{ padding: '2px' }}>{p.pageNotes}</td>
                                                    <td style={{ padding: '2px' }}>{p.action}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div id="context-menu" className={this.state.contextMenu?.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: this.state.contextMenu?.clientY,
                left: this.state.contextMenu?.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'
            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => e.preventDefault()} onMouseDown={this.setQuickEditValues}>Edit Permissions</a>
                        <div style={{ height: '6px' }} />
                        <div className="divider" />
                        <div style={{ height: '6px' }} />
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={e => e.preventDefault()} onMouseDown={this.postRemoveEmployeePermission}>Remove</a>
                    </li>
                </ul>
            </div>
            <div id="quick-edit" className={this.state.quickEditValues.show ? '' : 'hide'} style={{
                zIndex: 101,
                position: 'fixed',
                top: this.state.quickEditValues.clientY,
                left: this.state.quickEditValues.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'
            }}>
                <div className="row">
                    <div className="input-field col s12">
                        <input id="quick-edit-action" type="text" value={this.state.quickEditValues.action} onChange={this.quickEditChange} />
                        <label htmlFor="quick-edit-action">Page Permission</label>
                    </div>
                    <div className="input-field col s12">
                        <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.postUpdatePermission}>Update Permissions</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(EmployeesTab);