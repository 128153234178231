import React from 'react';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import Axios from 'axios';
import { withRouter } from 'react-router';
import moment from 'moment';
import { logOut } from '../func';

class ClockOutTimer extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
        this.ip = 0;
        this.worker = null;
    }

    initState = () => ({
        tenMinuteWarning: null,
        top: 20,
        left: window.innerWidth - 260,
        countDown: '10:00',
        showCountDown: false,
    })

    componentDidMount = () => {
        Axios.get('https://www.cloudflare.com/cdn-cgi/trace')
            .then(result => {
                const arr = result.data.toString().match(/ip=[^\n]*/i)
                const ip = arr[0].split('=')[1];
                this.ip = ip;
                this.startSharedWorker();
                this.getUserTimeclockPunches();
            })
    }

    getUserTimeclockPunches = () => {
        Axios.get('/api/v1/user/read/all/usertimeclockrange', {
            params: {
                ...this.authData,
                start: moment().format('YYYY-MM-DD 05:00:00'),
                end: moment().add(1, 'day').format('YYYY-MM-DD 00:00:00')
            }
        })
            .then(result => this.calculateRemainingMilliseconds(result.data))
            .catch(logOut)
    }

    calculateRemainingMilliseconds = punches => {
        if (punches.length % 2 === 0) { return }

        let millisecondsWorked = 0;

        for (let i = 0; i < punches.length - 1; i += 2) {
            const diff = moment(punches[i + 1].timeStamp).diff(moment(punches[i].timeStamp), 'milliseconds');
            millisecondsWorked += diff;
        }

        millisecondsWorked += moment.utc().diff(moment.utc(punches[punches.length - 1].timeStamp), 'milliseconds');

        const millisecondsRemainingInWorkday = 28800000 - millisecondsWorked;

        this.worker.port.postMessage({ type: 'initialize', millisecondsRemainingInWorkday });
    }

    startSharedWorker = () => {
        this.worker = new SharedWorker('/workers/timeoutWorker.js', 'ORX_TOOLS_SHARED_TIMEOUT_WORKER');

        this.worker.port.addEventListener('message', e => {
            const { type } = e.data;

            switch (type) {
                case 'countdown':
                    return this.setState({ showCountDown: true, countDown: e.data.countDown })
                case 'cancelled':
                    return this.setState({ showCountDown: false, countDown: '' })
                case 'logout':
                    return this.clockOut();
                case 'terminate':
                    localStorage.clear();
                    setTimeout(() => this.props.history.replace('/'), 1000);
                    return;
                default:
                    return;
            }
        })

        this.worker.port.start();
    }

    clockOut = () => {
        Axios.post('/api/v1/user/create/one/timeclock', {
            ...this.authData,
            ip: this.ip
        })
            .then(result => {
                this.worker.port.postMessage({ type: 'terminate' })
                this.props.dispatch(result.data);
                localStorage.clear();
                this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'You Have Been Clocked Out!', class: 'green white-text' } })
                return setTimeout(() => this.props.history.replace('/'), 1500);
            })
            .catch(err => this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Unable to clock you out!', class: 'red white-text' } }))
    }

    cancelCountdown = e => {
        e.preventDefault();
        this.worker.port.postMessage({ type: 'cancel' })
    }

    render = () => (
        <div className={this.state.showCountDown ? '' : 'hide'}>
            <Draggable>
                <div
                    style={{
                        zIndex: 200,
                        backgroundColor: 'white',
                        border: '1px solid black',
                        borderRadius: '3px',
                        width: '240px',
                        height: '60px',
                        position: 'fixed',
                        top: this.state.top,
                        left: this.state.left,
                        boxShadow: '2px 2px 2px #999',
                        padding: '4px'
                    }}
                >
                    <span style={{ cursor: 'default' }}>AUTOMATIC CLOCK-OUT IN:</span><br />
                    <span className="orange-text text-darken-2 bold left" style={{ fontSize: '1.4em' }}>{Math.floor(parseInt(this.state.countDown) / 60)}:{(parseInt(this.state.countDown) % 60).toString().padStart(2, '0')}</span>
                    <a href="/" style={{ position: 'absolute', right: '4px', bottom: '0px' }} onClick={this.cancelCountdown}>Cancel</a>
                </div>
            </Draggable>
        </div >
    )
}

const mapStateToProps = ({ clockOutTime }) => ({ clockOutTime })

export default withRouter(connect(mapStateToProps)(ClockOutTimer));