import moment from "moment";
import React from 'react';

const ChecksTab = props => (
    <div style={{ padding: '1vw' }}>
        {props.checks ?
            <table>
                <thead>
                    <tr>
                        <th style={{ padding: '0px' }}>Check Number</th>
                        <th style={{ padding: '0px' }}>Date Issued</th>
                        <th style={{ padding: '0px' }}>Amount</th>
                        <th style={{ padding: '0px' }}>Date Cleared</th>
                    </tr>
                </thead>
                <tbody>
                    {props.checks?.map(c => (
                        <tr key={c.id}>
                            <td style={{ padding: '2px' }}>{c.checkNumber}</td>
                            <td style={{ padding: '2px' }}>{moment.utc(c.dateIssued).local().format('MM/DD/YYYY')}</td>
                            <td style={{ padding: '2px' }}>${c.amount}</td>
                            <td style={{ padding: '2px' }}>{c.dateCleared ? moment.utc(c.dateCleared).local().format('MM/DD/YYYY') : ''}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            : null}
    </div>
)

export default ChecksTab;