import Axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { logOut, registerRoutes } from "../../../func";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css';
import { useDispatch } from "react-redux";
import * as xlsx from 'xlsx';
import moment from "moment";

const TableDetails = props => {
    const {
        tableName,
        setParentColumns,
        filters,
        joins
    } = props;

    const { path } = useRouteMatch();
    const dispatch = useDispatch();

    const [columns, setColumns] = useState(null);
    const [rows, setRows] = useState(null)
    const [downloading, setDownloading] = useState(false);

    const gridRef = useRef();
    const ref = useRef({
        abortController: new AbortController(),
    })

    const networkCalls = registerRoutes({
        getColumns: {
            func: tableName => {
                if (!tableName) { return }

                Axios.get('/api/v2/websiteadministration/read/all/columns/by/tablename', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        tableName,

                    }
                })
                    .then(result => setColumns(result.data))
                    .catch(logOut)
            },
            type: 'r'
        },
        getRows: {
            func: (tableName, filters, joins) => {
                ref.current.abortController?.abort();
                ref.current.abortController = new AbortController();

                if (!tableName) { return }

                setRows(null)
                Axios.post('/api/v2/websiteadministration/read/tablerows', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    tableName,
                    filters,
                    joins
                }, { signal: ref.current.abortController?.signal })
                    .then(result => setRows(result.data))
                    .catch(logOut)
            },
            type: 'r'
        },
        downloadFile: {
            func: (tableName, filters, joins) => new Promise((resolve, reject) => {
                setDownloading(true);

                Axios.post('/api/v2/websiteadministration/read/tablerows', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    tableName,
                    filters,
                    joins,
                    isDownload: true
                })
                    .then(result => resolve(result.data))
                    .catch(err => logOut(err, resolve))
                    .finally(() => setDownloading(false))
            }),
            type: 'r'
        }
    }, path)

    useEffect(() => {
        networkCalls.getColumns(tableName);
    }, [tableName])

    useEffect(() => {
        setParentColumns(columns);
    }, [columns])

    useEffect(() => {
        networkCalls.getRows(tableName, filters, joins)
    }, [filters, joins])

    const getColumnDefs = () => {
        const sortedColumns = columns?.sort((a, b) => a.ordinalPosition - b.ordinalPosition).map(col => ({ field: `${tableName}_${col.columnName}`, filter: true, resizable: true, sortable: true }))
        let arr = [];
        joins.forEach(join => {
            arr = [...arr, ...join.columns.map(col => ({ field: `${join.columnName}_${col.columnName}`, filter: true, resizable: true, sortable: true }))]
        })

        return [...(sortedColumns || []), ...(arr || [])]
    }

    const downloadFile = async e => {
        e?.preventDefault();

        if (downloading)
            dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Still processing previous request. Large Datasets can take a long time to process because downloads do not have row limits.', class: ' blue white-text' } })

        const rows = await networkCalls.downloadFile(tableName, filters, joins);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, xlsx.utils.json_to_sheet(rows))
        xlsx.writeFile(wb, `${tableName}_${moment().format('YYYY_MM_DD_hh_mm')}.xlsx`);
    }

    return (
        <>
            <div
                className="ag-theme-balham"
                style={{
                    height: 'CALC(100vh - 121px)',
                    width: '100%',
                    position: 'relative',
                    top: '6px'
                }}
                onContextMenu={oncontextmenu}
            >
                {rows ?
                    <>
                        <span style={{ padding: '0px', margin: '0px' }} className="col s1">{rows?.length >= 500 ? '500+' : rows.length} Results</span>
                        <a
                            className=""
                            style={{ textAlight: 'right', marginBottom: '4px' }}
                            disabled={downloading ? 'disabled' : ''}
                            href="/"
                            onClick={downloadFile}
                        >Download Results</a>
                    </>
                    : null}
                <AgGridReact
                    ref={gridRef}
                    rowHeight={20}
                    rowData={rows || []}
                    columnDefs={getColumnDefs()}
                    animateRows={true}
                />
            </div>
        </>
    )
}

export default TableDetails;