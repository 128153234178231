import React from 'react';
import { Input } from '../../../layout/form';

const ContactTab = props => (
    <div style={{ padding: '1vw' }}>
        <div className="row">
            <div className="col s4 m3 l2">
                <table>
                    <tbody>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}>Phone</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}>Email</td></tr>
                        <tr><td style={{ padding: '4px', textAlign: 'right' }}>Website</td></tr>
                    </tbody>
                </table>
            </div>
            <div className="col s8 m9 l10">
                <table>
                    <tbody>
                        <Input id="phone" type="text" value={props.values.phone} onChange={props.onChange} col="s4 m2" />
                        <Input id="email" type="text" value={props.values.email} onChange={props.onChange} col="s6 m4" />
                        <Input id="website" type="text" value={props.values.website} onChange={props.onChange} col="s5 l4" />
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row" style={{ marginTop: '40px' }}>
            <div className="col s12">
                <a href="/" onClick={e => { e.preventDefault(); props.setTab({ target: { id: 'tab-address' } }) }}>
                    <i className="material-icons tooltipped"
                        data-position="top"
                        data-tooltip="Previous"
                        style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                    >arrow_back</i>
                </a>
                <a href="/" onClick={e => { e.preventDefault(); props.setTab({ target: { id: 'tab-returnInfo' } }) }}>
                    <i className="material-icons tooltipped"
                        data-position="top"
                        data-tooltip="Next"
                        style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                    >arrow_forward</i>
                </a>
                <a href="/" onClick={e => { e.preventDefault(); props.revert(); }}>
                    <i className="material-icons tooltipped"
                        data-position="top"
                        data-tooltip="Discard Contact Changes"
                        style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                    >refresh</i>
                </a>
            </div>
        </div>
    </div>
);

export default ContactTab;