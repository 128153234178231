import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut, openSideNav, registerRoutes } from "../../func";
import moment from "moment";
import Axios from 'axios';

class RecallOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        recallList: null,
        ndc: '',
        medName: '',
        manufacturerName: '',
        lotNumber: '',
        expirationStart: '',
        expirationEnd: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        document.querySelectorAll('.recall-search').forEach(el => el.addEventListener('keyup', this.enterListener))
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.recall-search').forEach(el => el.removeEventListener('keyup', this.enterListener))
    }

    enterListener = e => {
        if (e.keyCode === 13)
            this.search();
    }

    networkCalls = {
        search: {
            func: () => {
                Axios.get('/api/v1/recall/read/search', {
                    params: {
                        ...this.authData,
                        ...this.state
                    }
                })
                    .then(result => this.setState({ recallList: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        },
        confirmDelete: {
            func: id => {
                if (window.confirm('Are you sure you want to delete this recall?'))
                    Axios.post('/api/v1/recall/delete/one', {
                        ...this.authData,
                        id
                    })
                        .then(result => this.props.dispatch(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ recallList: null }, this.search))
            },
            type: 'd'
        }
    }

    search = e => { e?.preventDefault(); this.networkCalls.search(); }
    confirmDelete = id => this.networkCalls.confirmDelete(id);

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Recalls</span>
                </div>
            </div>
            <div className="row">
                <h4 className="center">Recalls</h4>
            </div>
            <div className="row">
                <div className="input-field col s1">
                    <input className="recall-search" id="ndc" type="text" onChange={this.onChange} value={this.state.ndc} />
                    <label htmlFor="ndc">NDC (partial ok)</label>
                </div>
                <div className="input-field col s2">
                    <input id="medName" type="text" onChange={this.onChange} value={this.state.medName} />
                    <label htmlFor="medName">Med Name</label>
                </div>
                <div className="input-field col s2">
                    <input id="manufacturerName" type="text" onChange={this.onChange} value={this.state.manufacturerName} />
                    <label htmlFor="manufacturerName">Mfr Name</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s2">
                    <input className="recall-search" id="lotNumber" type="text" onChange={this.onChange} value={this.state.lotNumber} />
                    <label htmlFor="lotNumber">Lot Number</label>
                </div>
                <div className="input-field col s2">
                    <input className="recall-search" id="expirationStart" type="date" onChange={this.onChange} value={this.state.expirationStart} />
                    <label htmlFor="expirationStart">Exp Start Date</label>
                </div>
                <div className="input-field col s2">
                    <input className="recall-search" id="expirationEnd" type="date" onChange={this.onChange} value={this.state.expirationEnd} />
                    <label htmlFor="expirationEnd">Exp End Date</label>
                </div>
                <div className="input-field col s2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.search}>Search Recalls</a>
                </div>
                <div className="input-field col s2 offset-s2">
                    <Link to="/recall/create" className="btn-small teal white-text waves-effect waves-light col s12">Add New Recall</Link>
                </div>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th>NDC</th>
                            <th>Med Name</th>
                            <th>Manufacturer</th>
                            <th>Lot Number</th>
                            <th>Expiration Start</th>
                            <th>Expiration End</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.recallList?.map(r => (
                            <tr key={`recall=${r.id}`}>
                                <td style={{ padding: '4px' }}>{r.ndc}</td>
                                <td style={{ padding: '4px' }}>{r.medName}</td>
                                <td style={{ padding: '4px' }}>{r.manufacturerName}</td>
                                <td style={{ padding: '4px' }}>{r.lotNumber}</td>
                                <td style={{ padding: '4px' }}>{moment(r.expirationStart).format('MM/DD/YYYY')}</td>
                                <td style={{ padding: '4px' }}>{moment(r.expirationEnd).format('MM/DD/YYYY')}</td>
                                <td style={{ padding: '4px' }}><Link to={`recall/${r.id}`}>Details</Link></td>
                                <td style={{ padding: '4px' }}><i className="material-icons" style={{ cursor: 'pointer' }} onClick={() => this.confirmDelete(r.id)}>delete</i></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect()(RecallOverview);