import React from 'react';
import M from '@materializecss/materialize';
import { Input, CheckBox } from '../../../layout/form';


class ReturnInfoTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        exclusiveReturnWholesalerName: this.props.values.exclusiveReturnWholesalerName,
    })

    componentDidMount = () => {
        this.initExclusiveReturnWholesalerAutoComplete(this.props.wholesalerListAsObject);
    }

    componentWillUnmount = () => {
        this.disposeExclusiveReturnWholesalerAutoComplete();
    }

    UNSAFE_componentWillReceiveProps = props => {
        this.initExclusiveReturnWholesalerAutoComplete(props.wholesalerListAsObject);
    }

    initExclusiveReturnWholesalerAutoComplete = data => {
        this.disposeExclusiveReturnWholesalerAutoComplete();
        M.Autocomplete.init(document.querySelector('#exclusiveReturnWholesalerId'), {
            data,
            onAutocomplete: exclusiveReturnWholesalerName => {
                this.setState({ exclusiveReturnWholesalerName });
                this.props.onExclusiveReturnWholesalerChange(exclusiveReturnWholesalerName);
            }
        })
    }

    disposeExclusiveReturnWholesalerAutoComplete = () => {
        const ac = M.Autocomplete.getInstance(document.querySelector('#exclusiveReturnWholesalerId'));
        if (ac) { ac.destroy(); }
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <table>
                        <tbody>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Exclusive Return Wholesaler</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Direct Return</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>RA Required</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Destroy In House</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Minimum Total Return Value</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8 m9 l10">
                    <table>
                        <tbody>
                            <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
                                <td style={{ padding: '0px' }}>
                                    <div className="input-field" style={{ margin: '0px', padding: '0px' }}>
                                        <input
                                            id="exclusiveReturnWholesalerId"
                                            type="text"
                                            className="col s6 m4 autocomplete"
                                            style={{ margin: '0px', padding: '0px', height: '30.5px' }}
                                            value={this.state.stateName}
                                            onChange={e => this.setState({ exclusiveReturnWholesalerName: e.target.value })}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <CheckBox id="directReturn" value={this.props.values.directReturn} onChange={this.props.onCheckboxChange} col="s4" />
                            <CheckBox id="raRequired" value={this.props.values.raRequired} onChange={this.props.onCheckboxChange} col="s4" />
                            <CheckBox id="destroyInHouse" value={this.props.values.destroyInHouse} onChange={this.props.onCheckboxChange} col="s4" />
                            <Input id="minValue" type="number" value={this.props.values.minValue} onChange={this.props.onChange} col="s4 m2" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row" style={{ marginTop: '40px' }}>
                <div className="col s12">
                    <a href="/" onClick={e => { e.preventDefault(); this.props.setTab({ target: { id: 'tab-contact' } }) }}>
                        <i className="material-icons tooltipped"
                            data-position="top"
                            data-tooltip="Previous"
                            style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                        >arrow_back</i>
                    </a>
                    {this.props.revert ?
                        <a href="/" onClick={e => { e.preventDefault(); this.props.revert(); }}>
                            <i className="material-icons tooltipped"
                                data-position="top"
                                data-tooltip="Discard Return Info Changes"
                                style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                            >refresh</i>
                        </a>
                        :
                        <a href="/" onClick={e => { e.preventDefault(); this.props.setTab({ target: { id: 'tab-returnPolicy' } }) }}>
                            <i className="material-icons tooltipped"
                                data-position="top"
                                data-tooltip="Next"
                                style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                            >arrow_forward</i>
                        </a>
                    }
                    {this.props.update ?
                        <a href="/" onClick={e => { e.preventDefault(); this.props.update(); }} disabled={this.props.updating ? 'disabled' : ''}>
                            <i className="material-icons tooltipped"
                                data-position="top"
                                data-tooltip="Update Manufacturer"
                                style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                            >upload</i>
                        </a>
                        : null}
                </div>
            </div>
        </div>
    )
}

export default ReturnInfoTab;