import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import Axios from 'axios';
import M from '@materializecss/materialize';
import moment from 'moment';

class OutboundChecks extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
        this.formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    }


    initState = () => ({
        allPharmacies: null,
        allPharmaciesIndex: 0,
        checksToBeWritten: [],
        currentCheck: this.initCheckValues(),
        currentOrder: null,
        employee: null,
        loadingOrderDetail: false,
        loadingOrders: false,
        orderTransactionDetails: null,
        orderList: null,
        orderHeaderDollarAmount: 0,
        checksWithValue: null,
    })

    initCheckValues = () => ({
        payToTheOrderOf: '',
        memo: '',
        date: moment().format('YYYY-MM-DD'),
        orders: {},
        value: 0
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.Modal.init(document.querySelectorAll('.modal'));
        M.Collapsible.init(document.querySelectorAll('.collapsible'));
        this.getMainPharmacyList();
        this.getEmployee();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.addEventListener('keydown', this.arrowListener);
    }

    removeListeners = () => {
        document.removeEventListener('keydown', this.arrowListener);
    }

    arrowListener = e => {
        if (e.ctrlKey && (e.keyCode === 38 || e.keyCode === 40)) {
            e.preventDefault();
            let allPharmaciesIndex = this.state.allPharmaciesIndex;

            if (e.keyCode === 40) {
                if (allPharmaciesIndex === this.state.allPharmacies.length - 1)
                    return;

                allPharmaciesIndex++;
            }
            else {
                if (allPharmaciesIndex === 0)
                    return;

                allPharmaciesIndex--;
            }
            this.selectPharmacy(allPharmaciesIndex);
        }
        else if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
            const yearElement = document.querySelector('li.active>div.collapsible-header');
            const selectedOrderElement = document.querySelector('li.active>div.collapsible-body>ul.collection>li.collection-item.blue.white-text');
            if (!yearElement || !selectedOrderElement) { return }
            const year = yearElement.innerHTML;
            let sibling;
            if (e.keyCode === 38) {
                sibling = selectedOrderElement.previousSibling;
            }
            else {
                sibling = selectedOrderElement.nextSibling;
            }

            if (!sibling) { return }
            else {
                const order = this.state.orderList[year].find(o => o.orderNumber === sibling.innerHTML);
                this.orderClick(order);
            }
        }
    }

    networkCalls = {
        getMainPharmacyList: {
            func: () => {
                Axios.get('/api/v1/pharmacy/read/all/with/balance', { params: this.authData })
                    .then(result => {
                        const p = result.data?.sort((a, b) => a.name > b.name ? 1 : -1);
                        this.setState({ allPharmacies: p, currentCheck: p[0] }, () => {
                            this.selectPharmacy(0);
                        })
                    })
                    .catch(logOut);
            },
            type: 'r'
        },
        getEmployee: {
            func: () => {
                Axios.get('/api/v1/employee/read/one/by/id', { params: { ...this.authData, id: this.employeeData.id } })
                    .then(result => {
                        
                        if(result.data[0]?.signature){
                            const u8 = new Uint8Array(result.data[0].signature.data);
                            let b64_str = '';
                            for(let i = 0; i < u8.length; i++)
                                b64_str += String.fromCharCode(u8[i])

                            const signature = window.btoa(b64_str);
                            result.data[0].signature = signature;
                        }

                        this.setState({ employee: result.data[0] })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getOrders: {
            func: pharmacyId => {
                this.setState({ loadingOrders: true }, () => {
                    Axios.get('/api/v1/order/header/read/all/by/pharmacyid', {
                        params: {
                            ...this.authData,
                            pharmacyId
                        }
                    })
                        .then(result => {
                            const years = {};
                            result.data.forEach(o => {
                                const year = moment(o.orderDate).year();
                                if (!years[year])
                                    years[year] = [];

                                years[year].push(o);
                            })

                            this.setState({ orderList: years, currentOrder: result.data[0] }, () => {
                                this.orderClick(result.data[0])
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loadingOrders: false }, () => {
                            M.Collapsible.getInstance(document.querySelector('#order-collapsible')).open(0);
                        }))
                })
            },
            type: 'r'
        },
        orderClick: {
            func: order => {
                if (!order) { return }
                this.setState({ currentOrder: order, loadingOrderDetail: true }, () => {
                    Axios.get('/api/v1/invoice/read/one/with/transactions', {
                        params: {
                            ...this.authData,
                            id: order.id
                        }
                    })
                        .then(result => {
                            const orderTransactionDetails = { total: 0, details: {} }
                            result.data.forEach(row => {
                                if (!orderTransactionDetails.details[row.type]) {
                                    if (row.type !== 'CHECK')
                                        orderTransactionDetails.details[row.type] = { amount: 0 }
                                    else
                                        orderTransactionDetails.details[row.type] = [];
                                }

                                switch (row.type) {
                                    case 'INVOICE':
                                        orderTransactionDetails.details[row.type].amount += parseFloat(row.amount);
                                        orderTransactionDetails.total += parseFloat(row.amount);
                                        break;
                                    case 'CHECK':
                                        orderTransactionDetails.details[row.type].push(row);
                                        orderTransactionDetails.total += parseFloat(row.amount);
                                        break;
                                    case 'PAYMENT RECEIVED':
                                        orderTransactionDetails.details[row.type].amount -= parseFloat(row.amount);
                                        orderTransactionDetails.total -= parseFloat(row.amount);
                                        break;
                                    case 'CREDIT MEMO':
                                        orderTransactionDetails.details[row.type].amount -= parseFloat(row.amount);
                                        orderTransactionDetails.total -= parseFloat(row.amount);
                                        break;
                                    default:
                                        break;
                                }
                            })

                            const orderHeaderDollarAmount = this.state.currentCheck.orders[order.orderNumber] ? this.state.currentCheck.orders[order.orderNumber].value : 0;
                            this.setState({ orderTransactionDetails, orderHeaderDollarAmount })

                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loadingOrderDetail: false }, () => {
                            document.querySelector('#orderHeaderDollarAmount').select();
                        }))
                })
            },
            type: 'r'
        },
        saveAndPrint: {
            func: () => {
                const checkNumber = window.prompt('Starting Check Number:')

                Axios.post('/api/v1/outbound/check/create/many/outboundchecks', {
                    ...this.authData,
                    checks: this.state.checksWithValue,
                    employeeId: this.employeeData.id,
                    checkNumber
                }, { responseType: 'blob' })
                    .then(result => {
                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', `checks.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.props.history.replace('/accounting');
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        reprint: {
            func: id => {
                const checkNumber = window.prompt('New Check Number:');

                Axios.post('/api/v1/outbound/check/update/reprint/one', {
                    ...this.authData,
                    checkNumber,
                    id
                }, { responseType: 'blob' })
                    .then(result => {
                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', `reprint_check_${checkNumber}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut);
            },
            type: 'u'
        }
    }

    getMainPharmacyList = () => this.networkCalls.getMainPharmacyList();
    getEmployee = () => this.networkCalls.getEmployee();
    getOrders = pharmacyId => this.networkCalls.getOrders(pharmacyId);
    orderClick = order => this.networkCalls.orderClick(order);
    saveAndPrint = e => { e?.preventDefault(); this.networkCalls.saveAndPrint(); }
    reprint = (e, id) => { e?.preventDefault(); this.networkCalls.reprint(id) }

    selectPharmacy = index => {
        const pharmacy = this.state.allPharmacies[index];
        const checksToBeWritten = JSON.parse(JSON.stringify(this.state.checksToBeWritten));
        const checkIndex = checksToBeWritten.findIndex(c => c.pharmacyId === pharmacy.id);
        if (checkIndex === -1) {
            let payToTheOrderOf = '' + pharmacy.name + '\n' + pharmacy.addressLineOne;
            if (pharmacy.addressLineTwo) { payToTheOrderOf += '\n' + pharmacy.addressLineTwo }
            payToTheOrderOf += '\n' + pharmacy.city + ', ' + pharmacy.state?.toString().toUpperCase() + ' ' + pharmacy.zip;

            const newCheck = {
                ...this.initCheckValues(),
                pharmacyName: pharmacy.name,
                pharmacyId: pharmacy.id,
                payToTheOrderOf
            }
            checksToBeWritten.push(newCheck);
        }

        this.setState({
            allPharmaciesIndex: index,
            checksToBeWritten,
            currentCheck: checksToBeWritten[checkIndex === -1 ? checksToBeWritten.length - 1 : checkIndex],
        }, () => {
            this.getOrders(pharmacy.id)
            M.textareaAutoResize(document.querySelector('#payToTheOrderOf'))
            M.updateTextFields();
        })
    }


    checkChange = e => {
        const checksToBeWritten = JSON.parse(JSON.stringify(this.state.checksToBeWritten));
        const checkIndex = checksToBeWritten.findIndex(c => c.pharmacyId === this.state.allPharmacies[this.state.allPharmaciesIndex].id);
        checksToBeWritten[checkIndex][e.target.id] = typeof e.target.value === 'string' ? e.target.value : e.target.value;

        this.setState({ checksToBeWritten, currentCheck: checksToBeWritten[checkIndex] })
    }

    changeOrderHeaderDollarAmount = e => {
        if (!this.state.currentOrder) { return }
        const { value } = e.target;
        const checksToBeWritten = JSON.parse(JSON.stringify(this.state.checksToBeWritten));
        const currentCheck = JSON.parse(JSON.stringify(this.state.currentCheck));
        const currentOrder = JSON.parse(JSON.stringify(this.state.currentOrder))

        if (!currentCheck.orders[currentOrder.orderNumber])
            currentCheck.orders[currentOrder.orderNumber] = { id: currentOrder.id, value: 0 }

        currentCheck.orders[currentOrder.orderNumber].value = value;
        currentCheck.value = Object.values(currentCheck.orders).reduce((sum, current) => sum + parseFloat(current.value), 0);
        checksToBeWritten[checksToBeWritten.findIndex(c => c.pharmacyId === currentCheck.pharmacyId)] = currentCheck;

        this.setState({ checksToBeWritten, currentCheck, orderHeaderDollarAmount: e.target.value })
    }



    openPrintModal = e => {
        e?.preventDefault();
        const checksWithValue = JSON.parse(JSON.stringify(this.state.checksToBeWritten)).filter(c => !isNaN(parseFloat(c.value)) && parseFloat(c.value) > 0.001);
        this.setState({ checksWithValue }, () => {
            M.Modal.getInstance(document.querySelector('#print-modal')).open();
        })
    }

    render = () => (
        <div className="main" style={{ fontSize: '.9em' }}>
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Outbound Checks</span>
                </div>
            </div>
            <div className="row">
                <div className="col s3">
                    <div style={{ maxHeight: '90vh', overflowX: 'auto' }} className="card">
                        <div className="card-content">
                            <ul id="pharmacies" className="collection">
                                {this.state.allPharmacies?.map((p, index) => {
                                    const check = this.state.checksToBeWritten.find(c => c.pharmacyId === p.id);
                                    const balance = check?.value;
                                    return (
                                        <li
                                            key={`all-pharmacies-${p.id}`}
                                            style={{
                                                padding: '4px 8px',
                                                cursor: 'pointer',
                                                fontWeight: balance ? '700' : 'inherit'
                                            }}
                                            className={`collection-item  ${this.state.allPharmaciesIndex === index ? 'blue white-text' : ''}`}
                                            onClick={() => this.selectPharmacy(index)}
                                        >
                                            {p.name.substring(0, 44)
                                            }<span className="right">${(p.balance + (balance ? balance : 0)).toFixed(2)}</span>
                                        </li>
                                    )
                                }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                </div>
                <div className="col s9">
                    <div className="row no-margin">
                        <div className="card col s12">
                            <div className="card-content">
                                {this.state.allPharmacies && this.state.allPharmacies[this.state.allPharmaciesIndex] && (
                                    <div>
                                        <div className="row no-margin" >
                                            <div className="input-field col s4">
                                                <textarea className="materialize-textarea" id="payToTheOrderOf" type="text" onChange={this.checkChange} value={this.state.currentCheck.payToTheOrderOf} />
                                                <label htmlFor="payToTheOrderOf"><b>PAY TO THE ORDER OF</b></label>
                                            </div>
                                            <div className="input-field col s2 offset-s2">
                                                <input id="date" type="date" onChange={this.checkChange} value={this.state.currentCheck.date} />
                                                <label htmlFor="date">Date</label>
                                            </div>
                                            <div className="input-field col s2">
                                                <input disabled id="value" type="number" onChange={this.checkChange} value={this.state.currentCheck.value?.toFixed(2)} />
                                                <label htmlFor="value"><b>DOLLARS</b></label>
                                            </div>
                                        </div>
                                        <div className="row no-margin">
                                            <div className="input-field col s6">
                                                <input id="memo" type="text" onChange={this.checkChange} value={this.state.currentCheck.memo} />
                                                <label htmlFor="memo"><b>MEMO</b></label>
                                            </div>
                                            <div className="col s4 ">
                                                <img className="responsive-img" style={{ maxHeight: '.4in' }} src={this.state.employee?.signature ? `data:image/png;base64,${this.state.employee?.signature}` : '//:0'} alt="signature" />
                                                <p><i>authorized signature: {this.state.employee?.firstName} {this.state.employee?.lastName}</i></p>
                                            </div>
                                            <div className="input-field col s2">
                                                <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.openPrintModal}>Print Checks</a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row no-margin">
                        <div className="col s3" style={{ maxHeight: '632px', overflowY: 'auto' }}>
                            <div className="row">
                                <ul id="order-collapsible" className="collapsible popout">
                                    {this.state.orderList ? Object.keys(this.state.orderList).map(key => (
                                        <li key={`year-list-${key}`}>
                                            <div className="collapsible-header">{key}</div>
                                            <div className="collapsible-body no-padding">
                                                <ul className="collection">
                                                    {this.state.orderList[key].map(o => (
                                                        <li
                                                            key={`order-list-${o.id}`}
                                                            className={`collection-item ${o.id === this.state.currentOrder?.id ? 'blue white-text' : ''}`}
                                                            style={{ padding: '2px', margin: '8px', cursor: 'pointer' }}
                                                            onClick={() => this.orderClick(o)}
                                                        >
                                                            {o.orderNumber}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    )) : null}
                                </ul>
                            </div>
                        </div>
                        <div className="col s9">
                            {this.state.currentOrder ?
                                <div className="card">
                                    <div className="card-content">
                                        <div className="row">
                                            <p className="col s4"><b>Service Date:</b> {moment(this.state.currentOrder?.orderDate).format('MMM DD, YYYY')}</p>
                                            <p className="col s4"><b>Phone: </b> {this.state.allPharmacies && this.state.allPharmacies[this.state.allPharmaciesIndex].phone}</p>
                                            <p className="col s4"><b>Email: </b> {this.state.allPharmacies && this.state.allPharmacies[this.state.allPharmaciesIndex].email}</p>
                                        </div>
                                        {this.state.loadingOrderDetail ?
                                            <div className="progress white">
                                                <div className="indeterminate blue" />
                                            </div>
                                            :
                                            <div className="row">
                                                <table className="highlight">
                                                    <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Date</th>
                                                            <th>Info</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.orderTransactionDetails ? Object.keys(this.state.orderTransactionDetails.details).map(key => {
                                                            const t = this.state.orderTransactionDetails.details[key];
                                                            if (!Array.isArray(t))
                                                                return (
                                                                    <tr key={`transaction-detail=${key}`}>
                                                                        <td style={{ padding: '2px' }}>{key}</td>
                                                                        <td style={{ padding: '2px' }}></td>
                                                                        <td style={{ padding: '2px' }}></td>
                                                                        <td style={{ padding: '2px', textAlign: 'right' }}>{t.amount?.toFixed(2)}</td>
                                                                    </tr>
                                                                )
                                                            else
                                                                return t.map((c, index) => (
                                                                    <tr key={JSON.stringify({ c, index })}>
                                                                        <td style={{ padding: '2px' }}>
                                                                            <a href="/" onClick={e => this.reprint(e, c.id)}>Reprint</a>
                                                                        </td>
                                                                        <td style={{ padding: '2px' }}>{moment(c.date).format('MM/DD/YYYY')}</td>
                                                                        <td style={{ padding: '2px' }}>{c.info}</td>
                                                                        <td style={{ padding: '2px', textAlign: 'right' }}>{c.amount.toFixed(2)}</td>
                                                                    </tr>
                                                                ))
                                                        }) : null}
                                                        <tr>
                                                            <td style={{ padding: '0px' }}></td>
                                                            <td style={{ padding: '0px' }}></td>
                                                            <td style={{ padding: '0px' }}></td>
                                                            <td style={{ padding: '0px', textAlign: 'right' }}><b><span className="left">Total</span> {this.state.orderTransactionDetails?.total.toFixed(2)}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="row">
                                                    <div className="input-field col s3 offset-s9">
                                                        <input
                                                            id="orderHeaderDollarAmount"
                                                            type="number"
                                                            onChange={this.changeOrderHeaderDollarAmount}
                                                            value={this.state.orderHeaderDollarAmount} />
                                                        <label htmlFor="orderHeaderDollarAmount">Amt to be Paid</label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <p>No Orders To Display</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div id="print-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5 className="left">Checks to be Written</h5>
                        <h5 className="right bold">{this.formatter.format(this.state.checksWithValue?.reduce((sum, curr) => sum + curr.value, 0))}</h5>
                    </div>
                    <div className="row">
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th>Pharmacy</th>
                                    <th>Check Date</th>
                                    <th>Memo</th>
                                    <th>Amounts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.checksWithValue?.map(c => (
                                    <tr key={`check-with-value-pharmacy-${c.pharmacyId}`}>
                                        <td style={{ padding: '0px' }}>{c.pharmacyName}</td>
                                        <td style={{ padding: '0px' }}>{moment(c.date).format('MM/DD/YYYY')}</td>
                                        <td style={{ padding: '0px' }}>{c.memo}</td>
                                        <td>
                                            <table>
                                                <tbody>
                                                    {Object.keys(c.orders).map(key => (
                                                        <tr key={`print-modal-order-${key}`}>
                                                            <td style={{ padding: '0px' }}>{key}</td>
                                                            <td style={{ padding: '0px', textAlign: 'right' }}>{c.orders[key].value}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td style={{ padding: '0px' }}><b>Total</b></td>
                                                        <td style={{ padding: '0px', textAlign: 'right' }}><b>{Object.values(c.orders).reduce((acc, curr) => acc + parseFloat(curr.value), 0)}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col s4 offset-s8">
                            <a href="/" className="btn-small green white-text waves-effect waves-light col s12" onClick={this.saveAndPrint}>Save And Print</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(OutboundChecks);