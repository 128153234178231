import React from 'react';
import { Switch, Route } from 'react-router-dom';
import WarehouseManagerTimeClock from './new_warehouseManagerTimeclock';
import OrderSearch from './orderSearch';
import OrderDetailOverview from "../orderDetail/orderDetailOverview/orderDetailOverview";
import ReportGenerator from './reportGenerator';

const AdminRouter = props => (
    <Switch>
        <Route exact path="/warehouse/manager/timeclock" component={WarehouseManagerTimeClock} />
        <Route exact path="/warehouse/manager/order/search" component={OrderSearch} />
        <Route exact path="/warehouse/manager/regular/:id" component={OrderDetailOverview} />
        <Route exact path="/warehouse/manager/report/generator" component={ReportGenerator} />
    </Switch>
)

export default AdminRouter;