import Axios from 'axios';
import React from 'react';
import M from '@materializecss/materialize';
import { connect } from 'react-redux';
import { logOut } from '../../func';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        username: '',
        password: '',
        capsLockIsOn: false,
    })

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    componentDidMount = () => {
        M.updateTextFields();
        document.getElementById('password').addEventListener('keyup', this.capsLockListener);
    }

    componentWillUnmount = () => {
        document.getElementById('password').removeEventListener('keyup', this.capsLockListener);
    }

    capsLockListener = e => {
        const capsLockIsOn = e?.getModifierState('CapsLock')
        this.setState({ capsLockIsOn }, () => {
            M.updateTextFields();
        })
    }

    submit = (e) => {
        e.preventDefault();

        this.setState({ errMsg: '', loading: true }, () => {
            if (!this.state.username) {
                return this.setState({ errMsg: 'Username must not be blank', loading: false })
            }

            if (!this.state.password) {
                return this.setState({ errMsg: 'Password must not be blank', loading: false })
            }

            Axios.post('/api/v1/auth/update/login/manual', {
                username: this.state.username,
                password: this.state.password
            })
                .then(async result => {
                    localStorage.setItem('auth_data', JSON.stringify({ ...result.data.auth_data, user_timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles' }));
                    localStorage.setItem('employee_data', JSON.stringify(result.data.employee_data))
                    this.props.renderParent();
                })
                .catch(logOut)
                .finally(() => this.setState({ loading: false }))
        })
    }

    render = () => (
        <div className="container">
            <div style={{ height: '20vh' }} />
            <div className="row">
                <h3 className="center">Outdate Rx Employee Tools</h3>
            </div>
            <div className="row">
                <div className="col s12 m6 offset-m3 l4 offset-l4">
                    <div className="card center">
                        <div className="card-content">
                            <h5>Log In</h5>
                            <form>
                                <div className="row">
                                    <div className="input-field">
                                        <input id="username" name="username" type="text" onChange={this.onChange} value={this.state.username} />
                                        <label htmlFor="username">Username</label>
                                    </div>
                                    <div className="input-field" style={{ marginTop: '30px' }}>
                                        <input id="password" name="password" type="password" onChange={this.onChange} value={this.state.password} />
                                        {this.state.capsLockIsOn ?
                                            <label htmlFor="password" className="red white-text" style={{ padding: '4px', borderRadius: '2px' }}>CAPSLOCK IS ON</label>
                                            :
                                            <label htmlFor="password">Password</label>
                                        }
                                    </div>
                                    {this.state.loading ?
                                        <div className="preloader-wrapper small active">
                                            <div className="spinner-layer spinner-blue-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div><div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div><div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="input-field" style={{ marginTop: '40px' }}>
                                            <button type="submit" className="btn-small blue white-text" onClick={this.submit}>Submit</button>
                                        </div>
                                    }
                                    <div className="row">
                                        <p className="red-text">{this.state.errMsg}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default connect()(Login);