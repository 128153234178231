import Axios from "axios";
import React from 'react';
import { connect } from "react-redux";
import { logOut, registerRoutes } from "../../../func";

class PrintTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        src: '',
        type: '',
        hover: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
    }

    networkCalls = {
        printZebraBoxLabels: {
            func: () => {
                const copyCount = window.prompt('How many labels would you like to print?');

                if (copyCount < 1)
                    return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Invalid Number of Copies', class: 'orange white-text' } })

                this.setState({ loading: true, src: '', type: '' }, () => {
                    Axios.get('/api/v1/order/header/print/zebraboxlabels', {
                        params: {
                            ...this.authData,
                            id: this.props.orderHeaderId,
                            copyCount
                        }, responseType: 'arraybuffer'
                    })
                        .then(result => {
                            const blob = new Blob([result.data], { type: 'application/pdf' });
                            const url = window.URL.createObjectURL(blob);
                            this.setState({ src: url, type: 'application/pdf' })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        printForm41: {
            func: () => {
                this.setState({ loading: true, src: '', type: '' }, () => {
                    Axios.post('/api/v1/order/header/print/formfortyone', {
                        ...this.authData,
                        orderHeaderId: this.props.orderHeaderId
                    }, { responseType: 'arraybuffer' })
                        .then(result => {
                            const blob = new Blob([result.data], { type: 'application/pdf' })
                            const url = window.URL.createObjectURL(blob) + '#view=Fit';
                            this.setState({ src: url, type: 'application/pdf' })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        printAlphabeticalReport: {
            func: type => {
                this.setState({ loading: true, src: '', type: '' }, () => {
                    Axios.post('/api/v1/order/header/print/alphabetical', {
                        ...this.authData,
                        orderHeaderId: this.props.orderHeaderId,
                        type
                    }, { responseType: 'arraybuffer' })
                        .then(result => {
                            const blob = new Blob([result.data], { type: 'application/pdf' })
                            const url = window.URL.createObjectURL(blob) + '#view=Fit';
                            this.setState({ src: url, type: 'application/pdf' })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    onMouseEnter = hover => this.setState({ hover })
    onMouseLeave = () => this.setState({ hover: '' })

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    {this.state.loading ?
                        <div className="progress blue">
                            <div className="indeterminate" />
                        </div>
                        :
                        <ul className="collection">
                            <ReportListItem name="Zebra Labels" hover={this.state.hover} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={() => this.networkCalls.printZebraBoxLabels()} />
                            <ReportListItem name="Form 41" hover={this.state.hover} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={() => this.networkCalls.printForm41()} />
                            <ReportListItem name="Regulars" hover={this.state.hover} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={() => this.networkCalls.printAlphabeticalReport('regular')} />
                            <ReportListItem name="III - V" hover={this.state.hover} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={() => this.networkCalls.printAlphabeticalReport('iii-v')} />
                            <ReportListItem name="II" hover={this.state.hover} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={() => this.networkCalls.printAlphabeticalReport('ii')} />
                        </ul>
                    }
                </div>
                <div className="col s8 m9 l10">
                    {this.state.src ?
                        <embed style={{ width: '100%', minHeight: 'CALC(100vh - 134px)' }} src={this.state.src} type={this.state.type} />
                        : null}
                </div>
            </div>
        </div>
    )
}


const ReportListItem = props => (
    <li
        className={`collection-item ${props.hover === props.name ? 'blue white-text' : ''}`}
        onMouseEnter={() => props.onMouseEnter(props.name)}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
        style={{ cursor: 'pointer', padding: '6px' }}
    >{props.name}</li>
)

export default connect()(PrintTab);