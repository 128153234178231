import Axios from "axios";
import React from 'react';
import { connect } from "react-redux";
import { logOut, registerRoutes } from "../../../func";
import { Input, TextArea, IconButton } from "../../../layout/form";

class CreateRequestTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        saving: false,
        values: this.initValues()
    })

    initValues = () => ({
        title: '',
        description: '',
        files: []
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path)
    }

    networkCalls = {
        saveRequest: {
            func: () => {
                this.setState({ saving: true }, () => {
                    const formData = new FormData();
                    const { title, description, files } = this.state.values;

                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];
                        formData.append('files[]', file);
                    }

                    formData.append('_id', this.authData._id);
                    formData.append('_session_id', this.authData._session_id);
                    formData.append('_token', this.authData._token);
                    formData.append('user_timezone', this.authData.user_timezone);
                    formData.append('title', title);
                    formData.append('description', description);

                    Axios.post('/api/v1/file/upload/featurerequest', formData, {
                        headers: { 'content-type': 'multipart/form-data' }
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.props.refresh();
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        }
    }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    addFile = e => {
        e.preventDefault();
        const files = this.state.values.files;
        const newFiles = e.dataTransfer.files;

        for (let i = 0; i < newFiles.length; i++) {
            if (files.filter(f => f.name === newFiles[i].name).length === 0)
                files.push(newFiles[i])
        }

        this.setState(p => ({ values: { ...p.values, files } }))
    }

    removeFile = index => {
        const files = this.state.values.files;
        files.splice(index, 1);
        this.setState(p => ({ values: { ...p.values, files } }))
    }

    clearValues = () => this.setState({ values: this.initValues() })

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <table>
                        <tbody>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Title</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>description</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8 m9 l10">
                    <div className="row">
                        <table>
                            <tbody>
                                <Input id="title" type="text" value={this.state.values.title} onChange={this.onChange} col="s12 m8 l6" />
                                <TextArea id="description" value={this.state.values.description} onChange={this.onChange} col="s12 m8 l6" />
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col s12 m8 l6" style={{ minHeight: '200px', border: '1px #ccc dashed' }} onDrop={this.addFile} onDragOver={e => e.preventDefault()}>
                            {!this.state.values.files?.length ?
                                <p>Drag and drop files here</p>
                                :
                                this.state.values.files?.map((f, index) => (
                                    <p key={f.name}>{f.name} <i className="material-icons white red-text" style={{ fontSize: '1em', cursor: 'pointer' }} onClick={() => this.removeFile(index)}>close</i></p>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '40px', padding: '8px' }}>
                        <IconButton icon="save" onClick={this.networkCalls.saveRequest} disabled={this.state.saving} tooltip="Report Issue" />
                        <IconButton icon="clear" onClick={this.clearValues} tooltip="Clear Values" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(CreateRequestTab);