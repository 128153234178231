import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { logOut, openSideNav, registerRoutes } from '../../func';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import moment from 'moment';

class UnmatchedCredits extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        unmatchedCredits: null,
        selectedCreditIndex: -1,
        selectedDebitMemoIndex: -1,
        values: this.initValues(),
    })

    initValues = () => ({
        manufacturerName: '',
        prefix: '',
        month: '',
        control: '',
        number: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getUnmatchedCredits();
        M.FormSelect.init(document.querySelectorAll('select'))
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelectorAll('.search-params').forEach(el => {
            if (el)
                el.addEventListener('keyup', this.enterListener)
        })
    }

    removeListeners = () => {
        document.querySelectorAll('.search-params').forEach(el => {
            if (el)
                el.removeEventListener('keyup', this.enterListener);
        })
    }

    enterListener = e => {
        if (e.keyCode === 13)
            this.searchDebitMemos();
    }

    networkCalls = {
        getUnmatchedCredits: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/creditmemo/read/all/unmatchedcredits', {
                        params: this.authData
                    })
                        .then(result => this.setState({ unmatchedCredits: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        searchDebitMemos: {
            func: () => {
                Axios.get('/api/v1/debitmemo/read/search', {
                    params: {
                        ...this.authData,
                        ...this.state.values
                    }
                })
                    .then(result => this.setState({ debitMemoList: result.data, selectedDebitMemoIndex: -1 }))
                    .catch(logOut)
            },
            type: 'r'
        },
        match: {
            func: () => {
                const debitMemoId = this.state.debitMemoList[this.state.selectedDebitMemoIndex].id;
                const creditMemoId = this.state.unmatchedCredits[this.state.selectedCreditIndex].id;

                Axios.post('/api/v1/creditmemo/update/assign/debitmemo', {
                    ...this.authData,
                    creditMemoId,
                    debitMemoId
                })
                    .then(result => {
                        this.setState({
                            values: this.initValues(),
                            debitMemoList: null,
                            selectedCreditIndex: -1,
                            selectDebitMemo: -1
                        }, () => {
                            this.props.dispatch(result.data);
                            this.getUnmatchedCredits();
                        })
                    })
                    .catch(logOut)
            },
            type: 'u'
        }
    }

    getUnmatchedCredits = () => this.networkCalls.getUnmatchedCredits();
    searchDebitMemos = e => { e?.preventDefault(); this.networkCalls.searchDebitMemos(); }
    match = e => { e?.preventDefault(); this.networkCalls.match(); }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    selectCredit = index => this.setState({ selectedCreditIndex: index })
    selectDebitMemo = (e, index) => {
        e?.preventDefault();

        this.setState({ selectedDebitMemoIndex: index })
    }

    getPrefixes = () => ([
        'R',
        'H',
        '1R',
        '1H'
    ])

    getControl = () => ([
        'R',
        'C2',
        'C345'
    ])

    assembleDebitMemoNumber = dm => `ORX${dm.prefix || ''}-${dm.month}-${dm.control === 'R' ? '' : 'C'}${dm.number.toString().padStart(4, '0')}`;

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Unmatched Credits</span>
                </div>
            </div>
            <div className="row">
                <div className="col s6">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <h6 className="center">Unmatched Credits</h6>
                            </div>
                            <div className="row">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Mfr</th>
                                            <th>Debit Memo #</th>
                                            <th>Date Created</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.unmatchedCredits && this.state.unmatchedCredits.map((uc, index) => (
                                            <tr key={uc.id} style={{ cursor: 'pointer' }} onClick={() => this.selectCredit(index)} className={this.state.selectedCreditIndex === index ? 'blue white-text' : ''}>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{uc.reportedManufacturerName}</td>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{uc.reportedDebitMemoNumber}</td>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{moment(uc.dateCreated).format('MM/DD/YYYY')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s6">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <h6 className="center">Possible Matches</h6>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="manufacturerName" className="search-params" type="text" onChange={this.onChange} value={this.state.values.manufacturerName} />
                                    <label htmlFor="manufacturerName">Mfr Name</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s2">
                                    <select id="prefix" className="search-params" onChange={this.onChange} value={this.state.values.prefix}>
                                        <option value={''}></option>
                                        {this.getPrefixes().map(p => <option key={p} value={p}>{p}</option>)}
                                    </select>
                                    <label htmlFor="prefix">Prefix</label>
                                </div>
                                <div className="input-field col s2">
                                    <input id="month" className="search-params" type="text" onChange={this.onChange} value={this.state.values.month} />
                                    <label htmlFor="month">Month</label>
                                </div>
                                <div className="input-field col s2">
                                    <select id="control" className="search-params" onChange={this.onChange} value={this.state.values.control}>
                                        <option value={''}></option>
                                        {this.getControl().map(c => <option key={c} value={c}>{c}</option>)}
                                    </select>
                                    <label htmlFor="control">Control</label>
                                </div>
                                <div className="input-field col s2">
                                    <input id="number" className="search-params" type="text" onChange={this.onChange} value={this.state.values.number} />
                                    <label htmlFor="number">Number</label>
                                </div>
                                <div className="input-field col s4">
                                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.searchDebitMemos}>Search Debit Memos</a>
                                </div>
                            </div>
                            <div className="row">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Mfr Name</th>
                                            <th>Debit Memo #</th>
                                            <th>Date Created</th>
                                            <th>Pharmacy</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.debitMemoList && this.state.debitMemoList.map((dm, index) => (
                                            <tr key={dm.id} style={{ cursor: 'pointer' }} onClick={e => this.selectDebitMemo(e, index)} className={this.state.selectedDebitMemoIndex === index ? 'teal white-text' : ''}>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{dm.id}</td>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{dm.manufacturerName}</td>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{this.assembleDebitMemoNumber(dm)}</td>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{moment(dm.dateCreated).format('MM/DD/YYYY')}</td>
                                                <td style={{ padding: '4px', borderRadius: '0px' }}>{dm.pharmacyName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.selectedDebitMemoIndex >= 0 && this.state.selectedCreditIndex >= 0 && (
                <a href="/" className="btn-small orange white-text waves-effect waves-light" style={{ position: 'fixed', bottom: '20px', right: '20px', minWidth: '20%', zIndex: 3000 }} onClick={this.match}>Assign Debit Memo</a>
            )}
        </div>
    )
}

export default connect()(UnmatchedCredits);