import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logOut } from '../func';

class Permission extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        permissions: null,
    })

    componentDidMount = () => {
        this.getPermissions();
    }

    UNSAFE_componentWillReceiveProps = props => {
        if (this.props.currentPath !== props.currentPath)
            this.checkPermissions(props.currentPath);
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextState !== this.state)
            return true;
        else
            return false;
    }

    getPermissions = () => {
        Axios.get('/api/v1/user/read/all/permission', { params: this.authData })
            .then(result => {
                this.props.dispatch({ type: 'SET', payload: { permissions: result.data } })
                this.setState({ permissions: result.data })
            })
            .catch(logOut)
    }

    checkPermissions = currentPath => {
        if (currentPath === '/' || currentPath === '/user/timeclock')
            return;
        else {
            const allPermissions = this.state.permissions.pages.find(p => p.route === '/**')

            if (allPermissions) {
                return;
            }
            else {
                const activePermission = this.state.permissions.pages.find(p => p.route === currentPath)

                if (activePermission)
                    return;
                else
                    this.props.history.goBack();
            }
        }
    }

    render = () => {
        return this.state.permissions ? this.props.children : <div>...Loading</div>
    }
}
const mapStateToProps = ({ currentPath }) => ({ currentPath })

export default withRouter(connect(mapStateToProps)(Permission));