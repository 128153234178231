import React from 'react';
import M from '@materializecss/materialize';
import moment from "moment";
import { IconButton } from '../../../layout/form';
import { logOut, registerRoutes } from '../../../func';
import Axios from "axios";

class OngoingTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        sendingMessage: false,
        issueIndex: -1,
        message: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
    }

    componentWillUnmount = () => {
        this.removeToolTips();
    }

    UNSAFE_componentWillReceiveProps = newProps => {
        if (newProps.issues?.length && this.props.issues?.length && newProps.issues[0]?.id !== this.props.issues[0]?.id)
            this.setState(this.initState())
    }

    networkCalls = {
        sendMessage: {
            func: () => {
                this.setState({ sendingMessage: true }, () => {
                    Axios.post('/api/v1/user/create/one/errortrackingcorrespondence', {
                        ...this.authData,
                        errorTrackingId: this.props.issues[this.state.issueIndex].id,
                        message: this.state.message
                    })
                        .then(result => {
                            this.props.dispatch(result.data)
                            this.setState({ message: '' })
                            this.props.refresh();
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ sendingMessage: false }))
                })
            },
            type: 'c'
        },
        uploadFile: {
            func: e => {
                e?.preventDefault();

                if (this.props.closed)
                    return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'You cannot upload files to a closed issue', class: 'orange white-text' } })

                const files = e.dataTransfer.files;
                const formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    formData.append('files[]', file)
                }

                formData.append('_id', this.authData._id);
                formData.append('_session_id', this.authData._session_id);
                formData.append('_token', this.authData._token);
                formData.append('user_timezone', this.authData.user_timezone);
                formData.append('errorTrackingCorrespondenceId', parseInt(e.target.id.split('-')[1]));

                Axios.post('/api/v1/file/upload/errortrackingcorrespondenceattachment', formData, {
                    headers: { 'content-type': 'multipart/form-data' }
                })
                    .then(result => {
                        this.props.dispatch(result.data);
                        this.props.refresh();
                    })
                    .catch(logOut);
            },
            type: 'u'
        },
        downloadFile: {
            func: attachment => {
                Axios.post('/api/v1/file/download/errortrackingcorrespondenceattachment', {
                    ...this.authData,
                    filename: attachment.filename,
                    filepath: attachment.filepath
                }, { responseType: 'arraybuffer' })
                    .then(result => {
                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', attachment.filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut)
            },
            type: 'r'
        }
    }

    initTooltips = () => {
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 });
    }

    removeToolTips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt) { tt.destroy(); }
        })
    }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    viewDetails = issueIndex => {
        this.setState({ issueIndex }, () => {
            setTimeout(this.initTooltips, 300);
        })
    }

    viewIssueList = () => {
        this.removeToolTips();
        this.setState({ issueIndex: -1 })
    }

    getStatus = arr => {
        const status = arr[arr.length - 1]?.status?.toLowerCase();
        let className = '';

        switch (status) {
            case 'in progress':
                className = 'green-text'
                break;
            case 'paused':
                className = 'purple-text'
                break;
            case 'resolved':
                className = 'green-text';
                break;
            case 'rejected':
                className = 'red-text';
                break;
            default:
                break;
        }

        return <td style={{ padding: '2px' }} className={`${className} bold`}>{status.toUpperCase()}</td>
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            {this.state.issueIndex < 0 ?
                <table className="highlight">
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Description</th>
                            <th style={{ padding: '4px' }}>Date Reported</th>
                            <th style={{ padding: '4px' }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.issues?.map((i, index) => (
                            <tr key={i.id} style={{ cursor: 'pointer' }} onClick={() => this.viewDetails(index)}>
                                <td style={{ padding: '2px' }}>{i.title.toUpperCase()}</td>
                                <td style={{ padding: '2px' }}>{moment.utc(i.statuses[0].timeStamp).local().format('M/D/YY h:mm A')}</td>
                                {this.getStatus(i.statuses)}
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                <div>
                    <div className="row">
                        <div className="col s3 m1">
                            <IconButton icon="arrow_back" onClick={this.viewIssueList} tooltip="Back To List" />
                        </div>
                        <div className="col s9 m11">
                            <h5 style={{ margin: '4px' }}>{this.props.issues[this.state.issueIndex].title?.toUpperCase()}</h5>
                        </div>
                    </div>
                    <div className="col s8" style={{ padding: '12px', height: `CALC(100vh - 220px)`, overflowY: 'auto' }}>
                        {this.props.issues[this.state.issueIndex].thread?.map(t => {
                            if (t.status)
                                return (
                                    <div key={`status-${t.id}`} style={{ marginBottom: '20px' }}>
                                        <div className="divider" />
                                        <h6 className="green-text text-darken-3" style={{ fontSize: '.85em' }}>{t.status?.toUpperCase()} - {moment.utc(t.timeStamp).local().format('MM/DD/YY h:mm a')}</h6>
                                        <div className="divider" />
                                    </div>
                                )
                            else if (t.createdById === this.employeeData.id)
                                return (
                                    <div key={`message-${t.id}`} className="row">
                                        <span id={`innerspan-${t.id}`} className="grey-text col s12" style={{ fontSize: '.7em', paddingRight: '8px', textAlign: 'right' }}>{t.createdBy} - {moment(t.timeStamp).format('ddd MMMM Do YYYY h:mm a')}</span>
                                        <div id={`dragdiv-${t.id}`} className="col s9 offset-s3 blue white-text drop-ok" style={{ borderRadius: '8px 8px 0px 8px' }} onDrop={this.networkCalls.uploadFile} onDragOver={e => { e.preventDefault(); }}>
                                            <p id={`dragp-${t.id}`} className="drop-ok" style={{ whiteSpace: 'pre-wrap' }}>{t.message}</p>
                                            {t.attachments ?
                                                <ul id={`attachments-${t.id}`} className="right">
                                                    {t.attachments.map(a => (
                                                        <li key={`anchor-${a.id}`} id={`li-${t.id}-${a.id}`}><a id={`anchor-${t.id}-${a.id}`} href="/" onClick={e => { e.preventDefault(); this.networkCalls.downloadFile(a) }} className="white-text" style={{ fontSize: '.9em', textAlign: 'right' }}>{a.filename}</a></li>
                                                    ))}
                                                </ul>
                                                : null}
                                        </div>
                                    </div>
                                )
                            else
                                return (
                                    <div key={`message-${t.id}`} className="row">
                                        <span className="grey-text col s12" style={{ fontSize: '.7em', paddingLeft: '8px' }}>{t.createdBy} - {moment(t.timeStamp).format('ddd MMMM Do YYYY h:mm a')}</span>
                                        <div className="col s9 grey lighten-3" style={{ position: 'relative', borderRadius: '8px 8px 8px 0px' }} onDragOver={e => { e.preventDefault(); e.dataTransfer.dropEffect = 'none' }}>
                                            <p style={{ whiteSpace: 'pre-wrap' }}>{t.message}</p>
                                            {t.attachments ?
                                                <ul>
                                                    {t.attachments.map(a => (
                                                        <li><a href="/" onClick={e => { e.preventDefault(); this.networkCalls.downloadFile(a) }} className="blue-text" style={{ fontSize: '.9em', textAlign: 'right' }}>{a.filename}</a></li>
                                                    ))}
                                                </ul>
                                                : null}
                                        </div>
                                    </div>
                                )
                        })}
                    </div>
                    {!this.props.closed &&
                        <div className="col s4">
                            <div className="input-field col s12">
                                <textarea id="message" className="materialize-textarea" value={this.state.message} onChange={this.onChange} />
                                <label htmlFor="message">New Message</label>
                                {this.state.sendingMessage ?
                                    <a href="/" className="grey-text right" onClick={e => e.preventDefault()}>SEND</a>
                                    :
                                    <a href="/" onClick={e => { e?.preventDefault(); this.networkCalls.sendMessage(); }} className="right">SEND</a>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default OngoingTab