import React from 'react';
import { Input } from '../../../layout/form';
import M from '@materializecss/materialize';

class AddressTab extends React.Component {

    componentDidMount = () => {
        if (this.props.stateListAsObject)
            this.initAutocomplete(this.props.stateListAsObject);
    }

    componentWillUnmount = () => {
        this.disposeAutocomplete();
    }

    UNSAFE_componentWillReceiveProps = props => {
        if (props.stateListAsObject)
            this.initAutocomplete(props.stateListAsObject);
    }

    initAutocomplete = data => {
        this.disposeAutocomplete();
        M.Autocomplete.init(document.querySelector('#stateName'), {
            data,
            onAutocomplete: stateName => {
                this.setState({ stateName });
                this.props.onStateChange(stateName);
            }
        })
    }

    disposeAutocomplete = () => {
        const el = document.querySelector('#stateName');
        if (el) {
            const ac = M.Autocomplete.getInstance(el);
            if (ac) {
                ac.destroy();
            }
        }
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <table>
                        <tbody>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Street Number</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Apt/Suite</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>City</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>State</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Zip</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8 m9 l10">
                    <form>
                        <table>
                            <tbody>
                                <Input id="addressLineOne" type="text" value={this.props.values.addressLineOne} onChange={this.props.onChange} col="s12 m6 l5" />
                                <Input id="addressLineTwo" type="text" value={this.props.values.addressLineTwo} onChange={this.props.onChange} col="s12 m6 l5" />
                                <Input id="city" type="text" value={this.props.values.city} onChange={this.props.onChange} col="s8 m4 l3" />
                                <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
                                    <td style={{ padding: '0px' }}>
                                        <div className="input-field" style={{ margin: '0px', padding: '0px' }}>
                                            <input
                                                id="stateName"
                                                type="text"
                                                className="col s3 m2 autocomplete"
                                                style={{ margin: '0px', padding: '0px', height: '30.5px' }}
                                                value={this.props.values.stateName}
                                                onChange={this.props.onChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <Input id="zip" type="text" value={this.props.values.zip} onChange={this.props.onChange} col="s4 m3 l2" />
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
            <div className="row" style={{ marginTop: '40px' }}>
                <div className="col s12">
                    <a href="/" onClick={e => { e.preventDefault(); this.props.setTab({ target: { id: 'tab-general' } }) }}>
                        <i className="material-icons tooltipped"
                            data-position="top"
                            data-tooltip="Previous"
                            style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                        >arrow_back</i>
                    </a>
                    <a href="/" onClick={e => { e.preventDefault(); this.props.setTab({ target: { id: 'tab-contact' } }) }}>
                        <i className="material-icons tooltipped"
                            data-position="top"
                            data-tooltip="Next"
                            style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                        >arrow_forward</i>
                    </a>
                    <a href="/" onClick={e => { e.preventDefault(); this.props.revert(); }}>
                        <i className="material-icons tooltipped"
                            data-position="top"
                            data-tooltip="Discard Address Changes"
                            style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                        >refresh</i>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AddressTab;