import React from 'react';
import SideNav from './layout/sidenav';
import Reminder from './layout/reminder';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PageNotFound from './pages/PageNotFound';
import LogBackInModal from './layout/logBackInModal';
import PharmacyRouter from './pages/pharmacy/pharmacyRouter';
import Login from './pages/login/login';
import RegularOrderRouter from './pages/regularOrders/regularOrderRouter';
import RedbookRouter from './pages/redbook/redbookRouter';
import WholesalerRouter from './pages/wholesaler/wholesalerRouter';
import ReturnHandlerRouter from './pages/returnHandler/returnHandlerRouter';
import ManufacturerRouter from './pages/manufacturer/manufacturerRouter';
import DebitMemoRouter from './pages/debitMemo/debitMemoRouter';
import ShippingRouter from './pages/shipping/shippingRouter';
import IndateRouter from './pages/indates/indateRouter';
import ControlsRouter from './pages/controls/controlsRouter';
import AccountingRouter from './pages/accounting/accountingRouter';
import UserRouter from './pages/user/userRouter';
import AdminRouter from './pages/warehouseManager/warehouseManagerRouter';
import SalesRouter from './pages/sales/salesRouter';
import RecallRouter from './pages/recalls/recallRouter';
import Axios from 'axios';
import M from '@materializecss/materialize';
import UtilityRouter from './pages/utilities/utilityRouter';
import InvoiceRouter from './pages/invoicing/invoiceRouter';
import WebsiteAdministrationRouter from './pages/websiteAdministration/websiteAdministrationRouter';
import ClockOutTimer from './utilities/clockOutTimer';
import Permission from './utilities/permission';
import Changelog from './pages/websiteAdministration/changelog';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { arbitrary: true, underTen: false };
  }

  componentDidMount = () => {
    setTimeout(this.checkTokenAge, 1000);
  };

  checkTokenAge = () => {
    const authData = localStorage.getItem('auth_data')
      ? JSON.parse(localStorage.getItem('auth_data'))
      : '';
    if (!authData) {
      return;
    }

    Axios.post('/api/v1/auth/read/tokenage', authData)
      .then((result) => {
        if (result.data <= 10 && result.data >= 1) {
          this.setState({ underTen: true }, () => {
            M.toast({
              html: ` <div style="display: flex; flex-direction: column">
                      <b>Your Token is about to expire</b>
                      <a target="_blank" href="/" class="btn-small white black-text">renew</a>
                    </div>`,
              displayLength: '5000',
              classes: 'purple white-text',
            });

            setTimeout(
              this.checkTokenAge,
              this.state.underTen ? 60000 : 300000
            );
          });
        } else {
          this.setState({ underTen: false }, () => {
            setTimeout(
              this.checkTokenAge,
              this.state.underTen ? 60000 : 300000
            );
          });
        }
      })
      .catch((err) => console.log('ERROR CAUGHT ON TOKEN AGE CHECK: ', err));
  };

  reRender = () => this.setState((p) => ({ arbitrary: !p.arbitrary }));

  render = () => {
    if (!window.localStorage.getItem('auth_data'))
      return <Login renderParent={this.reRender} />;
    else {
      return (
        <div style={{ position: 'relative' }}>
          {process.env.REACT_APP_SHOW_DEV_RIBBON === 'true' && (
            <p
              className="red white-text"
              style={{
                position: 'fixed',
                top: '17px',
                right: '-76px',
                transform: 'rotate(45deg)',
                width: '240px',
                textAlign: 'center',
                cursor: 'default',
                zIndex: 999,
              }}
            >
              DEVELOPMENT
            </p>
          )}
          <SideNav />
          <ClockOutTimer />
          <Permission>
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/user/timeclock" />}
              />
              <Route path="/pharmacy" component={PharmacyRouter} />
              <Route path="/regular" component={RegularOrderRouter} />
              <Route path="/redbook" component={RedbookRouter} />
              <Route path="/wholesaler" component={WholesalerRouter} />
              <Route path="/returnhandler" component={ReturnHandlerRouter} />
              <Route path="/manufacturer" component={ManufacturerRouter} />
              <Route path="/debitmemo" component={DebitMemoRouter} />
              <Route path="/shipping" component={ShippingRouter} />
              <Route path="/indate" component={IndateRouter} />
              <Route path="/controls" component={ControlsRouter} />
              <Route path="/accounting" component={AccountingRouter} />
              <Route path="/user" component={UserRouter} />
              <Route path="/warehouse/manager" component={AdminRouter} />
              <Route path="/sales" component={SalesRouter} />
              <Route path="/recall" component={RecallRouter} />
              <Route path="/utility" component={UtilityRouter} />
              <Route path="/invoice" component={InvoiceRouter} />
              <Route
                path="/website/administration"
                component={WebsiteAdministrationRouter}
              />
              <Route exact path="/changelog" component={Changelog} />
              <Route path="**" component={PageNotFound} />
            </Switch>
          </Permission>
          <LogBackInModal />
          <Reminder />
        </div>
      );
    }
  };
}

const mapStateToProps = ({ clockOutTime }) => ({ clockOutTime });

export default connect(mapStateToProps)(App);
