import { setWebsocketURL } from "../func";
import store from "../redux/store";

class Socket {
    constructor() {
        this.url = null;
        this.socket = null;
        this.messageActions = this.initMessageActions();
        this.onReady = null;
        this.dataArray = null;
        this.timeout = null;
        this.timer = 0;
        this.timerSubscription = null;
    }

    initMessageActions = () => ({
        ping: (type, body) => this.socket.send(JSON.stringify({ type: 'PONG', body: null })),
        data: (type, body) => {
            console.log('MESSAGE FROM WEBSOCKET @', this.url);
            console.log('TYPE: ', type);
            console.log('BODY: ', body);
        },
        error: (type, body) => store.dispatch(body),
        default: (type, body) => {
            console.log('UNCAUGHT MESSAGE FROM SOCKET @', this.url);
            console.log('TYPE: ', type);
            console.log('BODY: ', body);
        }
    })

    checkPulse = () => {
        if (this.timeout)
            clearTimeout(this.timeout);


        this.timeout = setTimeout(() => {
            this.close();
            this.open();
        }, 30000);
    }

    open = (url, dataArray) => {
        if (url)
            this.url = url;
        if (dataArray)
            this.dataArray = dataArray;

        this.socket = new WebSocket(setWebsocketURL(this.url, this.dataArray));
        this.setOnMessage();

        if (this.onReady && typeof this.onReady === 'function') {
            const onReadyLoop = (shouldSend) => {
                setTimeout(() => {
                    if (shouldSend)
                        this.onReady(this.socket);
                    else
                        onReadyLoop(this.socket.readyState === 1 ? true : false)
                }, 250)
            }
            onReadyLoop(false);
        }

        this.checkPulse();
        this.startTimer();
    }

    setOnError = func => {
        if (!func || typeof func !== 'function')
            this.socket.onerror = err => store.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Unable to open socket, page will not update', class: 'red white-text' } })
        else
            this.socket.onerror = func;
    }

    setOnMessage = () => {
        if (!this.socket) { return }

        this.socket.onmessage = message => {
            try {
                this.checkPulse();
                this.resetTimer();

                const { type, body } = JSON.parse(message.data);
                this.messageActions[type.toLowerCase()](type, body);

            } catch (err) {
                store.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'An unknown error occurred in the websocket. Check console for details', class: 'red white-text' } })
                console.log(err);
            }
        }
    }

    setMessageAction = (type, func) => {
        this.messageActions[type.toLowerCase()] = func;
        this.setOnMessage();
    }

    setOnReady = func => this.onReady = func;

    close = () => {
        this.socket.close();

        if (this.timeout)
            clearTimeout(this.timeout);

        if (this.clearTimeout)
            clearInterval(this.clearTimeout);
    }

    send = data => {
        if (typeof data === 'object' || Array.isArray(data))
            this.socket.send(JSON.stringify(data));
        else
            this.socket.send(data);
    }

    startTimer = () => {
        if (this.timerInterval)
            clearInterval(this.timerInterval)

        this.timerInterval = setInterval(() => {
            this.timer++;

            if (this.timerSubscription && typeof this.timerSubscription === 'function')
                this.timerSubscription(this.timer);
        }, 1000);
    }

    resetTimer = () => {
        clearInterval(this.timerInterval);
        this.timer = 0;

        if (this.timerSubscription && typeof this.timerSubscription === 'function')
            this.timerSubscription(this.timer);

        this.startTimer();

    }

    subscribeToTimer = callback => this.timerSubscription = callback;
}

export default Socket;