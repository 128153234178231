import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SearchRedbook from './searchRedbook';
import RedbookDetail from './redbookDetail';
import UpdateRedbook from './updateRedbook';
import CreateRedbook from './createRedbook';

const RedbookRouter = props => (
    <Switch>
        <Route exact path="/redbook" component={SearchRedbook} />
        <Route exact path="/redbook/create" component={CreateRedbook} />
        <Route exact path="/redbook/:id" component={RedbookDetail} />
        <Route exact path="/redbook/:id/update" component={UpdateRedbook} />
    </Switch>
)

export default RedbookRouter;