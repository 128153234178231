import React from 'react';
import { Switch, Route } from 'react-router-dom';
import IndateSort from './indateSort';
import IndateProcess from './indateProcess';

const IndateRouter = props => (
    <Switch>
        <Route exact path="/indate" component={IndateProcess} />
        <Route exact path="/indate/sort" component={IndateSort} />
    </Switch>
)

export default IndateRouter;