import React from 'react';

const GeneralTab = props => {
    return (
        <div style={{ padding: '1vw' }}>
            {props.orderHeader ?
                <div className="row">
                    <div className="col s4 m3 l2">
                        <table>
                            <tbody>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Order Number</td></tr>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Order ID</td></tr>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Pharmacy</td></tr>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Pharmacy ID</td></tr>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Fee Percentage</td></tr>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Return Service Declined</td></tr>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>Destruction Only</td></tr>
                                <tr><td className="bold" style={{ textAlign: 'right', padding: '4px' }}>House Return</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col s8 m9 l10">
                        <table>
                            <tbody>
                                <tr><td style={{ padding: '4px' }}>{props.orderHeader?.orderNumber}</td></tr>
                                <tr><td style={{ padding: '4px' }}>{props.orderHeader?.id}</td></tr>
                                <tr><td style={{ padding: '4px' }}>{props.pharmacy?.name}</td></tr>
                                <tr><td style={{ padding: '4px' }}>{props.pharmacy?.id}</td></tr>
                                <tr><td style={{ padding: '4px' }}>{props.orderHeader?.outdateFeeOverride}</td></tr>
                                <tr><td style={{ padding: '4px' }}>{props.orderHeader?.returnServiceDeclined ? 'YES' : 'NO'}</td></tr>
                                <tr><td style={{ padding: '4px' }}>{props.orderHeader?.isDestructionOnly ? 'YES' : 'NO'}</td></tr>
                                <tr><td style={{ padding: '4px' }}>{props.orderHeader?.isHouseReturn ? 'YES' : 'NO'}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                : <p>No Order Loaded</p>}
        </div>
    )
}

export default GeneralTab;