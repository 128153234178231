import { connect } from "react-redux"
import { logOut, openSideNav, registerRoutes } from "../../func";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect, Fragment } from 'react';
import Axios from "axios";
import moment from "moment";
const pageId = crypto.randomUUID();

const IndateProcess = props => {
    const { dispatch } = props;

    const [indateBarcode, setIndateBarcode] = useState('');
    const [returnAuthorizationNumber, setReturnAuthorizationNumber] = useState('');
    const [indate, setIndate] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [contextMenu, setContextMenu] = useState({ show: false, clientX: 0, clientY: 0 });
    const [loading, setLoading] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [searching, setSearching] = useState(false);

    const [networkCalls] = useState(registerRoutes({
        printIndateBarcodes: {
            func: e => {
                e?.preventDefault();

                Axios.get('/api/v1/indate/print/randombarcodes', {
                    params: JSON.parse(localStorage.getItem('auth_data')),
                    signal: ref.current.mainAbortController.signal
                })
                    .then(result => {
                        const w = window.open('', '_blank');
                        w.document.write(result.data);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.onclick = () => w.pageLoad();
                        w.document.body.appendChild(link);
                        link.click();
                        w.document.body.removeChild(link);
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getIndate: {
            func: barcode => {
                setLoading(true);
                ref.current.getIndateAbortController?.abort();
                ref.current.getIndateAbortController = new AbortController();

                Axios.get('/api/v2/order/detail/read/one/indate/by/barcode', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        indateBarcode: barcode ? barcode : ref.current.indateBarcode
                    },
                    signal: ref.current.getIndateAbortController.signal
                })
                    .then(result => {
                        setIndateBarcode('');
                        setIndate(result.data);
                    })
                    .catch(err => {
                        setIndate(null);
                        logOut(err);
                    })
                    .finally(() => setLoading(false))
            },
            type: 'r'
        },
        addRA: {
            func: () => {

                const { indateBarcode, debitMemo } = ref.current.indate;

                Axios.post('/api/v1/debitmemo/update/ra/add', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    returnAuthorizationNumber: ref.current.returnAuthorizationNumber,
                    debitMemoId: debitMemo.id
                })
                    .then(result => {
                        dispatch(result.data.msg);
                        networkCalls.getIndate(indateBarcode);
                        setReturnAuthorizationNumber('');
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        deleteRA: {
            func: ra => {
                if (!window.confirm(`Are you sure you want to delete RA: ${ra.returnAuthorizationNumber}?`))
                    return;

                const { indateBarcode } = ref.current.indate;

                Axios.post('/api/v1/debitmemo/update/ra/delete', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    id: ra.id
                })
                    .then(result => {
                        dispatch(result.data);
                        networkCalls.getIndate(indateBarcode)
                    })
                    .catch(logOut)
            },
            type: 'd'
        },
        printDebitMemo: {
            func: e => {
                e?.preventDefault();
                setPrinting(true);
                ref.current.printAbortController?.abort();
                ref.current.printAbortController = new AbortController();
                const { indate } = ref.current;

                Axios.get('/api/v1/debitmemo/print/one/pdf', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        id: ref.current.indate.debitMemo.id,
                        isDestruction: false
                    },
                    responseType: 'blob',
                    signal: ref.current.printAbortController.signal
                })
                    .then(result => {
                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', `${assembleDebitMemoNumber(indate)}-${moment().format('YYYYMMDD')}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        if (result.headers.merck) {
                            dispatch(JSON.parse(result.headers.merck));

                            const { firstName, lastName } = JSON.parse(localStorage.getItem('employee_data'))

                            Axios.get('/api/v1/debitmemo/print/merck', {
                                params: {
                                    ...JSON.parse(localStorage.getItem('auth_data')),
                                    employeeName: firstName + ' ' + lastName,
                                    id: parseInt(ref.current.indate.debitMemo.id)
                                },
                                responseType: 'blob',
                                signal: ref.current.printAbortController.signal
                            })
                                .then(result => {
                                    const blob = new Blob([result.data]);
                                    const url = window.URL.createObjectURL(blob);
                                    const link = document.createElement('a');
                                    link.hidden = true;
                                    link.href = url;
                                    link.setAttribute('download', `${assembleDebitMemoNumber(indate)}-MERCK.pdf`);
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                })
                                .catch(logOut)
                        }
                    })
                    .catch(logOut)
                    .finally(() => setPrinting(false))
            },
            type: 'r'
        },
        verifyDebitMemo: {
            func: () => {
                setUpdating(true);
                const { indate } = ref.current;

                Axios.post('/api/v2/debitmemo/update/one/verify', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoId: indate.debitMemo.id
                })
                    .then(result => {
                        dispatch(result.data)
                        networkCalls.getIndate(indate.indateBarcode);
                    })
                    .catch(logOut)
                    .finally(() => setUpdating(false))
            },
            type: 'u'
        },
        undoVerification: {
            func: e => {
                e?.preventDefault();
                const { indate } = ref.current;

                setContextMenu(p => ({ ...p, show: false }))
                setUpdating(true);

                Axios.post('/api/v2/debitmemo/update/one/undoverification', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoId: indate.debitMemo.id
                })
                    .then(result => {
                        dispatch(result.data);
                        networkCalls.getIndate(indate.indateBarcode);
                    })
                    .catch(logOut)
                    .finally(() => setUpdating(false))
            },
            type: 'u'
        },
        searchRedbook: {
            func: () => {
                ref.current.redbookSearchAbortController?.abort();
                ref.current.redbookSearchAbortController = new AbortController();

                setSearching(true);
                setEditValues(p => ({
                    ...p,
                    deaClass: '',
                    medName: 'Med Not Found',
                    manufacturer: '',
                    packSize: ''
                }))

                Axios.get('/api/v2/redbook/read/one/deep/by/ndc', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        ndc: ref.current.ndc
                    },
                    signal: ref.current.redbookSearchAbortController.signal
                })
                    .then(result => {
                        if (result.data) {
                            let { returnPolicyId, manufacturer } = result.data;
                            console.log('ONE: ', returnPolicyId, manufacturer);

                            returnPolicyId = returnPolicyId ? returnPolicyId : manufacturer.returnPolicyId ? manufacturer.returnPolicyId : manufacturer.returnPolicies && manufacturer.returnPolicies.length === 1 ? manufacturer.returnPolicies[0].id : 'No Default Return Policy';
                            const returnPolicy = typeof returnPolicyId === 'string' ? null : manufacturer.returnPolicies.find(row => parseInt(row.id) === parseInt(returnPolicyId))

                            console.log(returnPolicyId, returnPolicy);


                            setEditValues(p => ({
                                ...p,
                                deaClass: result.data.deaClass,
                                medName: result.data.medName,
                                manufacturer: manufacturer.name,
                                packSize: result.data.packSize,
                                returnPolicyId,
                                returnPolicy
                            }))
                        }
                    })
                    .catch(logOut)
                    .finally(setSearching(false))
            },
            type: 'r'
        },
        updateOrderDetail: {
            func: values => {
                setUpdating(true);
                const { nonReturnableReasonCodeId } = ref.current;


                Axios.post('/api/v2/order/detail/update/one', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    ...values
                })
                    .then(result => {
                        dispatch(result.data);
                        setEditValues({})

                        if (parseInt(nonReturnableReasonCodeId) !== 5) {
                            setIndate(null);
                            document.querySelector(`#barcode-${pageId}`)?.focus();
                        }
                    })
                    .catch(logOut)
                    .finally(() => setUpdating(false))
            },
            type: 'u'
        }
    }, props.match.path))

    const ref = useRef({
        mainAbortController: new AbortController(),
        getIndateAbortController: null,
        printAbortController: null,
        redbookSearchAbortController: null,
        indateBarcode: '',
        returnAuthorizationNumber: '',
        indate: null,
        ndc: '',
    })

    useEffect(() => {
        ref.current.mainAbortController = new AbortController();

        const {
            mainAbortController,
            getIndateAbortController,
            redbookSearchAbortController,
            printAbortController,
        } = ref.current;

        const barcodeEnterListener = e => { if (e.keyCode === 13) { networkCalls.getIndate() } }
        const raEnterListener = e => { if (e.keyCode === 13) { networkCalls.addRA() } }
        const clickAwayListener = e => { if (!document.querySelector(`#context-menu-${pageId}`).contains(e.target)) { setContextMenu(p => ({ ...p, show: false })) } }
        const escapeListener = e => {
            if (e.keyCode === 27) {
                setContextMenu(p => ({ ...p, show: false }));
                setEditValues({});
            }
        }

        const initListeners = () => {
            document.querySelector(`#barcode-${pageId}`).addEventListener('keyup', barcodeEnterListener)
            document.querySelector(`#returnAuthorizationNumber-${pageId}`)?.addEventListener('keyup', raEnterListener);
            document.addEventListener('click', clickAwayListener);
            document.addEventListener('keyup', escapeListener)
        }

        const removeListeners = () => {
            document.querySelector(`#barcode-${pageId}`)?.removeEventListener('keyup', barcodeEnterListener)
            document.querySelector(`#returnAuthorizationNumber-${pageId}`)?.removeEventListener('keyup', raEnterListener);
            document.removeEventListener('click', clickAwayListener);
            document.removeEventListener('keyup', escapeListener);
        }

        initListeners();

        return () => {
            removeListeners();
            mainAbortController.abort();
            getIndateAbortController?.abort();
            redbookSearchAbortController?.abort();
            printAbortController?.abort();
        }
    }, [networkCalls, indate])

    useEffect(() => ref.current.indateBarcode = indateBarcode, [indateBarcode])
    useEffect(() => ref.current.returnAuthorizationNumber = returnAuthorizationNumber, [returnAuthorizationNumber])
    useEffect(() => ref.current.indate = indate, [indate])
    useEffect(() => ref.current.nonReturnableReasonCodeId = editValues.nonReturnableReasonCodeId, [editValues.nonReturnableReasonCodeId]);

    const getDollarValue = () => indate ? `$${((indate.fullPackage + indate.partialPackage / indate.redbook.packSize) * indate.redbook.awp).toFixed(2)}` : '';
    const assembleDebitMemoNumber = indate => indate?.debitMemo?.dateNumberAssigned ? `ORX${indate.debitMemo.prefix || ''}-${indate.debitMemo.month}-${indate.debitMemo.control === 'R' ? 'R' : 'C'}${indate.debitMemo.number.toString().padStart(4, '0')}` : '';

    const onContextMenu = e => {
        e?.preventDefault();
        const { clientX, clientY } = e;

        setContextMenu({
            show: true,
            clientX: clientX + 145 > window.innerWidth ? clientX - 131 : clientX,
            clientY: clientY + 45 > window.innerHeight ? clientY - 41 : clientY
        })
    }

    const edit = e => {
        e?.preventDefault();
        setEditValues({
            ndc: indate.redbook.ndc,
            fullPackage: indate.fullPackage || 0,
            partialPackage: indate.partialDecimal ? `${indate.partialPackage || 0}.${indate.partialDecimal}` : indate.partialPackage || 0,
            lotNumber: indate.lotNumber || '',
            expiration: indate.expiration ? moment(indate.expiration).format('YYYY-MM-DD') : '',
            nonReturnableReasonCodeId: indate.nonReturnableReasonCodeId || ''
        })
        setContextMenu(p => ({ ...p, show: false }))
    }

    const onChange = ({ target }) => setEditValues(p => ({ ...p, [target.id.split('-')[0]]: target.value }))

    useEffect(() => {
        ref.current.ndc = editValues.ndc;
        if (editValues.ndc)
            networkCalls.searchRedbook()
    }, [editValues.ndc, networkCalls])

    const update = e => {
        e?.preventDefault();

        const { nonReturnableReasonCodeId, returnPolicy } = editValues;

        if (parseInt(nonReturnableReasonCodeId) !== 5 && !window.confirm('Are you sure you want to make this med non-returnable?'))
            return;

        let earliestReturnDate = null;

        if (returnPolicy) {
            const expiration = moment(editValues.expiration).add(1, 'day');
            const earliestReturnInMonths = expiration.clone().subtract(returnPolicy.earliestReturnInMonths, 'months');
            earliestReturnDate = earliestReturnInMonths.format('YYYY-MM-DD');
        }

        networkCalls.updateOrderDetail({
            orderDetailId: ref.current.indate.id,
            ndc: editValues.ndc,
            fullPackage: editValues.fullPackage || 0,
            partialPackage: editValues.partialPackage || 0,
            lotNumber: editValues.lotNumber || null,
            expiration: editValues.expiration || null,
            nonReturnableReasonCodeId,
            indateBarcode: parseInt(nonReturnableReasonCodeId) === 5 ? ref.current.indate.indateBarcode : null,
            returnPolicyId: editValues.returnPolicyId,
            earliestReturnDate: parseInt(nonReturnableReasonCodeId) === 5 ? earliestReturnDate : null,
        })
    }

    return (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Indate Processing</span>
                </div>
            </div>
            <div className="row">
                <div className="row">
                    <div className="col s4 l2 offset-s8 offset-l10">
                        <a href="/" className="btn-small white black-text waves-effect waves-dark col s12" onClick={networkCalls.printIndateBarcodes}>Print Indate Barcodes</a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m4 l2" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor={`barcode-${pageId}`}>Scan Barcode</label>
                    <input id={`barcode-${pageId}`} className="browser-default" type="text" onChange={({ target }) => setIndateBarcode(target.value)} value={indateBarcode} />
                </div>
                {loading ?
                    <div className="preloader-wrapper active" style={{ width: '20px', height: '20px', position: 'relative', top: '18px' }}>
                        <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div><div className="gap-patch">
                                <div className="circle"></div>
                            </div><div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div>
                                    <p><b style={{ fontSize: '1.2em' }}>{indate?.manufacturer.name} <span className="orange-text">{indate?.redbook.deaClass}</span><span className="right">{getDollarValue()}</span></b></p>
                                    <p className="right">{indate ? moment(indate.earliestReturnDate).format('MM/DD/YYYY') : ''}</p>
                                </div>
                                <p>{indate?.returnHandler?.name?.toUpperCase()}</p>
                            </div>
                            <div className="row">
                                <p>{indate ? `DM #: ${indate.debitMemo.number ? assembleDebitMemoNumber(indate) : 'Not Verified'}` : ''}</p>
                                <p>{indate ? 'Order #' : ''} {indate ? <Link to={`/regular/${indate.orderHeader.id}`}>{indate.orderHeader.orderNumber}</Link> : ''}</p>
                                <div className="right col s6 m2">
                                    {indate?.debitMemo?.number ?
                                        <a
                                            href="/"
                                            className="btn-small white black-text waves-effect waves-dark col s12"
                                            onClick={networkCalls.printDebitMemo}
                                            disabled={printing}
                                        >Print Debit Memo</a>
                                        : null}
                                </div>
                            </div>
                            <div className="row">
                                {indate?.debitMemo?.number ?
                                    <Fragment>
                                        <div>
                                            <span style={{ paddingRight: '8px' }}>RA #</span>
                                            <input id={`returnAuthorizationNumber-${pageId}`} className="browser-default" type="text" value={returnAuthorizationNumber} onChange={({ target }) => setReturnAuthorizationNumber(target.value)} />
                                        </div>
                                        <ul style={{ marginLeft: '40px', marginTop: '0px' }}>
                                            {indate?.returnAuthorizations?.map(ra => ra.id ? <li key={ra.id}>{ra.returnAuthorizationNumber} <i className="material-icons red-text" style={{ fontSize: '1.2em', position: 'relative', top: '3px', cursor: 'pointer' }} onClick={() => networkCalls.deleteRA(ra)}>close</i></li> : null)}
                                        </ul>
                                    </Fragment>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {indate ?
                <div className="row">
                    <div className="col s12">
                        <div className="card">
                            <div className="card-content">
                                <div className="row">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>NDC</th>
                                                <th>DEA</th>
                                                <th>Med Name</th>
                                                <th>Mfr</th>
                                                <th>Pack Size</th>
                                                <th>Full Pkg</th>
                                                <th>Partial Cnt</th>
                                                <th>Lot Number</th>
                                                <th>Exp Date</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typeof editValues.ndc === 'string' ?
                                                <Fragment>
                                                    <tr>
                                                        <td style={{ padding: '2px' }}><input className="browser-default" id={`ndc-${pageId}`} type="text" value={editValues.ndc} onChange={onChange} /></td>
                                                        {searching ?
                                                            <td style={{ padding: '2px' }} colSpan={4}><div className="progress blue"><div className="indeterminate" /></div></td>
                                                            :
                                                            <Fragment>
                                                                <td style={{ padding: '2px' }}>{editValues.deaClass}</td>
                                                                <td style={{ padding: '2px' }}>{editValues.medName}</td>
                                                                <td style={{ padding: '2px' }}>{editValues.manufacturer}</td>
                                                                <td style={{ padding: '2px' }}>{editValues.packSize}</td>
                                                            </Fragment>
                                                        }

                                                        <td style={{ padding: '2px' }}><input className="browser-default" id={`fullPackage-${pageId}`} type="text" value={editValues.fullPackage} onChange={onChange} /></td>
                                                        <td style={{ padding: '2px' }}><input className="browser-default" id={`partialPackage-${pageId}`} type="text" value={editValues.partialPackage} onChange={onChange} /></td>
                                                        <td style={{ padding: '2px' }}><input className="browser-default" id={`lotNumber-${pageId}`} type="text" value={editValues.lotNumber} onChange={onChange} /></td>
                                                        <td style={{ padding: '2px' }}><input className="browser-default" id={`expiration-${pageId}`} type="date" value={editValues.expiration} onChange={onChange} /></td>
                                                        <td style={{ padding: '2px' }}><input className="browser-default" id={`nonReturnableReasonCodeId-${pageId}`} type="text" value={editValues.nonReturnableReasonCodeId} onChange={onChange} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        <td style={{ padding: '2px' }}></td>
                                                        {typeof editValues.returnPolicyId === 'string' ?
                                                            <td style={{ padding: '2px' }}>No Default Return Policy</td>
                                                            :
                                                            <td style={{ padding: '2px' }}><a href="/" style={{ height: '24px', lineHeight: '24px' }} className="btn-small orange white-text waves-effect waves-light col s12" onClick={update}>Update</a></td>
                                                        }
                                                    </tr>
                                                </Fragment>
                                                :
                                                <tr onContextMenu={onContextMenu}>
                                                    <td style={{ padding: '2px' }}>{indate.redbook.ndc}</td>
                                                    <td style={{ padding: '2px' }}>{indate.redbook.deaClass}</td>
                                                    <td style={{ padding: '2px' }}>{indate.redbook.medName}</td>
                                                    <td style={{ padding: '2px' }}>{indate.manufacturer.name}</td>
                                                    <td style={{ padding: '2px' }}>{indate.redbook.packSize}</td>
                                                    <td style={{ padding: '2px' }}>{indate.fullPackage}</td>
                                                    <td style={{ padding: '2px' }}>{`${parseInt(indate.partialPackage) || 0}${indate.partialDecimal ? `.${indate.partialDecimal}` : ''}`}</td>
                                                    <td style={{ padding: '2px' }}>{indate.lotNumber}</td>
                                                    <td style={{ padding: '2px' }}>{indate.expiration ? moment(indate.expiration).format('MM/DD/YYYY') : ''}</td>
                                                    <td style={{ padding: '2px' }}>{indate.nonReturnableReasonCodeId}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col s12 m2 offset-m10">
                                        {indate?.debitMemo?.number ?
                                            <p className="green-text">VERIFIED</p>
                                            :
                                            <a
                                                href="/"
                                                className="btn-small blue white-text waves-effect waves-light col s12"
                                                onClick={e => { e?.preventDefault(); networkCalls.verifyDebitMemo() }}
                                                disabled={updating}
                                            >Verify Debit Memo</a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            <div id={`context-menu-${pageId}`} className={contextMenu.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: contextMenu.clientY,
                left: contextMenu.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    {indate?.debitMemo?.number ?
                        <li>
                            <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={networkCalls.undoVerification}>Undo Verification</a>
                        </li>
                        :
                        <li>
                            <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={edit}>Edit</a>
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}

export default connect()(IndateProcess);