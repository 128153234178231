import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../func';
import moment from 'moment';

class InvoiceOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        orderList: null,
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.Tabs.init(document.querySelector('.tabs'));
        this.getOrdersAwaitingInvoicing();
    }

    networkCalls = {
        getOrdersAwaitingInvoicing: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/invoice/read/all/awaitinginvoice', {
                        params: this.authData
                    })
                        .then(result => this.setState({ orderList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getOrdersAwaitingInvoicing = () => this.networkCalls.getOrdersAwaitingInvoicing();

    render = () => (
        <div className="main">
            <div style={{ display: 'flex' }}>
                <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                <Link to="/">Home</Link>
                <i className="material-icons">chevron_right</i>
                <Link to="/accounting">Accounting</Link>
                <i className="material-icons">chevron_right</i>
                <span className="grey-text">Awaiting Invoicing</span>
            </div>
            <div className="row">
                <h5 className="center">Orders Waiting To Be Invoiced</h5>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th>Pharmacy Name</th>
                            <th></th>
                            <th>Order Number</th>
                            <th>Order Date</th>
                            <th>Status</th>
                            <th>Rate</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orderList && this.state.orderList.map(o => (
                            <tr key={`order-${o.orderNumber}`}>
                                <td style={{ padding: '4px' }}>
                                    {o.pharmacyName}
                                </td>
                                <td style={{ padding: '4px' }}>
                                    {o.existingInvoices && <span className="new badge blue" style={{ fontWeight: 600, marginLeft: '12px' }} data-badge-caption="PREVIOUSLY INVOICED"></span>}
                                    {o.pharmacyInvoiceTemplateId ? '' : <Link to={`/pharmacy/${o.pharmacyId}/invoicetemplate`}><span className="new badge red" data-badge-caption="NO TEMPLATE" style={{ fontWeight: 600, marginLeft: '12px' }}></span></Link>}
                                    {o.isDestructionOnly ? <span className="new badge orange" style={{ fontWeight: 600, marginLeft: '12px' }} data-badge-caption="DESTRUCTION ONLY"></span> : ''}
                                </td>
                                <td style={{ padding: '4px' }}>{o.orderNumber}</td>
                                <td style={{ padding: '4px' }}>{moment(o.orderDate).format('MM/DD/YYYY')}</td>
                                <td style={{ padding: '4px' }}>{o.status}</td>
                                <td style={{ padding: '4px' }}>{o.outdateFeeOverride}</td>
                                <td style={{ padding: '4px' }}><Link to={{ pathname: `/invoice/create`, state: { orderNumber: o.orderNumber } }}>Create Invoice</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}


export default connect()(InvoiceOverview);