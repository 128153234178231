import Axios from 'axios';
import { useEffect, useState, useRef } from "react";
import { logOut, registerRoutes } from "../../../func";

const Combine = props => {
    const {
        activeDebitMemo,
        debitMemoList,
        path,
        passTabToParent,
        dispatch
    } = props;

    const ref = useRef({
        combineIds: [],
        activeDebitMemo: activeDebitMemo,
        debitMemoList: debitMemoList
    })

    const [compatibleDebitMemoList, setCompatibleDebitMemoList] = useState(null);
    const [combineIds, setCombineIds] = useState([]);
    const [saving, setSaving] = useState(false);

    const networkCalls = useRef(registerRoutes({
        save: {
            func: () => {
                setSaving(true)

                Axios.post('/api/v2/debitmemo/update/combine', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoIds: [activeDebitMemo.id, ...ref.current.combineIds]
                })
                    .then(result => {
                        dispatch(result.data);
                        passTabToParent('y');
                    })
                    .catch(logOut)
                    .finally(() => setSaving(false))
            },
            type: 'u'
        }
    }, path))


    useEffect(() => {
        if (!ref.current.activeDebitMemo || !ref.current.debitMemoList) { return }

        const arr = ref.current.debitMemoList?.filter(row => {
            return (
                row.id !== ref.current.activeDebitMemo.id
                && row.manufacturerId === ref.current.activeDebitMemo.manufacturerId
                && row.control === ref.current.activeDebitMemo.control
                && !row.pharmacyWholesalerId
                && !row.verifiedById
                && !row.invoiceId
                && !row.dateNumberAssigned
                && !row.dateClosed
                && !row.prefix
                && !row.month
                && !row.number
                && !row.isDestroyed
            )
        })

        setCompatibleDebitMemoList([...arr]);
    }, [dispatch, passTabToParent])



    useEffect(() => ref.current.combineIds = combineIds, [combineIds])
    useEffect(() => ref.current.activeDebitMemo = activeDebitMemo, [activeDebitMemo])
    useEffect(() => ref.current.debitMemoList = debitMemoList, [debitMemoList])

    const getValue = orderDetails => parseFloat(orderDetails.reduce((a, b) => ({ value: a.value + b.value })).value).toFixed(2);

    const toggleCombineId = id => {
        const arr = combineIds;
        const index = combineIds.indexOf(id);

        if (index === -1)
            arr.push(id);
        else
            arr.splice(index, 1);

        setCombineIds([...arr])
    }

    const combineDebitMemos = e => {
        e?.preventDefault();
        networkCalls.current.save();
    }

    return (
        <div style={{ flex: 1, overflowY: 'auto' }} className="card">
            <div className="card-content">
                <div className="row">
                    {compatibleDebitMemoList?.length ?
                    <table>
                        <thead>
                            <tr>
                                <th style={{ padding: '0px' }}>ID</th>
                                <th style={{ padding: '0px' }}>Manufacturer</th>
                                <th style={{ padding: '0px' }}>Controls</th>
                                <th style={{ padding: '0px' }}>Value</th>
                                <th style={{ padding: '0px' }}>Order Detail Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {compatibleDebitMemoList?.map(dm => (
                                <tr
                                    key={dm.id}
                                    onClick={() => toggleCombineId(dm.id)}
                                    style={{ cursor: 'pointer' }}
                                    className={combineIds.indexOf(dm.id) !== -1 ? 'blue white-text' : ''}
                                >
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{dm.id}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{dm.manufacturer.name}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{dm.control}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{getValue(dm.orderDetails)}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{dm.orderDetails.length}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
    :
    <h6>No Compatible Debit Memos.</h6>
}
                </div>
                <div className="row">
                    {combineIds?.length ?
                        <div className="col s12 m3 l2 offset-m9 offset-l10">
                            {saving ?
                                <div className="preloader-wrapper active" style={{ width: '20px', height: '20px', position: 'relative', top: '4px', left: '8px' }}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div><div className="gap-patch">
                                            <div className="circle"></div>
                                        </div><div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={combineDebitMemos}>Combine</a>
                            }
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}

export default Combine;