import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from "react";

const ReturnInfoTab = props => {

    useEffect(() => {
        setTimeout(setRowHeights, 10)
        window.addEventListener('resize', setRowHeights);
        return  () => window.removeEventListener('resize', setRowHeights);
    })

    const ids = [
        'return-handler',
        'return-handler-id',
        'exclusive-return-wholesaler',
        'exclusive-return-wholesaler-id',
        'direct-return',
        'destroy-in-house',
        'ra-required',
        'minimum-return-value'
    ]


    const setRowHeights = () => {
        ids.forEach(id => {
            document.querySelector(`#${id}`)?.setAttribute('height', document.querySelector(`#label-${id}`)?.clientHeight + 1)
        })
    }

    return (<div style={{ padding: '1vw' }}>
        {props.manufacturer ?
            <div>
                <div className="row">
                    <Link to="/manufacturer/create">
                        <i className="material-icons tooltipped" data-position="top" data-tooltip="Create New Manufacturer" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>add</i>
                    </Link>
                    <Link to={`/manufacturer/${props.manufacturer.id}/update`}>
                        <i className="material-icons tooltipped" data-position="top" data-tooltip="Edit Manufacturer" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>edit</i>
                    </Link>
                </div>
                <div className="row">
                    <div className="col s4 m3 l2">
                        <table>
                            <tbody>
                                <tr><td id="label-return-handler" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Return Handler</td></tr>
                                <tr><td id="label-return-handler-id" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Return Handler ID</td></tr>
                                <tr><td id="label-exclusive-return-wholesaler" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Exclusive Return Wholesaler</td></tr>
                                <tr><td id="label-exclusive-return-wholesaler-id" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Exclusive Return Wholesaler ID</td></tr>
                                <tr><td id="label-direct-return" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Direct Return</td></tr>
                                <tr><td id="label-destroy-in-house" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Destroy In House</td></tr>
                                <tr><td id="label-ra-required" className="bold" style={{ textAlign: 'right', padding: '4px' }}>RA Required</td></tr>
                                <tr><td id="label-minimum-return-value" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Minimum Return Value</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col s8 m9 l10">
                        <table className="highlight">
                            <tbody>
                                <tr><td id="return-handler" style={{ padding: '4px' }}>{props.manufacturer.returnHandler?.name || '\xa0'}</td></tr>
                                <tr><td id="return-handler-id" style={{ padding: '4px' }}>{props.manufacturer.returnHandler?.id || '\xa0'}</td></tr>
                                <tr><td id="exclusive-return-wholesaler" style={{ padding: '4px' }}>{props.manufacturer.exclusiveReturnWholesaler?.name || '\xa0'}</td></tr>
                                <tr><td id="exclusive-return-wholesaler-id" style={{ padding: '4px' }}>{props.manufacturer.exclusiveReturnWholesaler?.id || '\xa0'}</td></tr>
                                <tr><td id="direct-return" style={{ padding: '4px' }}>{props.manufacturer.directReturn ? 'Yes' : 'No'}</td></tr>
                                <tr><td id="destroy-in-house" style={{ padding: '4px' }}>{props.manufacturer.destroyInHouse ? 'Yes' : 'No'}</td></tr>
                                <tr><td id="ra-required" style={{ padding: '4px' }}>{props.manufacturer.raRequired ? 'Yes' : 'No'}</td></tr>
                                <tr><td id="minimum-return-value" style={{ padding: '4px' }}>{props.manufacturer.minValue || '\xa0'}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            :
            <div className="progress blue">
                <div className="indeterminate" />
            </div>
        }
    </div>
    )
}

export default ReturnInfoTab;