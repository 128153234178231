import Axios from "axios";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { logOut, registerRoutes } from "../../../func";

const Split = props => {
    const {
        path,
        dispatch,
        debitMemo,
        setParentTab
    } = props;

    const [splitArray, setSplitArray] = useState([]);
    const [saving, setSaving] = useState(false);
    const [networkCalls] = useState(registerRoutes({
        save: {
            func: () => {
                setSaving(true);

                Axios.post('/api/v2/debitmemo/update/split', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoId: debitMemo.id,
                    orderDetailIds: ref.current.splitArray
                })
                    .then(result => {
                        dispatch(result.data);
                        setParentTab('x');
                    })
                    .catch(logOut)
                    .finally(() => setSaving(false))
            },
            type: 'u'
        }
    }, path))

    const addToSplitArray = id => {
        if (splitArray.length >= debitMemo.orderDetails.length - 1)
            return dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'One Order Detail must remain on original Debit Memo', class: 'orange white-text' } })
        setSplitArray(p => [...p, id]);
    }

    const removeFromSplitArray = id => {
        const arr = splitArray;
        arr.splice(arr.indexOf(id), 1);
        setSplitArray([...arr]);
    }

    const save = e => {
        e?.preventDefault();
        networkCalls.save();
    }

    const ref = useRef({
        splitArray: []
    })

    useEffect(() => ref.current.splitArray = splitArray, [splitArray])

    return (
        <div style={{ flex: 1, overflowY: 'auto' }} className="card">
            <div className="card-content">
                <div className="row">
                    <div className="card">
                        <div className="card-content">
                            <h6 className="center" style={{ textDecoration: 'underline' }}>Split From</h6>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '0px' }}>NDC</th>
                                        <th style={{ padding: '0px' }}>DEA</th>
                                        <th style={{ padding: '0px' }}>Manufacturer</th>
                                        <th style={{ padding: '0px' }}>Pack Size</th>
                                        <th style={{ padding: '0px' }}>Full Pkg</th>
                                        <th style={{ padding: '0px' }}>Partial Cnt</th>
                                        <th style={{ padding: '0px' }}>Lot #</th>
                                        <th style={{ padding: '0px' }}>Expiration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {debitMemo?.orderDetails?.map(od => {
                                        if (splitArray.indexOf(od.id) !== -1)
                                            return null
                                        else return (
                                            <tr key={od.id} onClick={() => addToSplitArray(od.id)}>
                                                <td style={{ padding: '0px' }}>{od.redbook.ndc}</td>
                                                <td style={{ padding: '0px' }}>{od.redbook.deaClass}</td>
                                                <td style={{ padding: '0px' }}>{debitMemo.manufacturer.name}</td>
                                                <td style={{ padding: '0px' }}>{od.redbook.packSize}</td>
                                                <td style={{ padding: '0px' }}>{od.fullPackage}</td>
                                                <td style={{ padding: '0px' }}>{od.partialPackage} {od.partialDecimal ? `.${od.partialDecimal}` : ''}</td>
                                                <td style={{ padding: '0px' }}>{od.lotNumber}</td>
                                                <td style={{ padding: '0px' }}>{od.expiration ? moment(od.expiration).format('MM/DD/YYYY') : ''}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-content">
                            <h6 className="center" style={{ textDecoration: 'underline' }}>Split To</h6>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '0px' }}>NDC</th>
                                        <th style={{ padding: '0px' }}>DEA</th>
                                        <th style={{ padding: '0px' }}>Manufacturer</th>
                                        <th style={{ padding: '0px' }}>Pack Size</th>
                                        <th style={{ padding: '0px' }}>Full Pkg</th>
                                        <th style={{ padding: '0px' }}>Partial Cnt</th>
                                        <th style={{ padding: '0px' }}>Lot #</th>
                                        <th style={{ padding: '0px' }}>Expiration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {splitArray?.map(id => {
                                        const od = debitMemo?.orderDetails?.find(row => row.id === id)
                                        if (od)
                                            return (
                                                <tr key={od.id} onClick={() => removeFromSplitArray(od.id)}>
                                                    <td style={{ padding: '0px' }}>{od.redbook.ndc}</td>
                                                    <td style={{ padding: '0px' }}>{od.redbook.deaClass}</td>
                                                    <td style={{ padding: '0px' }}>{debitMemo.manufacturer.name}</td>
                                                    <td style={{ padding: '0px' }}>{od.redbook.packSize}</td>
                                                    <td style={{ padding: '0px' }}>{od.fullPackage}</td>
                                                    <td style={{ padding: '0px' }}>{od.partialPackage} {od.partialDecimal ? `.${od.partialDecimal}` : ''}</td>
                                                    <td style={{ padding: '0px' }}>{od.lotNumber}</td>
                                                    <td style={{ padding: '0px' }}>{od.expiration ? moment(od.expiration).format('MM/DD/YYYY') : ''}</td>
                                                </tr>
                                            )
                                        else
                                            return null
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {splitArray?.length ?
                        <div className="col s12 m3 l2 offset-m9 offset-l10">
                            {saving ?
                                <div className="preloader-wrapper active" style={{ width: '20px', height: '20px', position: 'relative', top: '4px', left: '8px' }}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div><div className="gap-patch">
                                            <div className="circle"></div>
                                        </div><div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={save}>Split</a>
                            }
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}

export default Split;