import { Link } from 'react-router-dom';
import { openSideNav } from '../../../func';
import { useEffect, useState } from 'react';
import CustomersTab from './customersTab';
import ExportTable from './exportTable';

const QuickBooksExport = () => {
  const [tab, setTab] = useState('Customers');

  const tabList = [
    'Customers',
    'Invoices (Positive)',
    'Invoices (Negative)',
    'Invoice Payments',
    'Outbound Checks',
    'Credits',
  ];

  useEffect(() => {}, [tab]);

  return (
    <div className="main">
      <div className="row">
        <div style={{ display: 'flex' }}>
          <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}>
            <i className="material-icons black-text">menu</i>
          </Link>
          <Link to="/">Home</Link>
          <i className="material-icons">chevron_right</i>
          <Link to="/accounting">Accounting</Link>
          <i className="material-icons">chevron_right</i>
          <span className="grey-text">Quickbooks Export</span>
        </div>
      </div>
      <div className="row">
        <div className="col s4 m3 l2">
          <div className="row">
            <ul className="collection">
              {tabList?.map((item) => (
                <li
                  key={item}
                  id={`tab-${item}`}
                  className={`collection-item ${
                    item === tab ? 'blue white-text' : ''
                  }`}
                  onClick={() => setTab(item)}
                  style={{ cursor: 'pointer' }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col s8 m9 l10">
          {tab === 'Customers' ? (
            <CustomersTab />
          ) : (
            <ExportTable exportType={tab} />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuickBooksExport;
