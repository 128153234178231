import moment from "moment";

const commissionRateList = props => {
    const {
        employeeCommissionList,
        setIsCreating,
        selectedCommissionId
    } = props;

    return (
        <div>
            <div className="row" style={{marginBottom: '8px'}}>
                {employeeCommissionList?.length ?
                    <table>
                        <thead>
                            <tr>
                                <th style={{ padding: '0px' }}>Effective Date</th>
                                <th style={{ padding: '0px' }}>Expiration Date</th>
                                <th style={{ padding: '0px' }}>Comm. Type</th>
                                <th style={{ padding: '0px' }}>New Accounts Only</th>
                                <th style={{ padding: '0px' }}>Payment Interval (mos.)</th>
                                <th style={{ padding: '0px' }}>Minimum Comm. Value</th>
                                <th style={{ padding: '0px' }}>Percentage Rate</th>
                                <th style={{ padding: '0px' }}>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeCommissionList.map(c => (
                                <tr key={c.id} className={selectedCommissionId === c.id ? 'blue white-text' : ''}>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{moment(c.effectiveDate).format('MM/DD/YY')}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.expirationDate ? moment(c.expirationDate).format('MM/DD/YY') : 'Current'}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.commissionType.toUpperCase()}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.payOnNewAccountsOnly ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.paymentIntervalInMonths}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.commissionMinimumValue}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>%{parseInt(c.commissionRate * 100)}</td>
                                    <td style={{ padding: '0px', borderRadius: '0px' }}>{c.notes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    :
                    <p>No Commissions To Display</p>
                }
            </div>
            <div className="row" style={{marginBottom: '0px'}}>
                <div className="col s12 m3 l2 right">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={e => { e.preventDefault(); setIsCreating(true) }}>Add Commission Rate</a>
                </div>
            </div>
        </div>
    )
}

export default commissionRateList;