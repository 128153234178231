import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PermissionOverview from './permissions/permissionOverview';
import CustomerPortalOverview from './customerPortal/customerPortalOverview';
import Employees from './employee/employees';
import CustomQuery from './customQuery/CustomQuery';

const WebsiteAdministrationRouter = props => (
    <Switch>
        <Route exact path="/website/administration/permission" component={PermissionOverview} />
        <Route exact path="/website/administration/customer/portal" component={CustomerPortalOverview} />
        <Route exact path="/website/administration/employees" component={Employees} />
        <Route exact path="/website/administration/:customquery" component={CustomQuery} />
    </Switch>
)

export default WebsiteAdministrationRouter;