import React from 'react';
import { Switch, Route } from 'react-router-dom';
import WholesalerDetail from './wholesalerDetail';
import WholesalerOverview from './wholesalerOverview';
import UpdateWholesaler from './updateWholesaler';
import CreateWholesaler from './createWholesaler';

const WholesalerRouter = props => (
    <Switch>
        <Route exact path="/wholesaler" component={WholesalerOverview} />
        <Route exact path="/wholesaler/create" component={CreateWholesaler} />
        <Route exact path="/wholesaler/:id" component={WholesalerDetail} />
        <Route exact path="/wholesaler/:id/update" component={UpdateWholesaler} />
    </Switch>
)

export default WholesalerRouter;