import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../../func';
import Axios from 'axios';
import GeneralTab from './generalTab';
import AccountsTab from './accountsTab';
import OrdersTab from './ordersTab';
import DeaInfoTab from './deaInfoTab';

class PharmacyDetail extends React.Component {
    constructor(props) {
        super(props);
        this.pharmacyId = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        tab: 'general',
        pharmacy: null,
        stateList: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getStates();
        this.getPharmacyData();
    }

    networkCalls = {
        getPharmacyData: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/one/by/id/deep', {
                        params: {
                            ...this.authData,
                            id: this.pharmacyId
                        }
                    })
                        .then(result => this.setState({ pharmacy: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', { params: this.authData })
                    .then(result => {
                        const stateListAsObject = {};
                        result.data.forEach(row => stateListAsObject[row.name] = null)

                        this.setState({ stateList: result.data, stateListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        }
    }

    getPharmacyData = () => this.networkCalls.getPharmacyData();

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] })

    getContent = () => {
        if (!this.state.pharmacy)
            return null;

        switch (this.state.tab) {
            case 'general':
                return <GeneralTab pharmacy={this.state.pharmacy} />
            case 'accounts':
                return <AccountsTab accounts={this.state.pharmacy.accounts} pharmacyId={this.state.pharmacy.id} />
            case 'orders':
                return <OrdersTab pharmacyId={this.state.pharmacy.id} path={this.props.match.path} />
            case 'deaInfo':
                return <DeaInfoTab
                    deaNumber={this.state.pharmacy.deaNumber}
                    path={this.props.match.path}
                    dispatch={this.props.dispatch}
                    stateList={this.state.stateList}
                    stateListAsObject={this.state.stateListAsObject}
                />
            default:
                return null;
        }
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/pharmacy">Pharmacies</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.state.pharmacy ? this.state.pharmacy.name : 'Pharmacy Detail'}</span>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="row">
                        <div className="col s4 m3 l2">
                            <div className="row">
                                <ul className="collection">
                                    <li id="tab-general" className={`collection-item ${this.state.tab === 'general' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>General</li>
                                    <li id="tab-accounts" className={`collection-item ${this.state.tab === 'accounts' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Accounts</li>
                                    <li id="tab-orders" className={`collection-item ${this.state.tab === 'orders' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Orders</li>
                                    <li id="tab-deaInfo" className={`collection-item ${this.state.tab === 'deaInfo' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>DEA Info</li>
                                </ul>
                            </div>
                            {this.state.tab === 'deaInfo' ?
                                <div className="row" style={{ border: '1px dotted #ccc', padding: '12px' }}>
                                    <h6 className="center bold" style={{ textDecoration: 'underline' }}>DEA Info</h6>
                                    <p>This tab holds pharmacy name and location information as it is shown on DEA records. That means that the info is tied to the <span style={{ fontStyle: 'italic' }}>DEA number</span> and <span style={{ fontStyle: 'italic' }}>NOT</span> the pharmacy.</p>
                                    <p>Multiple pharmacies may share the same information if they share the same DEA Number. If you change this information for one pharmaacy it will change it for all pharmacies that share that DEA number</p>
                                </div> : null}
                        </div>
                        <div className="col s8 m9 l10" style={{ minHeight: '82vh', maxHeight: '82vh', overflow: 'auto' }}>
                            {this.state.loading ?
                                <div className="progress blue">
                                    <div className="indeterminate" />
                                </div>
                                :
                                this.getContent()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(PharmacyDetail);