import React from 'react';
import { connect } from 'react-redux';
import { openSideNav, logOut, registerRoutes } from '../../../func';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import M from '@materializecss/materialize';
import moment from 'moment';

class SalesCommission extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        employeeList: null,
        employeeId: -1,
        totalDue: undefined,
        paidYearToDate: undefined
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getReps();
        this.networkCalls.getPreviousPayments();
    }

    networkCalls = {
        getReps: {
            func: () => {
                Axios.get('/api/v1/employee/read/active', { params: this.authData })
                    .then(result => {
                        const employeeList = result.data.filter(e => {
                            return e.isRep &&
                                `${e.firstName?.toLowerCase()} ${e.lastName?.toLowerCase()}` !== 'reese scott' &&
                                `${e.firstName?.toLowerCase()} ${e.lastName?.toLowerCase()}` !== 'seng tech'
                        })

                        this.setState({ employeeList }, () => {
                            M.Tabs.init(document.querySelectorAll('.tabs'));
                            this.employeeChange(-1);
                        })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getCommission: {
            func: () => {
                Axios.get('/api/v1/sales/read/commission/by/employeeid', {
                    params: {
                        ...this.authData,
                        employeeId: this.state.employeeId
                    }
                })
                    .then(result => this.sortCommissionData(result.data))
                    .catch(logOut)
            },
            type: 'r'
        },
        getPreviousPayments: {
            func: () => {
                Axios.get('/api/v1/sales/read/all/previouspayments', {
                    params: this.authData
                })
                    .then(result => this.setState({ previousPayments: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        },
        saveCommission: {
            func: e => {
                e?.preventDefault();

                const contactInfo = this.state.employeeList.filter(e => parseInt(e.id) === parseInt(this.state.employeeId))[0].contactInfo;
                const email = contactInfo.filter(row => row.contactType?.toLowerCase() === 'work email')[0]?.contactInfo;

                if(!email){return this.props.dispatch({type: 'GLOBAL_TOAST', payload: {msg: 'This rep has no email', class: 'red white-text'}})}

                if (!this.state.commissionData || !this.state.commissionData.length)
                    return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Commissions to be Paid', class: 'orange white-text' } })

                Axios.post('/api/v1/sales/create/many/commissions', {
                    ...this.authData,
                    commissionData: this.state.commissionData,
                    email
                })
                    .then(result => {
                        this.props.dispatch(result.data)
                        this.networkCalls.getPreviousPayments();
                        this.employeeChange(this.state.employeeId);
                    })
                    .catch(logOut);
            },
            type: 'c'
        }
    }

    employeeChange = employeeId => {
        const previousPayments = this.state.previousPayments?.filter(pp => pp.issuedToId === employeeId)

        this.setState({
            employeeId,
            paidYearToDate: undefined,
            totalDue: undefined,
            commissionData: null,
            lastPaymentDate: previousPayments && previousPayments.length ? previousPayments[previousPayments.length - 1].timeStamp : undefined,
            lastPaymentAmount: previousPayments && previousPayments.length ? previousPayments[previousPayments.length - 1].totalAmount : undefined
        }, this.networkCalls.getCommission)
    }

    sortCommissionData = data => {
        const commissionData = [];

        if (parseInt(this.state.employeeId) === -1) {
            data.forEach(row => {
                const general = {
                    pharmacyName: row.pharmacyName,
                    customerNumber: row.customerNumber,
                    serviceDate: row.serviceDate,
                    orderNumber: row.orderNumber,
                    invoiceTotal: row.invoiceTotal || 0,
                    creditTotal: row.creditTotal || 0,
                    percentOfInvoiceReceived: row.percentOfInvoiceReceived
                }

                commissionData.push({
                    ...general,
                    repId: row.repOnSiteId,
                    repName: row.repOnSiteName,
                    role: 'Rep On-Site',
                    commissionRate: row.repOnSiteCommissionRate,
                    previousPayments: row.repOnSitePreviousPayments || 0,
                    commission: row.repOnSiteCommission
                })

                if (row.supervisorId && row.supervisorId !== row.repOnSiteId)
                    commissionData.push({
                        ...general,
                        repId: row.supervisorId,
                        repName: row.supervisorName,
                        role: 'Supervisor',
                        commissionRate: row.supervisorCommissionRate,
                        previousPayments: row.supervisorPreviousPayments || 0,
                        commission: row.supervisorCommission
                    })

                if (row.pharmacyOwnerId && row.pharmacyOwnerId !== row.repOnSiteId && row.pharmacyOwnerId !== row.supervisorId)
                    commissionData.push({
                        ...general,
                        repId: row.pharmacyOwnerId,
                        repName: row.pharmacyOwnerName,
                        role: 'Account Owner',
                        commissionRate: row.pharmacyOwnerCommissionRate,
                        previousPayments: row.pharmacyOwnerPreveiousPayments || 0,
                        commission: row.pharmacyOwnerCommission
                    })
            })
        }
        else {
            data.forEach(row => {
                let repData;

                if (row.repOnSiteId === parseInt(this.state.employeeId))
                    repData = {
                        repId: row.repOnSiteId,
                        repName: row.repOnSiteName,
                        role: 'Rep On-Site',
                        commissionRate: row.repOnSiteCommissionRate,
                        previousPayments: row.repOnSitePreviousPayments || 0,
                        commission: row.repOnSiteCommission
                    }
                else if (row.supervisorId === parseInt(this.state.employeeId))
                    repData = {
                        repId: row.supervisorId,
                        repName: row.supervisorName,
                        role: 'Supervisor',
                        commissionRate: row.supervisorCommissionRate,
                        previousPayments: row.supervisorPreviousPayments || 0,
                        commission: row.supervisorCommission
                    }
                else
                    repData = {
                        repId: row.pharmacyOwnerId,
                        repName: row.pharmacyOwnerName,
                        role: 'Account Owner',
                        commissionRate: row.pharmacyOwnerCommissionRate,
                        previousPayments: row.pharmacyOwnerPreveiousPayments || 0,
                        commission: row.pharmacyOwnerCommission
                    }

                commissionData.push({
                    ...repData,
                    pharmacyName: row.pharmacyName,
                    customerNumber: row.customerNumber,
                    serviceDate: row.serviceDate,
                    orderNumber: row.orderNumber,
                    invoiceTotal: row.invoiceTotal || 0,
                    creditTotal: row.creditTotal || 0,
                    percentOfInvoiceReceived: row.percentOfInvoiceReceived
                })
            })
        }

        const totalDue = commissionData.reduce((a, b) => a + b.commission, 0)
        const paidYearToDate = commissionData.reduce((a, b) => a + b.previousPayments, 0)

        this.setState({ commissionData, totalDue, paidYearToDate })
    }

    formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Sales Commission</span>
                </div>
            </div>
            <div className="row">
                <div className="col s2">
                    <ul className="collection">
                        <li
                            className={`collection-item ${parseInt(this.state.employeeId) === -1 ? 'blue white-text' : ''}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.employeeChange(-1)
                            }>All</li>
                        {this.state.employeeList?.map(e => (
                            <li
                                key={e.id}
                                className={`collection-item ${parseInt(this.state.employeeId) === e.id ? 'blue white-text' : ''}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.employeeChange(e.id)}
                            >{e.firstName} {e.lastName}</li>
                        ))}
                    </ul>
                </div>
                <div className="col s10">
                    {this.state.employeeId !== -1 && this.state.totalDue !== undefined ?
                        <div className="row">
                            <div className="col s4 yellow black-text">
                                <h5>Last Payment: <span>{this.state.lastPaymentDate ? moment(this.state.lastPaymentDate).format('MMM DD, YYYY') : ''} - {this.state.lastPaymentAmount ? this.formatter.format(this.state.lastPaymentAmount) : ''}</span></h5>
                            </div>
                            <div className="col s2 yellow black-text">
                                <h5>Total Due: <span>{this.formatter.format(this.state.totalDue.toFixed(2))}</span></h5>
                            </div>
                            <div className="col s2 yellow black-text">
                                <h5>Paid YTD: <span>{this.formatter.format(this.state.paidYearToDate.toFixed(2))}</span></h5>
                            </div>
                            <div className="col s4 m2 offset-m2">
                                <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.networkCalls.saveCommission}>Pay and Notify</a>
                            </div>
                        </div>
                        : null}
                    <div className="row">
                        <table className="highlight">
                            <thead>
                                <tr>
                                    {parseInt(this.state.employeeId) === -1 ? <th style={{ padding: '2px' }}>Rep Name</th> : null}
                                    <th style={{ padding: '2px' }}>Role</th>
                                    <th style={{ padding: '2px' }}>Pharmacy</th>
                                    <th style={{ padding: '2px' }}>Customer Number</th>
                                    <th style={{ padding: '2px' }}>Service Date</th>
                                    <th style={{ padding: '2px' }}>Order Number</th>
                                    <th style={{ padding: '2px' }}>Invoice</th>
                                    <th style={{ padding: '2px' }}>Credits</th>
                                    <th style={{ padding: '2px' }}>% Received</th>
                                    <th style={{ padding: '2px' }}>Commission Rate</th>
                                    <th style={{ padding: '2px' }}>Previous Payments</th>
                                    <th style={{ padding: '2px' }}>Commission Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.commissionData?.map(row => (
                                    <tr key={`${row.orderNumber}-${row.repId}`}>
                                        {parseInt(this.state.employeeId) === -1 ? <td style={{ padding: '2px' }}>{row.repName}</td> : null}
                                        <td style={{ padding: '2px' }}>{row.role}</td>
                                        <td style={{ padding: '2px' }}>{row.pharmacyName}</td>
                                        <td style={{ padding: '2px' }}>{row.customerNumber}</td>
                                        <td style={{ padding: '2px' }}>{row.serviceDate}</td>
                                        <td style={{ padding: '2px' }}>{row.orderNumber}</td>
                                        <td style={{ padding: '2px' }}>{this.formatter.format(row.invoiceTotal)}</td>
                                        <td style={{ padding: '2px' }}>{this.formatter.format(row.creditTotal)}</td>
                                        <td style={{ padding: '2px' }}>{row.percentOfInvoiceReceived}</td>
                                        <td style={{ padding: '2px' }}>{row.commissionRate}</td>
                                        <td style={{ padding: '2px' }}>{this.formatter.format(row.previousPayments)}</td>
                                        <td style={{ padding: '2px' }}>{this.formatter.format(row.commission)}</td>
                                    </tr>
                                ))}
                                {this.state.commissionData ?
                                    <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
                                        {parseInt(this.state.employeeId) === -1 ? <td style={{ padding: '2px', fontWeight: '700' }}></td> : null}
                                        <td style={{ padding: '2px', fontWeight: '700' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}>{this.formatter.format(this.state.commissionData.reduce((a, b) => a + b.invoiceTotal, 0).toFixed(2))}</td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}>{this.formatter.format(this.state.commissionData.reduce((a, b) => a + b.creditTotal, 0).toFixed(2))}</td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}></td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}>{this.formatter.format(this.state.paidYearToDate?.toFixed(2))}</td>
                                        <td style={{ padding: '2px', fontWeight: '700' }}>{this.formatter.format(this.state.totalDue?.toFixed(2))}</td>
                                    </tr>
                                    : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(SalesCommission);