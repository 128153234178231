import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ManufacturerDetail from './manufacturerDetail/manufacturerDetail';
import ManufacturerOverview from './manufacturerOverview';
import UpdateManufacturer from './updateManufacturer/updateManufacturer';
import CreateManufacturer from './createManufacturer/createManufacturer';
import CreatePolicy from './returnPolicy/createPolicy';
import UpdatePolicy from './returnPolicy/updatePolicy';

const ManufacturerRouter = props => (
    <Switch>
        <Route exact path="/manufacturer" component={ManufacturerOverview} />
        <Route exact path="/manufacturer/create" component={CreateManufacturer} />
        <Route exact path="/manufacturer/:id" component={ManufacturerDetail} />
        <Route exact path="/manufacturer/:id/update" component={UpdateManufacturer} />
        <Route path="/manufacturer/:id/policy" component={PolicyRouter} />
    </Switch>
)


export default ManufacturerRouter;

const PolicyRouter = props => (
    <Switch>
        <Route exact path="/manufacturer/:id/policy/create" component={CreatePolicy} />
        <Route exact path="/manufacturer/:id/policy/:policyid" component={UpdatePolicy} />
    </Switch>
)