import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from "react";

const GeneralTab = props => {

    useEffect(() => {
        setTimeout(setRowHeights, 10)
        window.addEventListener('resize', setRowHeights);
        return () => window.removeEventListener('resize', setRowHeights);
    })

    const ids = [
        'id',
        'name',
        'website',
        'phone',
        'email',
        'address',
        'address-2',
        'city',
        'dea-number',
        'parent-party'
    ]


    const setRowHeights = () => {
        ids.forEach(id => {
            document.querySelector(`#${id}`)?.setAttribute('height', document.querySelector(`#label-${id}`)?.clientHeight + 1)
        })
    }

    return (
        <div style={{ padding: '1vw' }}>
            {props.manufacturer ?
                <div>
                    <div className="row">
                        <Link to="/manufacturer/create">
                            <i className="material-icons tooltipped" data-position="top" data-tooltip="Create New Manufacturer" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>add</i>
                        </Link>
                        <Link to={`/manufacturer/${props.manufacturer.id}/update`}>
                            <i className="material-icons tooltipped" data-position="top" data-tooltip="Edit Manufacturer" style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px' }}>edit</i>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col s4 m3 l2">
                            <table>
                                <tbody>
                                    <tr><td id="label-id" className="bold" style={{ textAlign: 'right', padding: '4px' }}>ID</td></tr>
                                    <tr><td id="label-name" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Name</td></tr>
                                    <tr><td id="label-website" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Website</td></tr>
                                    <tr><td id="label-phone" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Phone</td></tr>
                                    <tr><td id="label-email" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Email</td></tr>
                                    <tr><td id="label-address" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Address</td></tr>
                                    <tr><td id="label-address-2" className="bold" style={{ textAlign: 'right', padding: '4px' }}>&nbsp;</td></tr>
                                    <tr><td id="label-city" className="bold" style={{ textAlign: 'right', padding: '4px' }}>&nbsp;</td></tr>
                                    <tr><td id="label-dea-number" className="bold" style={{ textAlign: 'right', padding: '4px' }}>DEA Number</td></tr>
                                    <tr><td id="label-parent-party" className="bold" style={{ textAlign: 'right', padding: '4px' }}>Parent Party</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col s8 m9 l10">
                            <table className="highlight">
                                <tbody>
                                    <tr><td id="id" style={{ padding: '4px' }}>{props.manufacturer.id}</td></tr>
                                    <tr><td id="name" style={{ padding: '4px' }}>{props.manufacturer.name}</td></tr>
                                    <tr><td id="website" style={{ padding: '4px' }}>{props.manufacturer.website ? <a href={`https://${props.manufacturer.website}`} target="_blank" rel='noreferrer'>{props.manufacturer.website}</a> : '\xa0'}</td></tr>
                                    <tr><td id="phone" style={{ padding: '4px' }}>{props.manufacturer.phone || '\xa0'}</td></tr>
                                    <tr><td id="email" style={{ padding: '4px' }}>{props.manufacturer.email || '\xa0'}</td></tr>
                                    <tr><td id="address" style={{ padding: '4px' }}>{props.manufacturer.addressLineOne}</td></tr>
                                    <tr><td id="address-2" style={{ padding: '4px' }}>{props.manufacturer.addressLineTwo}</td></tr>
                                    <tr><td id="city" style={{ padding: '4px' }}>
                                        {props.manufacturer.city ? `${props.manufacturer.city},` : ''} {props.manufacturer.state?.code} {props.manufacturer.zip}
                                    </td></tr>
                                    <tr><td id="dea-number" style={{ padding: '4px' }}>{props.manufacturer.deaNumber || '\xa0'}</td></tr>
                                    <tr><td id="parent-party" style={{ padding: '4px' }}>{props.manufacturer.parentParty ? <Link to={`/manufacturer/${props.manufacturer.parentParty.id}`}>{props.manufacturer.parentParty.name}</Link> : '\xa0'}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                :
                <div className="progress blue">
                    <div className="indeterminate" />
                </div>
            }
        </div>
    )
}
export default GeneralTab;