import React from 'react';
import { connect } from 'react-redux';
import { logOut, registerRoutes } from '../../../func';
import M from '@materializecss/materialize';
import Axios from 'axios';
import moment from 'moment';

class AccessTab extends React.Component {
  constructor(props) {
    super(props);
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    this.state = this.initState();
  }

  initState = () => ({
    selectedPharmacy: null,
    userList: null,
    loadingPharmacy: false,
    createUserValues: this.initCreateUserValues(),
    additionalPharmaciesContextMenu: {
      clientX: 0,
      clientY: 0,
      show: false,
      pharmacy: null,
    },
    userContextMenu: {
      clientX: 0,
      clientY: 0,
      show: false,
      activityLog: null,
    },
    activityLog: {
      entries: null,
      show: false,
    },
    editPharmacyAccessValues: {
      pharmacyList: null,
      show: false,
      contextMenu: {
        clientY: 0,
        clientX: 0,
        show: false,
        pharmacy: null,
      },
    },
  });

  initCreateUserValues = () => ({
    username: '',
    firstName: '',
    lastName: '',
    title: '',
    phone: '',
    additionalPharmacies: [],
  });

  componentDidMount = () => {
    this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
    M.Autocomplete.init(document.querySelectorAll('#pharmacyName'), {
      data: this.props.pharmacyListAsObject,
    });
    M.Autocomplete.init(
      document.querySelectorAll('#edit-pharmacy-access-list-add-pharmacy'),
      {
        data: this.props.pharmacyListAsObject,
        limit: 7,
      }
    );
    this.initListeners();
  };

  componentWillUnmount = () => {
    this.removeListeners();
  };

  initListeners = () => {
    document.addEventListener('keyup', this.enterListener);
    document.addEventListener('mousedown', this.clickAwayListener);
    document.addEventListener('mouseup', this.selectListener);
  };

  removeListeners = () => {
    document.removeEventListener('keyup', this.enterListener);
    document.removeEventListener('mousedown', this.clickAwayListener);
    document.removeEventListener('mouseup', this.selectListener);
  };

  selectListener = (e) => {
    const { target } = e;
    const autocomplete = document.querySelector('#pharmacyName');
    const autocompleteId = autocomplete.getAttribute('data-target');
    const dropdown = document.querySelector(`#${autocompleteId}`);

    if (dropdown?.contains(target)) {
      const value = target.innerHTML
        .replace('<span class="highlight">', '')
        .replace('</span>', '');

      const selectedPharmacy = this.props.pharmacyList?.find(
        (p) => `${p.name} - ${p.customerNumber}` === value
      );

      if (selectedPharmacy)
        this.setState(
          { selectedPharmacy },
          this.networkCalls.getReadPortalUsers
        );
    }
  };

  enterListener = (e) => {
    if (e.keyCode === 13) {
      if (e.target.id === 'add-additional-pharmacy') {
        const additionalPharmacy = this.props.pharmacyList.find(
          (p) =>
            `${p.name} - ${p.customerNumber}` ===
            document.querySelector('#add-additional-pharmacy').value
        );

        if (
          additionalPharmacy &&
          additionalPharmacy.id === this.state.selectedPharmacy.id
        ) {
          this.props.dispatch({
            type: 'GLOBAL_TOAST',
            payload: {
              msg: 'Cannot Add Primary Pharmacy To Additional Pharmacy List',
              class: 'orange white-text',
            },
          });
          return (document.querySelector('#add-additional-pharmacy').value =
            '');
        }

        if (additionalPharmacy)
          this.setState(
            (p) => ({
              createUserValues: {
                ...p.createUserValues,
                additionalPharmacies: [
                  ...p.createUserValues.additionalPharmacies,
                  additionalPharmacy,
                ],
              },
            }),
            () =>
              (document.querySelector('#add-additional-pharmacy').value = '')
          );
      }

      if (e.target.id === 'pharmacyName') {
        const selectedPharmacy = this.props.pharmacyList.find(
          (p) =>
            `${p.name} - ${p.customerNumber}` ===
            document.querySelector('#pharmacyName').value
        );

        if (selectedPharmacy)
          this.setState(
            { selectedPharmacy },
            this.networkCalls.getReadPortalUsers
          );
      }
    }
  };

  clickAwayListener = (e) => {
    if (
      this.state.editPharmacyAccessValues?.contextMenu?.show &&
      !document
        .querySelector('#edit-pharmacy-access-context-menu')
        .contains(e.target)
    )
      return this.setState((p) => ({
        editPharmacyAccessValues: {
          ...p.editPharmacyAccessValues,
          contextMenu: {
            clientX: 0,
            clientY: 0,
            show: false,
            pharmacy: null,
          },
        },
      }));

    if (
      this.state.additionalPharmaciesContextMenu.show &&
      !document
        .querySelector('#additional-pharmacies-context-menu')
        .contains(e.target)
    )
      return this.setState({
        additionalPharmaciesContextMenu: {
          clientX: 0,
          clientY: 0,
          show: false,
          pharmacy: null,
        },
      });

    if (
      this.state.userContextMenu.show &&
      !document.querySelector('#user-context-menu').contains(e.target)
    )
      return this.setState((p) => ({
        userContextMenu: {
          ...p.userContextMenu,
          clientX: 0,
          clientY: 0,
          show: false,
        },
      }));
  };

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (newProps !== this.props) {
      M.Autocomplete.init(document.querySelectorAll('#pharmacyName'), {
        data: newProps.pharmacyListAsObject,
      });
      M.Autocomplete.init(
        document.querySelectorAll('#edit-pharmacy-access-list-add-pharmacy'),
        {
          data: newProps.pharmacyListAsObject,
          limit: 7,
          dropdownOptions: { container: <div className="test"></div> },
        }
      );
    }
  };

  networkCalls = {
    getReadPortalUsers: {
      func: () => {
        const pharmacyId = this.state.selectedPharmacy.id;
        this.setState({ loadingPharmacy: true }, () => {
          Axios.get(
            '/api/v1/website/administration/read/all/customerpermissions/by/pharmacyid',
            {
              params: {
                ...this.authData,
                pharmacyId,
              },
            }
          )
            .then((result) =>
              this.setState((p) => ({
                userList: result.data,
                createUserValues: {
                  ...p.createUserValues,
                  customerNumber: p.selectedPharmacy.customerNumber,
                },
              }))
            )
            .catch(logOut)
            .finally(() =>
              this.setState({ loadingPharmacy: false }, () => {
                M.updateTextFields();
                if (this.state.userList && !this.state.userList.length)
                  M.Autocomplete.init(
                    document.querySelectorAll('#add-additional-pharmacy'),
                    { data: this.props.pharmacyListAsObject }
                  );
              })
            );
        });
      },
      type: 'r',
    },
    postCreateCustomerPortalUser: {
      func: () => {
        const { username, firstName, lastName, title, additionalPharmacies } =
          this.state.createUserValues;
        const customerNumber = this.state.selectedPharmacy.customerNumber;

        const arr = [];
        additionalPharmacies.forEach((ap) => arr.push(ap.customerNumber));

        Axios.post(
          '/api/v1/website/administration/create/one/customerportaluser',
          {
            ...this.authData,
            username,
            firstName,
            lastName,
            title,
            additionalPharmacies: arr,
            customerNumber,
          }
        )
          .then((result) => {
            this.props.dispatch(result.data);
            this.networkCalls.getReadPortalUsers();
          })
          .catch(logOut);
      },
      type: 'c',
    },
    getReadUserActivityLog: {
      func: () => {
        Axios.get(
          '/api/v1/website/administration/read/many/activitylog/by/customerauthid',
          {
            params: {
              ...this.authData,
              customerAuthId: this.state.userContextMenu?.user?.authId,
            },
          }
        )
          .then((result) =>
            this.setState((p) => ({
              userContextMenu: {
                ...p.userContextMenu,
                clientX: 0,
                clientY: 0,
                show: false,
              },
              activityLog: {
                entries: result.data,
                show: true,
              },
            }))
          )
          .catch(logOut);
      },
      type: 'r',
    },
    postDeleteRevokeAccess: {
      func: () => {
        if (
          window.confirm(
            `Delet user: ${this.state.userContextMenu.user.username.toUpperCase()}?`
          )
        ) {
          const authId = this.state.userContextMenu.user.authId;

          Axios.post(
            '/api/v1/website/administration/delete/one/customerportaluser',
            {
              ...this.authData,
              authId,
            }
          )
            .then((result) => {
              this.props.dispatch(result.data);
              this.setState(
                {
                  userContextMenu: {
                    clientX: 0,
                    clientY: 0,
                    show: false,
                    user: null,
                  },
                },
                this.networkCalls.getReadPortalUsers
              );
            })
            .catch(logOut);
        } else
          this.setState({
            userContextMenu: {
              clientX: 0,
              clientY: 0,
              show: false,
              user: null,
            },
          });
      },
      type: 'd',
    },
    postReassignAdministratorRights: {
      func: () => {
        const authId = this.state.userContextMenu.user.authId;

        const primaryPharmacy =
          this.state.userContextMenu.user.pharmacies.filter((p) => p.isPrimary);
        const primaryPharmacyId = primaryPharmacy.length
          ? primaryPharmacy[0].id
          : -1;

        Axios.post(
          '/api/v1/website/administration/update/one/reassignadministratorrights',
          {
            ...this.authData,
            authId,
            primaryPharmacyId,
          }
        )
          .then((result) => {
            this.props.dispatch(result.data);
            this.setState(
              {
                userContextMenu: {
                  clientX: 0,
                  clientY: 0,
                  show: false,
                  user: null,
                },
              },
              this.networkCalls.getReadPortalUsers
            );
          })
          .catch(logOut);
      },
      type: 'u',
    },
    postUpdateUserPrimaryPharmacy: {
      func: () => {
        const authId = this.state.editPharmacyAccessValues?.user?.authId;
        const pharmacyId =
          this.state.editPharmacyAccessValues?.contextMenu?.pharmacy?.id;

        Axios.post(
          '/api/v1/website/administration/update/one/userprimarypharmacy',
          {
            ...this.authData,
            authId,
            pharmacyId,
          }
        )
          .then((result) => {
            this.props.dispatch(result.data);

            this.setState(
              (p) => ({
                editPharmacyAccessValues: {
                  ...p.editPharmacyAccessValues,
                  show: false,
                  contextMenu: {
                    clientX: 0,
                    clientY: 0,
                    show: false,
                    pharmacy: null,
                  },
                },
              }),
              this.networkCalls.getReadPortalUsers
            );
          })
          .catch(logOut);
      },
      type: 'u',
    },
    postUpdatePharmacyAccessList: {
      func: () => {
        const authId = this.state.editPharmacyAccessValues?.user?.authId;
        const removeList = [];
        const addList = [];

        this.state.editPharmacyAccessValues.pharmacyList.forEach((p) => {
          if (p.toBeRemoved) removeList.push(p.id);
          if (p.toBeAdded) addList.push(p.id);
        });

        Axios.post(
          '/api/v1/website/administration/update/one/userpharmacyaccesslist',
          {
            ...this.authData,
            authId,
            removeList,
            addList,
          }
        )
          .then((result) => {
            this.props.dispatch(result.data);

            this.setState(
              {
                editPharmacyAccessValues: {
                  show: false,
                  pharmacyList: null,
                  user: null,
                  contextMenu: {
                    clientY: 0,
                    clientX: 0,
                    show: false,
                    pharmacy: null,
                  },
                },
              },
              this.networkCalls.getReadPortalUsers
            );
          })
          .catch(logOut);
      },
      type: 'u',
    },
  };

  postCreateCustomerPortalUser = (e) => {
    e.preventDefault();
    this.networkCalls.postCreateCustomerPortalUser();
  };
  postUpdatePharmacyAccessList = (e) => {
    e.preventDefault();
    this.networkCalls.postUpdatePharmacyAccessList();
  };

  createUserChange = (e) => {
    const id = e.target.id.split('-')[1];
    this.setState((p) => ({
      createUserValues: {
        ...p.createUserValues,
        [id]: e.target.value,
      },
    }));
  };

  onAdditionalPharmaciesContextMenu = (e, pharmacy) => {
    e.preventDefault();
    let { clientX, clientY } = e;

    if (clientX + 300 > window.innerWidth) clientX = clientX - 280;

    this.setState({
      additionalPharmaciesContextMenu: {
        clientX,
        clientY,
        show: true,
        pharmacy,
      },
    });
  };

  onUserContextMenu = (e, user) => {
    e?.preventDefault();
    let { clientX, clientY } = e;

    if (clientX + 300 > window.innerWidth) clientX = clientX - 280;

    this.setState((p) => ({
      userContextMenu: {
        clientX,
        clientY,
        show: true,
        user,
      },
      activityLog:
        p.userContextMenu?.user?.id === user.id ? p.activityLog : null,
    }));
  };

  removeAdditionalPharmacy = () => {
    const { additionalPharmacies } = this.state.createUserValues;
    const { pharmacy } = this.state.additionalPharmaciesContextMenu;

    this.setState((p) => ({
      createUserValues: {
        ...p.createUserValues,
        additionalPharmacies: additionalPharmacies.filter(
          (ap) => ap.id !== pharmacy.id
        ),
      },
      additionalPharmaciesContextMenu: {
        clientX: 0,
        clientY: 0,
        show: false,
        pharmacy: null,
      },
    }));
  };

  removeAllAdditionalPharmacies = () => {
    this.setState((p) => ({
      createUserValues: {
        ...p.createUserValues,
        additionalPharmacies: [],
      },
      additionalPharmaciesContextMenu: {
        clientX: 0,
        clientY: 0,
        show: false,
        pharmacy: null,
      },
    }));
  };

  openEmailClient = (e) => {
    e?.preventDefault();
    window.location.href = `mailto:${this.state.userContextMenu?.user?.username}`;
    this.setState({
      userContextMenu: {
        clientX: 0,
        clientY: 0,
        show: false,
        user: null,
      },
    });
  };

  showEditPharmacyAccessList = (e) => {
    const user = JSON.parse(JSON.stringify(this.state.userContextMenu.user));
    const pharmacyList = user.pharmacies;

    this.setState({
      editPharmacyAccessValues: {
        show: true,
        pharmacyList,
        user,
      },
      userContextMenu: {
        show: false,
        clientX: 0,
        clientY: 0,
      },
    });
  };

  onPharmacyAccessListContextMenu = (e, pharmacy) => {
    e?.preventDefault();
    let { clientX, clientY } = e;

    if (clientX + 300 > window.innerWidth) clientX = clientX - 280;

    this.setState((p) => ({
      editPharmacyAccessValues: {
        ...p.editPharmacyAccessValues,
        contextMenu: {
          clientX,
          clientY,
          show: true,
          pharmacy,
        },
      },
    }));
  };

  addPharmacyToPharmacyAccessList = () => {
    const pharmacy = this.props.pharmacyList.find(
      (p) =>
        `${p.name} - ${p.customerNumber}` ===
        document.querySelector('#edit-pharmacy-access-list-add-pharmacy').value
    );
    const pharmacyList = this.state.editPharmacyAccessValues?.pharmacyList;

    pharmacyList.push({
      id: pharmacy.id,
      customerNumber: pharmacy.customerNumber,
      isPrimary: false,
      toBeAdded: true,
      name: pharmacy.name,
    });

    this.setState(
      (p) => ({
        editPharmacyAccessValues: {
          ...p.editPharmacyAccessValues,
          pharmacyList,
        },
      }),
      () =>
        (document.querySelector(
          '#edit-pharmacy-access-list-add-pharmacy'
        ).value = '')
    );
  };

  removePharmacyFromPharmacyAccessList = (e) => {
    const pharmacyList = this.state.editPharmacyAccessValues?.pharmacyList;
    const pharmacy = this.state.editPharmacyAccessValues?.contextMenu?.pharmacy;
    const index = pharmacyList.findIndex((p) => p.id === pharmacy.id);

    if (pharmacy.toBeAdded) pharmacyList.splice(index, 1);
    else pharmacyList[index].toBeRemoved = true;

    this.setState((p) => ({
      editPharmacyAccessValues: {
        ...p.editPharmacyAccessValues,
        pharmacyList,
        contextMenu: {
          clientX: 0,
          clientY: 0,
          show: false,
          pharmacy: null,
        },
      },
    }));
  };

  cancelRemovePharmacyFromPharmacyAccessList = () => {
    const pharmacyList = this.state.editPharmacyAccessValues?.pharmacyList;
    const pharmacy = this.state.editPharmacyAccessValues?.contextMenu?.pharmacy;
    const index = pharmacyList.findIndex((p) => p.id === pharmacy.id);

    delete pharmacyList[index].toBeRemoved;

    this.setState((p) => ({
      editPharmacyAccessValues: {
        ...p.editPharmacyAccessValues,
        pharmacyList,
      },
    }));
  };

  cancelEditPharmacyAccessList = () => {
    this.setState(
      {
        editPharmacyAccessValues: {
          show: false,
          user: null,
          pharmacyList: null,
        },
      },
      () =>
        (document.querySelector(
          '#edit-pharmacy-access-list-add-pharmacy'
        ).value = '')
    );
  };

  render = () => (
    <div style={{ padding: '1vw' }}>
      <div className="row">
        <div id="pharmacy-name-container" className="input-field col s12 m6">
          <input id="pharmacyName" type="text" className="autoComplete" />
          <label htmlFor="pharmacyName">
            Pharmacy Name -or- Customer Number
          </label>
        </div>
      </div>
      <div className="row">
        {this.state.loadingPharmacy ? (
          <div className="progress blue">
            <div className="indeterminate" />
          </div>
        ) : (
          <div className="col s12">
            <div className="card">
              {this.state.selectedPharmacy && this.state.userList && (
                <div className="card-content">
                  {this.state.selectedPharmacy &&
                  this.state.userList &&
                  this.state.userList.length ? (
                    <div className="row">
                      <table>
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Title</th>
                            <th>Administrator</th>
                            <th>Primary Pharmacy</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.userList?.map((u) => (
                            <tr
                              key={u.username}
                              className={
                                u.authId ===
                                  this.state.userContextMenu?.user?.authId &&
                                this.state.userContextMenu.show
                                  ? 'grey lighten-3'
                                  : ''
                              }
                              onContextMenu={(e) =>
                                this.onUserContextMenu(e, u)
                              }
                              style={{ cursor: 'default' }}
                            >
                              <td style={{ padding: '2px' }}>
                                {u.firstName} {u.lastName}
                              </td>
                              <td style={{ padding: '2px' }}>{u.title}</td>
                              <td style={{ padding: '2px' }}>
                                {u.isAdmin ? (
                                  <i
                                    className="material-icons"
                                    style={{ fontSize: '18px' }}
                                  >
                                    check
                                  </i>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td style={{ padding: '2px' }}>
                                {
                                  u.pharmacies.filter((p) => p.isPrimary)[0]
                                    ?.name
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col s12 m9">
                        <div className="row">
                          <h6>Create New Administrator</h6>
                        </div>
                        <div className="row">
                          <div className="input-field col s12 m4 l3">
                            <input
                              id="create-username"
                              type="text"
                              value={this.state.createUserValues.username}
                              onChange={this.createUserChange}
                            />
                            <label htmlFor="create-username">
                              Email Address (username)
                            </label>
                          </div>
                          <div className="input-field col s12 m2">
                            <input
                              id="create-phone"
                              type="text"
                              value={this.state.createUserValues.phone}
                              onChange={this.createUserChange}
                            />
                            <label htmlFor="create-phone">Phone</label>
                          </div>
                          <div className="input-field col s12 m4 l3">
                            <input
                              disabled
                              id="create-customerNumber"
                              type="text"
                              value={this.state.createUserValues.customerNumber}
                              onChange={this.createUserChange}
                            />
                            <label htmlFor="create-customerNumber">
                              Primary Pharmacy
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s12 m2">
                            <input
                              id="create-firstName"
                              type="text"
                              value={this.state.createUserValues.firstName}
                              onChange={this.createUserChange}
                            />
                            <label htmlFor="create-firstName">First Name</label>
                          </div>
                          <div className="input-field col s12 m2">
                            <input
                              id="create-lastName"
                              type="text"
                              value={this.state.createUserValues.lastName}
                              onChange={this.createUserChange}
                            />
                            <label htmlFor="create-lastName">Last Name</label>
                          </div>
                          <div className="input-field col s12 m2">
                            <input
                              id="create-title"
                              type="text"
                              value={this.state.createUserValues.title}
                              onChange={this.createUserChange}
                            />
                            <label htmlFor="create-title">Title</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="input-field col s12 m3">
                            <a
                              href="/"
                              className="btn-small blue white-text waves-effect waves-light col s12"
                              onClick={this.postCreateCustomerPortalUser}
                            >
                              Create User
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col s12 m3">
                        <div className="row">
                          <h6>Additional Pharmacies</h6>
                        </div>
                        <div className="row">
                          <div className="col s12">
                            <div className="card">
                              <div className="card-content">
                                <div className="row">
                                  <div className="input-field col s12">
                                    <input
                                      id="add-additional-pharmacy"
                                      type="text"
                                      className="autocomplete"
                                    />
                                    <label htmlFor="add-additional-pharmacy">
                                      Pharmacy Search
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <ul className="collection">
                                    {this.state.createUserValues.additionalPharmacies.map(
                                      (ap) => (
                                        <li
                                          key={ap.id}
                                          className={`collection-item ${
                                            ap.id ===
                                            this.state
                                              .additionalPharmaciesContextMenu
                                              .pharmacy?.id
                                              ? 'grey lighten-4'
                                              : ''
                                          }`}
                                          style={{
                                            padding: '2px',
                                            cursor: 'default',
                                          }}
                                          onContextMenu={(e) =>
                                            this.onAdditionalPharmaciesContextMenu(
                                              e,
                                              ap
                                            )
                                          }
                                        >
                                          {ap.name} - {ap.customerNumber}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        id="additional-pharmacies-context-menu"
        className={
          this.state.additionalPharmaciesContextMenu?.show ? '' : 'hide'
        }
        style={{
          zIndex: 100,
          position: 'fixed',
          top: this.state.additionalPharmaciesContextMenu?.clientY,
          left: this.state.additionalPharmaciesContextMenu?.clientX,
          border: '1px solid black',
          backgroundColor: 'white',
          boxShadow: '1px 1px 2px #ddd',
          borderRadius: '1px',
          padding: '8px',
          width: '280px',
          overflow: 'hidden',
        }}
      >
        <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
          <li>
            <a
              href="/"
              className="black-text"
              style={{
                font: 'console',
                fontSize: '.8em',
                wordWrap: 'break-word',
              }}
              onClick={(e) => e.preventDefault()}
              onMouseDown={this.removeAdditionalPharmacy}
            >
              Remove
            </a>
            <div style={{ height: '6px' }} />
            <div className="divider" />
            <div style={{ height: '6px' }} />
            <a
              href="/"
              className="black-text"
              style={{ font: 'console', fontSize: '.8em' }}
              onClick={(e) => e.preventDefault()}
              onMouseDown={this.removeAllAdditionalPharmacies}
            >
              Remove All
            </a>
          </li>
        </ul>
      </div>
      <div
        id="user-context-menu"
        className={this.state.userContextMenu?.show ? '' : 'hide'}
        style={{
          zIndex: 100,
          position: 'fixed',
          top: this.state.userContextMenu?.clientY,
          left: this.state.userContextMenu?.clientX,
          border: '1px solid black',
          backgroundColor: 'white',
          boxShadow: '1px 1px 2px #ddd',
          borderRadius: '1px',
          padding: '8px',
          width: '280px',
          overflow: 'hidden',
        }}
      >
        <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
          <li>
            <a
              href="/"
              className="black-text"
              style={{
                font: 'console',
                fontSize: '.8em',
                wordWrap: 'break-word',
              }}
              onClick={(e) => e.preventDefault()}
              onMouseDown={this.networkCalls.postDeleteRevokeAccess}
            >
              Revoke Customer Portal Access
            </a>
            {!this.state.userContextMenu?.user?.isAdmin ? (
              <React.Fragment>
                <div style={{ height: '6px' }} />
                <div className="divider" />
                <div style={{ height: '6px' }} />
                <a
                  href="/"
                  className="black-text"
                  style={{ font: 'console', fontSize: '.8em' }}
                  onClick={(e) => e.preventDefault()}
                  onMouseDown={
                    this.networkCalls.postReassignAdministratorRights
                  }
                >
                  Make Administrator
                </a>
              </React.Fragment>
            ) : null}
            <div style={{ height: '6px' }} />
            <div className="divider" />
            <div style={{ height: '6px' }} />
            {this.state.userContextMenu?.user?.isAdmin ? (
              <React.Fragment>
                <a
                  href="/"
                  className="black-text"
                  style={{ font: 'console', fontSize: '.8em' }}
                  onClick={(e) => e.preventDefault()}
                  onMouseDown={this.showEditPharmacyAccessList}
                >
                  Edit Pharmacy Access List
                </a>
                <div style={{ height: '6px' }} />
                <div className="divider" />
                <div style={{ height: '6px' }} />
              </React.Fragment>
            ) : null}
            <a
              href="/"
              className="black-text"
              style={{ font: 'console', fontSize: '.8em' }}
              onClick={(e) => e.preventDefault()}
              onMouseDown={this.networkCalls.getReadUserActivityLog}
            >
              View Recent Activity
            </a>
            <div style={{ height: '6px' }} />
            <div className="divider" />
            <div style={{ height: '6px' }} />
            <a
              href="/"
              className="black-text"
              style={{ font: 'console', fontSize: '.8em' }}
              onClick={this.openEmailClient}
            >
              Email {this.state.userContextMenu?.user?.username}
            </a>
          </li>
        </ul>
      </div>
      <div
        id="activity-log"
        className={this.state.activityLog?.show ? '' : 'hide'}
        style={{
          zIndex: 101,
          position: 'fixed',
          top: '40px',
          right: '40px',
          border: '1px solid black',
          backgroundColor: 'white',
          boxShadow: '2px 2px 3px #ccc',
          borderRadius: '1px',
          padding: '8px',
          width: '33vw',
          minWidth: '300px',
          overflowX: 'hidden',
          overflowY: 'auto',
          maxHeight: '90vh',
        }}
      >
        <div className="row">
          <div className=" col s10 offset-s1">
            <div className="row">
              <i
                className="material-icons right"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.setState((p) => ({
                    activityLog: { ...p.activityLog, show: false },
                  }))
                }
              >
                close
              </i>
            </div>
            <div className="row">
              <h6 className="center">
                {this.state.userContextMenu?.user?.firstName}{' '}
                {this.state.userContextMenu?.user?.lastName}
                {['s', 'z'].indexOf(
                  this.state.userContextMenu?.user?.lastName[
                    this.state.userContextMenu?.user?.lastName?.length
                  ]
                ) === -1
                  ? "'"
                  : "'s"}{' '}
                Activity Log
              </h6>
            </div>
            <div className="row">
              <table className="highlight" style={{ padding: '10px' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '2px' }}>Timestamp</th>
                    <th style={{ padding: '2px' }}>Page</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activityLog?.entries?.map((e) => (
                    <tr key={e.id}>
                      <td style={{ padding: '2px' }}>
                        {moment(e.timeStamp).format('MM/DD/YY hh:mm a')}
                      </td>
                      <td style={{ padding: '2px' }}>{e.route}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        id="edit-pharmacy-access-list"
        className={this.state.editPharmacyAccessValues?.show ? '' : 'hide'}
        style={{
          zIndex: 100,
          position: 'fixed',
          top: '40px',
          right: '40px',
          border: '1px solid black',
          backgroundColor: 'white',
          boxShadow: '2px 2px 3px #ccc',
          borderRadius: '1px',
          padding: '8px',
          width: '33vw',
          minWidth: '300px',
          overflowX: 'hidden',
          overflowY: 'auto',
          maxHeight: '90vh',
        }}
      >
        <div className="row">
          <div className=" col s10 offset-s1">
            <div className="row">
              <i
                className="material-icons right"
                style={{ cursor: 'pointer' }}
                onClick={this.cancelEditPharmacyAccessList}
              >
                close
              </i>
            </div>
            <div className="row">
              <h6 className="center">Pharmacy Access List</h6>
            </div>
            <div className="row">
              <div className="input-field col s11">
                <input
                  id="edit-pharmacy-access-list-add-pharmacy"
                  type="text"
                  className="autocomplete"
                />
                <label htmlFor="edit-pharmacy-access-list-add-pharmacy">
                  Pharmacy Name
                </label>
              </div>
              <div className="input-field col s1">
                <i
                  className="material-icons blue-text"
                  style={{
                    cursor: 'pointer',
                    padding: '4px 2px',
                    border: '1px solid #2196f3',
                    borderRadius: '3px',
                  }}
                  onClick={this.addPharmacyToPharmacyAccessList}
                >
                  add
                </i>
              </div>
            </div>
            <div className="row">
              <table className="highlight" style={{ padding: '10px' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '2px' }}>Name</th>
                    <th style={{ padding: '2px' }}>Customer Number</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.editPharmacyAccessValues?.pharmacyList?.map(
                    (p) => (
                      <tr
                        key={p.id}
                        className={`
                                            ${
                                              p.id ===
                                              this.state
                                                .editPharmacyAccessValues
                                                ?.contextMenu?.pharmacy?.id
                                                ? 'grey lighten-3'
                                                : ''
                                            }
                                            ${p.toBeAdded ? 'green-text' : ''}
                                            ${p.toBeRemoved ? 'red-text' : ''}
                                            `}
                        onContextMenu={(e) =>
                          this.onPharmacyAccessListContextMenu(e, p)
                        }
                        style={{ cursor: 'default' }}
                      >
                        <td style={{ padding: '2px' }}>
                          {p.name}
                          {p.isPrimary ? '*' : ''}
                        </td>
                        <td style={{ padding: '2px' }}>{p.customerNumber}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col s12 l6 offset-l6">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={this.postUpdatePharmacyAccessList}
                >
                  Save Changes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="edit-pharmacy-access-context-menu"
        className={
          this.state.editPharmacyAccessValues?.contextMenu?.show ? '' : 'hide'
        }
        style={{
          zIndex: 101,
          position: 'fixed',
          top: this.state.editPharmacyAccessValues?.contextMenu?.clientY,
          left: this.state.editPharmacyAccessValues?.contextMenu?.clientX,
          border: '1px solid black',
          backgroundColor: 'white',
          boxShadow: '1px 1px 2px #ddd',
          borderRadius: '1px',
          padding: '8px',
          width: '280px',
          overflow: 'hidden',
        }}
      >
        <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
          <li>
            {this.state.editPharmacyAccessValues?.contextMenu?.pharmacy
              ?.isPrimary ? (
              <p
                style={{
                  font: 'console',
                  fontSize: '.8em',
                  wordWrap: 'break-word',
                }}
                className="red-text"
              >
                Cannot Edit Primary Pharmacy
              </p>
            ) : null}
            {!this.state.editPharmacyAccessValues?.contextMenu?.pharmacy
              ?.isPrimary &&
            !this.state.editPharmacyAccessValues?.contextMenu?.pharmacy
              ?.toBeRemoved ? (
              <a
                href="/"
                className="black-text"
                style={{
                  font: 'console',
                  fontSize: '.8em',
                  wordWrap: 'break-word',
                }}
                onClick={(e) => e.preventDefault()}
                onMouseDown={this.removePharmacyFromPharmacyAccessList}
              >
                Remove Pharmacy
              </a>
            ) : null}
            {!this.state.editPharmacyAccessValues?.contextMenu?.pharmacy
              ?.isPrimary &&
            !this.state.editPharmacyAccessValues?.contextMenu?.pharmacy
              ?.toBeAdded &&
            !this.state.editPharmacyAccessValues?.contextMenu?.pharmacy
              ?.toBeRemoved ? (
              <React.Fragment>
                <div style={{ height: '6px' }} />
                <div className="divider" />
                <div style={{ height: '6px' }} />
                <a
                  href="/"
                  className="black-text"
                  style={{
                    font: 'console',
                    fontSize: '.8em',
                    wordWrap: 'break-word',
                  }}
                  onClick={(e) => e.preventDefault()}
                  onMouseDown={this.networkCalls.postUpdateUserPrimaryPharmacy}
                >
                  Make Primary Pharmacy
                </a>
              </React.Fragment>
            ) : null}
            {this.state.editPharmacyAccessValues?.contextMenu?.pharmacy
              ?.toBeRemoved ? (
              <React.Fragment>
                <div style={{ height: '6px' }} />
                <div className="divider" />
                <div style={{ height: '6px' }} />
                <a
                  href="/"
                  className="black-text"
                  style={{
                    font: 'console',
                    fontSize: '.8em',
                    wordWrap: 'break-word',
                  }}
                  onClick={(e) => e.preventDefault()}
                  onMouseDown={this.cancelRemovePharmacyFromPharmacyAccessList}
                >
                  Cancel Remove Pharmacy
                </a>
              </React.Fragment>
            ) : null}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default connect()(AccessTab);
