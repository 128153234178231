import React from 'react';

const ViewRequestsTab = props => (
    <div style={{ padding: '1vw' }}>
        <div className="row">
            {props.requests?.map(r => (
                <div key={r.id} className="col s12 m6 l4 xl3">
                    <div className="card" style={{ minHeight: '280px' }}>
                        <div className="card-content">
                            <div className="row">
                                <h5 className="center" style={{ margin: '0px', marginBottom: '6px' }}>{r.title?.toUpperCase()}</h5>
                                <p className="center">{r.createdBy}</p>
                                <div className="divider" />
                            </div>
                            <div className="row" style={{ minHeight: '80px' }}>
                                <p className="center bold" style={{ textDecoration: 'underline blue' }}>Description</p>
                                <p>{r.description}</p>
                            </div>
                            {r.attachments ? (
                                <div className="row">
                                    <p className="center bold" style={{ textDecoration: 'underline orange' }}>Attachments</p>
                                    {r.attachments?.map(a => <a key={a.id} href="/" className="center" onClick={e => { e.preventDefault(); props.downloadAttachment(a) }}>{a.filename}</a>)}
                                </div>
                            ) : null}
                            {r.votes ? (
                                <div className="row">
                                    <p className="col s12 center bold" style={{ textDecoration: 'underline green', marginBottom: '12px' }}>Votes</p>
                                    {r.votes.map(v => (
                                        <span
                                            key={v.id}
                                            className={`col badge white-text ${['blue', 'purple', 'pink', 'orange', 'red', 'teal', 'cyan', 'blue-grey', 'deep-purple'][(v.firstName.charCodeAt(0) + v.lastName.charCodeAt(0)) % 9]}`}
                                            data-badge-caption={`${v.firstName} ${v.lastName}`}
                                            style={{ borderRadius: '2px' }}
                                        />
                                    ))}
                                </div>
                            ) : null}
                            <div className="row">
                                <a href="/" className="white blue-text" style={{ position: 'absolute', bottom: '20px', right: '20px', fontSize: '1.1em' }} onClick={e => { e.preventDefault(); props.toggleVote(r) }}>{r.votes?.find(v => parseInt(v.id) === parseInt(props.employeeId)) ? 'REMOVE VOTE' : 'ADD VOTE'}</a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div >
    </div >
)

export default ViewRequestsTab;