import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SalesPharmacySearch from './salesPharmacySearch';
import PotentialCustomerList from './potentialCustomerList';
import PotentialCustomerDetail from './potentialCustomerDetail';

const InsideSalesRouter = props => (
    <Switch>
        <Route exact path="/sales" component={PotentialCustomerList} />
        <Route exact path="/sales/search" component={SalesPharmacySearch} />
        <Route exact path="/sales/potentialcustomer/:id" component={PotentialCustomerDetail} />
    </Switch>
)

export default InsideSalesRouter;