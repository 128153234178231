import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TimeClock from './timeClock';
import Corrections from './corrections';
import UserOverview from './userOverview';
import ErrorTracking from './errorTracking/errorTracking';
import FeatureRequest from './featureRequest/featureRequest';

const UserRouter = props => (
    <Switch>
        <Route exact path="/user" component={UserOverview} />
        <Route exact path="/user/timeclock" component={TimeClock} />
        <Route exact path="/user/corrections" component={Corrections} />
        <Route exact path="/user/errortracking" component={ErrorTracking} />
        <Route exact path="/user/featurerequest" component={FeatureRequest} />
    </Switch>
)

export default UserRouter;