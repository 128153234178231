const actions = {
    SET_VARIABLE: 'SET_VARIABLE',
    GLOBAL_TOAST: 'GLOBAL_TOAST',
    LOG_BACK_IN: 'LOG_BACK_IN',
    SIDENAV_STATE: 'SIDENAV_STATE',
    SAVE_STATE: 'SAVE_STATE',
    SET: 'SET',
    SET_CURRENT_PATH: 'SET_CURRENT_PATH',
    SET_ACTIVE_PERMISSION: 'SET_ACTIVE_PERMISSION',
    SET_CLOCK_OUT_TIME: 'SET_CLOCK_OUT_TIME',
    TIMEOUT_WORKER_POST_MESSAGE: 'TIMEOUT_WORKER_POST_MESSAGE',
    CLEAR: 'CLEAR'
}

export default actions;