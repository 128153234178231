import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PharmacyOverview from './pharmacyOverview';
import CreatePharmacy from './createPharmacy';
import UpdatePharmacy from './updatePharmacy';
import PharmacyDetail from './pharmacyDetail/pharmacyDetail';
import PharmacyAccountsDetail from './pharmacyAccountsDetail';
import InvoiceTemplate from './invoiceTemplate';

const PharmacyRouter = props => (
    <Switch>
        <Route exact path="/pharmacy" component={PharmacyOverview} />
        <Route exact path="/pharmacy/create" component={CreatePharmacy} />
        <Route exact path="/pharmacy/:id" component={PharmacyDetail} />
        <Route exact path="/pharmacy/:id/update" component={UpdatePharmacy} />
        <Route exact path="/pharmacy/:id/accounts" component={PharmacyAccountsDetail} />
        <Route exact path="/pharmacy/:id/invoicetemplate" component={InvoiceTemplate} />
    </Switch>
)

export default PharmacyRouter;