import React from "react";
import { Switch, Route } from 'react-router-dom';
import RecallOverview from './recallOverview';
import CreateRecall from './createRecall';
import UpdateRecall from './updateRecall';

const RecallRouter = props => (
    <Switch>
        <Route exact path="/recall" component={RecallOverview} />
        <Route exact path="/recall/create" component={CreateRecall} />
        <Route exact path="/recall/:id" component={UpdateRecall} />
    </Switch>
)

export default RecallRouter;