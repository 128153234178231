import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../func';
import moment from 'moment';
import Axios from 'axios';

class TimeClock extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.clearCurrentTimeInterval = 0;
        this.ip = 0;
        this.state = this.initState();
        this.timeoutWorker = new SharedWorker('/workers/timeoutWorker.js', 'ORX_TOOLS_SHARED_TIMEOUT_WORKER');
        this.timeoutWorker.port.start();
    }

    initState = () => ({
        referenceDate: moment('2021-11-21'),
        from: moment(),
        to: moment(),
        currentTime: '',
        loadingLastEntry: true,
        timeClockEntries: null,
        lastTimeClock: null,
        grandTotal: 0,
        previousDurationForToday: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getIpAddress();
        this.getCurrentTime();
        this.networkCalls.getLastEntry();
        this.onDayClick(moment().format('YYYY-MM-DD'), false)
    }

    componentWillUnmount = () => {
        clearInterval(this.clearCurrentTimeInterval)
    }

    networkCalls = {
        postNewEntry: {
            func: (isClockOut) => {
                Axios.post('/api/v1/user/create/one/timeclock', {
                    ...this.authData,
                    ip: this.ip
                })
                    .then(result => {
                        this.props.dispatch(result.data);

                        if (isClockOut) {
                            this.props.dispatch({ type: 'CLEAR' })
                            this.timeoutWorker.port.postMessage({ type: 'cancel' });
                            localStorage.clear();
                            setTimeout(() => this.props.history.replace('/'), 1500);
                        }
                        else {
                            this.networkCalls.getLastEntry();
                            this.onDayClick(moment().format('YYYY-MM-DD'), true)
                        }
                    })
                    .catch(logOut)
            },
            type: 'c'
        },
        getLastEntry: {
            func: () => {
                this.setState({ loadingLastEntry: true }, () => {
                    Axios.get('/api/v1/user/read/one/lasttimeclock', {
                        params: this.authData
                    })
                        .then(result => this.setState({ lastTimeClock: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loadingLastEntry: false }))
                })
            },
            type: 'r'
        },
        getUserTimeClockRange: {
            func: (from, to, isClockIn) => {
                this.setState({ from, to }, () => {
                    Axios.get('/api/v1/user/read/all/usertimeclockrange', {
                        params: {
                            ...this.authData,
                            start: from.format('YYYY-MM-DD 00:00:00'),
                            end: to.format('YYYY-MM-DD 00:00:00'),
                        }
                    })
                        .then(result => {
                            this.calculateTotals(result.data)
                            if (isClockIn)
                                this.startClockoutTimer(result.data);
                        })
                        .catch(logOut)
                })
            },
            type: 'r'
        }
    }

    startClockoutTimer = allPunches => {
        const start = moment().hours(5).minutes(0).seconds(0);
        const end = start.clone().add(19, 'hours');
        const todayPunches = allPunches.filter(row => moment(row.timeStamp).isBetween(start, end));

        if (!todayPunches.length || todayPunches.length % 2 === 0) { return }

        let millisecondsWorked = 0;

        for (let i = 0; i < todayPunches.length - 1; i += 2) {
            const diff = moment(todayPunches[i + 1].timeStamp).diff(moment(todayPunches[i + 1].timeStamp), 'milliseconds');
            millisecondsWorked += diff;
        }

        millisecondsWorked += moment.utc().diff(moment.utc(todayPunches[todayPunches.length - 1].timeStamp), 'milliseconds');

        const millisecondsRemainingInWorkday = 28800000 - millisecondsWorked;

        this.timeoutWorker.port.postMessage({ type: 'clock-in', millisecondsRemainingInWorkday })
    }

    getIpAddress = () => {
        Axios.get('https://www.cloudflare.com/cdn-cgi/trace')
            .then(result => {
                const arr = result.data.toString().match(/ip=[^\n]*/i)
                const ip = arr[0].split('=')[1];
                this.ip = ip;
            })
    }

    getCurrentTime = () => {
        this.clearCurrentTimeInterval = setInterval(() => {
            this.setState({ currentTime: moment().format('dddd, MMM D, YYYY HH:mm:ss') })
        }, 1000);
    }



    onDayClick = (date, isClockIn) => {
        date = moment(date).hours(0).minutes(0).seconds(0).milliseconds(0);
        let diff = date.diff(this.state.referenceDate, 'days');
        let from;

        if (diff >= 0)
            from = date.subtract(diff % 14, 'days');
        else {
            if (diff === -0)
                from = date;
            else
                from = date.add(-14 - (diff % 14), 'days');
        }

        const to = from.clone();
        to.add(13, 'days');

        this.networkCalls.getUserTimeClockRange(from, to, isClockIn);
    }

    calculateTotals = timeClockEntries => {
        const arr = [];
        let lastAction = '';
        let warning = false;
        let grandTotal = 0;

        timeClockEntries.forEach((entry, index) => {
            if (entry.action === lastAction) {
                warning = true;
            }

            lastAction = entry.action;

            if (index !== 0 && index % 2 === 1) {
                const diff = moment(entry.timeStamp).diff(moment(timeClockEntries[index - 1].timeStamp), 'minutes');
                grandTotal += Math.abs(diff);
                const duration = moment.duration(diff, 'minutes');
                arr.push({ ...entry, duration: `${duration.hours()}:${duration.minutes().toString().padStart(2, '0')}` })
            }
            else
                arr.push(entry);
        })

        if (warning)
            this.setState({ warning, timeClockEntries })
        else
            this.setState({ timeClockEntries: arr, grandTotal: moment.duration(grandTotal, 'minutes') });
    }

    getButtonText = () => {
        if (!this.state.lastTimeClock)
            return 'CLOCK IN';
        else if (Math.abs(moment().diff(moment.utc(this.state.lastTimeClock.timeStamp), 'hours')) >= 8)
            return 'CLOCK IN';
        else if (this.state.lastTimeClock.action.toLowerCase() === 'out')
            return 'CLOCK IN';
        else
            return 'CLOCK OUT'
    }

    createNewEntry = e => {
        e?.preventDefault();

        let isClockOut;
        if (!this.state.lastTimeClock)
            isClockOut = false;
        else if (Math.abs(moment().diff(moment.utc(this.state.lastTimeClock.timeStamp), 'hours')) >= 8)
            isClockOut = false;
        else if (this.state.lastTimeClock.action.toLowerCase() === 'out')
            isClockOut = false;
        else
            isClockOut = true;

        this.networkCalls.postNewEntry(isClockOut);
    }


    getClockedInDuration = () => {
        if (!this.state.lastTimeClock)
            return '';

        let previousDurationForToday = this.state.previousDurationForToday;

        if (!previousDurationForToday)
            previousDurationForToday = this.getPreviousDurationForToday();

        const { action } = this.state.lastTimeClock;

        if (action.toLowerCase() === 'in') {
            const duration = moment.duration(moment().diff(moment.utc(this.state.lastTimeClock.timeStamp), 'minutes'), 'minutes');

            if (previousDurationForToday)
                duration.add(previousDurationForToday.asSeconds(), 'seconds');

            return (
                <div>
                    <h4 className="center">You Are Clocked <span className="green-text text-darken-2"><b>IN</b></span></h4>
                    <h5 className="center">Current Duration: <span className="orange-text" style={{ fontWeight: '700' }}>{duration.hours()}</span> Hours <span className="orange-text" style={{ fontWeight: '700' }}>{duration.minutes()}</span> Minutes</h5>
                </div>
            )
        }
        else
            return <h5 className="center">You are Clocked <span className="red-text"><b>OUT</b></span></h5>
    }

    getPreviousDurationForToday = () => {
        if (!this.state.timeClockEntries) { return null }

        const entriesFromToday = JSON.parse(JSON.stringify(this.state.timeClockEntries)).filter(row => moment.utc(row.timeStamp).dayOfYear() === moment().dayOfYear());
        const duration = moment.duration();

        for (let i = 0; i < entriesFromToday.length - 1; i++) {
            if (entriesFromToday[i].action === 'in') {
                const diff = moment(entriesFromToday[i + 1].timeStamp).diff(entriesFromToday[i].timeStamp, 'seconds');
                duration.add(diff, 'seconds');
            }
        }
        return duration;
    }

    getGrandTotal = () => {
        if (!this.state.lastTimeClock || this.state.lastTimeClock.action.toLowerCase() === 'out')
            return this.state.grandTotal.hours() + ':' + this.state.grandTotal.minutes().toString().padStart(2, '0');


        const currentDuration = moment.duration(moment().diff(moment.utc(this.state.lastTimeClock.timeStamp), 'minutes'), 'minutes');
        currentDuration.add(this.state.grandTotal.asMinutes(), 'minutes');

        return currentDuration.hours() + ':' + currentDuration.minutes().toString().padStart(2, '0');

    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    < i className="material-icons">chevron_right</i>
                    <span className="grey-text">User Time Clock</span>
                </div>
            </div>
            <div className="row">
                <div className="col s3">
                    <div className="row">
                        <DayPicker
                            onDayClick={this.onDayClick}
                            selectedDays={[{ from: new Date(this.state.from.format('YYYY, MM, DD')), to: new Date(this.state.to.format('YYYY, MM, DD')) }]}
                            modifiers={{ start: new Date(this.state.from.format('YYYY, MM, DD')), end: new Date(this.state.to.format('YYYY, MM, DD')) }}
                        />
                    </div>
                    <div className="row">
                        <h5>Pay Period Summary</h5>
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Time</th>
                                    <th>Clock In/Out</th>
                                    <th>Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.timeClockEntries?.map(t => (
                                    <tr key={t.id}>
                                        <td style={{ padding: '2px' }}>{moment.utc(t.timeStamp).utcOffset(new Date().getTimezoneOffset() * -1).format('dddd MMM D')}</td>
                                        <td style={{ padding: '2px' }}>{moment.utc(t.timeStamp).utcOffset(new Date().getTimezoneOffset() * -1).format('h:mm a').toUpperCase()}</td>
                                        <td style={{ padding: '2px' }}>{t.action?.toUpperCase()}</td>
                                        <td style={{ padding: '2px' }}>{t.duration}</td>
                                    </tr>
                                ))}
                                {this.state.grandTotal ? (
                                    <tr style={{ border: '2px black solid' }}>
                                        <td style={{ padding: '2px' }}></td>
                                        <td style={{ padding: '2px' }}></td>
                                        <td style={{ padding: '2px', fontWeight: 'bold' }}>TOTAL</td>
                                        <td style={{ padding: '2px', fontWeight: 'bold' }}>{this.getGrandTotal()}</td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col s6">
                    <div className="row">
                        <h3 className="blue-grey-text text-darken-1 center">{this.state.currentTime}</h3>
                    </div>
                    <div className="row">
                        {this.getClockedInDuration()}
                    </div>
                    {this.state.warning ?
                        <div className="row">
                            <h3 className="red white-text center" style={{ padding: '18px' }}>There is a problem with your time card. Please see your manager.</h3>
                            <h5 className="center red-text">If your manager is unavailable you can still clock in and out like normal. See them as soon as you are able.</h5>
                        </div>
                        : null}
                    <div className="row">
                        {!this.state.loadingLastEntry &&
                            <div className="input-field col s4 offset-s4">
                                <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.createNewEntry}>{this.getButtonText()}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(TimeClock);