import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { logOut, openSideNav, registerRoutes } from '../../func';
import BackgroundLoader from '../../layout/backgroundLoader';

class RegularOrderOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'))
        this.state = this.loadState();
    }

    initState = () => ({
        loading: false,
        orders: null,
        orderNumber: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.loadState();
        this.getOrders();
    }

    componentWillUnmount = () => {
        this.saveState();
    }

    loadState = () => {
        if (this.props.loadedState)
            return { ...this.props.loadedState, preloaded: true }
        else
            return this.initState();
    }

    saveState = () => {
        const orders = JSON.parse(JSON.stringify(this.state.orders));

        this.props.dispatch({
            type: 'SAVE_STATE',
            payload: {
                url: this.props.match.path,
                state: {
                    orders
                }
            }
        })
    }

    networkCalls = {
        getOrders: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/order/header/read/all/sorterlist', {
                        params: this.authData
                    })
                        .then(result => this.setState({ orders: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        goTo: {
            func: e => {
                if (e.keyCode === 13) {
                    Axios.get('/api/v1/order/header/read/one/by/ordernumber', {
                        params: {
                            ...this.authData,
                            orderNumber: this.state.orderNumber?.trim()
                        }
                    })
                        .then(result => {
                            this.props.dispatch({type: 'SET', payload: {orderNumber: this.state.orderNumber.trim()}})
                            this.props.history.push({ pathname: `/regular/${result.data}`, state: { orderNumber: this.state.orderNumber?.trim() } })
                        })
                        .catch(logOut)
                }
            },
            type: 'r'
        }
    }

    getOrders = () => this.networkCalls.getOrders();
    goTo = e => this.networkCalls.goTo(e);


    onChange = e => this.setState({ [e.target.id]: e.target.value })

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Regular Orders</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Regular Orders</h5>
            </div>
            <div className="row">
                <div className="col s12 m2 row">
                    <Link to={{ pathname: "/warehouse/manager/regular/0", state: { tab: 'create' } }} className="btn-small col s12 blue white-text">Create New Order</Link>
                </div>
                <div className="col s12 m2 offset-m8 row">
                    <div className="input-field col s12">
                        <input id="orderNumber" type="text" onChange={this.onChange} value={this.state.goToOrderNumber} onKeyUp={this.goTo} />
                        <label htmlFor="orderNumber">Go To Order Number:</label>
                    </div>
                </div>
            </div>
            {this.state.loading && !this.state.preloaded ?
                <div className="progress white">
                    <div className="indeterminate blue" />
                </div>
                :
                <div className="row">
                    {this.state.preloaded && this.state.loading && (
                        <BackgroundLoader />
                    )}
                    <table>
                        <thead>
                            <tr>
                                <th>Pharmacy</th>
                                <th>Order Number</th>
                                <th>Order Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.orders && this.state.orders.map(o => (
                                <tr key={`order-${o.orderNumber}`}>
                                    <td style={{ padding: '5px' }}>{o.pharmacyName}</td>
                                    <td style={{ padding: '5px' }}>{o.orderNumber}</td>
                                    <td style={{ padding: '5px' }}>{moment(o.orderDate).format('MM/DD/YY')}</td>
                                    <td style={{ padding: '5px' }}>{o.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    const loadedState = state.stateByPage['/regular'] || null;
    return { loadedState }
}

export default connect(mapStateToProps)(RegularOrderOverview);