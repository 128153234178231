import moment from "moment";
import { Fragment, useEffect, useState } from 'react';
const pageId = crypto.randomUUID();

const GeneralTab = props => {
    const {
        med,
        passEarliestReturnDateToParent,
        passRecallToParent,
        formValues
    } = props;

    const [returnPolicyHighlights, setReturnPolicyHighlights] = useState({
        sealedOnly: false,
        minPackagePercentage: false,
        controlsAllowed: false,
        tooFarExpired: false,
        daysUntilExpiry: '',
        indate: false,
        calculatedWindow: '',
        calculatedMinPack: ''
    })

    const [isRecall, setIsRecall] = useState(false);

    const setRowHeights = () => {
        [
            'manufacturer',
            'returnHandler',
            'deaClass',
            'packSize',
            'liquidSize',
            'unit',
            'awp',
            'dateCreated',
            'returnPolicy',
            'rpIsReturnable',
            'rpWindow',
            'rpSealedOnly',
            'rpMinPercentage',
            'rpLabelsAllowed',
            'rpControlsAllowed',
            'recall'
        ].forEach(id => {
            const label = document.querySelector(`#label-${id}-${pageId}`);
            const data = document.querySelector(`#${id}-${pageId}`);

            if (label && data) {
                label.setAttribute('height', 'auto');
                data.setAttribute('height', 'auto');

                const height = label.clientHeight > data.clientHeight ? label.clientHeight : data.clientHeight;

                label.setAttribute('height', height);
                data.setAttribute('height', height);
            }
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setRowHeights);
        return () => window.removeEventListener('resize', setRowHeights);
    }, [])

    useEffect(setRowHeights, [med, isRecall])

    useEffect(() => {
        setReturnPolicyHighlights({
            sealedOnly: false,
            minPackagePercentage: false,
            controlsAllowed: false,
            tooFarExpired: false,
            indate: false,
            daysUntilExpiry: '',
        })

        passEarliestReturnDateToParent(undefined);

        const returnPolicy = med?.returnPolicy;

        if (returnPolicy) {

            if (formValues.expiration
                && returnPolicy.earliestReturnInMonths !== null && returnPolicy.earliestReturnInMonths !== undefined
                && returnPolicy.latestReturnInMonths !== null && returnPolicy.latestReturnInMonths !== undefined) {
                const expiration = moment(formValues.expiration).add(1, 'day');
                const earliestReturnInMonths = expiration.clone().subtract(returnPolicy.earliestReturnInMonths, 'months');
                const latestReturnInMonths = expiration.clone().add(returnPolicy.latestReturnInMonths, 'months');
                const calculatedWindow = `(${earliestReturnInMonths.format('MM/DD/YY')} - ${latestReturnInMonths.format('MM/DD/YY')})`

                if (moment().isBefore(earliestReturnInMonths))
                    setReturnPolicyHighlights(p => ({ ...p, indate: true }))

                if (moment().isAfter(latestReturnInMonths))
                    setReturnPolicyHighlights(p => ({ ...p, tooFarExpired: true }))

                const daysUntilExpiry = latestReturnInMonths.diff(moment(), 'days')

                setReturnPolicyHighlights(p => ({ ...p, calculatedWindow, daysUntilExpiry }))
                passEarliestReturnDateToParent(earliestReturnInMonths.format('YYYY-MM-DD'))
            }

            if (returnPolicy.sealedPackagesOnly && (!parseInt(formValues.fullPackage) || formValues.partialPackage))
                setReturnPolicyHighlights(p => ({ ...p, sealedOnly: true }))

            if (returnPolicy.minPackagePercentage !== null && returnPolicy.minPackagePercentage !== undefined) {
                const calculatedMinPack = Math.ceil(parseFloat(returnPolicy.minPackagePercentage) * parseInt(med.packSize))
                const calculatedTotalMed = (parseInt(formValues.fullPackage || 0) * parseInt(med.packSize) + parseFloat(formValues.partialPackage || 0));

                if (calculatedTotalMed < calculatedMinPack)
                    setReturnPolicyHighlights(p => ({ ...p, minPackagePercentage: true }))

                setReturnPolicyHighlights(p => ({ ...p, calculatedMinPack }))
            }

            if (!returnPolicy.controlsAllowed && med.deaClass && med.deaClass.toLowerCase() !== 'r')
                setReturnPolicyHighlights(p => ({ ...p, controlsAllowed: true }))
        }
    }, [formValues, med, passEarliestReturnDateToParent])

    useEffect(() => {
        setIsRecall(false);

        if (med) {
            const { recalls } = med;
            const { lotNumber, expiration } = formValues;

            if (recalls && (lotNumber || expiration)) {
                recalls.forEach(recall => {
                    if (recall.lotNumber && lotNumber && recall.lotNumber.toLowerCase() === lotNumber.toLowerCase()) {
                        setRowHeights();
                        return setIsRecall(true);
                    }

                    if (recall.expirationStart && recall.expirationEnd && expiration && moment(expiration).isBetween(moment(recall.expirationStart), moment(recall.expirationEnd), 'day', '[]')) {
                        return setIsRecall(true);
                    }
                })
            }
        }
    }, [formValues, med])

    useEffect(() => {
        passRecallToParent(isRecall);
    }, [isRecall, passRecallToParent])

    return (
        <div style={{ padding: '1vh' }}>
            {med ?
                <Fragment>
                    <div className="col s4 l3">
                        <table>
                            <tbody>
                                <tr><td id={`label-manufacturer-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Manufacturer</td></tr>
                                <tr><td id={`label-returnHandler-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Return Handler</td></tr>
                                <tr><td id={`label-deaClass-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>DEA Class</td></tr>
                                <tr><td id={`label-packSize-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Pack Size</td></tr>
                                <tr><td id={`label-unit-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Unit</td></tr>
                                <tr><td id={`label-liquidSize-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Liquid Size</td></tr>
                                <tr><td id={`label-awp-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>AWP</td></tr>
                                <tr><td id={`label-dateCreated-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Date Created</td></tr>
                                {isRecall ?
                                    <tr><td id={`label-recall-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Recall</td></tr>
                                    :
                                    <tr><td id={`label-returnPolicy-${pageId}`} className="bold" style={{ padding: '0px', textAlign: 'right' }}>Return Policy</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col s8 l9">
                        <table>
                            <tbody>
                                <tr><td id={`manufacturer-${pageId}`} style={{ padding: '2px' }}>{props.med?.manufacturer.name || '\xa0'}</td></tr>
                                <tr><td id={`returnHandler-${pageId}`} style={{ padding: '2px' }}>{props.med?.manufacturer?.returnHandler?.name || '\xa0'}</td></tr>
                                <tr><td id={`deaClass-${pageId}`} style={{ padding: '2px' }}>{props.med?.deaClass || '\xa0'}</td></tr>
                                <tr><td id={`packSize-${pageId}`} style={{ padding: '2px' }}>{props.med?.packSize || '\xa0'}</td></tr>
                                <tr><td id={`unit-${pageId}`} style={{ padding: '2px' }}>{props.med?.unit.unit || '\xa0'}</td></tr>
                                <tr><td id={`liquidSize-${pageId}`} style={{ padding: '2px' }}>{props.med?.liquidSize || '\xa0'}</td></tr>
                                <tr><td id={`awp-${pageId}`} style={{ padding: '2px' }}>{props.med?.awp || '\xa0'}</td></tr>
                                <tr><td id={`dateCreated-${pageId}`} style={{ padding: '0px' }}>{props.med?.dateCreated ? moment(props.med.dateCreated).format('MM/DD/YYYY h:mm A') : '\xa0'}</td></tr>
                                {isRecall ?
                                    <tr>
                                        <td id={`recall-${pageId}`} className="purple white-text" style={{ padding: '2px' }}>RECALLED</td>
                                    </tr>
                                    :
                                    props.med.returnPolicy ?
                                        <tr>
                                            {typeof props.med.returnPolicy === 'string' ?
                                                <td
                                                    id={`returnPolicy-${pageId}`}
                                                    style={{ padding: '0px', margin: '0px' }}
                                                    className="red-text bold"
                                                >{props.med.returnPolicy}</td>
                                                :
                                                <td id={`returnPolicy-${pageId}`} style={{ padding: '0px', margin: '0px' }}>
                                                    <div className="col s4">
                                                        <table>
                                                            <tbody>
                                                                <tr><td id={`label-rpIsReturnable-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Returnable</td></tr>
                                                                <tr><td id={`label-rpWindow-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Window</td></tr>
                                                                <tr><td id={`label-rpSealedOnly-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Sealed Only</td></tr>
                                                                <tr><td id={`label-rpMinPercentage-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Min Package Percentage</td></tr>
                                                                <tr><td id={`label-rpLabelsAllowed-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Labels Allowed</td></tr>
                                                                <tr><td id={`label-rpControlsAllowed-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Controls Allowed</td></tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col s8">
                                                        < table >
                                                            <tbody>
                                                                <tr><td id={`rpIsReturnable-${pageId}`} className={props.med.returnPolicy.isReturnable ? '' : 'orange bold lighten-2'} style={{ padding: '2px' }}>{props.med?.returnPolicy?.isReturnable ? 'Yes' : 'No'}</td></tr>
                                                                <tr><td id={`rpWindow-${pageId}`} className={returnPolicyHighlights.indate ? 'teal bold lighten-2' : returnPolicyHighlights.tooFarExpired ? 'orange bold lighten-2' : ''} style={{ padding: '2px' }}>{props.med?.returnPolicy ? `${props.med.returnPolicy.earliestReturnInMonths} - ${props.med.returnPolicy.latestReturnInMonths}` : '\xa0'}<span style={{ marginLeft: '12px' }}>{returnPolicyHighlights.calculatedWindow}</span><span className="right red white-text bold" style={{ padding: '0px 8px' }}>{returnPolicyHighlights.daysUntilExpiry !== '' && returnPolicyHighlights.daysUntilExpiry > -1 && returnPolicyHighlights.daysUntilExpiry < 32 ? `Not returnable in ${returnPolicyHighlights.daysUntilExpiry} days.` : ''}</span></td></tr>
                                                                <tr><td id={`rpSealedOnly-${pageId}`} className={returnPolicyHighlights.sealedOnly ? 'orange bold lighten-2' : ''} style={{ padding: '2px' }}>{props.med?.returnPolicy?.sealedPackagesOnly ? 'Yes' : 'No'}</td></tr>
                                                                <tr><td id={`rpMinPercentage-${pageId}`} className={returnPolicyHighlights.minPackagePercentage ? 'orange bold lighten-2' : ''} style={{ padding: '2px' }}>{props.med?.returnPolicy ? props.med.returnPolicy.minPackagePercentage * 100 : ''}%<span style={{ marginLeft: '12px' }}>({returnPolicyHighlights.calculatedMinPack})</span></td></tr>
                                                                <tr><td id={`rpLabelsAllowed-${pageId}`} style={{ padding: '2px' }}>{props.med?.returnPolicy?.labelsAllowed ? 'Yes' : 'No'}</td></tr>
                                                                <tr><td id={`rpControlsAllowed-${pageId}`} className={returnPolicyHighlights.controlsAllowed ? 'orange bold lighten-2' : ''} style={{ padding: '2px' }}>{props.med?.returnPolicy?.controlsAllowed ? 'Yes' : 'No'}</td></tr>
                                                            </tbody>
                                                        </table >
                                                    </div >
                                                </td >
                                            }
                                        </tr >
                                        : null}
                            </tbody >
                        </table >
                    </div >
                </Fragment>
                :
                <span>Med Not Found</span>
            }
        </div >
    )
}

export default GeneralTab;