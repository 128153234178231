import Axios from 'axios';
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import moment from "moment";
import { IconButton, Input, TableSelect, CheckBox, TextArea } from "../../../../layout/form";
import M from '@materializecss/materialize';
import { logOut, registerRoutes } from '../../../../func';
const componentId = '_' + crypto.randomUUID();

const AddCommissionRate = props => {
    const {
        path,
        employeeId,
        setIsCreating,
        dispatch
    } = props;

    const [values, setValues] = useState({
        effectiveDate: moment().format('YYYY-MM-DD'),
        commissionType: 'Simple',
        paymentIntervalInMonths: '',
        payOnNewAccountsOnly: false,
        commissionMinimumValue: '0.00',
        commissionRate: '0',

    })

    const [saving, setSaving] = useState(false);

    const networkCalls = registerRoutes({
        save: {
            func: values => {
                setSaving(true);
                Axios.post('/api/v2/employee/create/one/commissionrate', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    ...values
                })
                    .then(result => dispatch(result.data))
                    .catch(logOut)
                    .finally(() => setSaving(false))
            },
            type: 'c'
        }
    }, path)

    const onChange = ({ target }) => setValues(p => ({ ...p, [target.id]: target.value }))
    const onCheckboxChange = ({ target }) => setValues(p => ({ ...p, [target.id]: !p[target.id] }))

    const cancel = () => setIsCreating(false);

    const save = () => {
        ref.current.networkCalls.save({
            employeeId,
            effectiveDate: values.effectiveDate,
            commissionType: values.commissionType?.toLowerCase(),
            paymentIntervalInMonths: values.paymentIntervalInMonths,
            payOnNewAccountsOnly: values.payOnNewAccountsOnly,
            commissionMinimumValue: values.commissionMinimumValue,
            commissionRate: values.commissionRate,
            notes: values.notes || null
        })
    }

    const getOptions = () => (['Simple', 'Bonus'])

    useLayoutEffect(() => {
        const removeTooltips = () => document.querySelectorAll(`.tooltipped.${componentId}`).forEach(tt => M.Tooltip.getInstance(tt)?.destroy());
        const removeSelect = () => document.querySelectorAll(`select.${componentId}`).forEach(sel => M.FormSelect.getInstance(sel)?.destroy());

        return () => {
            removeTooltips();
            removeSelect();
        }
    }, [])

    useEffect(() => {
        const initTooltips = () => M.Tooltip.init(document.querySelectorAll(`.tooltipped.${componentId}`), { enterDelay: 500 })
        const initSelect = () => M.FormSelect.init(document.querySelectorAll(`select.${componentId}`));

        initTooltips();
        initSelect();
    })

    useEffect(() => {
        if (values.commissionType.toLowerCase() === 'simple')
            setValues(p => ({ ...p, payOnNewAccountsOnly: false }))
    }, [values.commissionType])

    const ref = useRef({
        networkCalls
    })

    return (
        <div style={{ padding: '2vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <div className="row">
                        <table>
                            <tbody>
                                <tr><td style={{ padding: '4px' }}>Effective Date</td></tr>
                                <tr><td style={{ padding: '4px' }}>Commission Type</td></tr>
                                <tr><td style={{ padding: '4px' }}>Payment Interval (mos.)</td></tr>
                                <tr><td style={{ padding: '4px' }}>Pay on New Accounts Only</td></tr>
                                <tr><td style={{ padding: '4px' }}>Minimum Value</td></tr>
                                <tr><td style={{ padding: '4px' }}>Commission Percentage Rate</td></tr>
                                <tr><td style={{ padding: '4px' }}>Notes</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        {saving ?
                            <span className='fade-in-fade-out blue-text'><i>SAVING</i></span>
                            :
                            <>
                                <IconButton icon="cancel" classes={componentId} tooltip="Cancel" onClick={cancel} disabled={saving} />
                                <IconButton icon="save" classes={componentId} tooltip="Save" onClick={save} disabled={saving} />
                            </>
                        }
                    </div>
                </div>
                <div className="col s8 m9 l10">
                    <table>
                        <tbody>
                            <Input id="effectiveDate" type="date" value={values.effectiveDate} onChange={onChange} col="s12 m3 l2" />
                            <TableSelect id="commissionType" options={getOptions()} value={values.commissionType} onChange={onChange} col="s12 m4 l3" />
                            <Input id="paymentIntervalInMonths" type="text" value={values.paymentIntervalInMonths} onChange={onChange} col="s6 m2 l1" />
                            <CheckBox id="payOnNewAccountsOnly" value={values.payOnNewAccountsOnly} onChange={onCheckboxChange} col="s12" />
                            <Input id="commissionMinimumValue" type="text" value={values.commissionMinimumValue} onChange={onChange} col="s8 m3 l2" />
                            <Input id="commissionRate" type="text" value={values.commissionRate} onChange={onChange} col="s8 m3 l2" />
                            <TextArea id="notes" value={values.notes} onChange={onChange} col="s12 m8 l5" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AddCommissionRate;