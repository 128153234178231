import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';

class IndateSort extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
        this.clear = 0;
    }

    initState = () => ({
        barcode: '',
        msg: '',
        indateDetail: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        document.getElementById('barcode').focus();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelector('#barcode').addEventListener('keyup', this.enterListener);
    }

    removeListeners = () => {
        document.querySelector('#barcode').removeEventListener('keyup', this.enterListener)
    }

    enterListener = e => {
        const { keyCode } = e;
        if (keyCode === 13)
            this.search();
    }

    networkCalls = {
        search: {
            func: () => {
                if (this.clear)
                    clearTimeout(this.clear);

                this.setState({ indateDetail: null }, () => {
                    Axios.get('/api/v2/order/detail/read/one/indate/by/barcode', {
                        params: {
                            ...this.authData,
                            indateBarcode: this.state.barcode
                        }
                    })
                        .then(result => {
                            this.setState({ indateDetail: result.data }, () => {
                                this.clear = setTimeout(() => {
                                    this.setState({ indateDetail: null })

                                }, 5000);
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ barcode: '' }))
                })
            },
            type: 'r'
        }
    }

    search = () => this.networkCalls.search();

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/indate">Indates</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Indate Sorting</span>
                </div>
            </div>
            <div className="row">
                <div className="col s16 m4" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="barcode">Barcode</label>
                    <input id="barcode" className="browser-default col s12" type="text" onChange={this.onChange} value={this.state.barcode} />
                </div>
            </div>
            <div className="row">
                <h1 style={{ fontSize: '8em' }}>{this.state.indateDetail && moment(this.state.indateDetail.earliestReturnDate).format('MMM YYYY')}</h1>
            </div>
        </div>
    )
}

export default connect()(IndateSort);