import actions from './actions';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Axios from 'axios';
import M from '@materializecss/materialize';

const reducer = (store = { sideNavIsOpen: true }, action) => {
    switch (action.type) {
        case actions.SET_VARIABLE: {
            const { value, name } = action.payload;
            return { ...store, [name]: value }
        }
        case actions.GLOBAL_TOAST: {
            const toastClass = action.payload.class;
            const msg = action.payload.msg;
            const symbol = action.payload.icon ? action.payload.icon.symbol : '';
            const iconClass = action.payload.icon ? action.payload.icon.class : '';

            M.toast({
                html: `<p class="${toastClass}">${msg}<i class="material-icons ${iconClass}">${symbol}</i></p>`,
                classes: action.payload.class,
            });

            return store;
        }
        case actions.LOG_BACK_IN: {
            M.Modal.getInstance(document.querySelector('#log-back-in-modal')).open();
            return store;
        }
        case actions.SIDENAV_STATE: {
            const sideNavIsOpen = action.payload.isOpen;
            return { ...store, sideNavIsOpen };
        }
        case actions.SAVE_STATE: {
            const { url, state } = action.payload;
            return { ...store, stateByPage: { ...store.stateByPage, [url]: state } }
        }
        case actions.SET: {
            return { ...store, ...action.payload }
        }
        case actions.SET_CURRENT_PATH: {
            const { currentPath } = action.payload;
            return { ...store, currentPath }
        }
        case actions.SET_ACTIVE_PERMISSION: {
            const { activePermission } = action.payload;
            return { ...store, activePermission }
        }
        case actions.SET_CLOCK_OUT_TIME: {
            return { ...store, clockOutTime: localStorage.getItem('clockOutTime') }
        }
        case actions.CLEAR: {
            return initStore();
        }
        default:
            return store;
    }
}


const initStore = () => ({
    sideNavIsOpen: true,
    stateByPage: {},
    activePermission: null,
    currentPath: '/',
    clockOutTime: null,
})

const store = process.env.NODE_ENV === 'production' ?
    createStore(reducer, initStore()) :
    createStore(reducer, initStore(), composeWithDevTools());

export default store;

export const seedStore = async (authData, dispatch) => {
    const states = await getStates(authData);
    dispatch({ type: actions.SET_VARIABLE, payload: { value: states, name: 'states' } })
    return { states }
}

const getStates = authData => new Promise((resolve) => {
    Axios.get('/api/v1/state/read/all', {
        params: authData
    })
        .then(result => resolve(result.data))
        .catch(err => resolve([]))
})