import Axios from "axios";
import React from 'react';
import { connect } from 'react-redux';
import { logOut, registerRoutes } from "../../../func";
import moment from 'moment';
import { IconButton, TextArea, CheckBox, TableSelect, Input } from "../../../layout/form";
import M from "@materializecss/materialize";

class StatusTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        statuses: null,
        creating: false,
        editing: false,
        saving: false,
        updating: false,
        deleting: false,
        values: this.initValues(),
    })

    initValues = () => ({
        status: '-1',
        isControls: false,
        notes: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.networkCalls.getStatuses();
        this.initToolTips();
    }

    componentWillUnmount = () => {
        this.removeToolTips();
    }

    initToolTips = () => {
        setTimeout(() => {
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
        }, 100)
    }

    removeToolTips = () => document.querySelectorAll('.tooltipped').forEach(el => M.Tooltip.getInstance(el)?.destroy());

    networkCalls = {
        getStatuses: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/order/status/read/many/by/orderheaderid', {
                        params: {
                            ...this.authData,
                            orderHeaderId: this.props.orderHeaderId
                        }
                    })
                        .then(result => this.setState({ statuses: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }, this.initToolTips))
                })
            },
            type: 'r'
        },
        createStatus: {
            func: () => {
                if (parseInt(this.state.values.status) === -1)
                    return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Invalid Status', class: 'red white-text' } })

                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/order/status/create/one', {
                        ...this.authData,
                        orderHeaderId: this.props.orderHeaderId,
                        isControls: this.state.values.isControls,
                        status: this.state.values.status,
                        notes: this.state.values.notes || null
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.removeToolTips();
                            this.setState({ creating: false }, () => {
                                this.networkCalls.getStatuses();
                                this.initToolTips();
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        },
        updateStatus: {
            func: () => {
                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/order/status/update/one', {
                        ...this.authData,
                        id: this.state.values.id,
                        status: this.state.values.status,
                        timeStamp: moment(this.state.values.timeStamp).format('YYYY-MM-DD HH:mm'),
                        isControls: this.state.values.isControls,
                        notes: this.state.values.notes

                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.setState({ editing: false }, this.networkCalls.getStatuses)
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ updating: false }))
                })
            },
            type: 'u'
        },
        deleteStatus: {
            func: id => {
                this.setState({ deleting: true }, () => {
                    Axios.post('/api/v1/order/status/delete', {
                        ...this.authData,
                        id
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.networkCalls.getStatuses();
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ deleting: false }))
                })
            },
            type: 'd'
        }
    }

    addStatus = () => {
        this.removeToolTips();
        this.setState({ creating: true }, this.initToolTips);
    }

    cancelCreate = () => {
        this.removeToolTips();
        this.setState({ creating: false, editing: false, values: this.initValues() }, this.initToolTips)
    }

    getStatusOptions = () => ([
        { key: -1, value: -1, display: '' },
        { key: 0, value: 'Representative On-Site', display: 'Representative On-Site' },
        { key: 1, value: 'Shipped to Outdate Rx', display: 'Shipped to Outdate Rx' },
        { key: 2, value: 'Received', display: 'Received' },
        { key: 3, value: 'Sort in Progress', display: 'Sort in Progress' },
        { key: 4, value: 'Sorted', display: 'Sorted' },
        { key: 5, value: 'Debit Memo Created', display: 'Debit Memo Created' },
        { key: 6, value: 'Shipped to Manufacturer', display: 'Shipped to Manufacturer' },
        { key: 7, value: 'Destroyed', display: 'Destroyed' }
    ])

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))
    checkBoxChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: !p.values[e.target.id] } }))

    editStatus = status => {
        this.removeToolTips();
        this.setState({
            editing: true,
            values: {
                id: status.id,
                status: status.status,
                createdBy: `${status.employee.firstName} ${status.employee.lastName}`,
                timeStamp: moment(status.timeStamp).format(moment(status.timeStamp).hours() === 0 ? 'YYYY-MM-DDT00:00' : 'YYYY-MM-DDTHH:mm'),
                isControls: status.isControls,
                notes: status.notes
            }
        })
    }

    deleteStatus = status => {
        if (window.confirm(`Delete status: ${status.employee.firstName} ${status.employee.lastName} - ${status.status}?`))
            this.networkCalls.deleteStatus(status.id);
    }

    render = () => {
        if (this.state.loading)
            return (
                <div style={{ padding: '1vw' }}>
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                </div>
            )
        else if (this.state.creating || this.state.editing)
            return (
                <div style={{ padding: '1vw' }}>
                    <div className="row">
                        <div className="col s4 m3 l2">
                            <table>
                                <tbody>
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Status</td></tr>
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Controls</td></tr>
                                    {this.state.editing ?
                                        <React.Fragment>
                                            <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Employee</td></tr>
                                            <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Time Stamp</td></tr>
                                        </React.Fragment>
                                        : null}
                                    <tr><td className="bold" style={{ padding: '4px', textAlign: 'right' }}>Notes</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col s8 m9 l10">
                            <div className="row">
                                <table>
                                    <tbody>
                                        <TableSelect id="status" value={this.state.values.status} onChange={this.onChange} options={this.getStatusOptions()} col="s12 m4" />
                                        <CheckBox id="isControls" value={this.state.values.isControls} onChange={this.checkBoxChange} col="s4 l2" />
                                        {this.state.editing ?
                                            <React.Fragment>
                                                <Input type="text" value={this.state.values.createdBy} onChange={this.onChange} disabled={true} col="s12 m5" />
                                                <Input id="timeStamp" type="datetime-local" value={this.state.values.timeStamp} onChange={this.onChange} col="s8 m4" />
                                            </React.Fragment>
                                            : null}
                                        <TextArea id="notes" value={this.state.values.notes} onChange={this.onChange} col="s12 m7" />
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                {this.state.editing ?
                                    <IconButton icon="upload" tooltip="Update Status" onClick={this.networkCalls.updateStatus} disabled={this.state.updating} />
                                    :
                                    <IconButton icon="save" tooltip="Save New Status" onClick={this.networkCalls.createStatus} disabled={this.state.saving} />
                                }
                                <IconButton icon="close" tooltip="Cancel" onClick={this.cancelCreate} disabled={this.state.saving || this.state.updating} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return (
                <div style={{ padding: '1vw' }}>
                    <div className="row">
                        <IconButton icon="add" tooltip="Add Status" onClick={this.addStatus} />
                    </div>
                    <div className="row">
                        <table>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Created By</th>
                                    <th>Time</th>
                                    <th>Controls</th>
                                    <th>Notes</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.statuses?.map(s => (
                                    <tr key={s.id}>
                                        <td style={{ padding: '2px' }}>{s.status}</td>
                                        <td style={{ padding: '2px' }}>{s.employee?.firstName} {s.employee?.lastName}</td>
                                        <td style={{ padding: '2px' }}>{moment(s.timeStamp).format(moment(s.timeStamp).hours() === 0 ? 'MM/DD/YY' : 'MM/DD/YY h:mm a')}</td>
                                        <td style={{ padding: '2px' }}><i className="material-icons" style={{ fontSize: '17px', padding: '0px', margin: '0px' }}>{s.isControls ? 'check' : ''}</i></td>
                                        <td style={{ padding: '2px' }}>{s.notes}</td>
                                        <td style={{ padding: '2px' }}>
                                            <IconButton icon="edit" onClick={() => this.editStatus(s)} tooltip="Edit Status" disabled={this.state.deleting} />
                                            <IconButton icon="delete" onClick={() => this.deleteStatus(s)} tooltip="Delete Status" disabled={this.state.deleting} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
    }
}

export default connect()(StatusTab);