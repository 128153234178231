import Axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import { seedStore } from '../../redux/store';
import { connect } from 'react-redux';
import { logOut, openSideNav, registerRoutes } from '../../func';

class UpdatePharmacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.id = parseInt(this.props.match.params.id);
        this.initProps();
    }

    initProps = async () => {
        if (!this.props.states) {
            const newProps = await seedStore(this.authData, this.props.dispatch);
            this.props = { ...this.props, newProps }
        }
    }

    initState = () => ({
        gettingPharmacy: false,
        updating: false,
        oldValues: null,
        states: null,
        values: this.initValues()
    })

    initValues = () => ({
        id: -1,
        stateId: -1,
        customerNumber: '',
        name: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        zip: '',
        phone: '',
        deaNumber: '',
        defaultFeePercentage: '',
        email: '',
        notes: '',
        isArchived: false,
        isHospital: false,
        is340b: false,
        isGov: false,
        isVa: false,
        threeFortyBNumber: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getPharmacy();
        this.getStates();
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const select = M.FormSelect.getInstance(el);
            if (select)
                select.destroy();
        })
    }

    networkCalls = {
        getPharmacy: {
            func: () => {
                this.setState({ gettingPharmacy: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/one/by/id/shallow', {
                        params: { ...this.authData, id: this.id }
                    })
                        .then(result => {
                            this.setState({ oldValues: result.data[0], values: result.data[0] }, () => {
                                M.updateTextFields();
                                M.FormSelect.init(document.querySelectorAll('select'));
                                M.textareaAutoResize(document.querySelector('#notes'));
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ gettingPharmacy: false }))
                })
            },
            type: 'r'
        },
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', {
                    params: this.authData
                })
                    .then(result => {
                        this.setState({ states: result.data }, () => {
                            M.FormSelect.init(document.querySelectorAll('select'));
                        })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        update: {
            func: () => {
                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/pharmacy/update/one', {
                        ...this.authData,
                        id: this.id,
                        ...this.state.values
                    })
                        .then(result => this.props.dispatch(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ updating: false }))
                })
            },
            type: 'u'
        }
    }

    getPharmacy = () => this.networkCalls.getPharmacy();
    getStates = () => this.networkCalls.getStates();
    update = e => { e?.preventDefault(); this.networkCalls.update(); }

    onChange = e => {
        const { id, value } = e.target;

        if (id !== 'isHospital' && id !== 'is340b' && id !== 'isGov' && id !== 'isVa')
            this.setState(p => ({ values: { ...p.values, [id]: value } }));
        else
            this.setState(p => ({ values: { ...p.values, [id]: !p.values[id] } }));
    }

    revert = e => {
        e.preventDefault();
        this.setState(p => ({ values: p.oldValues }));
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/pharmacy">Pharmacies</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/pharmacy/${this.id}`}>{this.state.oldValues ? this.state.oldValues.name : 'Pharmacy Detail'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.state.oldValues ? `Update ${this.state.oldValues.name}` : 'Update Pharmacy'}</span>
                </div>
            </div>
            <div className="row">
                <h4>Update <span className="orange-text">{this.state.oldValues ? this.state.oldValues.name : <div className="progress orange"><div className="indeterminate" /></div>}</span></h4>
            </div>
            <form onSubmit={e => e.preventDefault()}>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="name" type="text" onChange={this.onChange} value={this.state.values.name} />
                        <label htmlFor="name">Pharmacy Name</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m3">
                        <input id="customerNumber" type="text" onChange={this.onChange} value={this.state.values.customerNumber} />
                        <label htmlFor="customerNumber">Customer Number</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="deaNumber" type="text" onChange={this.onChange} value={this.state.values.deaNumber} />
                        <label htmlFor="deaNumber">DEA Number</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="threeFortyBNumber" type="text" onChange={this.onChange} value={this.state.values.threeFortyBNumber} />
                        <label htmlFor="threeFortyBNumber">340B Number</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 l6">
                        <input id="addressLineOne" type="text" onChange={this.onChange} value={this.state.values.addressLineOne} />
                        <label htmlFor="addressLineOne">Address Line One</label>
                    </div>
                    <div className="input-field col s12 l6">
                        <input id="addressLineTwo" type="text" onChange={this.onChange} value={this.state.values.addressLineTwo} />
                        <label htmlFor="addressLineTwo">Address Line Two</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6 m3">
                        <input id="city" type="text" onChange={this.onChange} value={this.state.values.city} />
                        <label htmlFor="city">City</label>
                    </div>
                    <div className="input-field col s3 m2">
                        <select id="stateId" onChange={this.onChange} value={this.state.values.stateId}>
                            <option className="grey-text" value={-1}>None</option>
                            {this.state.states && this.state.states.map(s => <option key={`select-${s.id}`} value={s.id}>{s.code}</option>)}
                        </select>
                        <label htmlFor="stateId">State</label>
                    </div>
                    <div className="input-field col s3 m2">
                        <input id="zip" type="text" onChange={this.onChange} value={this.state.values.zip} />
                        <label htmlFor="zip">Zip</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6 m2">
                        <input id="phone" type="text" onChange={this.onChange} value={this.state.values.phone} />
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="email" type="email" onChange={this.onChange} value={this.state.values.email} />
                        <label htmlFor="email">Email</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s4 m2">
                        <input id="defaultFeePercentage" type="number" onChange={this.onChange} value={this.state.values.defaultFeePercentage} />
                        <label htmlFor="defaultFeePercentage">Default Fee Percent</label>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="isHospital" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.isHospital ? 'checked' : ''} value={this.state.values.isHospital} />
                                <span>Hospital</span>
                            </label>
                        </p>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="is340b" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.is340b ? 'checked' : ''} value={this.state.values.is340b} />
                                <span>340B</span>
                            </label>
                        </p>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="isGov" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.isGov ? 'checked' : ''} value={this.state.values.isGov} />
                                <span>Fed Gov Account</span>
                            </label>
                        </p>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="isVa" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.isVa ? 'checked' : ''} value={this.state.values.isVa} />
                                <span>Veterans Affairs</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m8">
                        <textarea id="notes" className="materialize-textarea" onChange={this.onChange} value={this.state.values.notes} />
                        <label htmlFor="notes">Notes</label>
                    </div>
                </div>
                {this.state.updating ?
                    <div className="preloader-wrapper small active">
                        <div className="spinner-layer spinner-orange-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div><div className="gap-patch">
                                <div className="circle"></div>
                            </div><div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col s12 m2 row">
                            <a href="/" className="col s12 btn-small orange white-text" onClick={this.update}>Update Pharmacy</a>
                        </div>
                        <div className="col s12 m2 row">
                            <a href="/" className="col s12 btn-small white black-text" onClick={this.revert}>Discard Changes</a>
                        </div>
                    </div>
                }
            </form>
        </div>
    );
}

export default connect()(UpdatePharmacy);