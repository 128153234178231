import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';
import Axios from 'axios';
import { logOut, openSideNav, registerRoutes } from '../../func';

class CreatePharmacy extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        stateList: null,
        values: this.initValues()
    })

    initValues = () => ({
        saving: false,
        stateId: -1,
        customerNumber: '',
        name: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        zip: '',
        phone: '',
        deaNumber: '',
        defaultFeePercentage: 12,
        email: '',
        notes: '',
        isHospital: false,
        is340b: false,
        isGov: false,
        isVa: false,
        threeFortyBNumber: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getStates();
        M.FormSelect.init(document.querySelectorAll('select'));
        M.updateTextFields();
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const select = M.FormSelect.getInstance(el);
            if (select)
                select.destroy();
        })
    }

    networkCalls = {
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', {
                    params: this.authData
                })
                    .then(result => this.setState({ stateList: result.data }, () => M.FormSelect.init(document.querySelectorAll('select'))))
                    .catch(logOut)
            },
            type: 'r'
        },
        save: {
            func: () => {
                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/pharmacy/create/one', {
                        ...this.authData,
                        ...this.state.values
                    })
                        .then(result => {
                            this.props.dispatch(result.data.msg)
                            this.setState({ newId: result.data.newId }, () => {
                                // M.Modal.getInstance(document.querySelector('#new-user-modal')).open();
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        }
    }

    getStates = () => this.networkCalls.getStates();
    save = e => { e?.preventDefault(); this.networkCalls.save(); }

    onChange = e => {
        const { id, value } = e.target;

        if (id !== 'isHospital' && id !== 'isGov' && id !== 'is340b' && id !== 'isVa')
            this.setState(p => ({ values: { ...p.values, [id]: value } }))
        else
            this.setState(p => ({ values: { ...p.values, [id]: !p.values[id] } }))
    }

    clear = e => {
        e.preventDefault();
        this.setState({ values: this.initValues() }, () => {
            M.FormSelect.init(document.querySelectorAll('select'));
            M.updateTextFields();
            M.textareaAutoResize(document.querySelector('#notes'));
        })
    }

    customerNumberBlur = () => {
        let { customerNumber } = this.state.values;

        if (!customerNumber) { return }

        customerNumber = customerNumber.trim().replace(/\D/g, '');

        if (customerNumber.length !== 7)
            window.alert('Invalid Customer Number');

        else {
            customerNumber = customerNumber.split('');
            customerNumber.splice(2, 0, '-')
            customerNumber.splice(5, 0, '-')
            customerNumber = customerNumber.join('');
            this.setState(p => ({ values: { ...p.values, customerNumber } }))
        }
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/pharmacy">Pharmacies</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">New Pharmacy</span>
                </div>
            </div>
            <div className="row">
                <h4>New Pharmacy</h4>
            </div>
            <form onSubmit={e => e.preventDefault()}>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="name" type="text" onChange={this.onChange} value={this.state.values.name} />
                        <label htmlFor="name">Pharmacy Name</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m3">
                        <input id="customerNumber" type="text" onChange={this.onChange} onBlur={this.customerNumberBlur} value={this.state.values.customerNumber} />
                        <label htmlFor="customerNumber">Customer Number</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="deaNumber" type="text" onChange={this.onChange} value={this.state.values.deaNumber} />
                        <label htmlFor="deaNumber">DEA Number</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="threeFortyBNumber" type="text" onChange={this.onChange} value={this.state.values.threeFortyBNumber} />
                        <label htmlFor="threeFortyBNumber">340B Number</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 l6">
                        <input id="addressLineOne" type="text" onChange={this.onChange} value={this.state.values.addressLineOne} />
                        <label htmlFor="addressLineOne">Address Line One</label>
                    </div>
                    <div className="input-field col s12 l6">
                        <input id="addressLineTwo" type="text" onChange={this.onChange} value={this.state.values.addressLineTwo} />
                        <label htmlFor="addressLineTwo">Address Line Two</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6 m3">
                        <input id="city" type="text" onChange={this.onChange} value={this.state.values.city} />
                        <label htmlFor="city">City</label>
                    </div>
                    <div className="input-field col s3 m2">
                        <select id="stateId" onChange={this.onChange} value={this.state.values.stateId}>
                            <option className="grey-text" value={-1}>None</option>
                            {this.state.stateList && this.state.stateList.map(s => <option key={`state-${s.id}`} value={s.id}>{s.code}</option>)}
                        </select>
                    </div>
                    <div className="input-field col s3 m2">
                        <input id="zip" type="text" onChange={this.onChange} value={this.state.values.zip} />
                        <label htmlFor="zip">Zip</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6 m2">
                        <input id="phone" type="text" onChange={this.onChange} value={this.state.values.phone} />
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="input-field col s12 m6">
                        <input id="email" type="email" onChange={this.onChange} value={this.state.values.email} />
                        <label htmlFor="email">Email</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s4 m2">
                        <input id="defaultFeePercentage" type="number" onChange={this.onChange} value={this.state.values.defaultFeePercentage} />
                        <label htmlFor="defaultFeePercentage">Default Fee Percentage</label>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="isHospital" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.isHospital ? 'checked' : ''} value={this.state.values.isHospital} />
                                <span>Hospital</span>
                            </label>
                        </p>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="is340b" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.is340b ? 'checked' : ''} value={this.state.values.is340b} />
                                <span>340B</span>
                            </label>
                        </p>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="isGov" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.isGov ? 'checked' : ''} value={this.state.values.isGov} />
                                <span>Fed Gov Account</span>
                            </label>
                        </p>
                    </div>
                    <div className="input-field col s4 m2">
                        <p>
                            <label>
                                <input id="isVa" type="checkbox" className="filled-in" onChange={this.onChange} checked={this.state.values.isVa ? 'checked' : ''} value={this.state.values.isVa} />
                                <span>Veterans Affairs</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m8">
                        <textarea id="notes" className="materialize-textarea" onChange={this.onChange} value={this.state.values.notes} />
                        <label htmlFor="notes">Notes</label>
                    </div>
                </div>
                {this.state.saving ?
                    <div>Saving</div>
                    :
                    <div className="row">
                        <div className="col s12 m2 row">
                            <a href="/" className="col s12 btn-small blue white-text" onClick={this.save}>Save Pharmacy</a>
                        </div>
                        <div className="col s12 m2 row">
                            <a href="/" className="col s12 btn-small white black-text" onClick={this.clear}>Clear</a>
                        </div>
                    </div>
                }
            </form>
            <div id="new-user-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5 className="center">Would you like to sign up a user to the customer portal?</h5>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4 offset-m4">
                            <Link className="btn-small blue white-test col s12" to={`/pharmacy/${this.state.newId}/create/portaluser`}>Create Portal User</Link>
                        </div>
                        <div className="input-field col s12 m4">
                            <a href="/" className="btn-small red white-text col s12" onClick={(e) => { e.preventDefault(); M.Modal.getInstance(document.querySelector('#new-user-modal')).close(); }}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect()(CreatePharmacy);