import React from 'react';
import { Input } from "../../../layout/form";
import M from '@materializecss/materialize';

class GeneralTab extends React.Component {

    componentDidMount = () => {
        this.initReturnHandlerAutoComplete(this.props.returnHandlerListAsObject);
        this.initParentPartyAutomComplete(this.props.manufacturerListAsObject);
    }

    UNSAFE_componentWillReceiveProps = props => {
        if (props.returnHandlerListAsObject)
            this.initReturnHandlerAutoComplete(props.returnHandlerListAsObject);

        if (props.manufacturerListAsObject)
            this.initParentPartyAutomComplete(props.manufacturerListAsObject);
    }

    initReturnHandlerAutoComplete = data => {
        if (data) {
            this.disposeReturnHandlerAutoComplete();
            M.Autocomplete.init(document.querySelector('#returnHandlerName'), {
                data,
                onAutocomplete: returnHandlerName => {
                    this.setState({ returnHandlerName })
                    this.props.onReturnHandlerChange(returnHandlerName);
                }
            })
        }
    }

    initParentPartyAutomComplete = data => {
        if (data) {
            this.disposeParentPartyAutoComplete();
            M.Autocomplete.init(document.querySelector('#parentPartyName'), {
                data,
                onAutocomplete: parentPartyName => {
                    this.setState({ parentPartyName });
                    this.props.onParentPartyChange(parentPartyName);
                }
            })
        }
    }

    disposeReturnHandlerAutoComplete = () => {
        const ac = M.Autocomplete.getInstance(document.querySelector('#returnHandlerName'));
        if (ac) { ac.destroy(); }
    }

    disposeParentPartyAutoComplete = () => {
        const ac = M.Autocomplete.getInstance(document.querySelector('#parentPartyName'));
        if (ac) { ac.destroy(); }
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <table>
                        <tbody>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}><span className="red-text">*</span>Name</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>DEA Number</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Return Handler</td></tr>
                            <tr><td style={{ padding: '4px', textAlign: 'right' }}>Parent Party</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="col s8 m9 l10">
                    <table>
                        <tbody>
                            <Input id="name" type="text" value={this.props.values.name} onChange={this.props.onChange} col="col s12 m6 l5" />
                            <Input id="deaNumber" type="text" value={this.props.values.deaNumber} onChange={this.props.onChange} col="s4 m2" />
                            <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
                                <td style={{ padding: '0px' }}>
                                    <div className="input-field" style={{ padding: '0px', margin: '0px' }}>
                                        <input
                                            id="returnHandlerName"
                                            type="text"
                                            className="autocomplete col s6 m2"
                                            style={{ margin: '0px', padding: '0px', height: '30.5px' }}
                                            value={this.props.values.returnHandlerName}
                                            onChange={this.props.onChange}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', margin: '0px', padding: '0px' }}>
                                <td style={{ padding: '0px' }}>
                                    <div className="input-field" style={{ padding: '0px', margin: '0px' }}>
                                        <input
                                            id="parentPartyName"
                                            type="text"
                                            className="autocomplete col s6 m5"
                                            style={{ margin: '0px', padding: '0px', height: '30.5px' }}
                                            value={this.props.values.parentPartyName}
                                            onChange={this.props.onChange}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row" style={{ marginTop: '40px' }}>
                <a href="/" onClick={e => { e.preventDefault(); this.props.setTab({ target: { id: 'tab-address' } }) }}>
                    <i className="material-icons tooltipped"
                        data-position="top"
                        data-tooltip="Next"
                        style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                    >arrow_forward</i>
                </a>
                {this.props.revert ? (
                    <a href="/" onClick={e => { e.preventDefault(); this.props.revert(); }}>
                        <i className="material-icons tooltipped"
                            data-position="top"
                            data-tooltip="Discard General Changes"
                            style={{ border: '1px solid #2196f3', padding: '4px 2px', borderRadius: '3px', marginRight: '8px', cursor: 'pointer' }}
                        >refresh</i>
                    </a>
                ) : null}
            </div>
        </div>
    )
}

export default GeneralTab;