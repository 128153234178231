import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../func';

class CreateReturnHandler extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        saving: false,
        values: this.initValues()
    })


    initValues = () => ({
        stateId: -1,
        name: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        zip: '',
        phone: '',
        email: '',
        website: '',
        deaNumber: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getStates();
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const sel = M.FormSelect.getInstance(el);
            if (sel) { sel.destroy() }
        })
    }

    networkCalls = {
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', { params: this.authData })
                    .then(result => this.setState({ stateList: result.data }, () => {
                        M.FormSelect.init(document.querySelectorAll('select'))
                    }))
                    .catch(logOut)
            },
            type: 'r'
        },
        save: {
            func: () => {
                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/party/returnhandler/create/one', {
                        ...this.authData,
                        ...this.state.values,
                    })
                        .then(result => this.props.dispatch(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        }
    }

    getStates = () => this.networkCalls.getStates();
    save = e => { e?.preventDefault(); this.networkCalls.save(); }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    clear = e => {
        e.preventDefault();

        this.setState({ values: this.initValues() }, () => {
            M.updateTextFields();
            M.FormSelect.init(document.querySelectorAll('select'))
        })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/returnhandler">Return Handlers</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">New Return Handler</span>
                </div>
            </div>
            <div className="row">
                <h4>New Return Handler</h4>
            </div>
            <form>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="name" type="text" onChange={this.onChange} value={this.state.values.name} />
                        <label htmlFor="name">Return Handler Name</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="deaNumber" type="text" onChange={this.onChange} value={this.state.values.deaNumber} />
                        <label htmlFor="deaNumber">DEA Number</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m2">
                        <input id="phone" type="text" onChange={this.onChange} value={this.state.values.phone} />
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="input-field col s12 m2">
                        <input id="email" type="text" onChange={this.onChange} value={this.state.values.email} />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="input-field col s12 m2">
                        <input id="website" type="text" onChange={this.onChange} value={this.state.values.website} />
                        <label htmlFor="website">Website</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="addressLineOne" type="text" onChange={this.onChange} value={this.state.values.addressLineOne} />
                        <label htmlFor="addressLineOne">Address Line 1</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="addressLineTwo" type="text" onChange={this.onChange} value={this.state.values.addressLineTwo} />
                        <label htmlFor="addressLineTwo">Address Line 2</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s8 m4">
                        <input id="city" type="text" onChange={this.onChange} value={this.state.values.city} />
                        <label htmlFor="city">City</label>
                    </div>
                    <div className="input-field col s4 m2">
                        <select id="stateId" onChange={this.onChange} value={this.state.values.stateId}>
                            <option value={-1}></option>
                            {this.state.stateList && this.state.stateList.map(s => <option key={`s-${s.id}`} value={s.id}>{s.code}</option>)}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m2">
                        <input id="zip" type="text" onChange={this.onChange} value={this.state.values.zip} />
                        <label htmlFor="zip">Zip</label>
                    </div>
                </div>
                {this.state.saving ?
                    <div className="row">
                        <div className="preloader-wrapper small active">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col s12 m2">
                            <a href="/" className="col s12 btn-small blue white-text waves-effect" onClick={this.save}>Save Return Handler</a>
                        </div>
                        <div className="col s12 m2">
                            <a href="/" className="col s12 btn-small white black-text waves-effect" onClick={this.clear}>Clear</a>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}

export default connect()(CreateReturnHandler);