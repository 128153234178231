import Axios from "axios";
import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import { logOut, openSideNav, registerRoutes } from "../../../../func"
import AddCommissionRate from './AddCommissionRate';
import CommissionRateList from "./CommissionRateList";
import CommissionList from './CommissionList';


const SalesCommissionPage = props => {
    const [repList, setRepList] = useState(null);
    const [includeInactiveReps, setIncludeInactiveReps] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(0);
    const [loadingEmployeeCommission, setLoadingEmployeeCommission] = useState(false);
    const [employeeCommissionList, setEmployeeCommissionList] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [selectedCommissionId, setSelectedCommissionId] = useState(-1);

    const networkCalls = registerRoutes({
        getRepList: {
            func: () => {
                setLoadingPage(true);
                Axios.get('/api/v1/employee/read/reps', {
                    params: JSON.parse(localStorage.getItem('auth_data'))
                })
                    .then(result => {
                        setRepList(result.data)
                        const selectedEmployeeId = result.data.filter(r => !r.isArchived)[0].id;
                        setSelectedEmployeeId(selectedEmployeeId);
                        ref.current.networkCalls.getEmployeeCommissionList(selectedEmployeeId);
                    })
                    .catch(logOut)
                    .finally(() => setLoadingPage(false))
            },
            type: 'r'
        },
        getEmployeeCommissionList: {
            func: (employeeId) => {
                setLoadingEmployeeCommission(true);
                Axios.get('/api/v2/employee/read/commissionrates/by/employeeid', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        employeeId
                    }
                })
                    .then(result => setEmployeeCommissionList(result.data))
                    .catch(logOut)
                    .finally(() => setLoadingEmployeeCommission(false))
            },
            type: 'r'
        }
    }, props.match.path)

    const selectEmployee = id => {
        setSelectedEmployeeId(id);
        setIsCreating(false);
        networkCalls.getEmployeeCommissionList(id);
    }

    const toggleSwitch = bool => {
        setIncludeInactiveReps(bool);

        if (!bool && repList.find(r => r.id === selectedEmployeeId).isArchived) {
            const id = repList.filter(r => !r.isArchived)[0].id
            setSelectedEmployeeId(id)
            ref.current.networkCalls.getEmployeeCommissionList(id)
        }

    }

    const ref = useRef({
        networkCalls
    })

    useEffect(() => {
        ref.current.networkCalls.getRepList();
    }, [])

    return (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Sales Commission</span>
                </div>
            </div>
            {loadingPage ?
                <div className="progress blue">
                    <div className="indeterminate" />
                </div>
                :
                <div className="row">
                    <div className="col s12 m3 l2">
                        <div className="switch">
                            <label>
                                Include Inactive Reps
                                <input type="checkbox" checked={includeInactiveReps ? 'checked' : ''} onChange={e => toggleSwitch(e.target.checked)} />
                                <span className="lever"></span>
                            </label>
                        </div>
                        <ul className="collection">
                            {repList?.filter(e => includeInactiveReps ? true : !e.isArchived).map(e => (
                                <li key={e.id} className={`collection-item ${e.id === selectedEmployeeId ? 'blue white-text' : ''}`} onClick={() => selectEmployee(e.id)}>{e.firstName} {e.lastName}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="col s12 m9 l10" style={{ padding: '2vw' }}>
                        {isCreating ?
                            <AddCommissionRate
                                path={props.match.path}
                                employeeId={selectedEmployeeId}
                                setIsCreating={setIsCreating}
                                dispatch={props.dispatch}
                            />
                            :
                            <>
                                <div className="row">
                                    <div className="card">
                                        <div className="card-content" style={{maxHeight: '200px', overflow: 'auto'}}>
                                            {loadingEmployeeCommission ?
                                                <div className="progress blue">
                                                    <div className="indeterminate" />
                                                </div>
                                                :
                                                <CommissionRateList
                                                    employeeCommissionList={employeeCommissionList}
                                                    setIsCreating={setIsCreating}
                                                    selectedCommissionId={selectedCommissionId}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom: '0px'}}>
                                    <div className="card">
                                        <div className="card-content" style={{maxHeight: 'CALC(99vh - 380px)', overflow: 'auto'}}>
                                            {loadingEmployeeCommission ?
                                                null
                                                :
                                                <CommissionList
                                                    path={props.match.path}
                                                    selectedEmployeeId={selectedEmployeeId}
                                                    setSelectedCommissionId={setSelectedCommissionId}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default connect()(SalesCommissionPage);