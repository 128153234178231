import React from 'react';
import { Link } from 'react-router-dom';
import { openSideNav } from '../../func';

const UserOverview = props => (
    <div className="main">
        <div className="row">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                <Link to="/">Home</Link>
                < i className="material-icons">chevron_right</i>
                <span className="grey-text">User Menu</span>
            </div>
        </div>
        <div className="row">
            <div className="row">
                <h4 className="center">User Menu</h4>
            </div>
            <div className="col s12 m6 offset-m3">
                <div className="row">
                    <Link className="center" to="/user/timeclock">Time Clock</Link>
                </div>
                <div className="row">
                    <Link className="center" to="/user/corrections">Corrections</Link>
                </div>
            </div>
        </div>
    </div>
)

export default UserOverview;