import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../func';

class UpdateReturnHandler extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        updating: false,
        gettingRetunHandler: false,
        returnHandler: null,
        values: this.initValues()
    })

    initValues = () => ({
        id: this.id,
        stateId: -1,
        name: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        zip: '',
        phone: '',
        email: '',
        website: '',
        deaNumber: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getStates();
        this.getReturnHandler();
    }

    networkCalls = {
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', { params: this.authData })
                    .then(result => this.setState({ stateList: result.data }, () => M.FormSelect.init(document.querySelectorAll('select'))))
                    .catch(logOut)
            },
            type: 'r'
        },
        getReturnHandler: {
            func: () => {
                this.setState({ gettingRetunHandler: true }, () => {
                    Axios.get('/api/v1/party/returnhandler/read/one/by/id', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => {
                            this.setState({
                                returnHandler: this.deNullify(result.data),
                                values: {
                                    ...this.deNullify(result.data),
                                    stateId: result.data.stateId ? result.data.stateId : -1
                                }
                            }, () => {
                                M.updateTextFields();
                                M.FormSelect.init(document.querySelectorAll('select'));
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ gettingRetunHandler: false }))
                })
            },
            type: 'r'
        },
        update: {
            func: () => {
                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/party/returnhandler/update/one', {
                        ...this.authData,
                        ...this.state.values
                    })
                        .then(result => this.props.dispatch(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ updating: false }))
                })
            },
            type: 'u'
        }
    }

    getStates = () => this.networkCalls.getStates();
    getReturnHandler = () => this.networkCalls.getReturnHandler();
    update = e => { e?.preventDefault(); this.networkCalls.update(); }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    deNullify = values => {
        Object.keys(values).forEach(key => {
            if (values[key] === null)
                values[key] = '';
        })

        return values;
    }

    revert = e => {
        e.preventDefault();

        this.setState(p => ({ values: p.returnHandler }), () => {
            M.updateTextFields();
            M.FormSelect.init(document.querySelectorAll('select'))
        })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/returnhandler">Return Handlers</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/returnhandler/${this.id}`}>{this.state.returnHandler ? this.state.returnHandler.name : 'Return Handler'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Update</span>
                </div>
            </div>
            <div className="row">
                <h4>Update <span className="orange-text">{this.state.returnHandler ? this.state.returnHandler.name : ''}</span></h4>
            </div>
            <form>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="name" type="text" onChange={this.onChange} value={this.state.values.name} />
                        <label htmlFor="name">Return Handler Name</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="deaNumber" type="text" onChange={this.onChange} value={this.state.values.deaNumber} />
                        <label htmlFor="deaNumber">DEA Number</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m2">
                        <input id="phone" type="text" onChange={this.onChange} value={this.state.values.phone} />
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="input-field col s12 m2">
                        <input id="email" type="text" onChange={this.onChange} value={this.state.values.email} />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="input-field col s12 m2">
                        <input id="website" type="text" onChange={this.onChange} value={this.state.values.website} />
                        <label htmlFor="website">Website</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="addressLineOne" type="text" onChange={this.onChange} value={this.state.values.addressLineOne} />
                        <label htmlFor="addressLineOne">Address Line 1</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 m6">
                        <input id="addressLineTwo" type="text" onChange={this.onChange} value={this.state.values.addressLineTwo} />
                        <label htmlFor="addressLineTwo">Address Line 2</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s8 m4">
                        <input id="city" type="text" onChange={this.onChange} value={this.state.values.city} />
                        <label htmlFor="city">City</label>
                    </div>
                    <div className="input-field col s4 m2">
                        <select id="stateId" onChange={this.onChange} value={this.state.values.stateId}>
                            <option value={-1}></option>
                            {this.state.stateList && this.state.stateList.map(s => <option key={`s-${s.id}`} value={s.id}>{s.code}</option>)}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m2">
                        <input id="zip" type="text" onChange={this.onChange} value={this.state.values.zip} />
                        <label htmlFor="zip">Zip</label>
                    </div>
                </div>
                {this.state.updating ?
                    <div className="row">
                        <div className="preloader-wrapper small active">
                            <div className="spinner-layer spinner-orange-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col s12 m2">
                            <a href="/" className="col s12 btn-small orange white-text waves-effect" onClick={this.update}>Update Return Handler</a>
                        </div>
                        <div className="col s12 m2">
                            <a href="/" className="col s12 btn-small white black-text waves-effect" onClick={this.revert}>Discard Changes</a>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}

export default connect()(UpdateReturnHandler)