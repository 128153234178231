import Axios from "axios";
import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../../func';
import CreateIssueTab from "./createIssueTab";
import OngoingTab from './ongoingTab';

class ErrorTracking extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        tab: 'ongoing',
        ongoingIssues: null,
        closedIssues: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getIssues();
    }

    networkCalls = {
        getIssues: {
            func: () => {
                Axios.get('/api/v1/user/read/all/errortracking', { params: this.authData })
                    .then(result => this.hydrateState(result.data))
                    .catch(logOut)
            },
            type: 'r'
        }
    }

    hydrateState = errorTracking => {
        const ongoingIssues = [];
        const closedIssues = [];


        errorTracking.forEach(err => {
            err.thread = [...err.statuses, ...err.correspondence];
            err.thread.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp))

            err.statuses.filter(s => ['resolved', 'rejected'].indexOf(s.status) !== -1).length ? closedIssues.push(err) : ongoingIssues.push(err);
        })

        this.setState({ ongoingIssues, closedIssues })
    }

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] })

    getContent = () => {
        let content = null;
        switch (this.state.tab) {
            case 'ongoing':
                content = <OngoingTab
                    issues={this.state.ongoingIssues}
                    path={this.props.match.path}
                    refresh={this.networkCalls.getIssues}
                    dispatch={this.props.dispatch}
                />
                break;
            case 'closed':
                content = <OngoingTab
                    issues={this.state.closedIssues}
                    path={this.props.match.path}
                    refresh={this.networkCalls.getIssues}
                    dispatch={this.props.dispatch}
                    closed={true}
                />
                break;
            case 'create':
                content = <CreateIssueTab
                    path={this.props.match.path}
                    setTab={this.setTab}
                />
                break;
            default:
                break;
        }

        return content;
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Orx Tools Issue Tracker</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Orx Tools Issue Tracker</h5>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul id="left-hand-menu" className="collection">
                        <li id="tab-ongoing" className={`collection-item ${this.state.tab === 'ongoing' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Ongoing Issues</li>
                        <li id="tab-closed" className={`collection-item ${this.state.tab === 'closed' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Closed Issues</li>
                        <li id="tab-create" className={`collection-item ${this.state.tab === 'create' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Report A New Issue</li>
                    </ul>
                    {this.state.tab === 'create' ? (
                        <div style={{ padding: '6px', border: 'solid #ccc 1px' }}>
                            <h5 className="center bold" style={{ textDecoration: 'underline' }}>Instructions</h5>
                            <p>&bull; Your Title should briefly describe the problem.</p>
                            <p>&bull; Copy the url at the top of the page where the issue happened and paste it into the "Page URL" field.</p>
                            <p>&bull; If you can recreate the problem. List the steps in the description.</p>
                            <p>&bull; If you have screenshots of the problem, add them on the "Ongoing Issues" Tab</p>
                        </div>
                    ) : null}
                </div>
                <div className="col s8 m9 l10">
                    {this.getContent()}
                </div>
            </div>
        </div>
    )
}

export default connect()(ErrorTracking);