import React from 'react';
import { Switch, Route } from 'react-router-dom';
import InvoiceOverview from './invoiceOverview';
import CreateInvoice from './createInvoice';
import UpdateInvoice from './updateInvoice';
import InvoiceSearch from './invoiceSearch';


const InvoiceRouter = props => (
    <Switch>
        <Route exact path="/invoice" component={InvoiceOverview} />
        <Route exact path="/invoice/create" component={CreateInvoice} />
        <Route exact path="/invoice/update/:id" component={UpdateInvoice} />
        <Route exact path="/invoice/search" component={InvoiceSearch} />
    </Switch>
)

export default InvoiceRouter;