import React from 'react';
import { connect } from 'react-redux';
import { openSideNav } from '../../../func';
import { Link } from 'react-router-dom';
import GeneralTab from './generalTab';
import StatusTab from "./statusTab";
import TrackingTab from './trackingTab';
import PrintTab from './printTab';
import OrderInputTab from "./orderInputTab";
import CreateTab from './createTab';
import AttachmentTab from './attachmentTab';

class OrderDetailOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.orderHeaderId = this.props.match.params.id;
        this.state = this.initState();
    }

    initState = () => ({
        tab: this.props.location?.state?.tab || 'general'
    })

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] });

    reload = orderHeaderId => {
        this.props.history.push(`/regular/${orderHeaderId}`)
        this.orderHeaderId = orderHeaderId;
        this.setState({ tab: 'general' })
    }

    getContent = () => {
        let component = null;

        switch (this.state.tab) {
            case 'general':
                component = <GeneralTab path={this.props.match.path} orderHeaderId={this.orderHeaderId} history={this.props.history} />
                break;
            case 'status':
                component = <StatusTab path={this.props.match.path} orderHeaderId={this.orderHeaderId} />
                break;
            case 'tracking':
                component = <TrackingTab path={this.props.match.path} orderHeaderId={this.orderHeaderId} />
                break;
            case 'orderInput':
                component = <OrderInputTab path={this.props.match.path} orderHeaderId={this.orderHeaderId} history={this.props.history} />
                break;
            case 'printables':
                component = <PrintTab path={this.props.match.path} orderHeaderId={this.orderHeaderId} />
                break;
            case 'create':
                component = <CreateTab path={this.props.match.path} history={this.props.history} reload={this.reload} />
                break;
            case 'attach':
                component = <AttachmentTab path={this.props.match.path} orderHeaderId={this.orderHeaderId} dispatch={this.props.dispatch} />
                break;
            default:
                component = null;
                break;
        }

        return component;
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/regular">Regular Orders</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.props?.location?.state?.orderNumber || 'Order Detail'}</span>
                </div>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <div className="row">
                        <ul className="collection">
                            {this.orderHeaderId !== '0' ?
                                <React.Fragment>
                                    <li id="tab-general" className={`collection-item ${this.state.tab === 'general' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>General Order Info</li>
                                    <li id="tab-status" className={`collection-item ${this.state.tab === 'status' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Status</li>
                                    {this.props.match.path === '/warehouse/manager/regular/:id' ?
                                        <li id="tab-tracking" className={`collection-item ${this.state.tab === 'tracking' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Package Tracking</li>
                                        : null}
                                    <li id="tab-orderInput" className={`collection-item ${this.state.tab === 'orderInput' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Order Input</li>
                                    <li id="tab-printables" className={`collection-item ${this.state.tab === 'printables' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Reports</li>
                                    <li id="tab-attach" className={`collection-item ${this.state.tab === 'attach' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Attachments</li>
                                </React.Fragment>
                                : null}
                            {this.props.match.path === '/warehouse/manager/regular/:id' ?
                                <li id="tab-create" className={`collection-item ${this.state.tab === 'create' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>New Order</li>
                                : null}
                        </ul>
                    </div>
                </div>
                <div className="col s8 m9 l10">
                    {this.getContent()}
                </div>
            </div>
        </div>
    )
}

export default connect()(OrderDetailOverview);