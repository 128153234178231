import moment from 'moment';

const RecallTab = props => (
    <div style={{ padding: '1vh' }}>
        <table className='highlight'>
            <thead>
                <tr>
                    <th style={{ padding: '2px' }} > Lot Number</th>
                    <th style={{ padding: '2px' }} > Expiration Start</th>
                    <th style={{ padding: '2px' }} > Expiration End</th>
                    <th style={{ padding: '2px' }} > Date Created</th>
                </tr >
            </thead >
            <tbody>
                {props?.med?.recalls?.map(r => (
                    <tr key={r.id}>
                        <td style={{ padding: '2px' }}>{r.lotNumber ? r.lotNumber.toUpperCase() : 'N/A'}</td>
                        <td style={{ padding: '2px' }}>{r.expirationStart ? moment(r.expirationStart).format('MM/DD/YYYY') : 'N/A'}</td>
                        <td style={{ padding: '2px' }}>{r.expirationEnd ? moment(r.expirationEnd).format('MM/DD/YYYY') : 'N/A'}</td>
                        <td style={{ padding: '2px' }}>{moment(r.dateCreated).format('MM/DD/YYYY')}</td>
                    </tr>
                ))}
            </tbody>
        </table >
    </div >
)

export default RecallTab;