import { connect } from "react-redux"
import { useState, useEffect, useRef } from 'react';
import Axios from "axios";
import { logOut } from "../../../../func";
import React from 'react';
import GeneralTab from "./GeneralTab";
import ManufacturerTab from "./ManufacturerTab";
import SearchTab from "./SearchTab";
import CreateMedTab from "./CreateMedTab";
import RecallTab from "./RecallTab";

const RedbookDetail = props => {
    const {
        redbook,
        formValues,
        passEarliestReturnDateToParent,
        passRecallToParent,
        passPolicyToParent,
        path
    } = props;

    const [state, setState] = useState({
        med: null,
        lastMed: null,
        tab: 'general'
    })

    const ref = useRef({
        lastRedbook: null,
        abortController: null
    })

    useEffect(() => {
        if (!redbook)
            setState(p => ({ ...p, med: null }))
        else if (redbook && redbook !== ref.current.lastRedbook) {
            setState(p => ({ ...p, med: null }))
            ref.current.lastRedbook = redbook
            ref.current.abortController?.abort();
            ref.current.abortController = new AbortController();

            Axios.get('/api/v1/redbook/read/one/by/id/deep', {
                params: {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    id: typeof redbook === 'object' ? redbook.id : redbook
                },
                signal: ref.current.abortController.signal
            })
                .then(result => {
                    const med = result.data;

                    if (!med.manufacturer.returnPolicies)
                        med.returnPolicy = 'No Policy';
                    else if (med.returnPolicyId)
                        med.returnPolicy = med.manufacturer.returnPolicies.find(rp => rp.id === med.returnPolicyId)
                    else if (med.manufacturer.returnPolicyId)
                        med.returnPolicy = med.manufacturer.returnPolicies.find(rp => rp.id === med.manufacturer.returnPolicyId)
                    else if (med.manufacturer.returnPolicies.length === 1)
                        med.returnPolicy = med.manufacturer.returnPolicies[0]
                    else
                        med.returnPolicy = 'Multiple Policies';

                    
                    if(!med.returnPolicy)
                        med.returnPolicy = 'Policy Expired';

                    passPolicyToParent(med.returnPolicy);

                    setState(p => ({ ...p, med, lastMed: med }))
                })
                .catch(logOut)
        }
        else
            setState(p => ({ ...p, med: p.lastMed }))
    }, [redbook, passPolicyToParent])

    const passRedbookToParent = rb => {
        props.passRedbookToParent(rb);
        setTab('general');
    }

    const setTab = tab => setState(p => ({ ...p, tab }))

    const getContent = () => {
        switch (state.tab) {
            case 'general':
                return <GeneralTab
                    med={state.med}
                    formValues={formValues}
                    passEarliestReturnDateToParent={passEarliestReturnDateToParent}
                    passRecallToParent={passRecallToParent}
                />
            case 'manufacturer':
                return <ManufacturerTab
                    manufacturer={state.med?.manufacturer}
                />
            case 'search':
                return <SearchTab
                    passRedbookToParent={passRedbookToParent}
                    path={path}
                />
            case 'create':
                return <CreateMedTab
                    passRedbookToParent={passRedbookToParent}
                    setTab={setTab}
                    path={path}
                />
            case 'recall':
                return <RecallTab
                    med={state.med}
                    path={path}
                />
            default:
                return null;
        }
    }

    return (
        <div style={{ height: '67vh' }}>
            <div className="card" style={{ height: 'CALC(66vh - .01px)' }}>
                <div className="card-content" style={{ position: 'relative', height: '100%', padding: '12px' }}>
                    {redbook &&
                        <div className="row" style={{ marginBottom: '0px' }}>
                            <h5 className={redbook && redbook?.deaClass !== 'R' ? 'red-text' : 'blue-text'} style={{ padding: '8px 20px', borderRadius: '3px' }}>{redbook?.medName}</h5>
                        </div>
                    }
                    <div className="row" style={{ position: 'relative', height: 'CALC(66vh - 80px)', margin: '0px' }}>
                        <div className="col s4 m3 l2">
                            <ul className="collection">
                                <li className={`collection-item ${state.tab === 'general' ? 'blue white-text' : ''}`} onClick={() => setTab('general')} style={{ cursor: 'pointer' }}>General</li>
                                <li className={`collection-item ${state.tab === 'manufacturer' ? 'blue white-text' : ''}`} onClick={() => setTab('manufacturer')} style={{ cursor: 'pointer' }}>Manufacturer</li>
                                {redbook?.recalls ?
                                    <li className={`collection-item ${state.tab === 'recall' ? 'blue white-text' : ''}`} onClick={() => setTab('recall')} style={{ cursor: 'pointer' }}>Recalls</li>
                                    : null}
                                <li className={`collection-item ${state.tab === 'search' ? 'blue white-text' : ''}`} onClick={() => setTab('search')} style={{ cursor: 'pointer' }}>Search Redbook</li>
                                <li className={`collection-item ${state.tab === 'create' ? 'blue white-text' : ''}`} onClick={() => setTab('create')} style={{ cursor: 'pointer' }}>Create New Med</li>
                            </ul>
                        </div>
                        <div className="col s8 m9 l10" style={{ position: 'relative', height: 'CALC(66vh - 80px)', overflow: 'auto' }}>
                            {getContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(RedbookDetail);