import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { registerRoutes, logOut } from "../../../func";
import Axios from 'axios';
const componentId = crypto.randomUUID();

const OrderDetailList = props => {
    const { debitMemo, path, dispatch } = props;

    const [orderDetail, setOrderDetail] = useState(null);
    const [contextMenu, setContextMenu] = useState({ show: false })
    const [contextMenuAwp, setContextMenuAwp] = useState({ show: false })
    const [editValues, setEditValues] = useState({});
    const [awp, setAwp] = useState('');
    const [savingAwp, setSavingAwp] = useState(false);

    const [networkCalls] = useState(registerRoutes({
        deleteOrderDetail: {
            func: orderDetailId => {
                Axios.post('/api/v2/order/detail/delete/one', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    orderDetailId
                })
                    .then(result => dispatch(result.data))
                    .catch(logOut)
            },
            type: 'd'
        },
        searchRedbook: {
            func: ndc => {
                if (!ndc) {
                    setEditValues(p => ({
                        ...p,
                        deaClass: '',
                        manufacturer: '',
                        medName: 'Med Not Found',
                        packSize: ''
                    }))
                }
                else {
                    ref.current.redbookSearchAbortController?.abort();
                    ref.current.redbookSearchAbortController = new AbortController();

                    Axios.get('/api/v2/redbook/read/one/deep/by/ndc', {
                        params: {
                            ...JSON.parse(localStorage.getItem('auth_data')),
                            ndc
                        },
                        signal: ref.current.redbookSearchAbortController.signal
                    })
                        .then(result => {
                            const redbook = result.data
                            const { manufacturer } = redbook;
                            let returnPolicy = null;

                            if (!manufacturer.returnPolicies)
                                returnPolicy = 'No Return Policy';
                            else if (redbook.returnPolicyId)
                                returnPolicy = manufacturer.returnPolicies.find(row => parseInt(row.id) === parseInt(redbook.returnPolicyId)) || 'No Return Policy';
                            else if (manufacturer.returnPolicyId)
                                returnPolicy = manufacturer.returnPolicies.find(row => parseInt(row.id) === parseInt(manufacturer.returnPolicyId)) || 'No Return Policy'
                            else if (manufacturer.returnPolicies.length === 1)
                                returnPolicy = manufacturer.returnPolicies[0]
                            else
                                returnPolicy = 'Multiple Policies'

                            if (redbook)
                                setEditValues(p => ({
                                    ...p,
                                    deaClass: redbook.deaClass,
                                    manufacturer: redbook.manufacturer.name,
                                    medName: redbook.medName,
                                    packSize: redbook.packSize,
                                    returnPolicy
                                }))
                            else
                                setEditValues(p => ({
                                    ...p,
                                    deaClass: '',
                                    manufacturer: '',
                                    medName: 'Med Not Found',
                                    packSize: '',
                                    returnPolicy
                                }))

                        })
                        .catch(logOut)
                }
            },
            type: 'r'
        },
        updateOrderDetail: {
            func: () => {
                const { editValues } = ref.current;
                const values = {
                    orderDetailId: editValues.id,
                    ndc: editValues.ndc,
                    fullPackage: editValues.fullPackage || 0,
                    partialPackage: editValues.partialPackage || 0,
                    lotNumber: editValues.lotNumber || '',
                    expiration: editValues.expiration || '',
                    nonReturnableReasonCodeId: editValues.nonReturnableReasonCodeId || null,
                    indateBarcode: editValues.indateBarcode || null,
                    returnPolicyId: editValues.returnPolicy?.id || 0
                }

                Axios.post('/api/v2/order/detail/update/one', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    ...values
                })
                    .then(result => {
                        dispatch(result.data);
                        setEditValues({})
                    })
                    .catch(logOut)
            },
            type: 'u'
        },
        saveAwp: {
            func: awp => {
                setSavingAwp(true)
                const { orderDetail } = ref.current;

                Axios.post('/api/v2/redbook/update/one/awp', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    redbookId: orderDetail.redbookId,
                    orderHeaderId: debitMemo.orderHeaderId,
                    awp
                })
                    .then(result => {
                        dispatch(result.data);
                        setContextMenuAwp({ show: false })
                        setAwp('')
                    })
                    .catch(logOut)
                    .finally(() => setSavingAwp(false))
            },
            type: 'u'
        }
    }, path))

    const ref = useRef({
        editValues: {},
        redbookSearchAbortController: null,
        orderDetail: null,
    })

    const onContextMenu = (e, od) => {
        e?.preventDefault();
        if (!editValues.id) {
            setOrderDetail(od);

            const clientX = e.clientX + 150 > window.innerWidth ? e.clientX - 145 : e.clientX;
            const clientY = e.clientY + 80 > window.innerHeight ? e.clientY - 75 : e.clientY;

            setContextMenu({
                show: true,
                clientX,
                clientY
            })
        }
    }

    useEffect(() => {
        const clickAwayListener = ({ target }) => {
            const contextMenu = document.querySelector(`#context-menu-${componentId}`);
            const contextMenuAwp = document.querySelector(`#context-menu-awp-${componentId}`)

            if (!contextMenu?.contains(target)) {
                setContextMenu({ show: false })

                if (!contextMenuAwp.contains(target))
                    setOrderDetail(null)
            }

            if (!contextMenuAwp.contains(target) && !contextMenu.contains(target)) {
                setContextMenuAwp({ show: false })
                setAwp('')
            }
        }
        const keyupListener = e => {
            const { editValues } = ref.current;

            if (e.keyCode === 27) {
                setContextMenu({ show: false })
                setEditValues({});
            }
            if (e.keyCode === 13 && editValues.id)
                networkCalls.updateOrderDetail();
        }

        const initListeners = () => {
            document.addEventListener('click', clickAwayListener);
            document.addEventListener('keyup', keyupListener);
        }
        const removeListeners = () => {
            document.removeEventListener('click', clickAwayListener);
            document.addEventListener('keyup', keyupListener);
        }

        initListeners();
        return () => removeListeners();
    }, [networkCalls])

    useEffect(() => {
        networkCalls.searchRedbook(editValues.ndc);
    }, [editValues.ndc, networkCalls])

    useEffect(() => ref.current.editValues = editValues, [editValues])

    const deleteOrderDetail = e => {
        e?.preventDefault();
        const { id } = orderDetail;
        networkCalls.deleteOrderDetail(id);
    }

    const editOrderDetail = e => {
        e?.preventDefault();

        setEditValues({
            id: orderDetail.id,
            ndc: orderDetail.redbook.ndc,
            deaClass: orderDetail.redbook.deaClass,
            medName: orderDetail.redbook.medName,
            manufacturer: debitMemo.manufacturer.name,
            packSize: orderDetail.redbook.packSize,
            unit: orderDetail.redbook.unit,
            awp: orderDetail.redbook.awp,
            fullPackage: orderDetail.fullPackage || 0,
            partialPackage: orderDetail.partialDecimal ? `${orderDetail.partialPackage}.${orderDetail.partialDecimal}` : orderDetail.partialPackage || 0,
            lotNumber: orderDetail.lotNumber || '',
            expiration: orderDetail.expiration || '',
            nonReturnableReasonCodeId: orderDetail.nonReturnableReasonCodeId || '',
            returnPolicyId: orderDetail.returnPolicyId || 0
        })

        setContextMenu({ show: false })
    }

    const onChange = e => setEditValues(p => ({ ...p, [e.target.id.split('-')[0]]: e.target.value }))

    useEffect(() => setEditValues({}), [debitMemo])
    useEffect(() => ref.current.orderDetail = orderDetail, [orderDetail])

    const closeContextMenuAndStopEditing = () => {
        setContextMenu({ show: false })
        setTimeout(() => {
            setOrderDetail(null);
        }, 500);
    }

    const getValue = orderDetails => parseFloat(orderDetails.reduce((a, b) => ({ value: a.value + b.value })).value).toFixed(2);

    const saveAwp = e => {
        e?.preventDefault();
        networkCalls.saveAwp(awp);
        setContextMenuAwp({ show: false })
    }

    const openContextMenuAwp = e => {
        e.preventDefault();

        setContextMenuAwp({
            show: true,
            clientX: contextMenu.clientX,
            clientY: contextMenu.clientY
        })

        setContextMenu({ show: false })
        setTimeout(() => {
            document.querySelector('#awp')?.focus();
        }, 250);
    }

    return (
        <div style={{ flex: 1, overflowY: 'auto' }} className="card">
            <div className="card-content">
                {getValue(debitMemo?.orderDetails) < debitMemo?.manufacturer?.minValue || 0 ?
                    <div className="row">
                        <h6 className="center yellow black-text">DM does not meet minimum value requirements (${debitMemo?.manufacturer?.minValue})</h6>
                    </div>
                    : null}
                <div className="row">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '2px' }}>NDC</th>
                                <th style={{ padding: '2px' }}>DEA</th>
                                <th style={{ padding: '2px' }}>Med Name</th>
                                <th style={{ padding: '2px' }}>Mfr</th>
                                <th style={{ padding: '2px' }}>Pack Size</th>
                                <th style={{ padding: '2px' }}>Full Pkg</th>
                                <th style={{ padding: '2px' }}>Partial Cnt</th>
                                <th style={{ padding: '2px' }}>Lot #</th>
                                <th style={{ padding: '2px' }}>Expiration</th>
                                <th style={{ padding: '2px' }}>AWP</th>
                                <th style={{ padding: '2px' }}>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {debitMemo?.orderDetails?.map(od => (
                                <tr key={od.id} className={orderDetail?.id === od.id && !editValues.id ? 'blue white-text' : ''} onContextMenu={e => onContextMenu(e, od)}>
                                    {
                                        editValues?.id === od.id ?
                                            <Fragment>
                                                <td style={{ padding: '2px' }}><input id={`ndc-${componentId}`} type="text" className="browser-default" onChange={onChange} value={editValues.ndc} /></td>
                                                <td style={{ padding: '2px' }}>{editValues.deaClass}</td>
                                                <td style={{ padding: '2px' }}>{editValues.medName}</td>
                                                <td style={{ padding: '2px' }}>{editValues.manufacturer}</td>
                                                <td style={{ padding: '2px' }}>{editValues.packSize} {editValues.unit}</td>
                                                <td style={{ padding: '2px' }}><input id={`fullPackage-${componentId}`} type="text" className="browser-default" onChange={onChange} value={editValues.fullPackage} /></td>
                                                <td style={{ padding: '2px' }}><input id={`partialPackage-${componentId}`} type="text" className="browser-default" onChange={onChange} value={editValues.partialPackage} /></td>
                                                <td style={{ padding: '2px' }}><input id={`lotNumber-${componentId}`} type="text" className="browser-default" onChange={onChange} value={editValues.lotNumber} /></td>
                                                <td style={{ padding: '2px' }}><input id={`expiration-${componentId}`} type="date" className="browser-default" onChange={onChange} value={editValues.expiration} /></td>
                                                <td style={{ padding: '2px' }}>{editValues.awp}</td>
                                                <td style={{ padding: '2px' }}><input id={`nonReturnableReasonCodeId-${componentId}`} type="text" className="browser-default" onChange={onChange} value={editValues.nonReturnableReasonCodeId} /></td>
                                                {parseInt(editValues.nonReturnableReasonCodeId) === 5 ?
                                                    <td style={{ padding: '2px' }}><input id={`indateBarcode-${componentId}`} type="text" placeholder="Indate Barcode" className="browser-default" onChange={onChange} value={editValues.indateBarcode} /></td>
                                                    : null}
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.redbook.ndc}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.redbook.deaClass}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.redbook.medName}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{debitMemo.manufacturer.name}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.redbook.packSize} {od.redbook.unit}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.fullPackage}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.partialDecimal ? `${od.partialPackage || 0}.${od.partialDecimal}` : od.partialPackage}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.lotNumber}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.expiration ? moment(od.expiration).format('MM/DD/YY') : ''}</td>
                                                <td className={!od.redbook.awp ? 'red white-text' : ''} style={{ padding: '2px', borderRadius: '0px' }}>{od.redbook.awp || 'No Price'}</td>
                                                <td style={{ padding: '2px', borderRadius: '0px' }}>{od.isRecall ? <i className="purple-text bold">RECALL</i> : od.nonReturnableReasonCodeId || ''}</td>
                                            </Fragment>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    {debitMemo?.dateNumberAssigned ?
                        <p className="col s12 m2 offset-m10 green white-text">Verified By: {debitMemo.verifiedBy.firstName} {debitMemo.verifiedBy.lastName}</p>
                        : null}
                </div>
            </div>
            <div id={`context-menu-${componentId}`} className={contextMenu.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: contextMenu.clientY,
                left: contextMenu.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    {orderDetail?.redbook.awp ?
                        null
                        :
                        <Fragment>
                            <li>
                                <a href="/" style={{ font: 'console', fontSize: '.8em' }} onClick={openContextMenuAwp}>Update AWP</a>
                                <div style={{ height: '6px' }} />
                                <div className="divider" />
                                <div style={{ height: '6px' }} />
                            </li>
                        </Fragment>
                    }
                    {debitMemo.dateNumberAssigned ?
                        null
                        :
                        <Fragment>
                            <li>
                                <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={editOrderDetail}>Edit</a>
                            </li>
                            <div style={{ height: '6px' }} />
                            <div className="divider" />
                            <div style={{ height: '6px' }} />
                        </Fragment>
                    }
                    <li>
                        <a href={`/regular/${debitMemo.orderHeaderId}/orderdetail`} target="_blank" rel="noreferrer" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={closeContextMenuAndStopEditing}>Open Order Detail Page</a>
                    </li>
                    <div style={{ height: '6px' }} />
                    <div className="divider" />
                    <div style={{ height: '6px' }} />
                    <li>
                        <a href={`/redbook/${orderDetail?.redbook.id}`} target="_blank" rel="noreferrer" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={closeContextMenuAndStopEditing}>Open Redbook Page</a>
                    </li>
                    {debitMemo.dateNumberAssigned ?
                        null
                        :
                        <Fragment>
                            <div style={{ height: '6px' }} />
                            <div className="divider" />
                            <div style={{ height: '6px' }} />
                            <li>
                                <a href="/" className="red-text" style={{ font: 'console', fontSize: '.8em' }} onClick={deleteOrderDetail}>Delete Order Detail</a>
                            </li>
                        </Fragment>
                    }
                </ul>
            </div>
            <div id={`context-menu-awp-${componentId}`} className={contextMenuAwp.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: contextMenuAwp.clientY,
                left: contextMenuAwp.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <input id="awp" className="browser-default" type="text" onChange={e => setAwp(e.target.value)} value={awp} />
                    </li>
                    <div style={{ height: '10px' }} />
                    <li>
                        <a
                            href="/"
                            style={{ height: '28px', lineHeight: '28px' }}
                            className="btn-small blue white-text waves-effect waves-light col s12"
                            onClick={saveAwp}
                            disabled={savingAwp}
                        >Save AWP</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default OrderDetailList;