import React from 'react';
import { connect } from 'react-redux';
import { registerRoutes, logOut, openSideNav } from '../../../func';
import { Link } from 'react-router-dom';
import { Input, CheckBox, TextArea, IconButton } from '../../../layout/form';
import M from '@materializecss/materialize';
import Axios from "axios";

class CreatePolicy extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
        this.id = this.props.match.params.id;
    }

    initState = () => ({
        saving: false,
        values: this.initValues(),
    })

    initValues = () => ({
        isReturnable: false,
        sealedPackagesOnly: false,
        labelsAllowed: false,
        minPackagePercentage: 0,
        controlsAllowed: false,
        awpAdjustment: 0,
        effectiveDate: '',
        expirationDate: '',
        notes: '',
        earliestReturnInMonths: 0,
        latestReturnInMonths: 0
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getManufacturer();
        this.initTooltips();
    }

    componentWillUnmount = () => {
        this.removeTooltips();
    }

    networkCalls = {
        getManufacturer: {
            func: () => {
                Axios.get('/api/v1/party/manufacturer/read/one/by/id/shallow', {
                    params: {
                        ...this.authData,
                        id: this.id
                    }
                })
                    .then(result => this.setState({ manufacturer: result.data }))
                    .catch(logOut)
            },
            type: 'r'
        },
        save: {
            func: () => {
                this.setState({ saving: true }, () => {
                    Axios.post('/api/v1/returnpolicy/create/one', {
                        ...this.authData,
                        partyId: this.id,
                        isReturnable: this.state.values.isReturnable,
                        sealedPackagesOnly: this.state.values.sealedPackagesOnly,
                        earliestReturnInMonths: this.state.values.earliestReturnInMonths || 0,
                        latestReturnInMonths: this.state.values.latestReturnInMonths || 0,
                        labelsAllowed: this.state.values.labelsAllowed,
                        minPackagePercentage: this.state.values.minPackagePercentage || 0,
                        controlsAllowed: this.state.values.controlsAllowed,
                        awpAdjustment: this.state.values.awpAdjustment || 0,
                        notes: this.state.values.notes || null,
                        effectiveDate: this.state.values.effectiveDate,
                        expirationdate: this.state.values.expirationdate || null
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.props.history.replace(`/manufacturer/${this.id}`)
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        }
    }

    initTooltips = () => {
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
    }

    removeTooltips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt) { tt.destroy(); }
        })
    }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))
    checkBoxChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: !p.values[e.target.id] } }))

    clear = () => this.setState({ values: this.initValues() })

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/manufacturer">Manufacturers</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/manufacturer/${this.id}`}>{this.state.mfr ? this.state.mfr.name : 'Manufacturer Detail'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">New Policy</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">New {this.state.manufacturer ? this.state.manufacturer.name : ''} Policy</h5>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection">
                        <li className="collection-item blue white-text">Create New Return Policy</li>
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    <div style={{ padding: '1vw' }}>
                        <div className="col s4 m3 l2">
                            <table>
                                <tbody>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Earliest Return</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Latest Return</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Returnable</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Sealed Only</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Labels Allowed</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Controls Allowed</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Min Package %</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>AWP Adjustment</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Effective Date</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Expiration Date</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Notes</td></tr>
                                </tbody>
                            </table>
                            <div className="row" style={{ marginTop: '40px' }}>
                                <IconButton icon="save" disabled={this.state.saving} onClick={this.networkCalls.save} tooltip="Save Policy" />
                                <IconButton icon="clear" onClick={this.clear} tooltip="Clear Values" />
                            </div>
                        </div>
                        <div className="col s8 m9 l10">
                            <table>
                                <tbody>
                                    <Input id="earliestReturnInMonths" type="number" value={this.state.values.earliestReturnInMonths} onChange={this.onChange} col="s12 m2" />
                                    <Input id="latestReturnInMonths" type="number" value={this.state.values.latestReturnInMonths} onChange={this.onChange} col="s12 m2" />
                                    <CheckBox id="isReturnable" value={this.state.values.isReturnable} onChange={this.checkBoxChange} col=" s12 m4" />
                                    <CheckBox id="sealedPackagesOnly" value={this.state.values.sealedPackagesOnly} onChange={this.checkBoxChange} col="s12 m4" />
                                    <CheckBox id="labelsAllowed" value={this.state.values.labelsAllowed} onChange={this.checkBoxChange} col="s12 m4" />
                                    <CheckBox id="controlsAllowed" value={this.state.values.controlsAllowed} onChange={this.checkBoxChange} col="s12 m4" />
                                    <Input id="minPackagePercentage" type="number" value={this.state.values.minPackagePercentage} onChange={this.onChange} col="s12 m2" />
                                    <Input id="awpAdjustment" type="number" value={this.state.values.awpAdjustment} onChange={this.onChange} col="s12 m2" />
                                    <Input id="effectiveDate" type="date" value={this.state.values.effectiveDate} onChange={this.onChange} col="s12 m3" />
                                    <Input id="expirationDate" type="date" value={this.state.values.expirationDate} onChange={this.onChange} col="s12 m3" />
                                    <TextArea id="notes" value={this.state.values.notes} onChange={this.onChange} col="s12 m8" />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(CreatePolicy);