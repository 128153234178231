import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';

class ReturnHandlerOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        returnHandlerList: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getReturnHandlers();
    }

    networkCalls = {
        getReturnHandlers: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/party/returnhandler/read/all', {
                        params: this.authData
                    })
                        .then(result => this.setState({ returnHandlerList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getReturnHandlers = () => this.networkCalls.getReturnHandlers();

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Return Handlers</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Return Handlers</h5>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Name</th>
                            <th style={{ padding: '4px' }}>City, State</th>
                            <th style={{ padding: '4px' }}>Phone</th>
                            <th style={{ padding: '4px' }}>Email</th>
                            <th style={{ padding: '4px' }}>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.returnHandlerList && this.state.returnHandlerList.map(rh => (
                            <tr key={rh.id}>
                                <td style={{ padding: '4px' }}><Link to={`/returnhandler/${rh.id}`}>{rh.name}</Link></td>
                                <td style={{ padding: '4px' }}>{rh.city}, {rh.state}</td>
                                <td style={{ padding: '4px' }}>{rh.phone}</td>
                                <td style={{ padding: '4px' }}>{rh.email}</td>
                                <td style={{ padding: '4px' }}>{rh.website}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <Link to="/returnhandler/create" className="col s12 btn-small blue white-text waves-effect">Create New Return Handler</Link>
                </div>
            </div>
        </div>
    )
}

export default connect()(ReturnHandlerOverview);