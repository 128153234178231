import { Fragment } from "react";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import Axios from "axios";
import { logOut, registerRoutes } from "../../../../func";
const componentId = crypto.randomUUID();

const EditTableRow = props => {
    const {
        orderDetail,
        path,
        order
    } = props;

    const [searching, setSearching] = useState(false);
    const [redbook, setRedbook] = useState(null);
    const [returnPolicy, setReturnPolicy] = useState('');
    const [recalls, setRecalls] = useState(null);
    const [isRecall, setIsRecall] = useState(false);
    const [earliestReturnDate, setEarliestReturnDate] = useState(undefined);

    const [values, setValues] = useState({
        ndc: orderDetail.redbook.ndc || '',
        fullPackage: orderDetail.fullPackage || 0,
        partialPackage: `${orderDetail.partialPackage}${parseInt(orderDetail.partialDecimal) ? `.${orderDetail.partialDecimal}` : ''}`,
        lotNumber: orderDetail.lotNumber || '',
        expiration: orderDetail.expiration ? moment(orderDetail.expiration).format('YYYY-MM-DD') : '',
        nonReturnableReasonCodeId: orderDetail.nonReturnableReasonCodeId || '',
        indateBarcode: orderDetail.indateBarcode || '',
        vaDestructionNumber: orderDetail.vaDestructionNumber || ''
    })

    const initReturnPolicyHighlights = () => ({
        sealedOnly: false,
        minPackagePercentage: false,
        controlsAllowed: false,
        tooFarExpired: false,
        indate: false
    })
    const [returnPolicyHighlights, setReturnPolicyHighlights] = useState(initReturnPolicyHighlights())

    const [networkCalls] = useState(registerRoutes({
        getRedbook: {
            func: (ndc, abortController) => {
                setSearching(true);

                Axios.get('/api/v2/redbook/read/one/deep/by/ndc', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        ndc
                    },
                    signal: abortController.signal
                })
                    .then(result => {
                        const redbook = result.data;
                        const { manufacturer } = redbook;
                        let returnPolicy = null;

                        if (!manufacturer.returnPolicies)
                            returnPolicy = 'No Return Policy';
                        else if (redbook.returnPolicyId)
                            returnPolicy = manufacturer.returnPolicies.find(row => parseInt(row.id) === parseInt(redbook.returnPolicyId)) || 'No Return Policy';
                        else if (manufacturer.returnPolicyId)
                            returnPolicy = manufacturer.returnPolicies.find(row => parseInt(row.id) === parseInt(manufacturer.returnPolicyId)) || 'No Return Policy'
                        else if (manufacturer.returnPolicies.length === 1)
                            returnPolicy = manufacturer.returnPolicies[0]
                        else
                            returnPolicy = 'Multiple Policies'

                        setRedbook(redbook);
                        setReturnPolicy(returnPolicy);
                        setRecalls(redbook.recalls);
                    })
                    .catch(logOut)
                    .finally(() => setSearching(false))
            },
            type: 'r'
        }
    }, path))

    const ref = useRef({
        abortController: null,
        recalls: null,
        redbook: null,
        returnPolicy: ''
    })
    useEffect(() => ref.current.recalls = recalls, [recalls]);
    useEffect(() => ref.current.redbook = redbook, [redbook]);
    useEffect(() => ref.current.returnPolicy = returnPolicy, [returnPolicy])

    const onChange = e => setValues(p => ({ ...p, [e.target.id.split('-')[0]]: e.target.value }))

    const checkReturnPolicy = () => {
        setReturnPolicyHighlights(initReturnPolicyHighlights());
        const { returnPolicy, redbook } = ref.current;

        if (returnPolicy) {
            if (
                values.expiration
                && returnPolicy.earliestReturnInMonths !== null && returnPolicy.earliestReturnInMonths !== undefined
                && returnPolicy.latestReturnInMonths !== null && returnPolicy.latestReturnInMonths !== undefined
            ) {
                const expiration = moment(values.expiration).add(1, 'day');
                const earliestReturnInMonths = expiration.clone().subtract(returnPolicy.earliestReturnInMonths, 'months');
                const latestReturnInMonths = expiration.clone().add(returnPolicy.latestReturnInMonths, 'months');

                if (moment().isBefore(earliestReturnInMonths))
                    setReturnPolicyHighlights(p => ({ ...p, indate: true }))


                if (moment().isAfter(latestReturnInMonths))
                    setReturnPolicyHighlights(p => ({ ...p, tooFarExpired: true }))

                if (returnPolicy.sealedPackagesOnly && (!parseInt(values.fullPackage) || values.partialPackage))
                    setReturnPolicyHighlights(p => ({ ...p, sealedOnly: true }))

                if (redbook && returnPolicy.minPackagePercentage !== null && returnPolicy.minPackagePercentage !== undefined) {
                    const calculatedMinPack = Math.ceil(parseFloat(returnPolicy.minPackagePercentage) * parseInt(redbook.packSize))
                    const calculatedTotalMed = (parseInt(values.fullPackage || 0) * parseInt(redbook.packSize) + parseFloat(values.partialPackage || 0));

                    if (calculatedTotalMed < calculatedMinPack)
                        setReturnPolicyHighlights(p => ({ ...p, minPackagePercentage: true }))
                }

                if (!returnPolicy.controlsAllowed && redbook.deaClass.toLowerCase() !== 'r')
                    setReturnPolicyHighlights(p => ({ ...p, controlsAllowed: true }))

                setEarliestReturnDate(earliestReturnInMonths.format('YYYY-MM-DD'));
            }
        }
    }

    useEffect(() => {
        setRedbook(null);
        setReturnPolicy('');
        ref.current.abortController?.abort();

        if (!values.ndc || values.ndc.length < 7)
            return;

        ref.current.abortController = new AbortController();
        networkCalls.getRedbook(values.ndc, ref.current.abortController);

    }, [values.ndc, orderDetail, networkCalls])

    useEffect(() => {
        const checkIsRecall = () => {
            let isRecall = false;
            const { lotNumber, expiration } = values;
            const { recalls } = ref.current;

            if (recalls && (lotNumber || expiration)) {
                recalls.forEach(recall => {
                    if (recall.lotNumber && lotNumber && recall.lotNumber.toLowerCase() === lotNumber.toLowerCase())
                        isRecall = true;

                    if (recall.expirationStart && recall.expirationEnd && expiration && moment(expiration).isBetween(moment(recall.expirationStart), moment(recall.expirationEnd), 'day', '[]'))
                        isRecall = true;
                })
            }

            setIsRecall(isRecall);
        }

        checkIsRecall();
        checkReturnPolicy();
    }, [values])

    const updateOrderDetail = e => {
        e?.preventDefault();

        props.update({
            orderDetailId: orderDetail.id,
            ndc: values.ndc,
            fullPackage: values.fullPackage || 0,
            partialPackage: values.partialPackage || 0,
            lotNumber: values.lotNumber || '',
            expiration: values.expiration || '',
            nonReturnableReasonCodeId: values.nonReturnableReasonCodeId || null,
            indateBarcode: values.indateBarcode || null,
            earliestReturnDate: earliestReturnDate || null,
            returnPolicyId: returnPolicy.id,
            vaDestructionNumber: values.vaDestructionNumber || null
        })
    }

    useEffect(checkReturnPolicy, [returnPolicy]);

    return (
        <Fragment>
            {typeof returnPolicy === 'string' ?
                <tr className="red-text bold" style={{ borderBottom: 'none', borderTop: '2px solid black' }}>
                    <td style={{ padding: '2px' }}>{returnPolicy || 'Med Not Found'}</td>
                </tr>
                :

                <tr style={{ borderBottom: 'none', borderTop: '2px solid black' }}>
                    <td className={!returnPolicy.isReturnable ? 'red-text bold' : ''} style={{ padding: '2px' }}>{returnPolicy.isReturnable ? 'Returnable' : 'No Returns'}</td>
                    <td className={`${returnPolicyHighlights.indate ? 'teal bold' : ''} ${returnPolicyHighlights.tooFarExpired ? 'orange bold' : ''} lighten-2`} style={{ padding: '2px' }}>{returnPolicy.earliestReturnInMonths}-{returnPolicy.latestReturnInMonths}</td>
                    <td className={returnPolicyHighlights.sealedOnly ? 'orange bold lighten-2' : ''} style={{ padding: '2px' }}>{returnPolicy.sealedPackagesOnly ? 'Sealed Only' : 'Unsealed OK'}</td>
                    <td className={returnPolicyHighlights.minPackagePercentage ? 'orange bold lighten-2' : ''} style={{ padding: '2px' }}>Min Pack: {returnPolicy.minPackagePercentage * 100}%</td>
                    <td style={{ padding: '2px' }}>{returnPolicy.labelsAllowed ? 'Labels OK' : 'No Labels'}</td>
                    <td className={returnPolicyHighlights.controlsAllowed ? 'orange bold lighten-2' : ''} style={{ padding: '2px' }}>{returnPolicy.controlsAllowed ? 'Controls OK' : 'No Controls'}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            }
            <tr style={{ borderBottom: 'none' }}>
                <td style={{ padding: '2px' }}><input style={{ maxWidth: '120px' }} className="browser-default" id={`ndc-${componentId}`} type="text" value={values.ndc} onChange={onChange} /></td>
                {searching ?
                    <td style={{ padding: '2px' }} colSpan={3}>
                        <div className="progress blue">
                            <div className="indeterminate" />
                        </div>
                    </td>
                    :
                    <Fragment>
                        <td style={{ padding: '2px' }}>{redbook?.deaClass}</td>
                        <td style={{ padding: '2px' }}>{redbook?.medName}</td>
                        <td style={{ padding: '2px' }}>{redbook?.manufacturer?.name}</td>
                    </Fragment>
                }
                <td style={{ padding: '2px' }}><input style={{ maxWidth: '40px' }} className="browser-default" id={`fullPackage-${componentId}`} type="text" value={values.fullPackage} onChange={onChange} /></td>
                <td style={{ padding: '2px' }}><input style={{ maxWidth: '40px' }} className="browser-default" id={`partialPackage-${componentId}`} type="text" value={values.partialPackage} onChange={onChange} /></td>
                <td style={{ padding: '2px' }}><input style={{ maxWidth: '100px' }} className="browser-default" id={`lotNumber-${componentId}`} type="text" value={values.lotNumber} onChange={onChange} /></td>
                <td style={{ padding: '2px' }}><input className="browser-default" id={`expiration-${componentId}`} type="date" value={values.expiration} onChange={onChange} /></td>

                {order?.returnServiceDeclined ?
                    <td style={{ padding: '2px' }}><input disabled={true} style={{ maxWidth: '40px' }} className="browser-default" id={`nonReturnableReasonCodeId-${componentId}`} type="text" value={values.nonReturnableReasonCodeId} onChange={onChange} /></td>
                    :
                    <>
                        {isRecall ?
                            <td style={{ padding: '2px' }} className="purple-text">RECALL</td>
                            :
                            <td style={{ padding: '2px' }}><input style={{ maxWidth: '40px' }} className="browser-default" id={`nonReturnableReasonCodeId-${componentId}`} type="text" value={values.nonReturnableReasonCodeId} onChange={onChange} /></td>
                        }
                    </>
                }
                {order?.isVa ?
                    <td style={{ padding: '2px' }}><input style={{ maxWidth: '120px' }} className="browser-default" id={`vaDestructionNumber-${componentId}`} type="text" value={values.vaDestructionNumber} onChange={onChange} /></td>
                    :
                    null
                }
            </tr>
            <tr style={{ borderBottom: '2px solid black' }}>
                {parseInt(values.nonReturnableReasonCodeId) === 5 ?
                    <td style={{ padding: '2px' }}><input style={{ maxWidth: '120px' }} className="browser-default" placeholder="Indate Barcode" id={`indateBarcode-${componentId}`} type="text" value={values.indateBarcode} onChange={onChange} /></td>
                    : null}
                <td style={{ padding: '2px' }}>
                    <a
                        href="/"
                        style={{ height: '18px', lineHeight: '18px' }}
                        className="btn orange white-text waves-effect waves-light"
                        disabled={
                            !redbook
                            || (!parseInt(values.fullPackage) && !parseFloat(values.partialPackage))
                            || (parseInt(values.nonReturnableReasonCodeId) === 5 && !values.indateBarcode)
                            || !returnPolicy
                            || typeof returnPolicy === 'string'}
                        onClick={updateOrderDetail}
                    >Update</a>
                </td>
            </tr>
        </Fragment>
    )
}

export default EditTableRow;