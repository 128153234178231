import { Link } from 'react-router-dom';
import { logOut, openSideNav } from '../../../func';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import AddOrderDetailForm from './AddOrderDetailForm';
import RedbookDetail from './redbookDetail/RedbookDetail';
import OrderDetailList from './orderDetailList/OrderDetailList';
import Axios from "axios";

const OrderDetailPage = props => {
    const { match } = props;
    const listOnly = props.location.pathname.search('listonly') !== -1
    const [order, setOrder] = useState(undefined);
    const [isVa, setIsVa] = useState(undefined);
    const [redbook, setRedbook] = useState(undefined);
    const [formValues, setFormValues] = useState(undefined);
    const [earliestReturnDate, setEarliestReturnDate] = useState(undefined);
    const [isRecall, setIsRecall] = useState(false);
    const [policy, setPolicy] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        Axios.get('/api/v1/order/header/read/one/by/id/simple', {
            params: {
                ...JSON.parse(localStorage.getItem('auth_data')),
                id: match.params.id
            },
            signal: abortController.signal
        })
            .then(result => setOrder(result.data))
            .catch(logOut)

        return () => abortController.abort();
    }, [match])

    useEffect(() => {
        const abortController = new AbortController();
        if (order?.orderNumber) {
            Axios.get('/api/v1/pharmacy/read/one/by/ordernumber', {
                params: {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    orderNumber: order.orderNumber
                },
                signal: abortController.signal
            })
                .then(result => setIsVa(result.data.isVa))
                .catch(logOut)

        }
        return () => abortController.abort();
    }, [order])

    return (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/regular">Regular Orders</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/regular/${props.match.params.id}`}>{order?.orderNumber ? order.orderNumber : 'Order Number'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Order Detail</span>
                </div>
            </div>
            {listOnly ?
                <div className="row">
                    <OrderDetailList
                        path={match.path}
                        orderHeaderId={match.params.id}
                        order={order}
                        isVa={isVa}
                        listOnly={listOnly}
                    />
                </div>
                :
                <div className="row">
                    <div className="col s12 m3 l2">
                        <AddOrderDetailForm
                            path={match.path}
                            orderHeaderId={match.params.id}
                            earliestReturnDate={earliestReturnDate}
                            passRedbookToParent={setRedbook}
                            passFormValuesToParent={setFormValues}
                            redbook={redbook}
                            isVa={isVa}
                            isRecall={isRecall}
                            policy={policy}
                            order={order}
                        />
                    </div>
                    <div className="col s12 m9 l10">
                        <RedbookDetail
                            path={match.path}
                            redbook={redbook}
                            formValues={formValues}
                            passEarliestReturnDateToParent={setEarliestReturnDate}
                            passRedbookToParent={setRedbook}
                            passRecallToParent={setIsRecall}
                            passPolicyToParent={setPolicy}
                        />
                        <OrderDetailList
                            path={match.path}
                            orderHeaderId={match.params.id}
                            order={order}
                            maxLines={5}
                            isVa={isVa}
                        />
                    </div>
                </div>
            }
        </div >
    )
}

export default connect()(OrderDetailPage);