import store from './redux/store';
import M from '@materializecss/materialize';
import actions from './redux/actions';
import Axios from 'axios';
const NODE_ENV = process.env.NODE_ENV;

export const logOut = async (err, callback) => {
    if (!window.localStorage.getItem('auth_data'))
        return window.location.replace('/');

    if (Axios.isCancel(err))
        return;

    if (err.response) {
        let status = err.response.status;
        let msg;
        let data;

        if (err.response.request.responseType === 'blob') {
            data = await readDataAsFile(err.response.data)
        }
        else {
            msg = err.response.data.payload.msg;
            data = err.response.data;
        }


        if (status === 401) {
            window.localStorage.clear();
            store.dispatch(err.response.data)
            store.dispatch({ type: actions.LOG_BACK_IN })
        }
        else {
            if (data && data.type && data.type === actions.GLOBAL_TOAST)
                store.dispatch(data)
        }
    }

    if (callback && typeof callback === 'function')
        return callback(err);
    else
        return null;
}

export const permissionToast = (action) => store.dispatch({ type: actions.GLOBAL_TOAST, payload: { msg: `Access Denied (${action?.toUpperCase()})`, class: 'red white-text' } })

export const openSideNav = e => {
    e.preventDefault();
    M.Sidenav.getInstance(document.querySelector('#side-navigation')).open();
}

const readDataAsFile = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
        resolve(JSON.parse(this.result));
    }
    reader.readAsText(blob);
})

//PERMISSIONS
export const registerRoutes = (networkCalls, path) => {
    if (path !== undefined)
        store.dispatch({ type: 'SET_CURRENT_PATH', payload: { currentPath: path } })

    if (!typeof networkCalls === 'object')
        throw new Error('You Must pass an object of Functions to registerRoutes()');

    if (path === '/' || path === '/user/timeclock')
        Object.keys(networkCalls).forEach(key => networkCalls[key] = networkCalls[key].func)
    else {
        const { permissions } = store.getState();
        const allPermissions = permissions.pages.find(p => p.route === '/**')

        if (allPermissions)
            Object.keys(networkCalls).forEach(key => networkCalls[key] = networkCalls[key].func)
        else {
            const activePermission = permissions.pages.find(p => p.route === path);

            if (!activePermission || !activePermission.action || typeof activePermission.action !== 'string')
                Object.keys(networkCalls).forEach(key => {
                    const type = networkCalls[key].type
                    networkCalls[key] = () => permissionToast(type)
                })
            else {

                const action = activePermission.action.split('');

                Object.keys(networkCalls).forEach(key => {
                    if (action.indexOf(networkCalls[key].type) !== -1)
                        networkCalls[key] = networkCalls[key].func;
                    else {
                        const type = networkCalls[key].type;
                        networkCalls[key] = () => permissionToast(type);
                    }
                })
            }
        }
    }
    return networkCalls;
}

export const setWebsocketURL = (path, params) => {
    const { _token, _session_id, _id, user_timezone } = JSON.parse(localStorage.getItem('auth_data'));
    const url = new URL(`${NODE_ENV === 'production' ? 'wss' : 'ws'}://${NODE_ENV === 'production' ? window.location.hostname : 'localhost:8080'}${path}`)
    url.searchParams.append('_token', _token);
    url.searchParams.append('_session_id', _session_id);
    url.searchParams.append('_id', _id);
    url.searchParams.append('user_timezone', user_timezone);

    if (params && typeof Array.isArray(params))
        params.forEach(param => url.searchParams.append(param.key, param.value));

    return url.href;
}