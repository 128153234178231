import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { logOut, registerRoutes, openSideNav } from "../../../func";
import { Link } from 'react-router-dom';
import DebitMemoList from './DebitMemoList';
import OrderDetailList from "./OrderDetailList";
import ReturnAuthorizationList from "./ReturnAuthorizationList";
import AttachmentList from "./AttachmentList";
import Split from './Split';
import Combine from './Combine';

const DebitMemoProcessPage = props => {
    const [orderHeaderId, setOrderHeaderId] = useState(null);
    const [debitMemoList, setDebitMemoList] = useState(null);
    const [activeDebitMemo, setActiveDebitMemo] = useState(null);
    const [activeTab, setActiveTab] = useState(null);

    const ref = useRef({
        abortController: null
    })

    const [networkCalls] = useState(registerRoutes({
        getOrderHeaderId: {
            func: () => {
                ref.current.abortController?.abort();
                ref.current.abortController = new AbortController();

                Axios.get('/api/v1/order/header/read/one/by/ordernumber', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        orderNumber: props.match.params.ordernumber
                    },
                    signal: ref.current.abortController.signal
                })
                    .then(result => setOrderHeaderId(result.data))
                    .catch(logOut)
            },
            type: 'r'
        }
    }, props.match.path))

    useEffect(() => {
        const { abortController } = ref.current;
        networkCalls.getOrderHeaderId();

        return () => abortController?.abort();
    }, [networkCalls])

    const getTab = () => {
        switch (activeTab) {
            case 'ra':
                return <ReturnAuthorizationList
                    debitMemo={activeDebitMemo}
                    path={props.match.path}
                    dispatch={props.dispatch}
                />
            case 'attachment':
                return <AttachmentList
                    debitMemo={activeDebitMemo}
                    path={props.match.path}
                    dispatch={props.dispatch}
                />
            case 'split':
                return <Split
                    debitMemo={activeDebitMemo}
                    path={props.match.path}
                    dispatch={props.dispatch}
                    setParentTab={setActiveTab}

                />
            case 'combine':
                return <Combine
                    path={props.match.path}
                    dispatch={props.dispatch}
                    setParentTab={setActiveTab}
                    debitMemoList={debitMemoList}
                    activeDebitMemo={activeDebitMemo}
                />
            default:
                return <OrderDetailList
                    debitMemo={activeDebitMemo}
                    path={props.match.path}
                    dispatch={props.dispatch}
                />
        }

    }

    return (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/debitmemo">Debit Memos</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{props.match?.params?.ordernumber}</span>
                </div>
            </div>
            <div className="row">
                <h5 style={{ display: 'inline-block' }}><span className="orange-text">{props.match?.params?.ordernumber}</span> Debit Memos</h5>
            </div>
            {orderHeaderId ?
                <div className="row">
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: 'CALC(100vh - 160px)' }}>
                        <DebitMemoList
                            orderHeaderId={orderHeaderId}
                            path={props.match.path}
                            passDebitMemoToParent={setActiveDebitMemo}
                            passTabToParent={setActiveTab}
                            passDebitMemoListToParent={setDebitMemoList}
                            dispatch={props.dispatch}
                        />
                        {activeDebitMemo ? getTab() : null}
                    </div>
                </div>
                : null}
        </div>
    )
}

export default connect()(DebitMemoProcessPage);