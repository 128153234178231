import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';

class WholesalerOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        wholesalerList: null,
        filterList: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getWholesalers()
    }

    networkCalls = {
        getWholesalers: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/party/wholesaler/read/all/simple', {
                        params: this.authData
                    })
                        .then(result => this.setState({ wholesalerList: result.data, filterList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getWholesalers = () => this.networkCalls.getWholesalers();

    filter = e => {
        const { value } = e.target;
        if (!value)
            return this.setState(p => ({ filterList: p.wholesalerList, filter: value }))

        const filterList = this.state.wholesalerList.filter(w => w.name.toLowerCase().search(value.toLowerCase()) !== -1);
        this.setState({ filterList, filter: value })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Wholesalers</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Wholesalers</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m2">
                    <Link to="/wholesaler/create" className="btn-small blue white-text waves-effect col s12">Create New Wholesaler</Link>
                </div>
                <div className="input-field col s12 m3 offset-m7">
                    <input id="filter" type="text" onChange={this.filter} value={this.state.filter} />
                    <label htmlFor="filter">Filter Wholesalers</label>
                </div>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Name</th>
                            <th style={{ padding: '4px' }}>City, State</th>
                            <th style={{ padding: '4px' }}>Phone</th>
                            <th style={{ padding: '4px' }}>Email</th>
                            <th style={{ padding: '4px' }}>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.filterList && this.state.filterList.map(w => (
                            <tr key={`wh-${w.id}`}>
                                <td style={{ padding: '4px' }}><Link to={`/wholesaler/${w.id}`}>{w.name}</Link></td>
                                <td style={{ padding: '4px' }}>{w.city}, {w.state}</td>
                                <td style={{ padding: '4px' }}>{w.phone}</td>
                                <td style={{ padding: '4px' }}>{w.email}</td>
                                <td style={{ padding: '4px' }}>{w.website}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect()(WholesalerOverview);