import React from 'react';
import { connect } from 'react-redux';
import { logOut, registerRoutes } from '../../../func';
import Axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

class ActivityTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        activityList: null,
        contextMenu: {
            clientX: 0,
            clientY: 0,
            show: false,
            logEntry: null
        },
        showDetail: false,
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.networkCalls.getReadRecentCustomerPortalActivity();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.addEventListener('click', this.clickAwayListener);
    }

    removeListeners = () => {
        document.removeEventListener('click', this.clickAwayListener);
    }

    clickAwayListener = e => {
        if (
            this.state.contextMenu.show &&
            !document.querySelector('#context-menu').contains(e.target)
        )
            this.setState({
                contextMenu: {
                    clientX: 0,
                    clientY: 0,
                    show: false
                }
            })
    }

    networkCalls = {
        getReadRecentCustomerPortalActivity: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/website/administration/read/all/recentactivity', {
                        params: this.authData
                    })
                        .then(result => this.setState({ activityList: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    onContextMenu = (e, logEntry) => {
        e?.preventDefault();
        let { clientX, clientY } = e;

        if (clientX + 300 > window.innerWidth)
            clientX = clientX - 280;

        this.setState({
            contextMenu: {
                clientX,
                clientY,
                show: true,
                logEntry
            },
            showDetail: false,
        })
    }

    viewDetails = () => this.setState(p => ({
        showDetail: true,
        contextMenu: {
            ...p.contextMenu,
            clientX: 0,
            clientY: 0,
            show: false
        }
    }))

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <h5>Recent Customer Portal Activity</h5>
            </div>
            <div className="row">
                {this.state.loading ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '2px' }}>User</th>
                                <th style={{ padding: '2px' }}>Primary Pharmacy</th>
                                <th style={{ padding: '2px' }}>Route</th>
                                <th style={{ padding: '2px' }}>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.activityList?.map(row => (
                                <tr
                                    key={row.id}
                                    className={this.state.contextMenu?.logEntry?.id === row.id ? 'grey lighten-3' : ''}
                                    style={{ cursor: 'default' }}
                                    onContextMenu={e => this.onContextMenu(e, row)}
                                >
                                    <td style={{ padding: '2px' }}>{row.firstName} {row.lastName}</td>
                                    <td style={{ padding: '2px' }}>{row.pharmacyName}</td>
                                    <td style={{ padding: '2px' }}>{row.route}</td>
                                    <td style={{ padding: '2px' }}>{moment(row.timeStamp).format('MM/DD/YYYY hh:mm a')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
            <div id="context-menu" className={this.state.contextMenu?.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: this.state.contextMenu?.clientY,
                left: this.state.contextMenu?.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px',
                width: '280px',
                overflow: 'hidden'
            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em', wordWrap: 'break-word' }} onClick={e => e.preventDefault()} onMouseDown={this.viewDetails}>View Details</a>
                    </li>
                </ul>
            </div>
            <div id="log-entry-detail" className={this.state.showDetail ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: '40px',
                right: '40px',
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '2px 2px 3px #ccc',
                borderRadius: '1px',
                padding: '8px',
                width: '33vw',
                minWidth: '300px',
                overflowX: 'hidden',
                overflowY: 'auto',
                maxHeight: '90vh'
            }}>
                <div className="row">
                    <i className="material-icons col s1 offset-s11" style={{ cursor: 'pointer' }} onClick={() => this.setState({ showDetail: false })}>close</i>
                </div>
                <div className="row">
                    <h5 className="center" style={{ textDecoration: 'underline' }}>Entry Details</h5>
                </div>
                {this.state.contextMenu?.logEntry ?
                    <div className="row">
                        <div className="col s4">
                            <table>
                                <tbody className="bolder">
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Log Entry ID</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Name</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Pharmacy</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>EmailAddress</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Page Route</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Device Used</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Operating System</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Browser</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Browser Version</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px', textAlign: 'right', marginRight: '12px' }}>Time Stamp</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col s8">
                            <table>
                                <tbody>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.id}</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.firstName} {this.state.contextMenu.logEntry.lastName}</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.pharmacyId ?
                                        <Link to={`/pharmacy/${this.state.contextMenu.logEntry.pharmacyId}`}>{this.state.contextMenu.logEntry.pharmacyName}</Link>
                                        : <span>&nbsp;</span>
                                    }</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.emailAddress ?
                                        <a href={`mailto:${this.state.contextMenu.logEntry.emailAddress}`}>{this.state.contextMenu.logEntry.emailAddress}</a>
                                        : <span>&nbsp;</span>
                                    }</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.route ?
                                        <a href={`https://reports.outdaterx.com${this.state.contextMenu.logEntry.route}`}>{this.state.contextMenu.logEntry.route}</a>
                                        : <span>&nbsp;</span>
                                    }</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.device || <span>&nbsp;</span>}</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.os || <span>&nbsp;</span>}</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.browser || <span>&nbsp;</span>}</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{this.state.contextMenu.logEntry.browserVersion || <span>&nbsp;</span>}</td></tr>
                                    <tr style={{ border: 'none' }}><td style={{ padding: '2px' }}>{moment(this.state.contextMenu.logEntry.timeStamp).format('MM/DD/YYYY hh:mm a')}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default connect()(ActivityTab);