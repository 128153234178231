import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { logOut, registerRoutes } from '../../../func';
import { AutoComplete, CheckBox, IconButton, Input, TextArea } from '../../../layout/form';
import M from '@materializecss/materialize';
import moment from "moment";

class CreateTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        saving: false,
        pharmacyList: null,
        employeeList: null,
        values: this.initValues()
    })

    initValues = () => ({
        serviceDate: '',
        orderNumber: '',
        parentOrderNumber: '',
        outdateFeeOverride: '',
        returnServiceDeclined: false,
        isDestructionOnly: false,
        isHouseReturn: false,
        notes: '',
        defaultFeePercentage: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.path);
        this.networkCalls.getPharmacies();
        this.networkCalls.getEmployees();
        this.initTooltips();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeTooltips();
        this.removeListners();
    }

    initListeners = () => {
        document.querySelector('#pharmacyId').addEventListener('blur', this.pharmacyListener);
    }

    removeListners = () => {
        document.querySelector('#pharmacyId').removeEventListener('blur', this.pharmacyListener);
    }

    pharmacyListener = e => {
        const pharmacy = this.state.pharmacyList.filter(p => `${p.name} - ${p.customerNumber}` === e.target.value)[0];
        this.setState(p => ({ values: { ...p.values, defaultFeePercentage: pharmacy ? pharmacy.defaultFeePercentage : null } }))
    }

    networkCalls = {
        getPharmacies: {
            func: () => {
                Axios.get('/api/v1/pharmacy/read/all/simple', {
                    params: this.authData
                })
                    .then(result => {
                        const pharmacyList = result.data;
                        const pharmacyListAsObject = {};
                        pharmacyList.forEach(p => pharmacyListAsObject[`${p.name} - ${p.customerNumber}`] = null);

                        this.setState({ pharmacyList, pharmacyListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getEmployees: {
            func: () => {
                Axios.get('/api/v1/employee/read/active', {
                    params: this.authData
                })
                    .then(result => {
                        const employeeList = result.data;
                        const employeeListAsObject = {}
                        employeeList.forEach(e => employeeListAsObject[`${e.firstName} ${e.lastName}`] = null);

                        this.setState({ employeeList, employeeListAsObject })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        save: {
            func: () => {
                this.setState({ saving: true }, () => {
                    const pharmacyId = this.state.pharmacyList.filter(p => `${p.name} - ${p.customerNumber}` === document.querySelector('#pharmacyId').value)[0]?.id;
                    const employeeId = this.state.employeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#employeeId').value)[0]?.id;

                    Axios.post('/api/v1/order/header/create/one', {
                        ...this.authData,
                        pharmacyId,
                        employeeId,
                        orderNumber: this.state.values.orderNumber.trim(),
                        outdateFeeOverride: this.state.values.outdateFeeOverride,
                        serviceDate: this.state.values.serviceDate ? moment(this.state.values.serviceDate).format('YYYY-MM-DD hh:mm') : null,
                        notes: this.state.values.notes,
                        returnServiceDeclined: this.state.values.returnServiceDeclined,
                        isHouseReturn: this.state.values.isHouseReturn,
                        isDestructionOnly: this.state.values.isDestructionOnly,
                        parentOrderNumber: this.state.values.parentOrderNumber || null
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.networkCalls.goTo(this.state.values.orderNumber);
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ saving: false }))
                })
            },
            type: 'c'
        },
        goTo: {
            func: orderNumber => {
                Axios.get('/api/v1/order/header/read/one/by/ordernumber', {
                    params: {
                        ...this.authData,
                        orderNumber,
                    }
                })
                    .then(result => this.props.reload(result.data))
                    .catch(logOut)
            },
            type: 'r'
        }
    }

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))
    checkBoxChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: !p.values[e.target.id] } }))

    initTooltips = () => setTimeout(() => M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 }))
    removeTooltips = () => document.querySelectorAll('.tooltipped').forEach(el => M.Tooltip.getInstance(el)?.destroy());

    render = () => (
        <div style={{ padding: '1vw' }}>
            <div className="row">
                <div className="col s4 m3 l2">
                    <div className="row">
                        <table>
                            <tbody>
                                <tr><td style={{ padding: '4px' }}>Pharmacy Name</td></tr>
                                <tr><td style={{ padding: '4px' }}>Service Date</td></tr>
                                <tr><td style={{ padding: '4px' }}>Rep On-Site</td></tr>
                                <tr><td style={{ padding: '4px' }}>Order Number</td></tr>
                                <tr><td style={{ padding: '4px' }}>Parent Order Number</td></tr>
                                <tr><td style={{ padding: '4px' }}>Fee Override {this.state.values?.defaultFeePercentage ? `(default: ${this.state.values.defaultFeePercentage}%)` : ''}</td></tr>
                                <tr><td style={{ padding: '4px' }}>Return Service Declined</td></tr>
                                <tr><td style={{ padding: '4px' }}>Destruction Only</td></tr>
                                <tr><td style={{ padding: '4px' }}>House Return</td></tr>
                                <tr><td style={{ padding: '4px' }}>Notes</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <IconButton icon="save" tooltip="Save" onClick={() => this.networkCalls.save()} />
                    </div>
                </div>
                <div className="col s8 m9 l10">
                    <table>
                        <tbody>
                            <AutoComplete id="pharmacyId" data={this.state.pharmacyListAsObject} col="s12 m9 l7" />
                            <Input id="serviceDate" type="datetime-local" value={this.state.values.serviceDate} onChange={this.onChange} col="s12 m4 l3" />
                            <AutoComplete id="employeeId" data={this.state.employeeListAsObject} col="s8 m5" />
                            <Input id="orderNumber" type="text" value={this.state.values.orderNumber} onChange={this.onChange} col="s12 m2" />
                            <Input id="parentOrderNumber" type="text" value={this.state.values.parentOrderNumber} onChange={this.onChange} col="s12 m2" />
                            <Input id="outdateFeeOverride" type="number" value={this.state.values.outdateFeeOverride} onChange={this.onChange} col="s4 m1" />
                            <CheckBox id="returnServiceDeclined" value={this.state.values.returnServiceDeclined} onChange={this.checkBoxChange} col="s12 m3" />
                            <CheckBox id="isDestructionOnly" value={this.state.values.isDestructionOnly} onChange={this.checkBoxChange} col="s12 m3" />
                            <CheckBox id="isHouseReturn" value={this.state.values.isHouseReturn} onChange={this.checkBoxChange} col="s12 m3" />
                            <TextArea id="notes" value={this.state.values.notes} onChange={this.onChange} col="s12 m8" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default connect()(CreateTab);