import Axios from "axios";
import { logOut, registerRoutes } from "../../../func";

const { useState, useEffect, useRef } = require("react");
const componentId = crypto.randomUUID();

const ReturnAuthorizationList = props => {
    const {
        debitMemo,
        path,
        dispatch
    } = props;

    const [returnAuthorizationNumber, setReturnAuthorizationNumber] = useState('');
    const [adding, setAdding] = useState(false);
    const [returnAuthorization, setReturnAuthorization] = useState(null);
    const [contextMenu, setContextMenu] = useState({ show: false })

    const [networkCalls] = useState(registerRoutes({
        addReturnAuthorization: {
            func: () => {
                if (adding) { return }

                setAdding(true);
                const { debitMemo, returnAuthorizationNumber } = ref.current;

                Axios.post('/api/v2/debitmemo/update/one/addra', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoId: debitMemo.id,
                    returnAuthorizationNumber
                })
                    .then(result => {
                        dispatch(result.data)
                        setReturnAuthorizationNumber('')
                    })
                    .catch(logOut)
                    .finally(() => setAdding(false))
            },
            type: 'u'
        },
        deleteReturnAuthorization: {
            func: e => {
                e?.preventDefault();
                setContextMenu({ show: false })

                const { returnAuthorization } = ref.current;

                Axios.post('/api/v2/debitmemo/update/one/deletera', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    returnAuthorizationId: returnAuthorization.id
                })
                    .then(result => dispatch(result.data))
                    .catch(logOut)
            },
            type: 'd'
        }
    }, path))

    const ref = useRef({
        debitMemo: null,
        returnAuthorizationNumber: '',
        returnAuthorization: null
    })

    const onChange = ({ target }) => setReturnAuthorizationNumber(target.value)

    useEffect(() => {
        const enterListener = e => { if (e.keyCode === 13) { networkCalls.addReturnAuthorization() } }
        const clickAwayListener = ({ target }) => {
            if (!document.querySelector(`#context-menu-${componentId}`).contains(target)) {
                setContextMenu({ show: false })
                setReturnAuthorization(null);
            }
        }

        const initListeners = () => {
            document.addEventListener('click', clickAwayListener)
            document.querySelector(`#returnAuthorizationNumber-${componentId}`)?.addEventListener('keyup', enterListener)
        }

        const removeListeners = () => {
            document.removeEventListener('click', clickAwayListener);
            document.querySelector(`#returnAuthorizationNumber-${componentId}`)?.removeEventListener('keyup', enterListener)
        }

        initListeners()

        return () => {
            removeListeners();
        }
    }, [])

    useEffect(() => ref.current.debitMemo = debitMemo, [debitMemo]);
    useEffect(() => ref.current.returnAuthorizationNumber = returnAuthorizationNumber, [returnAuthorizationNumber]);
    useEffect(() => ref.current.returnAuthorization = returnAuthorization, [returnAuthorization])

    const onContextMenu = (e, ra) => {
        e?.preventDefault();
        setReturnAuthorization(ra);
        const clientX = e.clientX + 150 > window.innerWidth ? e.clientX - 145 : e.clientX;
        const clientY = e.clientY + 80 > window.innerHeight ? e.clientY - 75 : e.clientY;

        setContextMenu({
            show: true,
            clientX,
            clientY
        })
    }

    return (
        <div style={{ flex: 1, overflowY: 'auto' }} className="card">
            <div className="card-content">
                <div className="row">
                    <input id={`returnAuthorizationNumber-${componentId}`} className="browser-default" placeholder="ADD RA #" type="text" value={returnAuthorizationNumber} onChange={onChange} />
                    {adding ?
                        <div className="preloader-wrapper active" style={{ width: '20px', height: '20px', position: 'relative', top: '4px', left: '8px' }}>
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                <div className="row">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '0px' }}>Return Authorization Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {debitMemo?.returnAuthorizations?.map(ra => (
                                <tr key={ra.id} className={parseInt(ra.id) === parseInt(returnAuthorization?.id) ? 'blue white-text' : ''} onContextMenu={e => onContextMenu(e, ra)}>
                                    <td style={{ padding: '0px' }}>{ra.returnAuthorizationNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div id={`context-menu-${componentId}`} className={contextMenu.show ? '' : 'hide'} style={{
                zIndex: 100,
                position: 'fixed',
                top: contextMenu.clientY,
                left: contextMenu.clientX,
                border: '1px solid black',
                backgroundColor: 'white',
                boxShadow: '1px 1px 2px #ddd',
                borderRadius: '1px',
                padding: '8px'

            }}>
                <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                    <li>
                        <a href="/" className="red-text" style={{ font: 'console', fontSize: '.8em' }} onClick={networkCalls.deleteReturnAuthorization}>Delete RA</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ReturnAuthorizationList;