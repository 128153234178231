import React from 'react';
import { connect } from "react-redux";

class EmployeeListTab extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({})

    render = () => (
        <div style={{ padding: '1vw' }}>
            <table>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.employeeList?.map(e => (
                        <tr key={e.id}>
                            <td>{e.firstName}</td>
                            <td>{e.lastName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default connect()(EmployeeListTab);