import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { logOut, openSideNav, registerRoutes } from '../../func';

class DebitMemoOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        orderList: null,
        search: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getOrders();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }



    initListeners = () => {
        document.getElementById('search').addEventListener('keyup', this.searchListener);
    }

    removeListeners = () => {
        document.getElementById('search').removeEventListener('keyup', this.searchListener);
    }

    networkCalls = {
        searchListener: {
            func: ({ keyCode }) => {
                if (keyCode === 13) {
                    const search = this.state.search.toLowerCase();
                    let month = null;
                    let start = null;
                    let end = null;
                    let control = null;
                    let number = null;

                    [start, month, end] = search.split('-');
                    if (end && end.toLowerCase().search('c') !== -1) {
                        control = end[0];
                        number = end.toLowerCase().split('c')[1]
                    }
                    else {
                        control = 'r';
                        number = end;
                    }
                    start.split(''); //TO GET RID OF REACT WARNING ABOUT UNUSED VARIABLE

                    Axios.get('/api/v1/debitmemo/read/one/search', {
                        params: {
                            ...this.authData,
                            orderNumber: month ? null : this.state.search,
                            month: month || null,
                            control: control || null,
                            number: parseInt(number) || null
                        }
                    })
                        .then(result => {
                            if (!result.data)
                                this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Order Not Found', class: 'orange white-text' } })
                            else
                                this.props.history.push(`/debitmemo/${result.data.orderNumber}`)
                        })
                        .catch(logOut)
                }
            },
            type: 'r'
        },
        getOrders: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/debitmemo/read/orders/by/returnable/debitmemo', {
                        params: this.authData
                    })
                        .then(result => this.setState({ orderList: result.data }))
                        .catch(logOut)
                })
            },
            type: 'r'
        }
    }

    searchListener = e => this.networkCalls.searchListener(e);
    getOrders = () => this.networkCalls.getOrders();

    onChange = ({ target }) => this.setState({ [target.id]: target.value })

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Debit Memos</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Debit Memos</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 offset-m9">
                    <input id="search" type="text" onChange={this.onChange} value={this.state.search} />
                    <label htmlFor="search">Search</label>
                </div>
            </div>
            <div className="row">
                <table className="highlight">
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Pharmacy</th>
                            <th style={{ padding: '4px' }}>Order Number</th>
                            <th style={{ padding: '4px' }}>Order Date</th>
                            <th style={{ padding: '4px' }}>Status</th>
                            <th style={{ padding: '4px' }}>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orderList && this.state.orderList.map(o => (
                            <tr key={`order-${o.id}`}>
                                <td style={{ padding: '4px' }}>{o.pharmacyName}</td>
                                <td style={{ padding: '4px' }}>{o.orderNumber}</td>
                                <td style={{ padding: '4px' }}>{moment(o.orderDate).format('MM/DD/YY')}</td>
                                <td style={{ padding: '4px' }}>{o.status}</td>
                                <td style={{ padding: '4px' }}><Link to={`/debitmemo/${o.orderNumber}`}>Details</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect()(DebitMemoOverview);