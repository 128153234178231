import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import M from '@materializecss/materialize';
import { logOut, openSideNav, registerRoutes } from '../../../func';
import { Input, TextArea, CheckBox, IconButton } from '../../../layout/form';

class UpdatePolicy extends React.Component {
    constructor(props) {
        super(props);
        this.partyId = this.props.match.params.id;
        this.policyId = this.props.match.params.policyid;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        loading: false,
        updating: false,
        oldValues: null,
        newValues: this.initValues()
    })

    initValues = () => ({
        isReturnable: false,
        sealedPackagesOnly: false,
        labelsAllowed: false,
        minPackagePercentage: 0,
        controlsAllowed: false,
        awpAdjustment: 0,
        effectiveDate: '',
        expirationDate: '',
        notes: '',
        earliestReturnInMonths: 0,
        latestReturnInMonhths: 0
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getPolicy();
        this.initTooltips();
    }

    componentWillUnmount = () => {
        this.removeTooltips();
    }

    networkCalls = {
        getPolicy: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/returnpolicy/read/one', {
                        params: {
                            ...this.authData,
                            id: this.policyId
                        }
                    })
                        .then(result => this.hydrateValues(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        update: {
            func: () => {
                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/returnpolicy/update/one', {
                        ...this.authData,
                        isReturnable: this.state.newValues.isReturnable,
                        sealedPackagesOnly: this.state.newValues.sealedPackagesOnly,
                        earliestReturnInMonths: this.state.newValues.earliestReturnInMonths,
                        latestReturnInMonths: this.state.newValues.latestReturnInMonths,
                        labelsAllowed: this.state.newValues.labelsAllowed,
                        minPackagePercentage: this.state.newValues.minPackagePercentage,
                        controlsAllowed: this.state.newValues.controlsAllowed,
                        awpAdjustment: this.state.newValues.awpAdjustment,
                        notes: this.state.newValues.notes || null,
                        effectiveDate: this.state.newValues.effectiveDate,
                        expirationDate: this.state.newValues.expirationDate || null,
                        id: this.policyId
                    })
                        .then(result => {
                            this.props.dispatch(result.data)
                            this.props.history.replace(`/manufacturer/${this.partyId}`)
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ updating: false }))
                })
            },
            type: 'u'
        }
    }

    hydrateValues = rp => {
        const values = {
            isReturnable: rp.isReturnable ? true : false,
            sealedPackagesOnly: rp.sealedPackagesOnly ? true : false,
            labelsAllowed: rp.labelsAllowed ? true : false,
            minPackagePercentage: rp.minPackagePercentage || 0,
            controlsAllowed: rp.controlsAllowed ? true : false,
            awpAdjustment: rp.awpAdjustment || 0,
            effectiveDate: rp.effectiveDate || '',
            expirationDate: rp.expirationDate || '',
            notes: rp.notes || '',
            earliestReturnInMonths: rp.earliestReturnInMonths || 0,
            latestReturnInMonths: rp.latestReturnInMonths || 0
        }
        this.setState({ oldValues: values, newValues: values, manufacturerName: rp.manufacturerName }, () => M.textareaAutoResize(document.querySelector('#notes')))
    }

    initTooltips = () => {
        M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
    }

    removeTooltips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt) { tt.destroy(); }
        })
    }

    revert = () => this.setState(p => ({ newValues: p.oldValues }), () => M.textareaAutoResize(document.querySelector('#notes')))

    onChange = e => this.setState(p => ({ newValues: { ...p.newValues, [e.target.id]: e.target.value } }))
    checkBoxChange = e => this.setState(p => ({ newValues: { ...p.newValues, [e.target.id]: !p.newValues[e.target.id] } }))

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/manufacturer">Manufacturers</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/manufacturer/${this.id}`}>{this.state.mfr ? this.state.mfr.name : 'Manufacturer Detail'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">New Policy</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Update {this.state.manufacturerName ? this.state.manufacturerName : ''} Policy</h5>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection">
                        <li className="collection-item blue white-text">Create New Return Policy</li>
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    <div style={{ padding: '1vw' }}>
                        <div className="col s4 m3 l2">
                            <table>
                                <tbody>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Earliest Return</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Latest Return</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Returnable</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Sealed Only</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Labels Allowed</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Controls Allowed</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Min Package %</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>AWP Adjustment</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Effective Date</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Expiration Date</td></tr>
                                    <tr><td style={{ padding: '4px', textAlign: 'right' }}>Notes</td></tr>
                                </tbody>
                            </table>
                            <div className="row" style={{ marginTop: '40px' }}>
                                <IconButton icon="upload" disabled={this.state.updating} onClick={this.networkCalls.update} tooltip="Update Policy" />
                                <IconButton icon="restore" onClick={this.revert} tooltip="Discard Changes" />
                            </div>
                        </div>
                        <div className="col s8 m9 l10">
                            <table>
                                <tbody>
                                    <Input id="earliestReturnInMonths" type="number" value={this.state.newValues.earliestReturnInMonths} onChange={this.onChange} col="s12 m2" disabled={this.state.loading} />
                                    <Input id="latestReturnInMonths" type="number" value={this.state.newValues.latestReturnInMonths} onChange={this.onChange} col="s12 m2" disabled={this.state.loading} />
                                    <CheckBox id="isReturnable" value={this.state.newValues.isReturnable} onChange={this.checkBoxChange} col=" s12 m4" disabled={this.state.loading} />
                                    <CheckBox id="sealedPackagesOnly" value={this.state.newValues.sealedPackagesOnly} onChange={this.checkBoxChange} col="s12 m4" disabled={this.state.loading} />
                                    <CheckBox id="labelsAllowed" value={this.state.newValues.labelsAllowed} onChange={this.checkBoxChange} col="s12 m4" disabled={this.state.loading} />
                                    <CheckBox id="controlsAllowed" value={this.state.newValues.controlsAllowed} onChange={this.checkBoxChange} col="s12 m4" disabled={this.state.loading} />
                                    <Input id="minPackagePercentage" type="number" value={this.state.newValues.minPackagePercentage} onChange={this.onChange} col="s12 m2" disabled={this.state.loading} />
                                    <Input id="awpAdjustment" type="number" value={this.state.newValues.awpAdjustment} onChange={this.onChange} col="s12 m2" disabled={this.state.loading} />
                                    <Input id="effectiveDate" type="date" value={this.state.newValues.effectiveDate} onChange={this.onChange} col="s12 m3" disabled={this.state.loading} />
                                    <Input id="expirationDate" type="date" value={this.state.newValues.expirationDate} onChange={this.onChange} col="s12 m3" disabled={this.state.loading} />
                                    <TextArea id="notes" value={this.state.newValues.notes} onChange={this.onChange} col="s12 m8" disabled={this.state.loading} />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(UpdatePolicy);