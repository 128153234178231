import React from 'react';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { logOut, openSideNav, registerRoutes } from '../../func';

class SearchRedbook extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
    }

    initState = () => ({
        loading: false,
        meds: null,
        values: this.initValues()
    })

    initValues = () => ({
        ndc: '',
        manufacturerName: '',
        deaClass: '',
        medName: '',
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    networkCalls = {
        search: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/redbook/read/search/by/general/deep', {
                        params: {
                            ...this.authData,
                            ...this.state.values
                        }
                    })
                        .then(result => this.setState({ meds: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    search = () => this.networkCalls.search();

    getClasses = () => (['R', '2', '3', '4', '5'])

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Redbook</span>
                </div>
                <div className="row">
                    <h5 className="center">Redbook</h5>
                </div>
                <form>
                    <div className="row">
                        <div className="input-field col s12 m2 l1">
                            <input id="ndc" type="text" onChange={this.onChange} value={this.state.values.ndc} />
                            <label htmlFor="ndc">NDC</label>
                        </div>
                        <div className="input-field col s12 m3 l3">
                            <input id="manufacturerName" type="text" onChange={this.onChange} value={this.state.values.manufacturerName} />
                            <label htmlFor="manufacturerName">Manufacturer</label>
                        </div>
                        <div className="input-field col s12 m3 l3">
                            <input id="medName" type="text" onChange={this.onChange} value={this.state.values.medName} />
                            <label htmlFor="medName">Med Name</label>
                        </div>
                        <div className="input-field col s12 m2 l2">
                            <select id="deaClass" onChange={this.onChange} value={this.state.values.deaClass}>
                                <option value={''}>ANY</option>
                                {this.getClasses().map(c => <option key={c} value={c}>{c}</option>)}
                            </select>
                            <label htmlFor="deaClass">DEA Class</label>
                        </div>
                        {this.state.loading ?
                            <div className="preloader-wrapper small active">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="input-field col s12 m2">
                                <button type="submit" className="btn-small blue white-text waves-effect" onClick={this.search}>Search Redbook</button>
                            </div>
                        }
                    </div>
                </form>
            </div>
            <table className="highlight">
                <thead>
                    <tr>
                        <th style={{ padding: '4px' }}>NDC</th>
                        <th style={{ padding: '4px' }}>Med Name</th>
                        <th style={{ padding: '4px' }}>Manufacturer</th>
                        <th style={{ padding: '4px' }}>DEA Class</th>
                        <th style={{ padding: '4px' }}>Pack Size</th>
                        <th style={{ padding: '4px' }}>Units</th>
                        <th style={{ padding: '4px' }}>Liquid Size</th>
                        <th style={{ padding: '4px' }}>AWP</th>
                        <th style={{ padding: '4px' }}>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.meds && this.state.meds.map(m => (
                        <tr key={`med-${m.id}`}>
                            <td style={{ padding: '4px' }}>{m.ndc}</td>
                            <td style={{ padding: '4px' }}>{m.medName}</td>
                            <td style={{ padding: '4px' }}>{m.manufacturer.name}</td>
                            <td style={{ padding: '4px' }}>{m.deaClass}</td>
                            <td style={{ padding: '4px' }}>{m.packSize}</td>
                            <td style={{ padding: '4px' }}>{m.unit?.unit}</td>
                            <td style={{ padding: '4px' }}>{m.liquidSize}</td>
                            <td style={{ padding: '4px' }}>{m.awp}</td>
                            <td style={{ padding: '4px' }}><Link to={`/redbook/${m.id}`}>Details</Link></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="row" style={{ marginTop: '28px' }}>
                <div className="col s12 m4 l2">
                    <Link to="/redbook/create" className="btn-small blue white-text col s12 waves-effect">Create New Med</Link>
                </div>
            </div>
        </div>
    )
}

export default connect()(SearchRedbook);