import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOut, openSideNav, registerRoutes } from '../../func';
import M from '@materializecss/materialize';
import Axios from 'axios';
import moment from 'moment';

class OrderSearch extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        orderList: null,
        employeeList: null,
        loading: false,
        pharmacyName: '',
        orderNumber: '',
        serviceDateStart: '',
        serviceDateEnd: '',
        trackingNumber: '',
        sortColumn: '',
        reverse: false
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.FormSelect.init(document.querySelectorAll('select'))
        this.getEmployees();
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelectorAll('.order-search').forEach(el => {
            el.addEventListener('keyup', this.enterListener);
        })
    }

    removeListeners = () => {
        document.querySelectorAll('.order-search').forEach(el => {
            el.removeEventListener('keyup', this.enterListener);
        })
    }

    enterListener = e => e.keyCode === 13 ? this.search() : null;

    networkCalls = {
        getEmployees: {
            func: () => {
                Axios.get('/api/v1/employee/read/all', {
                    params: this.authData
                })
                    .then(result => this.setState({ employeeList: result.data }, () => {
                        const obj = {};
                        result.data.forEach(e => obj[`${e.firstName} ${e.lastName}`] = null)

                        M.Autocomplete.init(document.querySelectorAll('.autocomplete'), { data: obj })
                    }))
                    .catch(logOut)
            },
            type: 'r'
        },
        search: {
            func: () => {
                const {
                    pharmacyName,
                    orderNumber,
                    serviceDateStart,
                    serviceDateEnd
                } = this.state

                const rep = this.state.employeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#repName').value)[0];
                const repId = rep ? rep.id : -1;

                const sorter = this.state.employeeList.filter(e => `${e.firstName} ${e.lastName}` === document.querySelector('#sorterName').value)[0]
                const sorterId = sorter ? sorter.id : -1;

                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/order/header/read/many/search', {
                        params: {
                            ...this.authData,
                            pharmacyName,
                            orderNumber,
                            serviceDateStart: serviceDateStart ? moment(serviceDateStart).format('YYYY-MM-DD') : '2020-01-01',
                            serviceDateEnd: serviceDateEnd ? moment(serviceDateEnd).format('YYYY-MM-DD') : '2050-12-31',
                            repId,
                            sorterId
                        }
                    })
                        .then(result => this.setState({ orderList: result.data, sortColumn: '', reverse: false }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        }
    }

    getEmployees = () => this.networkCalls.getEmployees();
    search = e => { e?.preventDefault(); this.networkCalls.search(); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    sort = e => {
        const sortColumn = Array.from(e.target.classList).filter(c => c.search('sort-') !== -1)[0].split('-')[1];

        const orderList = JSON.parse(JSON.stringify(this.state.orderList));
        if (!orderList)
            return;

        const reverse = sortColumn === this.state.sortColumn ? !this.state.reverse : false;

        orderList.sort((a, b) => {
            let num;
            if (a[sortColumn] === b[sortColumn])
                return 0;
            else
                num = a[sortColumn] > b[sortColumn] ? 1 : -1;

            if (reverse)
                num *= -1;

            return num;
        })

        this.setState({ sortColumn, reverse, orderList })
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Warehouse Mgmt</span>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Order Search</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Order Search</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input id="pharmacyName" className="order-search" type="text" onChange={this.onChange} value={this.state.pharmacyName} />
                    <label htmlFor="pharmacyName">Pharmacy Name</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="orderNumber" className="order-search" type="text" onChange={this.onChange} value={this.state.orderNumber} />
                    <label htmlFor="orderNumber">Order Number</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="serviceDateStart" className="order-search" type="date" onChange={this.onChange} value={this.state.serviceDateStart} />
                    <label htmlFor="serviceDateStart">Service Date Start</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="serviceDateEnd" className="order-search" type="date" onChange={this.onChange} value={this.state.serviceDateEnd} />
                    <label htmlFor="serviceDateEnd">Service Date End</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input type="text" id="repName" className="autocomplete" />
                    <label htmlFor="repName">Rep On-Site</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input type="text" id="sorterName" className="autocomplete" />
                    <label htmlFor="sorteName">Sorter</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.search}>Search</a>
                </div>
            </div>
            <div className="row">
                {this.state.loading ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :
                    <table className="hightlight">
                        <thead>
                            <tr>
                                <th className="sort-pharmacyName" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Pharmacy Name {this.state.sortColumn === 'pharmacyName' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-orderNumber" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Order Number {this.state.sortColumn === 'orderNumber' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-serviceDate" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Service Date {this.state.sortColumn === 'serviceDate' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-repName" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Rep On-Site {this.state.sortColumn === 'repName' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-sorterName" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Sorted By {this.state.sortColumn === 'sorterName' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                                <th className="sort-sortDate" style={{ padding: '2px', cursor: 'pointer' }} onClick={this.sort}>Sort Date {this.state.sortColumn === 'sortDate' ? <i className="material-icons">{this.state.reverse ? 'arrow_drop_up' : 'arrow_drop_down'}</i> : null}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orderList?.map(o => (
                                <tr key={JSON.stringify(o)}>
                                    <td style={{ padding: '2px' }}><Link to={`/pharmacy/${o.pharmacyId}`}>{o.pharmacyName}</Link></td>
                                    <td style={{ padding: '2px' }}><Link to={`/warehouse/manager/regular/${o.orderHeaderId}`}>{o.orderNumber}</Link></td>
                                    <td style={{ padding: '2px' }}>{moment(o.serviceDate).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '2px' }}>{o.repName}</td>
                                    <td style={{ padding: '2px' }}>{o.sorterName}</td>
                                    <td style={{ padding: '2px' }}>{moment(o.sortDate).format('MM/DD/YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default connect()(OrderSearch)