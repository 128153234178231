import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { openSideNav, logOut, registerRoutes } from '../../../func';
import Axios from 'axios';
import M from '@materializecss/materialize';
import GeneralTab from "../createManufacturer/generalTab";
import AddressTab from "../createManufacturer/addressTab";
import ContactTab from "../createManufacturer/contactTab";
import ReturnInfoTab from "../createManufacturer/returnInfoTab";

class UpdateManufacturer extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
        this.id = this.props.match.params.id;
    }

    initState = () => ({
        loading: false,
        updating: false,
        tab: 'general',
        manufacturer: null,
        oldValues: null,
        newValues: this.initValues()
    })

    initValues = () => ({
        general: {
            name: '',
            deaNumber: '',
            parentPartyName: '',
            parentPartyId: '',
            returnHandlerName: '',
            returnHandlerId: ''
        },
        address: {
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            stateName: '',
            stateId: '',
            zip: ''
        },
        contact: {
            phone: '',
            email: '',
            website: ''
        },
        returnInfo: {
            exclusiveReturnWholesalerName: '',
            exclusiveReturnWholesalerId: '',
            directReturn: false,
            raRequired: false,
            destroyInHouse: false,
            minValue: 0
        }
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.networkCalls.getThisManufacturer();
        this.networkCalls.getStates();
        this.networkCalls.getReturnHandlers();
        this.networkCalls.getManufacturers();
        this.networkCalls.getWholesalers();
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    componentWillUnmount = () => {
        this.removeToolTips();
    }

    networkCalls = {
        getThisManufacturer: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/party/manufacturer/read/one/by/id/deep', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => this.hydrateManufacturer(result.data))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        getStates: {
            func: () => {
                Axios.get('/api/v1/state/read/all', { params: this.authData })
                    .then(result => {
                        const obj = {};
                        result.data.forEach(s => obj[`${s.code} - ${s.name}`] = null);
                        this.setState({ stateList: result.data, stateListAsObject: obj })
                    })
                    .catch(logOut)
            },
            type: 'r'
        },
        getManufacturers: {
            func: () => {
                Axios.get('/api/v1/party/manufacturer/read/all', { params: this.authData })
                    .then(result => {
                        const obj = {};
                        result.data.forEach(mfr => obj[mfr.name] = null);
                        this.setState({ manufacturerList: result.data, manufacturerListAsObject: obj })
                    })
                    .catch(logOut)
            }
        },
        getWholesalers: {
            func: () => {
                Axios.get('/api/v1/party/wholesaler/read/all/simple', { params: this.authData })
                    .then(result => {
                        const obj = {}
                        result.data.forEach(w => obj[w.name] = null);
                        this.setState({ wholesalerList: result.data, wholesalerListAsObject: obj })
                    })
                    .catch(logOut);
            }
        },
        getReturnHandlers: {
            func: () => {
                Axios.get('/api/v1/party/returnhandler/read/all', { params: this.authData })
                    .then(result => {
                        const obj = {}
                        result.data.forEach(rh => obj[rh.name] = null);
                        this.setState({ returnHandlerList: result.data, returnHandlerListAsObject: obj })
                    })
                    .catch(logOut)
            }
        },
        update: {
            func: () => {
                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/party/manufacturer/update/one', {
                        ...this.authData,
                        id: this.id,
                        parentPartyId: this.state.newValues.general.parentPartyId || -1,
                        returnHandlerId: this.state.newValues.general.returnHandlerId || -1,
                        exclusiveReturnWholesalerId: this.state.newValues.returnInfo.exclusiveReturnWholesalerId || -1,
                        stateId: this.state.newValues.address.stateId || -1,
                        returnPolicyId: this.state.manufacturer.returnPolicyId,
                        name: this.state.newValues.general.name,
                        addressLineOne: this.state.newValues.address.addressLineOne,
                        addressLineTwo: this.state.newValues.address.addressLineTwo,
                        city: this.state.newValues.address.city,
                        zip: this.state.newValues.address.zip,
                        phone: this.state.newValues.contact.phone,
                        email: this.state.newValues.contact.email,
                        website: this.state.newValues.contact.website,
                        deaNumber: this.state.newValues.general.deaNumber,
                        raRequired: this.state.newValues.returnInfo.raRequired ? true : false,
                        directReturn: this.state.newValues.returnInfo.directReturn ? true : false,
                        destroyInHouse: this.state.newValues.returnInfo.destroyInHouse ? true : false,
                        minValue: this.state.newValues.returnInfo.minValue || 0
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            setTimeout(() => {
                                this.props.history.replace(`/manufacturer/${this.id}`);
                            }, 800);
                        })
                        .catch(logOut)
                })
            },
            type: 'u'
        },
        replace: {
            func: () => {
                this.setState({ updating: true }, () => {
                    Axios.post('/api/v1/party/manufacturer/update/replace', {
                        ...this.authData,
                        id: this.id,
                        parentPartyId: this.state.newValues.general.parentPartyId || -1,
                        returnHandlerId: this.state.newValues.general.returnHandlerId || -1,
                        exclusiveReturnWholesalerId: this.state.newValues.returnInfo.exclusiveReturnWholesalerId || -1,
                        stateId: this.state.newValues.address.stateId || -1,
                        returnPolicyId: -1,
                        name: this.state.newValues.general.name,
                        addressLineOne: this.state.newValues.address.addressLineOne,
                        addressLineTwo: this.state.newValues.address.addressLineTwo,
                        city: this.state.newValues.address.city,
                        zip: this.state.newValues.address.zip,
                        phone: this.state.newValues.contact.phone,
                        email: this.state.newValues.contact.email,
                        website: this.state.newValues.contact.website,
                        deaNumber: this.state.newValues.general.deaNumber,
                        raRequired: this.state.newValues.returnInfo.raRequired ? true : false,
                        directReturn: this.state.newValues.returnInfo.directReturn ? true : false,
                        destroyInHouse: this.state.newValues.returnInfo.destroyInHouse ? true : false,
                        minValue: this.state.newValues.returnInfo.minValue || 0
                    })
                        .then(result => {
                            this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'Manufacturer Replaced', class: 'green white-text' } });
                            setTimeout(() => {
                                this.props.history.replace(`/manufacturer/${result.data.newManufacturerId}`);
                            }, 800);
                        })
                        .catch(logOut)
                })
            },
            type: 'u'
        }
    }

    hydrateManufacturer = mfr => {
        const obj = {
            general: {
                name: mfr.name || '',
                deaNumber: mfr.deaNumber || '',
                parentPartyName: mfr.parentPartyId ? mfr.parentParty.name : '',
                parentPartyId: mfr.parentPartyId || -1,
                returnHandlerName: mfr.returnHandlerId ? mfr.returnHandler.name : '',
                returnHandlerId: mfr.returnHandlerId || -1
            },
            address: {
                addressLineOne: mfr.addresLineOne || '',
                addressLineTwo: mfr.addressLineTwo || '',
                city: mfr.city || '',
                stateName: mfr.stateId ? mfr.state.name : '',
                stateId: mfr.stateId,
                zip: mfr.zip || ''
            },
            contact: {
                phone: mfr.phone || '',
                email: mfr.email || '',
                website: mfr.website || ''
            },
            returnInfo: {
                exclusiveReturnWholesalerName: '',
                exclusiveReturnWholesalerId: mfr.exclusiveReturnWholesalerId,
                directReturn: mfr.directReturn ? true : false,
                raRequired: mfr.raRequired ? true : false,
                destroyInHouse: mfr.destroyInHouse ? true : false,
                minValue: mfr.minValue || 0
            }
        }

        this.setState({
            manufacturer: mfr,
            oldValues: obj,
            newValues: obj
        })
    }

    onChange = (e, tab) => this.setState(p => ({ newValues: { ...p.newValues, [tab]: { ...p.newValues[tab], [e.target.id]: e.target.value } } }))

    onCheckboxChange = (e, tab) => this.setState(p => ({ newValues: { ...p.newValues, [tab]: { ...p.newValues[tab], [e.target.id]: !p.newValues[tab][e.target.id] } } }))

    setTab = e => this.setState({ tab: e.target.id.split('-')[1] })

    removeToolTips = () => {
        document.querySelectorAll('.tooltipped').forEach(el => {
            const tt = M.Tooltip.getInstance(el);
            if (tt)
                tt.destroy();
        })
    }

    getContent = () => {
        let component = null;
        switch (this.state.tab) {
            case 'general':
                component = <GeneralTab
                    onChange={e => this.onChange(e, 'general')}
                    values={this.state.newValues.general}
                    setTab={this.setTab}
                    manufacturerListAsObject={this.state.manufacturerListAsObject}
                    returnHandlerListAsObject={this.state.returnHandlerListAsObject}
                    onReturnHandlerChange={this.onReturnHandlerChange}
                    onParentPartyChange={this.onParentPartyChange}
                    revert={() => this.revert('general')}
                />
                break;
            case 'address':
                component = <AddressTab
                    onChange={e => this.onChange(e, 'address')}
                    values={this.state.newValues.address}
                    setTab={this.setTab}
                    stateListAsObject={this.state.stateListAsObject}
                    onStateChange={this.onStateChange}
                    revert={() => this.revert('address')}
                />
                break;
            case 'contact':
                component = <ContactTab
                    onChange={e => this.onChange(e, 'contact')}
                    values={this.state.newValues.contact}
                    setTab={this.setTab}
                    revert={() => this.revert('contact')}
                />
                break;
            case 'returnInfo':
                component = <ReturnInfoTab
                    onChange={e => this.onChange(e, 'returnInfo')}
                    onCheckboxChange={e => this.onCheckboxChange(e, 'returnInfo')}
                    values={this.state.newValues.returnInfo}
                    setTab={this.setTab}
                    wholesalerListAsObject={this.state.wholesalerListAsObject}
                    onExclusiveReturnWholesalerChange={this.onExclusiveReturnWholesalerChange}
                    revert={() => this.revert('returnInfo')}
                    update={this.updateOrReplace}
                    updating={this.state.updating}
                />
                break;
            default:
                break;
        }

        this.removeToolTips();

        setTimeout(() => {
            this.removeToolTips();
            M.Tooltip.init(document.querySelectorAll('.tooltipped'), { enterDelay: 500 })
        }, 500);

        return component;
    }

    onStateChange = stateName => {
        const stateId = this.state.stateList.filter(s => `${s.code} - ${s.name}` === stateName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, address: { ...p.newValues.address, stateName, stateId } } }))
    }

    onReturnHandlerChange = returnHandlerName => {
        const returnHandlerId = this.state.returnHandlerList.filter(rh => rh.name === returnHandlerName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, general: { ...p.newValues.general, returnHandlerName, returnHandlerId } } }))
    }

    onParentPartyChange = parentPartyName => {
        const parentPartyId = this.state.manufacturerList.filter(mfr => mfr.name === parentPartyName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, general: { ...p.newValues.general, parentPartyName, parentPartyId } } }))
    }

    onExclusiveReturnWholesalerChange = exclusiveReturnWholesalerName => {
        const exclusiveReturnWholesalerId = this.state.wholesalerList.filter(w => w.name === exclusiveReturnWholesalerName)[0].id;
        this.setState(p => ({ newValues: { ...p.newValues, returnInfo: { ...p.newValues.returnInfo, exclusiveReturnWholesalerName, exclusiveReturnWholesalerId } } }))
    }

    revert = tab => this.setState(p => ({ newValues: { ...p.newValues, [tab]: p.oldValues[tab] } }))

    updateOrReplace = () => M.Modal.getInstance(document.querySelector('#update-or-replace-modal')).open();

    cancelUpdate = e => {
        e.preventDefault();
        M.Modal.getInstance(document.querySelector('#update-or-replace-modal')).close();
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/manufacturer">Manufacturers</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to={`/manufacturer/${this.id}`}>{this.state.manufacturer ? this.state.manufacturer.name : 'Manufacturer Detail'}</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Update Manufacturer</span>
                </div>
            </div>
            <div className="row">
                <div className="col s4 m3 l2">
                    <ul className="collection">
                        <li id="tab-general" className={`collection-item ${this.state.tab === 'general' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>General</li>
                        <li id="tab-address" className={`collection-item ${this.state.tab === 'address' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Address</li>
                        <li id="tab-contact" className={`collection-item ${this.state.tab === 'contact' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Contact</li>
                        <li id="tab-returnInfo" className={`collection-item ${this.state.tab === 'returnInfo' ? 'blue white-text' : ''}`} onClick={this.setTab} style={{ cursor: 'pointer' }}>Return Info</li>
                    </ul>
                </div>
                <div className="col s8 m9 l10">
                    {this.getContent()}
                </div>
            </div>
            <div id="update-or-replace-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>Update Or Replace <span className="orange-text text-darken-3">{this.state.manufacturer?.name.toUpperCase()}</span>?</h5>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 l2">
                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={e => { e.preventDefault(); this.networkCalls.update(); }}>Update</a>
                        </div>
                        <div className="col s12 m4 l2">
                            <a href="/" className="btn-small white blue-text waves-effect waves-dark col s12" onClick={e => { e.preventDefault(); this.networkCalls.replace(); }}>Replace</a>
                        </div>
                        <div className="col s12 m4 l2 offset-l6">
                            <a href="/" className="btn-small white red-text waves-effect waves-dark col s12" onClick={this.cancelUpdate}>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(UpdateManufacturer);