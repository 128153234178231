import React from 'react';
import { connect } from 'react-redux';
import { logOut, openSideNav, registerRoutes } from '../../../func';
import { Link } from 'react-router-dom';
import AccessTab from './accessTab';
import ActivityTab from './activityTab';
import ErrorTab from './errorTab';
import Axios from 'axios';

class CustomerPortalOverview extends React.Component {
  constructor(props) {
    super(props);
    this.authData = JSON.parse(localStorage.getItem('auth_data'));
    this.state = this.initState();
  }

  initState = () => ({
    tab: 'access',
    pharmacyList: null,
  });

  componentDidMount = () => {
    this.networkCalls = registerRoutes(
      this.networkCalls,
      this.props.match.path
    );
    this.networkCalls.getReadAllPharmacies();
  };

  networkCalls = {
    getReadAllPharmacies: {
      func: () => {
        Axios.get('/api/v1/pharmacy/read/all/simple', { params: this.authData })
          .then((result) => {
            const pharmacyListAsObject = {};
            result.data.forEach(
              (p) =>
                (pharmacyListAsObject[`${p.name} - ${p.customerNumber}`] = null)
            );
            this.setState({ pharmacyList: result.data, pharmacyListAsObject });
          })
          .catch(logOut);
      },
      type: 'r',
    },
  };

  setTab = (e) => this.setState({ tab: e.target.id.split('-')[1] });

  render = () => (
    <div className="main">
      <div className="row">
        <div style={{ display: 'flex' }}>
          <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}>
            <i className="material-icons black-text">menu</i>
          </Link>
          <Link to="/">Home</Link>
          <i className="material-icons">chevron_right</i>
          <span className="grey-text">Website Admin</span>
          <i className="material-icons">chevron_right</i>
          <span className="grey-text">Customer Portal</span>
        </div>
      </div>
      <div className="row">
        <div className="col s4 m3 l2">
          <ul className="collection">
            <li
              id="tab-access"
              className={`collection-item ${
                this.state.tab === 'access' ? 'blue white-text' : ''
              }`}
              onClick={this.setTab}
              style={{ cursor: 'pointer' }}
            >
              Customer Access
            </li>
            <li
              id="tab-activity"
              className={`collection-item ${
                this.state.tab === 'activity' ? 'blue white-text' : ''
              }`}
              onClick={this.setTab}
              style={{ cursor: 'pointer' }}
            >
              Site Activity
            </li>
            <li
              id="tab-errors"
              className={`collection-item ${
                this.state.tab === 'errors' ? 'blue white-text' : ''
              }`}
              onClick={this.setTab}
              style={{ cursor: 'pointer' }}
            >
              Error List
            </li>
          </ul>
        </div>
        <div className="col s8 m9 l10">
          {this.state.tab === 'access' && (
            <AccessTab
              path={this.props.match.path}
              pharmacyList={this.state.pharmacyList}
              pharmacyListAsObject={this.state.pharmacyListAsObject}
            />
          )}
          {this.state.tab === 'activity' && (
            <ActivityTab path={this.props.match.path} />
          )}
          {this.state.tab === 'errors' && (
            <ErrorTab path={this.props.match.path} />
          )}
        </div>
      </div>
    </div>
  );
}

export default connect()(CustomerPortalOverview);
