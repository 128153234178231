import React from 'react';
const pageId = crypto.randomUUID();

const ManufacturerTab = props => {
    const { manufacturer } = props;

    const assembleAddress = ({ addressLineOne, addressLineTwo, city, state, zip }) => {
        let str = '';
        str += addressLineOne ? addressLineOne : '';
        str += addressLineTwo ? addressLineOne ? `\n${addressLineTwo}` : addressLineTwo : '';
        str += city ? str ? `\n${city}` : city : '';
        str += state ? city ? `, ${state.code}` : state.code : '';
        str += zip ? ` ${zip}` : ''

        return str ? str : '\xa0';
    }

    return (
        <div style={{ padding: '1vh' }}>
            {manufacturer ?
                <React.Fragment>
                    <div className="col s4 l3">
                        <table>
                            <tbody>
                                <tr><td id={`label-manufacturerName-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Manufacturer</td></tr>
                                <tr><td id={`label-parentPartyName-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Parent Party</td></tr>
                                <tr><td id={`label-deaNumber-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>DEA Number</td></tr>
                                <tr><td id={`label-address-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Address</td></tr>
                                <tr><td id={`label-phone-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Phone</td></tr>
                                <tr><td id={`label-email-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Email</td></tr>
                                <tr><td id={`label-website-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Website</td></tr>
                                <tr><td id={`label-directReturn-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Direct Return</td></tr>
                                <tr><td id={`label-destroyInHouse-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Destroy In House</td></tr>
                                <tr><td id={`label-minimumValue-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Minimum Value</td></tr>
                                <tr><td id={`label-returnPolicies-${pageId}`} className="bold" style={{ padding: '2px', textAlign: 'right' }}>Return Policies</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col s8 l9">
                        <table>
                            <tbody>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.name}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.parentParty?.name || '\xa0'}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.deaNumber || '\xa0'}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{assembleAddress(manufacturer)}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.phone || '\xa0'}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.email || '\xa0'}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.website || '\xa0'}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.directReturn ? 'Yes' : 'No'}</td></tr>
                                <tr><td style={{ padding: '2px' }}>{manufacturer.destroyInHouse ? 'Yes' : 'No'}</td></tr>
                                <tr><td style={{ padding: '2px' }}>${manufacturer.minValue || '0'}</td></tr>
                                <tr>
                                    <td style={{ padding: '2px' }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '0px' }}>Returnable</th>
                                                    <th style={{ padding: '0px' }}>Window</th>
                                                    <th style={{ padding: '0px' }}>Sealed Only</th>
                                                    <th style={{ padding: '0px' }}>Min %</th>
                                                    <th style={{ padding: '0px' }}>Labels</th>
                                                    <th style={{ padding: '0px' }}>Controls</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {manufacturer.returnPolicies?.map((rp, index) => (
                                                    <tr key={rp.id} style={{ borderBottom: index === manufacturer.returnPolicies.length - 1 ? 'none' : '' }}>
                                                        <td style={{ padding: '0px' }}>{rp.isReturnable ? 'Yes' : 'No'}</td>
                                                        <td style={{ padding: '0px' }}>{rp.earliestReturnInMonths} - {rp.latestReturnInMonths}</td>
                                                        <td style={{ padding: '0px' }}>{rp.sealedOnly ? 'Yes' : 'No'}</td>
                                                        <td style={{ padding: '0px' }}>{rp.minPackagePercentage === null ? 'Null' : rp.minPackagePercentage * 100}%</td>
                                                        <td style={{ padding: '0px' }}>{rp.labelsAllowed ? 'Yes' : 'No'}</td>
                                                        <td style={{ padding: '0px' }}>{rp.controlsAllowed ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
                :
                <span>Med Not Found</span>
            }
        </div>
    )
}

export default ManufacturerTab;