import moment from "moment";
import React from 'react';

class InvoiceTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        openInvoiceIds: [],
        openDebitMemoList: [],
        openInvoiceDetailList: [],
        openOrderDetailList: [],
    })

    toggleDisplay = (id, arrayName) => {
        const arr = JSON.parse(JSON.stringify(this.state[arrayName]));
        const index = arr.indexOf(id);

        if (index === -1)
            arr.push(id);
        else
            arr.splice(index, 1);

        this.setState({ [arrayName]: arr })
    }

    getDebitMemoNumber = dm => {
        if (!dm.number)
            return 'N/A';
        else
            return `ORX${dm.prefix || ''}-${dm.month}-${dm.control.toLowerCase() === 'r' ? '' : 'C'}${dm.number.toString().padStart(4, '0')}`
    }

    render = () => (
        <div style={{ padding: '1vw' }}>
            {this.props.invoices?.map(i => (
                <div key={i.id} className="row">
                    <h6
                        style={{ borderBottom: '2px solid black', cursor: 'pointer' }}
                        onClick={() => this.toggleDisplay(i.id, 'openInvoiceIds')}
                    >
                        <i className="material-icons" style={{ position: 'relative', top: '6px' }}>{this.state.openInvoiceIds.indexOf(i.id) !== -1 ? 'arrow_drop_down' : 'arrow_right'}</i>
                        <span style={{ marginRight: '30px' }}><b>#{i.id}</b></span>
                        <span style={{ marginRight: '30px' }}>{moment.utc(i.dateCreated).local().format('MM/DD/YYYY')}</span>
                        {i.dateClosed ? <span style={{ marginRight: '30px' }}><b>Closed: </b></span> : null}
                        {i.noteToCustomer ? <span>{i.noteToCustomer}</span> : null}
                    </h6>
                    {this.state.openInvoiceIds.indexOf(i.id) !== -1 ? (
                        <div className="row" style={{ paddingLeft: '40px' }}>
                            <div className="row">
                                <p style={{ borderBottom: '1px solid black', cursor: 'pointer' }} onClick={() => this.toggleDisplay(i.id, 'openInvoiceDetailList')}><i className="material-icons" style={{ position: 'relative', top: '6px' }}>{this.state.openInvoiceDetailList.indexOf(i.id) !== -1 ? 'arrow_drop_down' : 'arrow_right'}</i>Invoice Details</p>
                                <div style={{ paddingLeft: '40px' }}>
                                    {this.state.openInvoiceDetailList.indexOf(i.id) !== -1 ?
                                        <table style={{ paddingLeft: '40px' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '0px' }}>Code</th>
                                                    <th style={{ padding: '0px' }}>Rate</th>
                                                    <th style={{ padding: '0px' }}>Quantity</th>
                                                    <th style={{ padding: '0px' }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {i.details?.map(id => (
                                                    <tr key={id.id} style={{ border: 'none' }}>
                                                        <td style={{ padding: '0px' }}>{id.code}</td>
                                                        <td style={{ padding: '0px' }}>{id.rate}</td>
                                                        <td style={{ padding: '0px' }}>{id.quantity}</td>
                                                        <td style={{ padding: '0px' }}>{id.total}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        : null}
                                </div>
                            </div>
                            <div className="row">
                                <p style={{ borderBottom: '1px solid black', cursor: 'pointer' }} onClick={() => this.toggleDisplay(i.id, 'openDebitMemoList')}><i className="material-icons" style={{ position: 'relative', top: '6px' }}>{this.state.openDebitMemoList.indexOf(i.id) !== -1 ? 'arrow_drop_down' : 'arrow_right'}</i>Debit Memos</p>
                                {this.state.openDebitMemoList.indexOf(i.id) !== -1 ? i.debitMemos?.map(dm => (
                                    <div className="row" style={{ paddingLeft: '40px' }}>
                                        <p key={dm.id} style={{ borderBottom: '1px solid black', cursor: 'pointer' }} onClick={() => this.toggleDisplay(dm.id, 'openOrderDetailList')}><i className="material-icons" style={{ position: 'relative', top: '6px' }}>{this.state.openOrderDetailList.indexOf(dm.id) !== -1 ? 'arrow_drop_down' : 'arrow_right'}</i>
                                            <span style={{ marginRight: '30px' }}><b>{this.getDebitMemoNumber(dm)}</b></span>
                                            <span style={{ marginRight: '30px' }}>{dm.manufacturerName}</span>
                                            <span style={{ marginRight: '30px' }}>Pub Val: {parseFloat(dm.orderDetails?.reduce((sum, od) => sum += od.publishedValue, 0)).toFixed(2)}</span>
                                            <span style={{ marginRight: '30px' }}>Est Val: {parseFloat(dm.orderDetails?.reduce((sum, od) => sum += od.estimatedValue, 0)).toFixed(2)}</span>
                                            {dm.credits ? <span style={{ marginRight: '30px' }}>Credits: {parseFloat(dm.credits?.reduce((sum, cm) => sum += cm.orxCreditAmount, 0)).toFixed(2)}</span> : null}
                                            <span style={{ marginRight: '30px' }} className="bold purple-text">{dm.isIndate ? 'INDATE' : ''}</span>
                                            <span className="bold orange-text">{dm.isDestroyed ? 'DESTROYED' : ''}</span>
                                        </p>
                                        {this.state.openOrderDetailList.indexOf(dm.id) !== -1 ? (
                                            <div>
                                                <table style={{ paddingLeft: '40px', marginBottom: '20px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ padding: '0px' }}>Date Received</th>
                                                            <th style={{ padding: '0px' }}>Full Amt</th>
                                                            <th style={{ padding: '0px' }}>Pharmacy Amt</th>
                                                            <th style={{ padding: '0px' }}>Orx Amt</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dm.credits ? dm.credits?.map(cm => (
                                                            <tr key={cm.id} style={{ border: 'none' }}>
                                                                <td style={{ padding: '0px' }}>{moment.utc(cm.dateCreated).local().format('MM/DD/YYYY')}</td>
                                                                <td style={{ padding: '0px' }}>{cm.fullCreditAmount}</td>
                                                                <td style={{ padding: '0px' }}>{cm.pharmacyCreditAmount}</td>
                                                                <td style={{ padding: '0px' }}>{cm.orxCreditAmount}</td>
                                                            </tr>
                                                        )) : (
                                                            <tr style={{ border: 'none' }}>
                                                                <td style={{ padding: '0px' }}>No Credits</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <table style={{ paddingLeft: '40px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ padding: '0px' }}>NDC</th>
                                                            <th style={{ padding: '0px' }}>Med Name</th>
                                                            <th style={{ padding: '0px' }}>Pack Size</th>
                                                            <th style={{ padding: '0px' }}>Full Pk</th>
                                                            <th style={{ padding: '0px' }}>Partial Pk</th>
                                                            <th style={{ padding: '0px' }}>Published Val</th>
                                                            <th style={{ padding: '0px' }}>Est Val</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dm.orderDetails?.map(od => (
                                                            <tr style={{ border: 'none' }}>
                                                                <td style={{ padding: '0px' }}>{od.ndc}</td>
                                                                <td style={{ padding: '0px' }}>{od.medName}</td>
                                                                <td style={{ padding: '0px' }}>{od.packSize}</td>
                                                                <td style={{ padding: '0px' }}>{od.fullPackage}</td>
                                                                <td style={{ padding: '0px' }}>{od.partialPackage}</td>
                                                                <td style={{ padding: '0px' }}>{parseFloat(od.publishedValue).toFixed(2)}</td>
                                                                <td style={{ padding: '0px' }}>{parseFloat(od.estimatedValue).toFixed(2)}</td>
                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}
                                    </div>
                                )) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    )
}

export default InvoiceTab;