import Axios from "axios";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { logOut, registerRoutes } from "../../../func";
import Socket from '../../../utilities/socket';
const componentId = crypto.randomUUID()

const DebitMemoList = props => {
    const {
        orderHeaderId,
        path,
        passDebitMemoListToParent,
        passDebitMemoToParent,
        passTabToParent,
        dispatch
    } = props;

    const [debitMemo, setDebitMemo] = useState(null);
    const [debitMemoList, setDebitMemoList] = useState(null);
    const [contextMenu, setContextMenu] = useState({ show: false })
    const [contextMenuDestructionDate, setContextMenuDestructionDate] = useState({ show: false })
    const [printQueue, setPrintQueue] = useState([])
    const [lastUpdateTimer, setLastUpdateTimer] = useState(0);
    const [destructionDate, setDestructionDate] = useState('');

    const [networkCalls] = useState(registerRoutes({
        verify: {
            func: e => {
                e?.preventDefault();
                const { debitMemo } = ref.current;
                setContextMenu({ show: false })

                Axios.post('/api/v2/debitmemo/update/one/verify', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoId: debitMemo.id
                })
                    .then(result => dispatch(result.data))
                    .catch(logOut)
            },
            type: 'u'
        },
        undoVerification: {
            func: e => {
                e?.preventDefault();
                const { debitMemo } = ref.current;

                setContextMenu({ show: false })

                Axios.post('/api/v2/debitmemo/update/one/undoverification', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoId: debitMemo.id
                })
                    .then(result => dispatch(result.data))
                    .catch(logOut)

            },
            type: 'u'
        },
        print: {
            func: (e, isDestruction, destructionDate) => {
                e?.preventDefault();

                setContextMenu({ show: false })
                const { debitMemo } = ref.current;
                editPrintQueue(debitMemo.id, 'add')

                Axios.get('/api/v1/debitmemo/print/one/pdf', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        id: debitMemo.id,
                        isDestruction: isDestruction || false,
                        destructionDate: destructionDate || '',
                    },
                    responseType: 'blob',
                    signal: ref.current.printAbortController.signal
                })
                    .then(result => {
                        downloadPdf(result.data, `${debitMemo.debitMemoNumber}-${moment().format('YYYYMMDD')}.pdf`);
                        setDestructionDate('');

                        if (result.headers.merck && !isDestruction) {
                            dispatch(JSON.parse(result.headers.merck))
                            networkCalls.printMerck(debitMemo)
                        }
                    })
                    .catch(logOut)
                    .finally(() => editPrintQueue(debitMemo.id, 'remove'))
            },
            type: 'r'
        },
        printMerck: {
            func: debitMemo => {
                const { firstName, lastName } = JSON.parse(localStorage.getItem('employee_data'));
                editPrintQueue(debitMemo.id, 'add')

                Axios.get('/api/v1/debitmemo/print/merck', {
                    params: {
                        ...JSON.parse(localStorage.getItem('auth_data')),
                        employeeName: `${firstName} ${lastName}`,
                        id: debitMemo.id
                    },
                    responseType: 'blob',
                    signal: ref.current.printAbortController.signal
                })
                    .then(result => {
                        downloadPdf(result.data, `${debitMemo.debitMemoNumber}-${moment().format('YYYYMMDD')}-MERCK.pdf`);
                    })
                    .catch(logOut)
                    .finally(() => editPrintQueue(debitMemo.id, 'remove'))
            },
            type: 'r'
        },
        toggleDestruction: {
            func: debitMemoId => {
                Axios.post('/api/v2/debitmemo/update/one/toggledestruction', {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    debitMemoId
                })
                .then(result => dispatch(result.data))
                .catch(logOut)
            },
            type: 'u'
        }
    }, path))

    useEffect(() => {
        const socket = new Socket();
        socket.onReady = () => socket.send({ type: 'SUBSCRIBE', body: { orderHeaderId } })
        socket.setMessageAction('data', (type, body) => {
            setDebitMemoList(body);

            const { debitMemo } = ref.current;
            if (debitMemo)
                passDebitMemoToParent(body.find(row => parseInt(row.id) === parseInt(debitMemo.id)))
        })
        socket.subscribeToTimer(timer => setLastUpdateTimer(timer))
        socket.open('/api/v2/websocket/debitmemo/read/all/by/orderheaderid', [{ key: 'orderHeaderId', value: orderHeaderId }])

        return () => socket.close();
    }, [orderHeaderId, passDebitMemoToParent])

    useEffect(() => {
        const { printAbortController } = ref.current;
        const clickAwayListener = ({ target }) => {
            const contextMenu = document.querySelector(`#context-menu-${componentId}`)
            const contextMenuDestructionDate = document.querySelector(`#context-menu-destruction-date-${componentId}`);

            if (!contextMenu?.contains(target))
                setContextMenu({ show: false })
            if (!contextMenuDestructionDate?.contains(target) && !contextMenu?.contains(target))
                setContextMenuDestructionDate({ show: false })
        }
        const scrollListener = () => setContextMenu({ show: false })

        const initListeners = () => {
            document.addEventListener('click', clickAwayListener)
            document.querySelector(`#debit-memo-list-${componentId}`)?.addEventListener('scroll', scrollListener)
        }

        const removeListeners = () => {
            document.removeEventListener('click', clickAwayListener)
            document.querySelector(`#debit-memo-list-${componentId}`)?.removeEventListener('scroll', scrollListener);
        }

        initListeners();
        return () => {
            removeListeners();
            printAbortController?.abort();
        }
    }, [])

    const ref = useRef({
        debitMemo: null,
        printQueue: null,
        printAbortController: new AbortController(),
    })

    useEffect(() => ref.current.debitMemo = debitMemo, [debitMemo])
    useEffect(() => ref.current.printQueue = printQueue, [printQueue])
    useEffect(() => passDebitMemoListToParent(debitMemoList), [debitMemoList, passDebitMemoListToParent])

    const selectDebitMemo = (e, dm) => {
        e?.preventDefault();
        setDebitMemo(dm);
        passDebitMemoToParent(dm);
        passTabToParent('');
    }

    const getValue = orderDetails => {
        let hasNull = false;
        orderDetails.forEach(od => {
            if (od.value === null)
                hasNull = true;
        })

        if (hasNull)
            return NaN;
        else
            return parseFloat(orderDetails.reduce((a, b) => ({ value: a.value + b.value })).value).toFixed(2);
    }

    const getStatus = dm => {
        if (dm.receivingPartyId)
            return 'Shipped'
        else if (dm.isDestroyed)
            return 'Destroyed In House'
        else if (dm.dateNumberAssigned)
            return 'Verified'
        else {
            let status = dm.statuses[0];

            dm.statuses.forEach(s => {
                if (moment(s.timeStamp).isAfter(status.timeStamp))
                    status = s;
            })
            return status.status;
        }
    }

    const showContextMenu = (e, dm) => {
        e?.preventDefault();
        setDebitMemo(dm);
        passDebitMemoToParent(dm);
        passTabToParent('');

        const clientX = e.clientX + 150 > window.innerWidth ? e.clientX - 145 : e.clientX;
        const clientY = e.clientY + 80 > window.innerHeight ? e.clientY - 75 : e.clientY;

        setContextMenu({
            show: true,
            clientX,
            clientY
        })
    }

    const downloadPdf = (blob, filename) => {
        blob = new Blob([blob]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.hidden = true;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const editPrintQueue = (id, action) => {
        if (action === 'add') {
            const { printQueue } = ref.current;
            printQueue.push(id);
            setPrintQueue([...printQueue]);
        }
        if (action === 'remove') {
            const { printQueue } = ref.current;
            printQueue.splice(printQueue.indexOf(id), 1);
            setPrintQueue([...printQueue]);
        }
    }

    const editReturnAuthorizations = e => {
        e?.preventDefault();
        passTabToParent('ra');
        setContextMenu({ show: false })
    }

    const editAttachments = e => {
        e?.preventDefault();
        passTabToParent('attachment');
        setContextMenu({ show: false })
    }

    const openContextMenuDestructionDate = e => {
        e.preventDefault();

        setContextMenuDestructionDate({
            show: true,
            clientX: contextMenu.clientX,
            clientY: contextMenu.clientY
        })

        setContextMenu({ show: false })
        setTimeout(() => {
            document.querySelector('#destructionDate')?.focus();
        }, 250);
    }


    const printDestruction = e => {
        e?.preventDefault();
        networkCalls.print(e, true, destructionDate);
        setContextMenuDestructionDate({ show: false })
    }



    const splitDebitMemo = e => {
        e?.preventDefault();
        passTabToParent('split');
        setContextMenu({ show: false })
    }

    const combineDebitMemos = e => {
        e?.preventDefault();
        passTabToParent('combine')
        setContextMenu({ show: false })
    }

    const toggleDestruction = e => {
        e?.preventDefault();
        networkCalls.toggleDestruction(debitMemo.id)
        setContextMenu({ show: false })
    }

    return (
        <div id={`debit-memo-list-${componentId}`} style={{ flex: 1, overflowY: 'auto' }} className="card">
            <div className="card-content">
                <small className="grey-text lighten-text-2">Last Updated {lastUpdateTimer}s</small>
                <div className="row">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th style={{ padding: '2px' }}>Manufacturer</th>
                                <th style={{ padding: '2px' }}>Return Handler</th>
                                <th style={{ padding: '2px' }}>DM #</th>
                                <th style={{ padding: '2px' }}>RA #</th>
                                <th style={{ padding: '2px' }}>Controls</th>
                                <th style={{ padding: '2px' }}>Value</th>
                                <th style={{ padding: '2px' }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {debitMemoList?.map(dm => (
                                <tr
                                    key={dm.id}
                                    className={debitMemo && parseInt(debitMemo.id) === parseInt(dm.id) ? 'blue white-text' : ''}
                                    onClick={e => selectDebitMemo(e, dm)}
                                    onContextMenu={e => showContextMenu(e, dm)}
                                >
                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{dm.manufacturer.name}</td>
                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{dm.returnHandler.name}</td>
                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{dm.debitMemoNumber}</td>
                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{dm.returnAuthorizations ? dm.returnAuthorizations.map(row => row.returnAuthorizationNumber).join(', ') : ''}{dm.attachments ? <i className="material-icons" style={{ fontSize: '16px', position: 'relative', top: '2px' }}>attach_file</i> : null}</td>
                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{dm.control}</td>
                                    <td className={isNaN(getValue(dm.orderDetails)) ? 'red white-text' : ''} style={{ padding: '2px', borderRadius: '0px' }}>{isNaN(getValue(dm.orderDetails)) ? 'No Price' : getValue(dm.orderDetails)}</td>
                                    {printQueue.indexOf(dm.id) !== -1 ?
                                        <td style={{ padding: '2px', borderRadius: '0px' }} className="fade-in-fade-out bold"><i>Printing</i></td>
                                        :
                                        <td style={{ padding: '2px', borderRadius: '0px' }}>{getStatus(dm)}</td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {debitMemo ?
                <Fragment>
                    <div id={`context-menu-${componentId}`} className={contextMenu.show ? '' : 'hide'} style={{
                        zIndex: 100,
                        position: 'fixed',
                        top: contextMenu.clientY,
                        left: contextMenu.clientX,
                        border: '1px solid black',
                        backgroundColor: 'white',
                        boxShadow: '1px 1px 2px #ddd',
                        borderRadius: '1px',
                        padding: '8px'

                    }}>
                        <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                            {!debitMemo?.dateNumberAssigned ?
                                <Fragment>
                                    {isNaN(getValue(debitMemo.orderDetails)) ?
                                        null
                                        :
                                        <Fragment>
                                            <li>
                                                <a href="/" className="green-text" style={{ font: 'console', fontSize: '.8em' }} onClick={networkCalls.verify}>Verify</a>
                                            </li>
                                            <div style={{ height: '6px' }} />
                                            <div className="divider" />
                                            <div style={{ height: '6px' }} />
                                        </Fragment>
                                    }

                                    <li>
                                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={splitDebitMemo}>Split</a>
                                    </li>
                                    <div style={{ height: '6px' }} />
                                    <div className="divider" />
                                    <div style={{ height: '6px' }} />
                                    <li>
                                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={combineDebitMemos}>Combine</a>
                                    </li>
                                </Fragment>
                                :
                                <Fragment>
                                    <li>
                                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={editReturnAuthorizations}>Edit Return Authorizations</a>
                                    </li>
                                    <div style={{ height: '6px' }} />
                                    <div className="divider" />
                                    <div style={{ height: '6px' }} />
                                    <li>
                                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={editAttachments}>Edit Attachments</a>
                                    </li>
                                    <div style={{ height: '6px' }} />
                                    <div className="divider" />
                                    <div style={{ height: '6px' }} />
                                    <li>
                                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={networkCalls.print} >Print</a>
                                    </li>
                                    <div style={{ height: '6px' }} />
                                    <div className="divider" />
                                    <div style={{ height: '6px' }} />
                                    <li>
                                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={openContextMenuDestructionDate}>Print Destruction</a>
                                    </li>
                                    <div style={{ height: '6px' }} />
                                    <div className="divider" />
                                    <div style={{ height: '6px' }} />
                                    <li>
                                        <a href="/" className="black-text" style={{ font: 'console', fontSize: '.8em' }} onClick={toggleDestruction}>Toggle Destruction</a>
                                    </li>
                                    {!debitMemo.returnAuthorizations?.length ?
                                        <>
                                            <div style={{ height: '6px' }} />
                                            <div className="divider" />
                                            <div style={{ height: '6px' }} />
                                            <li>
                                                <a href="/" className="red-text" style={{ font: 'console', fontSize: '.8em' }} onClick={networkCalls.undoVerification}>Undo Verification</a>
                                            </li>
                                        </>
                                        : null}
                                </Fragment>
                            }
                        </ul>
                    </div>
                    <div id={`context-menu-destruction-date-${componentId}`} className={contextMenuDestructionDate.show ? '' : 'hide'} style={{
                        zIndex: 100,
                        position: 'fixed',
                        top: contextMenuDestructionDate.clientY,
                        left: contextMenuDestructionDate.clientX,
                        border: '1px solid black',
                        backgroundColor: 'white',
                        boxShadow: '1px 1px 2px #ddd',
                        borderRadius: '1px',
                        padding: '8px'
                    }}>
                        <ul style={{ padding: '0px 80px 0px 12px', margin: '0px' }}>
                            <li>
                                <input id="destructionDate" className="browser-default" type="datetime-local" onChange={e => setDestructionDate(e.target.value)} value={destructionDate} />
                            </li>
                            <div style={{ height: '10px' }} />
                            <li>
                                <a href="/" style={{ height: '28px', lineHeight: '28px' }} className="btn-small blue white-text waves-effect waves-light col s12" onClick={printDestruction}>Print Destruction</a>
                            </li>
                        </ul>
                    </div>
                </Fragment>
                : null}
        </div >
    )
}

export default DebitMemoList;