import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';

class RedbookDetail extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        detail: null,
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        this.getDetail();
    }

    networkCalls = {
        getDetail: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/redbook/read/one/by/id/deep', {
                        params: {
                            ...this.authData,
                            id: this.id
                        }
                    })
                        .then(result => this.setState({ detail: result.data }))
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        setReturnPolicy: {
            func: index => {
                Axios.post('/api/v1/redbook/update/one', {
                    ...this.authData,
                    ...this.state.detail,
                    returnPolicyId: this.state.detail.manufacturer.returnPolicies[index].id,
                    unitId: this.state.detail.unit.id ? this.state.detail.unit.id : -1,
                    manufacturerId: this.state.detail.manufacturer.id
                })
                    .then(result => this.props.dispatch(result.data))
                    .catch(logOut)
                    .finally(() => this.getDetail())
            },
            type: 'u'
        },
        removeReturnPolicy: {
            func: () => {
                Axios.post('/api/v1/redbook/update/one', {
                    ...this.authData,
                    ...this.state.detail,
                    returnPolicyId: -1,
                    unitId: this.state.detail.unit.id ? this.state.detail.unit.id : -1,
                    manufacturerId: this.state.detail.manufacturer.id
                })
                    .then(result => this.props.dispatch(result.data))
                    .catch(logOut)
                    .finally(() => this.getDetail())
            },
            type: 'u'
        }
    }

    setReturnPolicy = (e, index) => { e?.preventDefault(); this.networkCalls.setReturnPolicy(index) }
    removeReturnPolicy = e => { e?.preventDefault(); this.networkCalls.removeReturnPolicy(); }
    getDetail = e => {e?.preventDefault(); this.networkCalls.getDetail();}

    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/redbook">Redbook</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">{this.state.detail ? this.state.detail.ndc : 'NDC'}</span>
                </div>
                <div className="row">
                    <h4>{this.state.detail ? `${this.state.detail.ndc}: ${this.state.detail.medName}` : 'Med'}</h4>
                </div>

                {this.state.loading ?
                    <div className="progress blue">
                        <div className="indeterminate" />
                    </div>
                    :

                    <div>
                        {this.state.detail && (
                            <div>
                                <div className="row">
                                    <div className="col s12">
                                        <div className="card">
                                            <div className="card-content">
                                                <div className="row">
                                                    <h5>Med Details</h5>
                                                    <div className="divider" />
                                                </div>
                                                <div className="row">
                                                    <p><b>NDC: </b>{this.state.detail.ndc}</p>
                                                    <p><b>Med Name: </b>{this.state.detail.medName}</p>
                                                    <p><b>DEA Class: </b>{this.state.detail.deaClass ? this.state.detail.deaClass : '-'}</p>
                                                    <p><b>Pack Size: </b>{this.state.detail.packSize}</p>
                                                    <p><b>Unit: </b>{this.state.detail.unit.unit ? this.state.detail.unit.unit : '-'}</p>
                                                    <p><b>Liquid Size: </b>{this.state.detail.liquidSize}</p>
                                                    <p><b>Unit Price: </b>{this.state.detail.unitPrice}</p>
                                                    <p><b>AWP: </b>{this.state.detail.awp}</p>
                                                    <div className="divider" />
                                                </div>
                                                <div className="row">
                                                    <p><b>Date Created: </b>{moment(this.state.detail.dateCreated).format('MM/DD/YYYY')}</p>
                                                    <p><b>Last Updated: </b>{this.state.detail.lastUpdated ? moment(this.state.detail.lastUpdated).format('MM/DD/YYYY') : '-'}</p>
                                                    <p><b>Last Updated By: </b>{this.state.detail.lastUpdatedBy.id ? `${this.state.detail.lastUpdatedBy.firstName} ${this.state.detail.lastUpdatedBy.lastName}` : '-'}</p>
                                                    <div className="divider" />
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 m4 l2">
                                                        <Link to={`/redbook/${this.id}/update`} className="btn-small orange white-text waves-effect col s12">Update Med</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12">
                                        <div className="card">
                                            <div className="card-content">
                                                <div className="row">
                                                    <h5><Link to={`/manufacturer/${this.state.detail.manufacturer.id}`}>{this.state.detail.manufacturer.name}</Link></h5>
                                                    <div className="divider" />
                                                </div>
                                                <div className="row" style={{ overflow: 'auto' }}>
                                                    <table className="highlight">
                                                        <thead>
                                                            <tr>
                                                                <th>Returnable</th>
                                                                <th>Sealed Only</th>
                                                                <th>Return Window</th>
                                                                <th>Labels</th>
                                                                <th>Controls</th>
                                                                <th>Min Package Percent</th>
                                                                {/* <th>Actions</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.detail.manufacturer.returnPolicies && this.state.detail.manufacturer.returnPolicies.map((rp, index) => (
                                                                <tr key={`rp-${rp.id}`} className={parseInt(rp.id) === parseInt(this.state.detail.returnPolicyId) ? 'blue white-text' : ''}>
                                                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{rp.isReturnable ? 'Yes' : 'No'}</td>
                                                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{rp.sealedPackagesOnly ? 'Yes' : 'No'}</td>
                                                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{rp.earliestReturnInMonths} - {rp.latestReturnInMonths}</td>
                                                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{rp.labelsAllowed ? 'Yes' : 'No'}</td>
                                                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{rp.controlsAllowed ? 'Yes' : 'No'}</td>
                                                                    <td style={{ padding: '2px', borderRadius: '0px' }}>{rp.minPackagePercentage || '-'}</td>
                                                                    {/* <td>{this.state.detail.returnPolicyId === rp.id ?
                                                                        <a href="/" onClick={this.removeReturnPolicy}>Remove As Med Default</a>
                                                                        :
                                                                        <a href="/" onClick={e => this.setReturnPolicy(e, index)}>Set As Med Default</a>
                                                                    }</td> */}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

export default connect()(RedbookDetail);