import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import M from '@materializecss/materialize';
import moment from 'moment';


class CustomerListView extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.state = this.loadState();
    }

    initState = () => ({
        allPharmacies: null,
        filteredPharmacies: null,
        pharmacy: null,
        searchFilter: '',
        orders: null,
        transactions: null,
        loadingLineItems: false,
        loadingOrders: false,
        orderHeaderId: -1,
        preloaded: false,
        loading: false,
        activeOrderNumber: ''
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        if (!this.state.preloaded)
            this.getPharmacies();
        M.Collapsible.init(document.querySelectorAll('.collapsible'))
    }

    componentWillUnmount = () => {
        this.saveState();
    }

    loadState = () => this.props.loadedState ? { ...this.props.loadedState, preloaded: true } : this.initState();

    saveState = () => {
        const state = JSON.parse(JSON.stringify(this.state));
        this.props.dispatch({
            type: 'SAVE_STATE',
            payload: {
                url: this.props.match.path,
                state
            }
        })
    }

    refresh = () => this.setState(this.initState(), this.getPharmacies);

    networkCalls = {
        getPharmacies: {
            func: () => {
                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/pharmacy/read/all/with/balance', { params: this.authData })
                        .then(result => {
                            const p = result.data?.sort((a, b) => a.name > b.name ? 1 : -1);
                            this.setState({ allPharmacies: p, filteredPharmacies: p })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        pharmacyClick: {
            func: p => {
                if (p.id === (this.state.pharmacy?.id || -1)) { return }

                this.setState({ pharmacy: p, orders: null, transactions: null, loadingOrders: true }, () => {
                    Axios.get('/api/v1/order/header/read/all/by/pharmacyid', {
                        params: {
                            ...this.authData,
                            pharmacyId: p.id
                        }
                    })
                        .then(result => {
                            this.setState({ orders: result.data }, () => {
                                if (result.data.length) {
                                    this.getOrderInfo(result.data[0].id)
                                }
                            })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loadingOrders: false }, () => {
                            M.FormSelect.init(document.querySelectorAll('select'));
                        }))
                })
            },
            type: 'r'
        },
        getOrderInfo: {
            func: id => {
                this.setState({ loadingLineItems: true, orderHeaderId: id }, () => {
                    const activeOrderNumber = this.state.orders.filter(o => o.id === id)[0]?.orderNumber;
                    Axios.get('/api/v1/invoice/read/one/with/transactions', {
                        params: {
                            ...this.authData,
                            id: id
                        }
                    })
                        .then(result => {
                            const transactions = result.data.sort((a, b) => a.date > b.date ? 1 : -1);
                            this.setState({ transactions, activeOrderNumber })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loadingLineItems: false }))
                })
            },
            type: 'r',
        },
        printCreditSummary: {
            func: includeInvoice => {
                Axios.get('/api/v1/invoice/print/creditsummary', {
                    params: {
                        ...this.authData,
                        orderHeaderId: this.state.orderHeaderId,
                        invoiceId: parseInt(this.state.transactions.filter(t => t.type === 'INVOICE')[0]?.info),
                        includeInvoice
                    },
                    responseType: 'blob'
                })
                    .then(result => {
                        const orderNumber = this.state.orders.filter(o => o.id === parseInt(this.state.orderHeaderId))[0]?.orderNumber;

                        const blob = new Blob([result.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.hidden = true;
                        link.href = url;
                        link.setAttribute('download', `${orderNumber}_credit_summary.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch(logOut);
            },
            type: 'r',
        }
    }

    getPharmacies = () => this.networkCalls.getPharmacies();
    pharmacyClick = p => this.networkCalls.pharmacyClick(p);
    getOrderInfo = id => this.networkCalls.getOrderInfo(id);
    printCreditSummary = (e, includeInvoice) => { e?.preventDefault(); this.networkCalls.printCreditSummary(includeInvoice) }

    filterPharmacies = e => {
        if (!e.target.value)
            return this.setState(p => ({ filteredPharmacies: p.allPharmacies, searchFilter: '' }))

        const pharmacies = JSON.parse(JSON.stringify(this.state.allPharmacies));
        const filteredPharmacies = pharmacies.filter(p => p.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
        this.setState({ searchFilter: e.target.value, filteredPharmacies })
    }

    getLineItemsTotal = () => {
        const transactions = JSON.parse(JSON.stringify(this.state.transactions));
        let sum = 0;
        transactions?.forEach(t => {
            switch (t.type) {
                case 'INVOICE':
                    sum += t.amount;
                    break;
                case 'CHECK':
                    sum += t.amount;
                    break;
                case 'CREDIT MEMO':
                    sum -= t.amount;
                    break;
                case 'PAYMENT RECEIVED':
                    sum -= t.amount;
                    break;
                default:
                    break;
            }
        })
        return sum.toFixed(2);
    }

    getOrderHeaderNumber = () => {
        const arr = this.state.orders.filter(o => parseInt(o.id) === parseInt(this.state.orderHeaderId))
        const orderNumber = arr[0]?.orderNumber;
        return orderNumber;
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div className="col s12 m3" style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Customers</span>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m4 l3">
                    <div className="card" style={{ overflowY: 'auto', maxHeight: '90vh' }}>
                        <div className="card-content">
                            <div className="row">
                                <div className="input-field col s9">
                                    <input id="searchFilter" type="text" onChange={this.filterPharmacies} value={this.state.searchFilter} />
                                    <label htmlFor="filterPharmacies">Search</label>
                                </div>
                                {this.state.preloaded && (
                                    <div className="input-field col s3">
                                        <i
                                            className="material-icons grey-text text-darken-1"
                                            style={{ position: 'relative', top: '24px', left: '40px', cursor: 'pointer' }}
                                            onClick={this.refresh}
                                        >refresh</i>
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <ul className="collapsible">
                                    {this.state.filteredPharmacies && this.state.filteredPharmacies.map(p => (
                                        <li key={`li-${p.id}`} onClick={() => this.pharmacyClick(p)}>
                                            <div className={`collapsible-header ${p.id === this.state.pharmacy?.id ? 'blue white-text' : ''}`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '4px' }}>
                                                <span>{p.name}</span>
                                                <span>{p.balance?.toFixed(2)}</span>
                                            </div>
                                            <div className="collapsible-body">
                                                <table className="highlight">
                                                    <tbody>
                                                        <tr style={{ border: 'none' }}>
                                                            <td style={{ padding: '2px' }}>Invoices</td>
                                                            <td style={{ padding: '2px' }}>+</td>
                                                            <td style={{ padding: '2px' }}>{p.invoiceTotal?.toFixed(2)}</td>
                                                        </tr>
                                                        <tr style={{ border: 'none' }}>
                                                            <td style={{ padding: '2px' }}>Checks Issued</td>
                                                            <td style={{ padding: '2px' }}>+</td>
                                                            <td style={{ padding: '2px' }}>{p.customerPaymentTotal?.toFixed(2)}</td>
                                                        </tr>
                                                        <tr style={{ border: 'none' }}>
                                                            <td style={{ padding: '2px' }}>Payments Received</td>
                                                            <td style={{ padding: '2px' }}>-</td>
                                                            <td style={{ padding: '2px' }}>{p.invoicePaymentTotal?.toFixed(2)}</td>
                                                        </tr>
                                                        <tr style={{ borderBottom: 'black 2px solid' }}>
                                                            <td style={{ padding: '2px' }}>Credits</td>
                                                            <td style={{ padding: '2px' }}>-</td>
                                                            <td style={{ padding: '2px' }}>{p.creditMemoTotal?.toFixed(2)}</td>
                                                        </tr>
                                                        <tr className={p.balance < 0 ? 'green-text' : ''} style={{ border: 'none' }}>
                                                            <td style={{ padding: '2px' }}>Total</td>
                                                            <td style={{ padding: '2px' }}>{p.balance < 0 ? '-' : '+'}</td>
                                                            <td style={{ padding: '2px' }}>{Math.abs(p.balance)?.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col s12 m8 l9">
                    <div className="card">
                        <div className="card-content">
                            {this.state.pharmacy && (
                                <div>
                                    <div className="row">
                                        <div className="col s12 m6">
                                            <h5>{this.state.pharmacy.name}</h5>
                                        </div>
                                        <div className="col s6 offset-s6 m2 offset-m4">
                                            <ul>
                                                <li><Link to={`/pharmacy/${this.state.pharmacy.id}/invoicetemplate`}>Edit Invoice Template</Link></li>
                                                <li><Link to={`/pharmacy/${this.state.pharmacy.id}/accounts`}>Edit Wholesaler Accounts</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m6 l4">
                                            <div style={{ display: 'flex' }}>
                                                <b style={{ flex: 2 }}>Bill To:</b>
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.name}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ flex: 2 }}></p>
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.addressLineOne}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ flex: 2 }} />
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.addressLineTwo}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ flex: 2 }} />
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.city}, {this.state.pharmacy.state} {this.state.pharmacy.zip}</p>
                                            </div>
                                        </div>
                                        <div className="col s12 m6 l4 offset-l4">
                                            <div style={{ display: 'flex' }}>
                                                <b style={{ flex: 2 }}>Customer #: </b>
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.customerNumber}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <b style={{ flex: 2 }}>DEA #: </b>
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.deaNumber}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <b style={{ flex: 2 }}>Phone:</b>
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.phone}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <b style={{ flex: 2 }}>Email:</b>
                                                <p style={{ flex: 6 }}>{this.state.pharmacy.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.loadingOrders ?
                                            <span className="orange-text">Loading Orders</span>
                                            :
                                            <div className="input-field col s12 m4 l3">
                                                <select id="orderNumber" onChange={e => this.getOrderInfo(e.target.value)}>
                                                    {this.state.orders && this.state.orders.map(o => (
                                                        <option key={`orderNumber-${o.id}`} value={o.id}>{o.orderNumber}&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{moment(o.orderDate).format('MM/DD/YYYY')}</option>
                                                    ))}
                                                </select>
                                                <label htmlFor="orderNumber">Order Number</label>
                                            </div>
                                        }
                                        {!this.state.loadingOrders && <div className="col s4 m2"><Link to={{ pathname: '/accounting/creditdetail', state: { orderNumber: this.state.activeOrderNumber } }}>Credit Detail</Link></div>}
                                        {!this.state.loadingOrders && <div className="col s4 m2"><Link to={`/regular/${this.state.orderHeaderId}`}>Go To Order</Link></div>}
                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <div className="card">
                                                <div className="card-content">
                                                    {this.state.loadingLineItems ?
                                                        <div className="progress white">
                                                            <div className="indeterminate blue" />
                                                        </div>
                                                        :
                                                        <table className="highlight">
                                                            <thead>
                                                                <tr>
                                                                    <th>Type</th>
                                                                    <th>Info</th>
                                                                    <th>Date</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.transactions && this.state.transactions.map((t, index) => {
                                                                    if (t.type === 'INVOICE') {
                                                                        return (
                                                                            <tr key={`${t.type}-${index}`}>
                                                                                <td style={{ padding: '2px' }}><Link to={`/invoice/update/${t.id}`}>{t.type}</Link></td>
                                                                                <td style={{ padding: '2px' }}>{t.info}</td>
                                                                                <td style={{ padding: '2px' }}>{moment(t.date).format('MM/DD/YYYY')}</td>
                                                                                <td style={{ padding: '2px', textAlign: 'right' }}>{`${t.type === 'CREDIT MEMO' || t.type === 'PAYMENT RECEIVED' ? '-' : ''} ${t.amount?.toFixed(2)}`}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <tr key={`${t.type}-${index}`}>
                                                                                <td style={{ padding: '2px' }}>{t.type}</td>
                                                                                <td style={{ padding: '2px' }}>{t.info}</td>
                                                                                <td style={{ padding: '2px' }}>{moment(t.date).format('MM/DD/YYYY')}</td>
                                                                                <td style={{ padding: '2px', textAlign: 'right' }}>{`${t.type === 'CREDIT MEMO' || t.type === 'PAYMENT RECEIVED' ? '-' : ''} ${t.amount?.toFixed(2)}`}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                                <tr style={{ borderTop: '2px solid black' }}>
                                                                    <td style={{ padding: '2px' }}></td>
                                                                    <td style={{ padding: '2px' }}></td>
                                                                    <td style={{ padding: '2px' }}></td>
                                                                    <td style={{ padding: '2px', textAlign: 'right' }}><b>{this.getLineItemsTotal()}</b></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.orders && this.state.orders.length && (
                                        <div className="row">
                                            <div className="col s12 m4 right">
                                                <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={e => this.printCreditSummary(e, false)}>Print Credit Summary</a>
                                            </div>
                                            {(this.state.transactions && this.state.transactions.filter(t => t.type === 'INVOICE').length) ?
                                                <div className="col s12 m4 right">
                                                    <a href="/" className="btn-small teal white-text waves-effect waves-light col s12" onClick={e => this.printCreditSummary(e, true)}>Print Credit Summary & Invoice</a>
                                                </div>
                                                : null}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const loadedState = state.stateByPage['/accounting'] || null;
    return { loadedState };
}

export default connect(mapStateToProps)(CustomerListView);