import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut, openSideNav, registerRoutes } from '../../func';
import M from '@materializecss/materialize';

class OutboundCheckOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(localStorage.getItem('auth_data'));
        this.employeeData = JSON.parse(localStorage.getItem('employee_data'));
        this.state = this.initState();
    }

    initState = () => ({
        pharmacyName: '',
        checkNumber: '',
        startDate: '',
        endDate: '',
        listOfChecks: null
    })

    componentDidMount = () => {
        this.networkCalls = registerRoutes(this.networkCalls, this.props.match.path);
        M.Modal.init(document.querySelectorAll('.modal'));
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    initListeners = () => {
        document.querySelectorAll('.search-parameter').forEach(el => el.addEventListener('keyup', this.enterListener));
    }

    removeListeners = () => {
        document.querySelectorAll('.search-parameter').forEach(el => {
            if (el)
                el.removeEventListener('keyup', this.enterListener);
        })
    }

    enterListener = e => {
        if (e.keyCode === 13)
            this.search();
    }

    networkCalls = {
        search: {
            func: () => {
                let {
                    pharmacyName,
                    checkNumber,
                    startDate,
                    endDate
                } = this.state;

                this.setState({ loading: true }, () => {
                    Axios.get('/api/v1/outbound/check/read/search', {
                        params: {
                            ...this.authData,
                            pharmacyName,
                            checkNumber,
                            startDate: startDate ? startDate : '2000-01-01',
                            endDate: endDate ? endDate : '2050-12-31'
                        }
                    })
                        .then(result => {
                            if (!result.data.length)
                                return this.props.dispatch({ type: 'GLOBAL_TOAST', payload: { msg: 'No Results', class: 'orange white-text' } })

                            this.setState({ listOfChecks: result.data })
                        })
                        .catch(logOut)
                        .finally(() => this.setState({ loading: false }))
                })
            },
            type: 'r'
        },
        clearCheck: {
            func: index => {
                const check = this.state.listOfChecks[index];
                let dateCleared = window.prompt('When did the check clear? (MM/DD/YYYY)');
                dateCleared = moment(dateCleared);

                if (dateCleared.isValid())
                    Axios.post('/api/v1/outbound/check/update/clear/one', {
                        ...this.authData,
                        id: check.id,
                        dateCleared: dateCleared.format('YYYY-MM-DD')
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.search();
                        })
                        .catch(logOut)

            },
            type: 'u'
        },
        cancelCheck: {
            func: index => {
                const check = this.state.listOfChecks[index];
                if (window.confirm(`Are you Sure you want to cancel check ${check.checkNumber} to ${check.pharmacyName} for $${check.details.reduce((a, b) => a + parseFloat(b.amount), 0).toFixed(2)}?`))
                    Axios.post('/api/v1/outbound/check/update/cancel/one', {
                        ...this.authData,
                        id: check.id
                    })
                        .then(result => {
                            this.props.dispatch(result.data);
                            this.search();
                        })
                        .catch(logOut)
            },
            type: 'u'
        }
    }

    search = e => { e?.preventDefault(); this.networkCalls.search(); }
    clearCheck = (e, index) => { e?.preventDefault(); this.networkCalls.clearCheck(index); }
    cancelCheck = (e, index) => { e?.preventDefault(); this.networkCalls.cancelCheck(index); }

    onChange = e => this.setState({ [e.target.id]: e.target.value })

    getLineColor = c => {
        if (c.dateCleared)
            return 'green-text text-darken-2'
        else if (c.cancellation.id)
            return 'red-text';
        else
            return '';
    }

    render = () => (
        <div className="main">
            <div className="row">
                <div className="col s12 m3" style={{ display: 'flex' }}>
                    <Link to="/" onClick={openSideNav} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Home</Link>
                    <i className="material-icons">chevron_right</i>
                    <Link to="/accounting">Accounting</Link>
                    <i className="material-icons">chevron_right</i>
                    <span className="grey-text">Check Overview</span>
                </div>
            </div>
            <div className="row">
                <h5 className="center">Search Checks</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m4 l2">
                    <input id="pharmacyName" className="search-parameter" type="text" onChange={this.onChange} value={this.state.pharmacyName} />
                    <label htmlFor="pharmacyName">Pharmacy</label>
                </div>
                <div className="input-field col s12 m4 l2">
                    <input id="checkNumber" className="search-parameter" type="text" onChange={this.onChange} value={this.state.checkNumber} />
                    <label htmlFor="checkNumber">Check Number</label>
                </div>
                <div className="input-field col s12 m4 l2">
                    <input id="startDate" className="search-parameter" type="date" onChange={this.onChange} value={this.state.startDate} />
                    <label htmlFor="startDate">Date Issued Start</label>
                </div>
                <div className="input-field col s12 m4 l2">
                    <input id="endDate" className="search-parameter" type="date" onChange={this.onChange} value={this.state.endDate} />
                    <label htmlFor="endDate">Date Issued End</label>
                </div>
                <div className="input-field col s12 m4 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.search}>Search</a>
                </div>
            </div>
            {this.state.loading ?
                <div className="progress">
                    <div className="indeterminate blue" />
                </div>
                :
                <div className="row">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th>Pharmacy</th>
                                <th>Check Number</th>
                                <th>Date Issued</th>
                                <th>Date Cleared</th>
                                <th>Date Cancelled</th>
                                <th>Amount</th>
                                <th>Clear/Cancel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.listOfChecks?.map((c, index) => (
                                <tr className={this.getLineColor(c)} key={c.id}>
                                    <td style={{ padding: '2px' }}>{c.pharmacyName}</td>
                                    <td style={{ padding: '2px' }}>{c.checkNumber}</td>
                                    <td style={{ padding: '2px' }}>{moment(c.dateIssued).format('MM/DD/YYYY')}</td>
                                    <td style={{ padding: '2px' }}>{c.dateCleared ? moment(c.dateCleared).format('MM/DD/YYYY') : '-'}</td>
                                    <td style={{ padding: '2px' }}>{c.cancellation.id ? moment(c.cancellation.dateCancelled).format('MM/DD/YYYY') : '-'}</td>
                                    <td style={{ padding: '2px' }}>{c.details.reduce((a, b) => a + parseFloat(b.amount), 0).toFixed(2)}</td>
                                    <td style={{ padding: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        {!c.dateCleared && !c.cancellation.id ?
                                            <React.Fragment>
                                                <a href="/" onClick={e => this.clearCheck(e, index)}>Clear</a>
                                                <a href="/" onClick={e => this.cancelCheck(e, index)}>Cancel</a>
                                            </React.Fragment>
                                            :
                                            null
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            <div id="clear-modal" className="modal">
                <div className="modal-content">

                </div>
            </div>
        </div>
    )
}

export default connect()(OutboundCheckOverview);