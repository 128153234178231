import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import M from '@materializecss/materialize';

class PageNotFound extends React.Component {
    render = () => (
        <div className="main">
            <div className="row">
                <div style={{ display: 'flex' }}>
                    <Link to="/" onClick={e => { e.preventDefault(); M.Sidenav.getInstance(document.querySelector('#side-navigation')).open() }} style={{ marginRight: '12px' }}><i className="material-icons black-text">menu</i></Link>
                    <Link to="/">Go Home</Link>
                </div>
            </div>
            <div className="container">
                <h1>404 Page Not Found</h1>
            </div>
        </div>
    )
}

export default connect()(PageNotFound);